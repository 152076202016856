import { fixGroupedItems } from '../utils/HelperFunctions'
import { 
    updateSkillPut, addSkillPost, deleteSkillDelete, skillsGet, studentSkillsGet, activateSkillPost 
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { NavigatorService, showSuccess } from '../services/SharedServices'
import ActionTypes from './ActionTypes'

/* Skills start */

export async function addUpdateSkillFetch(skill, dispatch, goBack) {
    const fetchPromise = skill.id 
        ? updateSkillPut(skill) 
        : addSkillPost(skill)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(skill.id ? updateSkill(value) : addSkill(value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function deleteSkillFetch(id, dispatch, goBack) {
    const fetchPromise = deleteSkillDelete(id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(deleteSkill(value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function changeSkillIsActiveFetch(skill, dispatch, goBack) {
    const fetchPromise = activateSkillPost(skill.id)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setSkillIsActive(skill.id, skill.groupId, responseData.value))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function loadSkillsFetch(groupIds, teacherId, isActive, dispatch) {
    const fetchPromise = skillsGet(groupIds, teacherId, isActive)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setSkills(responseData.items, groupIds))
    return responseData
}

export async function loadStudentSkillsFetch(isTeacher, groupIds, teacherId, studentId, dispatch) {
    const fetchPromise = studentSkillsGet(studentId, groupIds, teacherId, true)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        // dispatch(addOrUpdateSkills(responseData.items, groupIds)) TODO: do we need this?
        const studentItems = fixGroupedItems(responseData, 'skillId')
        dispatch(setStudentSkills(isTeacher ? studentId : teacherId, studentItems, groupIds))
    }
    return responseData
}

/* Skills end */

/* Skills redux start */

export function addSkill(skill) {
    return { type: ActionTypes.TEACHER_ADD_SKILL_ACTION, skill }
}

export function updateSkill(skill) {
    return { type: ActionTypes.TEACHER_UPDATE_SKILL_ACTION, skill }
}

export function deleteSkill(skill) {
    return { type: ActionTypes.TEACHER_DELETE_SKILL_ACTION, skill } 
}

export function setSkillIsActive(id, groupId, isActive) {
    return { type: ActionTypes.TEACHER_SKILL_CHANGE_IS_ACTIVE_ACTION, id, isActive, groupId }
}

export function setSkills(skills, groupIds) {
    return { type: ActionTypes.TEACHER_SET_SKILLS_ACTION, skills, groupIds }
}

export function setStudentSkills(userId, skills, groupIds) {
    return { type: ActionTypes.SET_STUDENT_SKILLS, userId, skills, groupIds }
}

export function addOrUpdateSkills(skills, groupIds) {
    return { type: ActionTypes.TEACHER_ADD_OR_UPDATE_SKILLS_ACTION, skills, groupIds }
}

/* Skills redux end */