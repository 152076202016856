import React from 'react'

export const TeacherProfileConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/profile',
            component: React.lazy(() => import('./TeacherProfile')),
            applicationPath: 'TeacherProfileEdit',
        }
    ]
}