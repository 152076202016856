async function getCurrentToken() {
    return undefined
}

function removeNotification(_notification) {
}

function subscribeToTopic(topic) {
}

function unsubscribeFromTopic(topic) {
}

function displayLocalNotification(notification, channelId) {
}

async function askForPermission() {
    return false
}

async function getInitialNotification() {
    return undefined
}

async function createChannel(channelId, channelName) {
    return undefined
}

// if application is in foreground or background and notification has been received
function onNotification(handler) {
}

// notification is opened and app is in foreground or background
function onNotificationOpened(handler) {
}

// on fcm token refresh
function onTokenRefresh(handler) {
}

export default {
    getCurrentToken,
    removeNotification,
    subscribeToTopic,
    unsubscribeFromTopic,
    displayLocalNotification,
    askForPermission,
    getInitialNotification,
    createChannel,
    onNotification,
    onNotificationOpened,
    onTokenRefresh,
}