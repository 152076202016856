import ActionTypes from './ActionTypes'
import { emoticonsGet } from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'

export async function loadEmoticonsFetch(dispatch) {
    const fetchPromise = emoticonsGet()
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setEmoticons(responseData.items))
    return responseData
}

function setEmoticons(emoticons) {
    return { type: ActionTypes.SET_EMOTICONS_ACTION, emoticons }
}