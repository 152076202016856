import { texti18n, Messages } from './MessageConstants'
import { SUPPORTED_IMAGE_MIME_TYPES } from './ImageConstants'
import { compareDatesByDay } from './TimeHelperFunctions'
import { LinksHelper } from './LinkRegexHelper'
import { SUPPORTED_FILE_TYPES } from './FileConstants'
import { getFirstLastName } from 'dpn-common/utils/UserHelper'

export function isInteger(str, func = () => true) {
    const n = Math.floor(Number(str))
    return n !== Infinity && String(n) == str && func(n)
}

export function isEmail(str) {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return emailRegex.test(String(str).toLowerCase())
}

export function isUsername(str) {
    const usernameRegex = /^[a-zA-Z0-9.\-_]+$/
    return usernameRegex.test(String(str))
}

export function isFullName(value) {
    const { firstName, lastName } = getFirstLastName(value)
    return firstName.length > 0 && lastName.length > 0
}

let formValidatorsCache

export default function formValidators() {
    if (formValidatorsCache) {
        return formValidatorsCache
    }

    formValidatorsCache = {
        email: {
            func: value => !value || isEmail(value),
            message: texti18n(Messages.INVALID_EMAIL),
        },
        requiredEmail: {
            func: value => !!value && isEmail(value),
            message: texti18n(Messages.INVALID_EMAIL),
        },
        username: {
            func: value => !value || isUsername(value),
            message: texti18n(Messages.USERNAME_CONTAINS_LETTERS_NUMBERS),
        },
        requiredUsername: {
            func: value => !!value && isUsername(value),
            message: texti18n(Messages.USERNAME_CONTAINS_LETTERS_NUMBERS),
        },
        emailOrUsername: {
            func: value => !value || isUsername(value) || isEmail(value),
            message: texti18n(Messages.INVALID_EMAIL_OR_USERNAME),
        },
        required: {
            func: value => !!value,
            message: texti18n(Messages.FIELD_IS_REQUIRED),
        },
        any: { func: _ => true, message: '' },
        password: {
            func: value => !!value && String(value).length >= 6,
            message: texti18n(Messages.INVALID_PASSWORD),
        },
        passwordNotRequired: {
            func: value => !value || String(value).length >= 6,
            message: texti18n(Messages.INVALID_PASSWORD),
        },
        videoLink: {
            func: value => {
                const linkRegex = LinksHelper.getLinkRegex()
                const externalAppLinkRegex = LinksHelper.getExternalAppLinkRegex()
                return value.every(x => !!x && (x.match(linkRegex) || x.match(externalAppLinkRegex)))
            },
            message: Messages.INVALID_FIELD,
        },
        requiredPositiveInt: {
            func: value => !!value && isInteger(value, x => x > 0),
            message: Messages.INVALID_FIELD,
        },
        pdfImage: {
            func: value => value.newFiles.every(x => x.type == SUPPORTED_FILE_TYPES.PDF || Object.values(SUPPORTED_IMAGE_MIME_TYPES).includes(x.type)),
            message: Messages.INVALID_FIELD,
        },
        pdfImageMid: {
            func: value => value.newFiles.every(x => x.type == SUPPORTED_FILE_TYPES.PDF || x.type == SUPPORTED_FILE_TYPES.MID || x.type == SUPPORTED_FILE_TYPES.MIDI || Object.values(SUPPORTED_IMAGE_MIME_TYPES).includes(x.type)),
            message: Messages.INVALID_FIELD,
        },
        pdfImageAudioMid: {
            func: value => value.newFiles.every(x => [...(Object.values(SUPPORTED_FILE_TYPES)), ...(Object.values(SUPPORTED_IMAGE_MIME_TYPES))].includes(x.type)),
            message: Messages.INVALID_FIELD,
        },
        endDate: [
            {
                func: value => !!value,
                message: texti18n(Messages.FIELD_IS_REQUIRED),
            },
            {
                message: Messages.START_END_DATE_VALIDATION,
                func: (value, values, errors) => {
                    return !!errors['startDate'] || compareDatesByDay(values['startDate'], value)
                }
            }
        ],
        endTime: [
            {
                func: value => !!value[0].fromTimeInMinutes && !!value[0].toTimeInMinutes,
                message: texti18n(Messages.FIELD_IS_REQUIRED),
            },
            {
                func: value => value[0].fromTimeInMinutes < value[0].toTimeInMinutes,
                message: Messages.START_END_TIME_VALIDATION,
            }
        ],
        groupSchedules: [
            {
                func: value => value.every(x => (!x.fromTimeInMinutes && !x.toTimeInMinutes) || (x.fromTimeInMinutes < x.toTimeInMinutes)),
                message: Messages.START_END_TIME_VALIDATION,
            }
        ],
        acceptPrivacyPolicyAndToU: {
            func: value => !!value,
            message: texti18n(Messages.YOU_MUST_ACCEPT_PRIVACY_POLICY_AND_TOU),
        },
        fullName: {
            func: value => isFullName(value),
            message: texti18n(Messages.INVALID_FULL_NAME),
        },
        notRequiredFullName: {
            func: value => !value || isFullName(value),
            message: texti18n(Messages.INVALID_FULL_NAME),
        }
    }

    return formValidatorsCache
}