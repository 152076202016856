import { StudentsConfig } from './students/StudentsConfig'
import { TeacherProfileConfig } from './profile/TeacherProfileConfig'
import { TeacherSentencesConfig } from './presets/TeacherSentencesConfig'
// import { TeacherAssignLevelsConfig } from './presets/TeacherAssignLevelsConfig'
import { TeacherVideoTutorialsConfig } from './videoTutorials/TeacherVideoTutorialsConfig'
import { TeacherTutorialsConfig } from './teacherResources/TeacherTutorialsConfig'
import { TeacherSchoolYearConfig } from './schoolYears/TeacherSchoolYearConfig'
import { TeacherNotificationsConfig } from './settings/TeacherNotificationsConfig'
import { TeacherSettingsLeaderboardConfig } from './settings/TeacherSettingsLeaderboardConfig'
import { TeacherCommunicationConfig } from './chat/TeacherCommunicationConfig'
import { TeacherSkillsConfig } from './skills/TeacherSkillsConfig'
import { TeacherRewardsConfig } from './rewards/TeacherRewardsConfig'
import { TeacherAssignmentsConfig } from './assignments/TeacherAssignmentsConfig'
import { TeacherStudentInfoConfig } from './studentInfo/TeacherStudentInfoConfig'
import { TeacherGroupInfoConfig } from './groups/TeacherGroupInfoConfig'
import { GroupsConfig } from './students/GroupsConfig'
import { TeacherPianoAdventuresConfig } from './pianoAdventures/TeacherPianoAdventuresConfig'
import { TeacherLessonsConfig } from './lessons/TeacherLessonsConfig'
import { TeacherTemplateNotesConfig } from './presets/TeacherTemplateNotesConfig'
import { TeacherSupportConfig } from './support/TeacherSupportConfig'

export const teacherConfigs = [
    StudentsConfig,
    GroupsConfig,
    TeacherGroupInfoConfig,
    TeacherProfileConfig,
    TeacherSentencesConfig,
    // TeacherAssignLevelsConfig,
    TeacherVideoTutorialsConfig,
    TeacherTutorialsConfig,
    TeacherSchoolYearConfig,
    TeacherNotificationsConfig,
    TeacherSettingsLeaderboardConfig,
    TeacherCommunicationConfig,
    TeacherSkillsConfig,
    TeacherRewardsConfig,
    TeacherAssignmentsConfig,
    TeacherStudentInfoConfig,
    TeacherPianoAdventuresConfig,
    TeacherLessonsConfig,
    TeacherTemplateNotesConfig,
    TeacherSupportConfig,
]