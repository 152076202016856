import React from 'react'
import { playVideo } from 'dpn-common/utils/VideoUtils/VideoLinkUtils'
import LinkWithImage from './LinkWithImage'
import ImageComponent from '../General/ImageComponent'
import TextComponent from '../General/TextComponent'
import LinkComponent from 'components/General/LinkComponent'
import FetchService from 'dpn-common/services/FetchService'
import { ExternalAppLinkComponent } from 'components/General/ExternalAppComponent'

function getUrlText(index, link, onPressLink) {
    return (
        <LinkComponent
            className={'break-word mypa-url-link'}
            target='_blank'
            href={link}
            key={`link_${link}_${index}`}
            onClick={() => onPressLink ? onPressLink(link) : playVideo(link)}>
            {link}
        </LinkComponent>
    )
}

function renderLinkWithImage(i, link, openMenu) {
    return (
        <LinkWithImage
            key={`linkImage_${link}_${i}`}
            index={i}
            link={link}
            onContextMenu={openMenu}
        />
    )
}

function renderExternalAppLink(i, link, _onPressLink) {
    // return getUrlText(i, link, onPressLink)
    return (
        <ExternalAppLinkComponent key={`externalAppLink_${link}_${i}`} url={link} />
    )
}

function renderStringTokens(i, tokens) {
    if (Array.isArray(tokens)) {
        return (
            <div
                style={{ flexDirection: 'row' }}
                key={`container_${i}`}>
                {tokens.map(x => x)}
            </div>
        )
    }
    return (
        <TextComponent
            className={'break-word'}
            key={`msg_${i}`}>
            {tokens}
        </TextComponent>
    )
}

function renderEmoticon(index, url) {
    const imageUrl = FetchService.staticUrl(url)
    return (
        <div key={`emoticon_${url}_${index}`} style={{ width: '100%', overflowY: 'auto' }}>
            <ImageComponent
                style={{ height: 150 }}
                src={imageUrl}
            />
        </div>
    )
}

function renderTokens(tokenizer) {
    return (
        <div>
            {tokenizer.map(x => x)}
        </div>
    )
}

export default {
    getUrlText,
    renderLinkWithImage,
    renderStringTokens,
    renderEmoticon,
    renderTokens,
    renderExternalAppLink
}