let beforePlayCallback
let onExitCallback
let VideoPlayerService

const vimeoBaseUrl = 'https://player.vimeo.com/video/'
const youTubeBaseUrl = 'https://www.youtube.com/embed/'

function init(videoPlayerService, beforePlay = null, onExit = null) {
    VideoPlayerService = videoPlayerService
    if (beforePlayCallback === undefined && onExitCallback === undefined) {
        beforePlayCallback = beforePlay
        onExitCallback = onExit
    }
}

function playVimeoVideo(link) {
    const startIndex = link.lastIndexOf('/') + 1
    VideoPlayerService.playVimeoVideo(link.substring(startIndex))
}

function playYouTubeVideo(link) {
    const videoId = getYouTubeVideoId(link)
    VideoPlayerService.playYouTubeVideo(videoId, beforePlayCallback, onExitCallback)
}

export function isVimeoLink(link) {
    const vimeoSearchString = 'vimeo.com'
    return link.includes(vimeoSearchString)
}

export function isYouTubeLink(link) {
    for (let i = 0; i < searchStrings.length; ++i) {
        if (link.indexOf(searchStrings[i].searchString) !== -1) {
            return true
        }
    }
    return false
}

export function getEmbedLink(link) {
    if (isYouTubeLink(link)) {
        const videoId = getYouTubeVideoId(link)
        if (videoId !== '') {
            return `${youTubeBaseUrl}${videoId}`
        }
    }
    else if (isVimeoLink(link)) {
        const videoId = getVimeoVideoId(link)
        return `${vimeoBaseUrl}${videoId}`
    }
    // if not vimeo and not youTube or its invalid you tube or vimeo link just return it
    return link
}

function beforePlay() {
    beforePlayCallback && beforePlayCallback()
}

function onExit() {
    onExitCallback && onExitCallback()
}

export default {
    init,
    playVimeoVideo,
    playYouTubeVideo,
    beforePlay,
    onExit,
}

const searchStrings = Object.freeze([
    { searchString: 'youtube.', startSearchString: 'v=', endSearchString: '&' },
    { searchString: 'youtu.be', startSearchString: 'youtu.be/', endSearchString: '?' }
])

function extractVideoId(link, start, end) {
    let id = link.substring(link.indexOf(start) + start.length)
    if (id.indexOf(end) !== -1) {
        id = id.substring(0, id.indexOf(end))
    }
    return id
}

function getYouTubeVideoId(link) {
    for (let i = 0; i < searchStrings.length; ++i) {
        if (link.indexOf(searchStrings[i].searchString) !== -1) {
            return extractVideoId(link, searchStrings[i].startSearchString, searchStrings[i].endSearchString)
        }
    }
    return ''
}

export function getVimeoVideoId(link) {
    const index = link.lastIndexOf('/')
    const videoId = index !== -1 ? link.substring(index + 1) : '0'
    return parseInt(videoId, 10)
}