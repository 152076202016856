const SharedService = {
    init: function (
        alertService, asyncStorageService,
        linkingService, messageUIService,
        navigatorService, connectivityService, 
        platformService, sentryService,
        analyticsService, firebaseService) {

        this.AlertService = alertService
        this.AsyncStorageService = asyncStorageService
        this.LinkingService = linkingService
        this.MessageUIService = messageUIService
        this.NavigatorService = navigatorService
        this.ConnectivityService = connectivityService
        this.PlatformService = platformService
        this.SentryService = sentryService
        this.AnalyticsService = analyticsService
        this.FirebaseService = firebaseService
    },
    dispose: function () {
        const items = [this.AlertService, this.AsyncStorageService, this.LinkingService, this.MessageUIService, 
            this.PlatformService, this.SentryService, this.NavigatorService, this.ConnectivityService, this.AnalyticsService, this.FirebaseService]
        items.forEach(x => !!x && !!x.dispose && x.dispose())
    },

    AlertService: undefined,
    AsyncStorageService: undefined,
    LinkingService: undefined,
    MessageUIService: undefined,
    ConnectivityService: undefined,
    PlatformService: undefined,
    SentryService: undefined,
    NavigatorService: undefined,
    AnalyticsService: undefined,
    FirebaseService: undefined,
}

export default SharedService

/* AlertService proxies */
export function showError(error) {
    SharedService.AlertService.showError(error)
}

export function showSuccess(message) {
    SharedService.AlertService.showSuccess(message)
}

export function showInfo(message) {
    SharedService.AlertService.showInfo(message)
}

export function showMessage(title, message) {
    SharedService.AlertService.showMessage(title, message)
}

/* AsyncStorageService proxies */
export function setStorageItem(key, value, callback) {
    return SharedService.AsyncStorageService.setItem(key, value, callback)
}

export function getStorageItem(key, callback) {
    return SharedService.AsyncStorageService.getItem(key, callback)
}

export function removeStorageItem(key, callback) {
    return SharedService.AsyncStorageService.removeItem(key, callback)
}

export function saveFile(blob, name) {
    return SharedService.AsyncStorageService.saveFile(blob, name)
}

/* LinkingService proxies */
export function canOpenURL(url, onError) {
    return SharedService.LinkingService.canOpenURL(url, onError)
}

export function openURL(url, onError) {
    return SharedService.LinkingService.openURL(url, onError)
}

/* MessageUIService proxies */
export function ask(message, onPressOk, cancelBtnText, confirmBtnText, confirmBtnColor) {
    SharedService.MessageUIService.ask(message, onPressOk, cancelBtnText, confirmBtnText, confirmBtnColor)
}

export function infoAlert(message) {
    SharedService.MessageUIService.infoAlert(message)
}

export function errorAlert(message) {
    SharedService.MessageUIService.errorAlert(message)
}

export function shareInviteToStudent(bodyMessage) {
    SharedService.MessageUIService.shareInviteToStudent(bodyMessage)
}

/* PlatformService proxies */
export function getOS() {
    return SharedService.PlatformService.getOS()
}

/* ConnectivityService proxies */
export function isOnline() {
    return SharedService.ConnectivityService.isOnline()
}

export function subscribeToConnectivity(listener) {
    SharedService.ConnectivityService.subscribe(listener)
}

/* FirebaseService proxies */
export function loginWithEmailAndPassword(email, password) {
    return SharedService.FirebaseService.loginWithEmailAndPassword(email, password)
}

export function loginWithGoogle() {
    return SharedService.FirebaseService.loginWithGoogle()
}

export function loginWithApple() {
    return SharedService.FirebaseService.loginWithApple()
}

export function loginWithCustomToken(customLoginToken) {
    return SharedService.FirebaseService.loginWithCustomToken(customLoginToken)
}

export function getAccessToken(firebaseUser, forceRefresh) {
    return SharedService.FirebaseService.getAccessToken(firebaseUser, forceRefresh)
}

export function getFirebaseAuth() {
    return SharedService.FirebaseService.getFirebaseAuth()
}

export function getFirebaseUser() {
    return SharedService.FirebaseService.getFirebaseUser()
}

export function logout() {
    return SharedService.FirebaseService.logout()
}

/* NavigatorService */
function addRouteChangeListener(callback) {
    return SharedService.NavigatorService.addRouteChangeListener(callback)
}

function navigate(routeName, params, key) {
    SharedService.NavigatorService.navigate(routeName, params, key)
}

function push(routeName, params) {
    SharedService.NavigatorService.push(routeName, params)
}

function goBack() {
    SharedService.NavigatorService.goBack()
}

function pop(number) {
    SharedService.NavigatorService.pop(number)
}

function setParams(params) {
    SharedService.NavigatorService.setParams(params)
}

function reset(routeNames, routeParams, routeKeys) {
    SharedService.NavigatorService.reset(routeNames, routeParams, routeKeys)
}

function navigateAndReset(routeName, params) {
    SharedService.NavigatorService.navigateAndReset(routeName, params)
}

function toggleDrawer() {
    SharedService.NavigatorService.toggleDrawer()
}

function closeDrawer() {
    SharedService.NavigatorService.closeDrawer()
}

function openDrawer() {
    SharedService.NavigatorService.openDrawer()
}

function getCurrentRoute() {
    return SharedService.NavigatorService.getCurrentRoute()
}

function stateChanged(prevState, currentState) {
    SharedService.NavigatorService.stateChanged(prevState, currentState)
}

export const NavigatorService = {
    navigate,
    reset,
    push,
    goBack,
    pop,
    setParams,
    navigateAndReset,
    toggleDrawer,
    closeDrawer,
    openDrawer,
    stateChanged,
    getCurrentRoute,
    addRouteChangeListener,
}