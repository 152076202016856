export const SUPPORTED_FILE_TYPES = Object.freeze({
    PDF: 'application/pdf',
    MPEG: 'audio/mpeg',
    FLAC: 'audio/flac',
    MID: 'audio/mid',
    MIDI: 'audio/midi'
})

export const SUPPORTED_FILE_EXTENSIONS = Object.freeze({
    PDF: 'pdf',
    MPEG: 'mpeg',
    FLAC: 'flac',
    MID: 'mid',
    MIDI: 'midi'
})

export const SUPPORTED_IMPORT_FILE_TYPES = Object.freeze({
    CSV: '.csv'
})

export const NATIVE_CSV_FORMAT = Object.freeze({
    TEXT_CSV_LONG: 'text/comma-separated-values',
    TEXT_CSV: 'text/csv',
})

export const SUPPORTED_CSV_FILE_FORMATS = Object.freeze({
    APPLICATION_CSV: 'application/csv',
    APPLICATION_VND_MS_EXCEL: 'application/vnd.ms-excel',
    TEXT_CSV: 'text/csv',
})

export const SUPPORTED_IMPORT_FILE_EXTENSIONS = Object.freeze({
    CSV: 'csv'
})
