import { Messages, texti18n } from 'dpn-common/utils/MessageConstants'
import { createSelector } from 'reselect'
import { lessonsCompareFunc } from '../reducers/LessonsReducer'
import { getFromDict } from '../utils/HelperFunctions'

const lessonsSelector = state => state.lessons
const lessonsKeyIdsSelector = state => state.keyIds
const lessonsCurrentSchoolYearSelector = state => state.schoolYearId

export const lessonsByKeySelector = createSelector(
    lessonsSelector,
    lessonsKeyIdsSelector,
    lessonsCurrentSchoolYearSelector,
    (allLessons, keyIds, schoolYearId) => {
        const lessons = []
        const unloadedGroupIds = []
        keyIds.forEach(groupId => {
            const lessonsByGroupId = getFromDict(allLessons, groupId)
            const lessonsBySchoolYearId = getFromDict(lessonsByGroupId, schoolYearId)
            if (lessonsBySchoolYearId === undefined) {
                unloadedGroupIds.push(groupId)
            }
            else {
                lessons.push(...lessonsBySchoolYearId)
            }
        })
        lessons.sort((x, y) => lessonsCompareFunc(x)(y) ? -1 : 1)
        return { lessons, unloadedGroupIds }
    }
)
    
export const currentLessons = createSelector(
    lessonsSelector,
    lessonsKeyIdsSelector,
    lessonsCurrentSchoolYearSelector,
    (allLessons, keyIds, schoolYearId) => {
        const { lessons } = lessonsByKeySelector({ lessons: allLessons, keyIds, schoolYearId })
        return lessons.filter(x => x.isActive)
    }
)

export const pastLessons = createSelector(
    lessonsSelector,
    lessonsKeyIdsSelector,
    lessonsCurrentSchoolYearSelector,
    (allLessons, keyIds, schoolYearId) => {
        const { lessons } = lessonsByKeySelector({ lessons: allLessons, keyIds, schoolYearId })
        return lessons.filter(x => !x.isActive).reverse()
    }
)

/**
 * Selector which receives state (state.lesson.lessons)
 * and then converts dictionary of dictionaries from reducer to array
 * if state.lessons is undefined or empty object return undefined
 */
export const lessonsAsList = createSelector(
    state => state.lesson.lessons,
    allLessons => {
        const lessonGroupKeys = Object.keys(allLessons || {})  
        if (!lessonGroupKeys.length) {
            return undefined
        }
        const result = lessonGroupKeys.reduce((lstFromGroups, groupId) => {
            const lessonsByGroupId = allLessons[groupId]
            const tmpResult = Object.keys(lessonsByGroupId || {}).reduce((lstFromSchoolYears, schoolYearId) => {
                const lessonsBySchoolYearId = lessonsByGroupId[schoolYearId]
                return lstFromSchoolYears.concat(lessonsBySchoolYearId || [])
            }, lstFromGroups)
            return tmpResult
        }, [])
        return result
    }
)

export const publishedLessonsSelector = createSelector(
    lessons => lessons,
    lessons => lessons.filter(x => x.isPublished)
)

export const unpublishedLessonsSelector = createSelector(
    lessons => lessons,
    lessons => lessons.filter(x => !x.isPublished)
)

export const mergedLessonsSelector = createSelector(
    state => publishedLessonsSelector(state.lessons),
    state => unpublishedLessonsSelector(state.lessons),
    (publishedLessons, unpublishedLessons) => {
        let result = [].concat(publishedLessons)
        if (unpublishedLessons.length > 0) {
            // we need to mark last item before label with skipSeparator because we do not need separator before label 
            if (result.length > 0) {
                result[result.length - 1] = { ...result[result.length - 1], skipSeparator: true }
            }
            const label = { id: 'l0', title: texti18n(Messages.UNPUBLISHED), isTextLabel: true, isPublished: false, skipSeparator: true }
            result = result.concat(label).concat(unpublishedLessons)
        }
        if (result.length > 0) {
            result[result.length - 1] = { ...result[result.length - 1], skipSeparator: true }
        }
        return result
    }
)