import { hotjar } from 'react-hotjar'
import AppConfig from 'dpn-common/config/AppConfig'
import { USER_TYPES } from 'dpn-common/utils/AppConstants'

let hotjarInitialized = false

const HOTJAR_SNIPPET_VERSION = 6

function init() {
    if (!AppConfig.hotjarEnabled || !AppConfig.hotjarSiteID) {
        return
    }

    hotjar.initialize(AppConfig.hotjarSiteID, HOTJAR_SNIPPET_VERSION)
    hotjarInitialized = true
}

function setUser(user) {
    if (!hotjarInitialized) {
        return
    }

    if (user?.id) {
        hotjar.identify(user.id, {
            email: user.email,
            userID: user.id + '',
            username: user.username,
            extra: {
                is_teacher: user.role === USER_TYPES.TEACHER
            }
        })
    }
    else {
        hotjar.identify(0, {})
    }
}

function sendEvent(event) {
    if (!hotjarInitialized) {
        return
    }

    hotjar.event(`${event}`)
}

function stateChange(newState) {
    if (!hotjarInitialized) {
        return
    }

    // for some reason needed in order for site wide tracking to begin
    sendEvent(`stateChange - ${newState}`)

    hotjar.stateChange(`${newState}`)
}

export default {
    init,
    setUser,
    sendEvent,
    stateChange,
}