import AppConfig from 'dpn-common/config/AppConfig'
import * as amplitude from '@amplitude/analytics-browser'

let amplitudeInitialized = false


const mapUrlToEventName = new Map([
    ['/students', 'View Student List'],
    ['/login', 'Login'],
    ['/logout', 'Logout'],
    ['/profile', 'View Profile'],
    ['/student-profile', 'View Profile'],
    ['/communication/chat', 'View Messages'],
    ['/student-communication/chat', 'View Messages'],
    ['/communication/channels', 'View Channels'],
    ['/student-communication/channels', 'View Channels'],
    ['/teacher-groups', 'View Group List'],
    ['/support/video-tutorials', 'View Support'],
    ['/support/student-video-tutorials', 'View Support'],
    ['/student-teachers', 'View Teacher'],
    ['/piano-adventures/assignments', 'View Library'],
    ['/piano-adventures/interactive-hub', 'View My Hub'],
    ['/piano-adventures/student-interactive-hub', 'View My Hub'],
    ['/student-settings/leaderboard', 'View Settings'],
    ['/settings/school-years', 'View Settings'],
])

function init() {
    if (!AppConfig.AmplitudeAnalyticsEnabled || !AppConfig.AmplitudeAnalyticsApiKey) {
        return
    }
    amplitude.init(AppConfig.AmplitudeAnalyticsApiKey)
    amplitudeInitialized = true
}

function setUser(user) {
    if (user) {
        const identifyObj = new amplitude.Identify()
        identifyObj.set('role', user.role)
        identifyObj.set('first_name', user.firstName)
        identifyObj.set('last_name', user.lastName)
        identifyObj.set('username', user.username)
        identifyObj.set('email', user.email)
        identifyObj.set('id', user.id)
        
        amplitude.identify(identifyObj)
        amplitude.setUserId(user?.externalId)
    }
    else {
        amplitude.reset()
    }
    
}

function sendEvent(event, eventProperties) {
    if (!amplitudeInitialized) {
        return
    }
    event = mapUrlToEventName.has(event) ? mapUrlToEventName.get(event) : event
    amplitude.track(event, eventProperties)
}

function stateChange(newState) {
    sendEvent(newState)
}

export default {
    init,
    setUser,
    sendEvent,
    stateChange,
}

