import React from 'react'

export const TeacherVideoTutorialsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/video-tutorials',
            component: React.lazy(() => import('./TeacherVideoTutorials')),
            applicationPath: 'TeacherVideoTutorials',
        },
    ]
}