import { setFcmTokenFetchNoDispatch } from '../actions/UserActions'
import NotificationActionHandler from '../utils/NotificationActionHandler'
import AppConfig from 'dpn-common/config/AppConfig'
import { ACTION_NOTIFICATION_TYPES, NOTIFICATION_TOPICS } from './NotificationsConstants'
import { USER_TYPES } from './AppConstants'
import { getUserGroupDataByGroupId } from './GroupsDataUtils'

let pendingActionData
let NotificationsService = {
    getCurrentToken: async () => undefined,
    removeNotification: _notification => undefined,
    subscribeToTopic: _topic => undefined,
    unsubscribeToTopic: _topic => undefined,
}

function init(notificationsService) {
    NotificationsService = notificationsService
}

async function sendTokenToServer(isLoggedIn, token) {
    let result = false
    if (!token) {
        token = await getCurrentToken()
    }
    if (!token) {
        console.log('Firebase could not retrieve token')
    }
    else {
        result = await setFcmTokenFetchNoDispatch(token, isLoggedIn)
    }
    return result
}

function isValidNotification(notification) {
    const result = !!notification && notification.data !== undefined && notification.data !== null &&
            notification.data['receiverId'] !== undefined &&
            notification.data['receiverId'] !== null &&
            notification.data['receiverId'] !== '' &&
            notification.data['actionType'] !== undefined &&
            notification.data['actionType'] !== null &&
            notification.data['actionType'] !== ''
    if (!result) {
        console.log('<Notification Service> invalid notification', notification)
    }
    return result
}

function isUserNotification(actionData, loggedInUserId, loggedInUserType) {
    let result = false
    if (actionData['actionType'] !== ACTION_NOTIFICATION_TYPES.NEW_CHANNEL_MESSAGE) {
        result = loggedInUserId == actionData['receiverId']
    }
    else {
        result = loggedInUserType === actionData['receiverUserType']
    }
    return result
}

function addPendingActionData(notification, removeNotification) {
    if (isValidNotification(notification)) {
        pendingActionData = notification.data
        removeNotification && NotificationsService.removeNotification(notification)
        return true
    }
    return false
}

async function processOnNotificationOpened(notificationOpen, loggedInUserId, loggedInUserType, reduxParams) {
    let result = false
    if (notificationOpen) {
        const notification = notificationOpen
        NotificationsService.removeNotification(notification)
        if (_isValidAndUserOrGuestNotificationForJump(notification, loggedInUserId, loggedInUserType, reduxParams)) {
            result = await _jumpOnAction(loggedInUserType, notification.data, reduxParams)
            // hack because can not put addPendingActionData in action handlers
            if (result && loggedInUserType === USER_TYPES.GUEST) {
                pendingActionData = notification.data
            }
        }
    }
    return result
}

async function processOnNotificationReceived(notification, loggedInUserId, loggedInUserType, reduxParams) {
    let result = false
    if (_isValidAndUserOrGuestNotification(notification, loggedInUserId, loggedInUserType, reduxParams)) {
        const handler = NotificationActionHandler.getActionHandler(loggedInUserType, notification.data['actionType'])
        result = await handler.processAction(notification.data, reduxParams)
    }
    return result
}

function shouldDisplayLocalNotification(notification, loggedInUserId, loggedInUserType, reduxParams) {
    let result = false
    if (_isValidAndUserOrGuestNotification(notification, loggedInUserId, loggedInUserType, reduxParams)) {
        const handler = NotificationActionHandler.getActionHandler(loggedInUserType, notification.data['actionType'])
        result = handler.shouldDisplayLocalNotification(notification.data, reduxParams)
    }
    return result
}

async function executePendingAction(loggedInUserId, loggedInUserType, reduxParams) {
    let result = false
    if (pendingActionData !== undefined && 
        (USER_TYPES.GUEST === loggedInUserType || isUserNotification(pendingActionData, loggedInUserId, loggedInUserType, reduxParams))) {
        result = await _jumpOnAction(loggedInUserType, pendingActionData, reduxParams)
        // clear pending action data only if data is processed and if user type is not guest
        if (result && USER_TYPES.GUEST !== loggedInUserType) {
            pendingActionData = undefined
        }
    }
    return result
}

function unsubscribeFromAllTopics() {
    Object.values(NOTIFICATION_TOPICS).forEach(x => unsubscribeFromTopic(x))
}

function subscribeToTopic(topic) {
    NotificationsService.subscribeToTopic(topic)
}

function unsubscribeFromTopic(topic) {
    NotificationsService.unsubscribeFromTopic(topic)
}

function getCurrentToken() {
    return NotificationsService.getCurrentToken()
}

async function _jumpOnAction(loggedInUserType, actionData, reduxParams) {
    const handler = NotificationActionHandler.getActionHandler(loggedInUserType, actionData['actionType'])
    return await handler.jumpOnAction(actionData, reduxParams)
}

function _isValidAndUserOrGuestNotification(notification, loggedInUserId, loggedInUserType, reduxParams) {
    const result = isValidNotification(notification) && 
        (USER_TYPES.GUEST === loggedInUserType || isUserNotification(notification.data, loggedInUserId, loggedInUserType, reduxParams))
    return result
}

function _isUserNotificationForJump(actionData, loggedInUserId, loggedInUserType, { userGroups }) {
    let result = false
    if (actionData['actionType'] === ACTION_NOTIFICATION_TYPES.NEW_MESSAGE) {
        if (actionData['groupId'] !== undefined) {
            const groupId = parseInt(actionData['groupId'])
            const group = getUserGroupDataByGroupId(userGroups, groupId)
            result = !!group && !!group['users'] && group['users'].length > 0 && (group.userId === loggedInUserId || group['users'].some(x => x.userId === loggedInUserId))
        }
    }
    else {
        result = isUserNotification(actionData, loggedInUserId, loggedInUserType, { userGroups })
    }
    return result
}

function _isValidAndUserOrGuestNotificationForJump(notification, loggedInUserId, loggedInUserType, reduxParams) {
    const result = isValidNotification(notification) && 
        (USER_TYPES.GUEST === loggedInUserType || _isUserNotificationForJump(notification.data, loggedInUserId, loggedInUserType, reduxParams))
    return result
}

const dummy = () => false
const dummyAsync = async () => false
export default AppConfig.enableNotifications ? {
    init,
    sendTokenToServer,
    processOnNotificationOpened,
    processOnNotificationReceived,
    executePendingAction,
    isUserNotification,
    isValidNotification,
    shouldDisplayLocalNotification,
    subscribeToTopic,
    unsubscribeFromTopic,
    unsubscribeFromAllTopics,
    addPendingActionData,
    getCurrentToken,
} : {
    init,
    sendTokenToServer: dummy,
    processOnNotificationOpened: dummyAsync,
    processOnNotificationReceived: dummyAsync,
    executePendingAction: dummyAsync,
    isUserNotification: dummy,
    isValidNotification: dummy,
    shouldDisplayLocalNotification: dummy,
    subscribeToTopic: dummy,
    unsubscribeFromTopic: dummy,
    unsubscribeFromAllTopics: dummy,
    addPendingActionData: dummy,
    getCurrentToken: dummy,
}