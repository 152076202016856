let layouts = {} // key=>value per containerId 

const clearLayouts = function(containerId) {
    if (!containerId && containerId !== 0) {
        layouts = {}
    }
    else if (containerId in layouts) {
        layouts[containerId] = undefined
    }
}

const setData = function(containerId, itemId, index, height) {
    if (!(containerId in layouts)) {
        layouts[containerId] = {}
    }
    layouts[containerId][itemId] = { height, index }
}

const getCalculated = function(containerId, idsList) {
    const items = layouts[containerId] || {}
    const result = idsList.map(function(id, index) {
        return {
            index,
            offset: 0,
            length: items[id] ? items[id].height : 0,
        }
    })
    result.forEach((item, index) => {
        if (index > 0) {
            item.offset = result[index - 1].offset + result[index - 1].length
        }
    })
    return result.reduce((mp, item, index) => {
        mp[idsList[index]] = item
        return mp
    }, {})
}

const getAllUndefined = function(containerId, idsList) {
    const items = layouts[containerId]
    if (!items) {
        return idsList
    }
    return idsList.filter((id, index) => !(id in items) || ((items[id].index === 0 || index === 0) && index !== items[id].index))
}

export default {
    clearLayouts,
    setData,
    getCalculated,
    getAllUndefined,
}