import React from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'
import { getStorageItem, removeStorageItem, setStorageItem } from 'dpn-common/services/SharedServices'
import AnalyticsService from 'services/AnalyticsService'
import { Link } from 'react-router-dom'

const STORAGE_KEY = 'cookie_consent'

class CookieConsent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showBanner: false,
            analyticsChecked: true,
        }
    }

    componentDidMount() {
        this.checkCookieConsent()
    }

    checkCookieConsent = async () => {
        const data = await getStorageItem(STORAGE_KEY)
        if (data) {
            const cookieConsentData = JSON.parse(data)
            if (cookieConsentData) {
                const now = new Date()
                const expires = new Date(cookieConsentData.expires)
                if (expires > now) {
                    cookieConsentData.analyticsEnabled && this.initAnalytics()
                    return
                }

                await removeStorageItem(STORAGE_KEY)
            }
        }

        this.setState({ showBanner: true })
    }

    toggleAnalyticsChecked = () => this.setState({ analyticsChecked: !this.state.analyticsChecked })

    onAccept = async () => {
        const { analyticsChecked } = this.state

        const now = new Date()
        now.setFullYear(now.getFullYear() + 1)

        await setStorageItem(STORAGE_KEY, JSON.stringify({
            analyticsEnabled: analyticsChecked,
            expires: now.toISOString()
        }))

        this.setState({ showBanner: false })
        analyticsChecked && this.initAnalytics()
    }

    initAnalytics = () => AnalyticsService.init()

    render() {
        const { showBanner, analyticsChecked } = this.state

        if (!showBanner) {
            return null
        }

        return (
            <div className="fixed left-0 bottom-0 w-full z-10 p-12 text-white" style={{ backgroundColor: '#3C4252' }}>
                <div>{texti18n(Messages.COOKIE_CONSENT_TEXT_1)}</div>
                <div>{texti18n(Messages.COOKIE_CONSENT_TEXT_2)}</div>
                <div className="flex flex-row py-6">
                    <FormControlLabel
                        className="px-12"
                        control={
                            <Checkbox
                                disableRipple
                                disabled={true}
                                checked={true}
                            />
                        }
                        label={<p style={{ color: '#FFFFFF' }}>{texti18n(Messages.NECESSARY)}</p>}
                    />
                    <FormControlLabel
                        className="px-12"
                        control={
                            <Checkbox
                                disableRipple
                                disabled={false}
                                checked={analyticsChecked}
                                onClick={this.toggleAnalyticsChecked}
                            />
                        }
                        label={<p>{texti18n(Messages.USAGE_STATISTICS)}</p>}
                    />
                </div>
                <div className="w-full flex flex-row">
                    <div className="flex flex-1 justify-start items-center">
                        <Link
                            className="text-white"
                            underline='always'
                            to='/privacyPolicy'
                        >
                            {texti18n(Messages.PRIVACY_POLICY)}
                        </Link>
                    </div>
                    <div className="flex flex-1 justify-end">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.onAccept}
                            className="normal-case font-bold"
                        >
                            {texti18n(Messages.ALLOW_ALL)}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CookieConsent