import { combineReducers } from 'redux'
import baseReducer from './BaseReducer'
import teacherReducer from './TeacherReducer'
import lessonsReducer from './LessonsReducer'
import studentReducer from './StudentReducer'
import assignmentReducer from './AssignmentReducer'
import privateMessageReducer from './PrivateMessageReducer'
import settingsAndLanguagesReducer from './SettingsAndLanguagesReducer'
import emoticonReducer from './EmoticonReducer'
import sentencesReducer from './SentenceReducer'
import schoolYearsReducer from './SchoolYearReducer'
import assignLevelsReducer from './AssignLevelReducer'
import groupReducer from './GroupReducer'
import premadeAvatarsReducer from './PremadeAvatarsReducer'
import notesReducer from './NoteReducer'
import automaticLoginReducer from './AutomaticLoginReducer'
import { processUserDependantReducersMap } from './reducerUtils'

const userDependantReducers = {
    base: baseReducer,
    teacher: teacherReducer,
    lesson: lessonsReducer,
    student: studentReducer,
    assignment: assignmentReducer,
    privateMessage: privateMessageReducer,
    settingsAndLanguages: settingsAndLanguagesReducer,
    emoticon: emoticonReducer,
    sentence: sentencesReducer,
    schoolYear: schoolYearsReducer,
    assignLevel: assignLevelsReducer,
    group: groupReducer,
    premadeAvatar: premadeAvatarsReducer,
    note: notesReducer,
}

const generalReducers = {
    automaticLogin: automaticLoginReducer,
}

export const getAppReducer = (additionalGeneralReducers, additionalUserDependantReducers) => {
    const allUserDependantReducersMap = processUserDependantReducersMap({ ...userDependantReducers, ...additionalUserDependantReducers })
    
    return combineReducers({
        ...allUserDependantReducersMap,
        ...generalReducers,
        ...additionalGeneralReducers,
    })
}