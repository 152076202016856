import ActionTypes from './ActionTypes'
import {
    buyRewardPost, sellRewardDelete,
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { Messages, texti18n } from '../utils/MessageConstants'
import { STUDENT_DETAILS, GROUP_FETCH_PARAMS } from '../utils/AppConstants'
import { showSuccess, getStorageItem, setStorageItem, infoAlert } from '../services/SharedServices'
import { loadGroupsDataForStudentFetch } from './GroupActions'
import { acceptStudentInvitationPost, userConnectionGet } from 'dpn-common/services/IdentityEndpointsService'

export async function buyRewardFetch(rewardId, studentId, dispatch, teacherId) {
    const fetchPromise = buyRewardPost(rewardId, studentId)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        await loadGroupsDataForStudentFetch(GROUP_FETCH_PARAMS, dispatch, teacherId)
        showSuccess(texti18n(Messages.REWARD_BUY_SUCCESSFUL))
    }
    else {
        infoAlert(texti18n(responseData.message))
    }
    return responseData
}

export async function sellRewardFetch(rewardId, dispatch, teacherId) {
    const fetchPromise = sellRewardDelete(rewardId)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        await loadGroupsDataForStudentFetch(GROUP_FETCH_PARAMS, dispatch, teacherId)
        showSuccess(texti18n(Messages.REWARD_RETURN_SUCCESSFUL))
    }
    else {
        infoAlert(texti18n(responseData.message))
    }
    return responseData
}

export async function getUserConnectionsFetchNoDispatch() {
    const fetchPromise = userConnectionGet()
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.success ? responseData.items : undefined
}

export async function acceptStudentInvitationFetch(teacherId, code) {
    const fetchPromise = acceptStudentInvitationPost(teacherId, code)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData
}

export const chooseTeacher = async (teacher, studentId, dispatch) => {
    const key = `${STUDENT_DETAILS}_${studentId}`
    const studentData = await getStorageItem(key)
    const studentDetails = JSON.parse(studentData) || {}
    studentDetails.currentTeacherId = teacher.id
    await setStorageItem(key, JSON.stringify(studentDetails))

    dispatch(setStudentChosenTeacher(teacher))
}

export const loadSavedTeacher = async (studentId) => {
    const studentData = await getStorageItem(`${STUDENT_DETAILS}_${studentId}`)
    if (studentData) {
        return JSON.parse(studentData) || undefined
    }
}

export function setStudentChosenTeacher(teacher) {
    return { type: ActionTypes.STUDENT_SET_CHOSEN_TEACHER_ACTION, teacher }
}
