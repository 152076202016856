import React from 'react'

export const TeacherTemplateNotesConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/custom-presets/notes',
            component: React.lazy(() => import('./TeacherTemplateNotes')),
            applicationPath: 'TeacherTemplateNotes',
        },
        {
            path: '/teacherTemplateNotesSort',
            component: React.lazy(() => import('./TeacherTemplateNotesSort')),
            applicationPath: 'TeacherTemplateNotesSort',
        },
        {
            path: '/noteInfo',
            component: React.lazy(() => import('./NoteInfo')),
            applicationPath: 'NoteInfo',
        },
    ]
}