import ActionTypes from '../actions/ActionTypes'

const initialState = {
    premadeAvatars: undefined,
}

function premadeAvatarsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_PREMADE_AVATARS_ACTION:
        return Object.assign({}, state, {
            premadeAvatars: action.premadeAvatars,
        })
    default:
        return state
    }
}

export default premadeAvatarsReducer