import React from 'react'
import { Checkbox } from '@material-ui/core'
import Box from '@material-ui/core/Box'

function FuseNavBadge(props) {
    const checked = props.badge.props.checked
    
    return (
        <Box display="flex" m={1}>
            <Checkbox
                color={'default'}
                checked={checked}
                inputProps={{
                    'aria-label': 'primary checkbox',
                }}
            />
        </Box>
    )
}

export default React.memo(FuseNavBadge)