import React from 'react'

export const TeacherNotificationsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/notifications',
            component: React.lazy(() => import('./TeacherNotifications')),
            applicationPath: 'TeacherNotifications',
        },
        {
            path: '/settings/email-notifications',
            component: React.lazy(() => import('./TeacherEmailNotifications')),
            applicationPath: 'TeacherEmailNotifications',
        },
    ]
}