import React from 'react'

export const AutomaticLoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/automatic-login',
            component: React.lazy(() => import('./AutomaticLogin')),
            applicationPath: 'AutomaticLogin',
        }
    ]
}

