import React from 'react'

export const StudentVideoTutorialsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/student-video-tutorials',
            component: React.lazy(() => import('./StudentVideoTutorials')),
            applicationPath: 'StudentVideoTutorials',
        },
    ]
}