import ActionTypes from '../actions/ActionTypes'
import {
    addItemToItemsInDictionary, changeItemInItemsInDictionary, deleteItem,
    replaceOldItem, setDictionaryState, groupBy
} from '../utils/HelperFunctions'


const initialState = {
    // { groupId1: { parent: [assignment1, assignment2, ...] } }
    assignments: undefined,
}

function assignmentReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_ASSIGNMENTS_FOR_ASSIGNMENT_FOLDER: {
        const assignments = { ...state.assignments }
        if (action.assignmentFolderId) {
            assignments[action.groupId] = {
                ...assignments[action.groupId],
                [action.assignmentFolderId]: action.assignments
            }
            return {
                ...state,
                assignments
            }
        }
        else if (action.streamed) {
            const assignments = { ...state.assignments }
            if (action.clear || !(action.groupId in assignments)) {
                assignments[action.groupId] = {}
            }
            else {
                assignments[action.groupId] = { ...assignments[action.groupId] }
            }
            const grouped = groupBy(action.assignments, 'parentId', 0)
            const parentIds = Object.keys(grouped)
            parentIds.forEach(parentId => {
                const idsMap = new Set(grouped[parentId].map(x => x.id))
                const oldItems = (assignments[action.groupId][parentId] || []).filter(x => !idsMap.has(x.id))
                assignments[action.groupId][parentId] = oldItems.concat(grouped[parentId])
            })
            return {
                ...state,
                assignments
            }
        }
        else {
            assignments[action.groupId] = {}
            const recursive = (list, parentId) => {
                assignments[action.groupId][parentId] = list
                list.forEach(x => {
                    if (x.children && x.children.length) {
                        recursive(x.children, x.id)
                    }
                })
            }
            recursive(action.assignments, 0)
            return {
                ...state,
                assignments,
            }
        }
    }

    case ActionTypes.ADD_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: {
        const assignmentFolderId = action.assignmentFolderId ? action.assignmentFolderId : 0
        // addItemToItemsInDictionary will be calledif assignments[groupId] exists and its [] or array with more than zero elements
        const tempState = state.assignments[action.groupId]
            ? addItemToItemsInDictionary(state.assignments || {}, action.groupId, assignmentFolderId, action.assignment, compareFunc)
            : setDictionaryState(state.assignments || {}, action.groupId, assignmentFolderId, [action.assignment])
        
        return setDictionaryState(state, 'assignments', action.groupId, tempState[action.groupId])
    }

    case ActionTypes.UPDATE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: {
        const assignmentFolderId = action.assignmentFolderId ? action.assignmentFolderId : 0
        const tempState = changeItemInItemsInDictionary(state.assignments || {}, action.groupId, assignmentFolderId,
            replaceOldItemCreate(action.assignment, compareFunc))
        return setDictionaryState(state, 'assignments', action.groupId, tempState[action.groupId])
    }

    case ActionTypes.DELETE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: {
        const assignmentFolderId = action.assignmentFolderId ? action.assignmentFolderId : 0
        const tempState = changeItemInItemsInDictionary(state.assignments || {}, action.groupId, assignmentFolderId,
            deleteItemCreate(action.id))
        return setDictionaryState(state, 'assignments', action.groupId, tempState[action.groupId])
    }

    default:
        return state
    }
}

function compareFunc(assignment) {
    return x => {
        if (x.order != assignment.order) {
            return x.order > assignment.order
        }
        if (x.color != assignment.color) {
            return x.color > assignment.color
        }
        if (x.name != assignment.name) {
            return x.name.toLowerCase() > assignment.name.toLowerCase()
        }
        return x.id > assignment.id
    }
}

function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}

function replaceOldItemCreate(item, compareFunc) {
    return items => replaceOldItem(items, item, compareFunc)
}

export default assignmentReducer
