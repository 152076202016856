import { ACCENTS_MAP } from './AppConstants'

export function insertAtPosition(items, item, itemCompareFunc) {
    return insertAtPositionMutable([...items], item, itemCompareFunc)
}

export function replaceOldItem(items, newItem, itemCompareFunc) {
    const item = items.find(x => x.id === newItem.id)
    if (item) {
        const newItems = items.filter(x => x.id !== newItem.id)
        insertAtPositionMutable(newItems, newItem, itemCompareFunc)
        return newItems
    }
    return items
}

export function updateOldItem(items, itemId, updateItemFunc, itemCompareFunc) {
    const item = items.find(x => x.id === itemId)
    if (item) {
        const newItems = items.filter(x => x.id !== itemId)
        const newItem = { ...item }
        updateItemFunc(newItem)
        insertAtPositionMutable(newItems, newItem, itemCompareFunc)
        return newItems
    }
    return items
}

export function deleteItem(items, itemId) {
    const item = items.find(x => x.id === itemId)
    if (item) {
        const newItems = items.filter(x => x.id !== itemId)
        return newItems
    }
    return items
}

export function addMultipleItemsToItemsInDictionary(state, stateKey, dictKeyId, items, compareFunc) {
    let newItems = items
    const dictionary = state[stateKey] || {}
    if (dictKeyId in dictionary) {
        const oldItems = dictionary[dictKeyId]
        if (oldItems) {
            oldItems.forEach(item => {
                if (!newItems.some(x => x.id === item.id)) {
                    insertAtPositionMutable(newItems, item, compareFunc)
                }
            })
        } 
    }
    return setDictionaryState(state, stateKey, dictKeyId, newItems)
}

export function addItemToItemsInDictionary(state, stateKey, dictKeyId, item, compareFunc) {
    let newItems
    const dictionary = state[stateKey]
    if (dictKeyId in dictionary) {
        newItems = [...dictionary[dictKeyId]]
        insertAtPositionMutable(newItems, item, compareFunc)
    }
    else {
        newItems = [item]
    }
    return setDictionaryState(state, stateKey, dictKeyId, newItems)
}

export function changeItemInItemsInDictionary(state, stateKey, dictKeyId, func) {
    let response = state
    const dictionary = state[stateKey]
    if (dictionary && dictKeyId in dictionary) {
        const items = dictionary[dictKeyId]
        const newItems = func(items)
        response = setDictionaryState(state, stateKey, dictKeyId, newItems)
    }
    return response
}

export function setDictionaryState(state, stateKey, dictKeyId, newItems) {
    const response = Object.assign({}, state, {
        [stateKey]: { ...state[stateKey], [dictKeyId]: newItems } 
    })
    return response
}

export function getFromDict(dict, keyId, defValue) {
    return dict && keyId in dict ? dict[keyId] : defValue
}

export function arrayToMatrix(originalItems, noOfColumns) {
    const reminder = originalItems.length % noOfColumns
    const noOfRows = (originalItems.length - reminder) / noOfColumns + (reminder > 0 ? 1 : 0)
    const items = new Array(noOfRows)
    for (let i = 0, pos = 0; i < noOfRows; ++i, pos += noOfColumns) {
        const end = Math.min(originalItems.length, pos + noOfColumns)
        items[i] = originalItems.slice(pos, end)
    }
    return items
}

export function fixNumberOfColumnsInMatrix(matrix, noOfColumns, func) {
    if (matrix && matrix.length) {
        const last = matrix[matrix.length - 1]
        const count = noOfColumns - last.length
        for (let i = 0; i < count; i++) {
            last.push(func(i))
        }
    }
}

export function paginationAdd(oldItems, newItems, func) {
    if (!newItems || newItems.length === 0) {
        return oldItems
    }
    const lastNew = newItems[0]
    const index = oldItems.findIndex(x => func(lastNew, x))
    if (index === -1) {
        return oldItems.concat(newItems)
    }
    else if (oldItems.length - index >= newItems.length) {
        return oldItems
    }
    else {
        return oldItems.concat(newItems.slice(oldItems.length - index, newItems.length))
    }
}

export function insertAtPositionMutable(items, item, itemCompareFunc) {
    const compareFunc = itemCompareFunc(item)
    const indexOfFirstGreater = items.findIndex(compareFunc)
    if (indexOfFirstGreater === -1) {
        items.push(item)
    }
    else {
        items.splice(indexOfFirstGreater, 0, item)
    }
    return items
}

export function concatUnique(first, second) {
    if (first === undefined && second === undefined) {
        return undefined
    }

    if (first === undefined || second === undefined) {
        return first ? first : second
    }

    const newArr = [... first]

    for (let i = 0; i < second.length; ++i) {
        let found = false
        for (let j = 0; j < first.length; ++j) {
            if (first[j].id === second[i].id) {
                found = true
                break
            }
        }
        if (!found) {
            newArr.push(second[i])
        }
    }

    return newArr
}

export function calculateNumberOfItemsPerRow(windowSize, defaultItemsPerRow) {
    if (!windowSize)
        return defaultItemsPerRow
    const aspect = windowSize.width / windowSize.height
    let itemsPerRow = defaultItemsPerRow
    if (aspect > 1) {
        itemsPerRow = parseInt(Math.trunc(itemsPerRow * aspect))
    }
    return itemsPerRow
}

export function fixGroupedItems(responseData, keyIdName) {
    const { studentItems, items } = responseData
    const itemsMap = items.reduce((acc, x) => {
        acc[x.id] = x
        return acc
    }, {})
    studentItems.forEach(x => x.item = itemsMap[x[keyIdName]])
    return studentItems
}

export function reduxUpdateWait() {
    return new Promise(resolve => setTimeout(resolve, 200))
}

export function deepDiffer(one, two, maxDepth) {
    if (maxDepth === 0) {
        return true
    }
    if (one === two) {
        // Short circuit on identical object references instead of traversing them.
        return false
    }
    if (typeof one === 'function' && typeof two === 'function') {
        // We consider all functions equal unless explicitly configured otherwise
        return true
    }
    if (typeof one !== 'object' || one === null) {
        // Primitives can be directly compared
        return one !== two
    }
    if (typeof two !== 'object' || two === null) {
        // We know they are different because the previous case would have triggered
        // otherwise.
        return true
    }
    if (one.constructor !== two.constructor) {
        return true
    }
    if (Array.isArray(one)) {
        // We know two is also an array because the constructors are equal
        const len = one.length
        if (two.length !== len) {
            return true
        }
        for (let ii = 0; ii < len; ii++) {
            if (deepDiffer(one[ii], two[ii], maxDepth - 1)) {
                return true
            }
        }
    }
    else {
        for (const key in one) {
            if (deepDiffer(one[key], two[key], maxDepth - 1)) {
                return true
            }
        }
        for (const twoKey in two) {
            // The only case we haven't checked yet is keys that are in two but aren't
            // in one, which means they are different.
            if (one[twoKey] === undefined && two[twoKey] !== undefined) {
                return true
            }
        }
    }
    return false
}

export function formatMessage(message, args) {
    for (let i = 0; i < args.length; i++) {
        const regexp = new RegExp('\\{' + i + '\\}', 'gi')
        message = message.replace(regexp, args[i])
    }
    return message
}

export function timeoutPromise(promise, timeoutInMS, timeoutError) {
    if (!timeoutInMS || timeoutInMS <= 0) { // undefined <= 0 returns false in js
        return promise
    }
    // @see https://github.com/github/fetch/issues/175
    return new Promise(function (resolve, reject) {
        let timeoutId = setTimeout(function() {
            timeoutId = undefined
            reject(new Error(timeoutError))
        }, timeoutInMS)
        promise.then(function (value) {
            if (timeoutId) {
                clearTimeout(timeoutId)
                resolve(value)
            }
        }).catch(function (error) {
            if (timeoutId) {
                clearTimeout(timeoutId)
                reject(error)
            }
        })
    })
}

export function groupBy(items, keyProperty, keyValueIfNotExist) {
    return (items || []).reduce(function(acc, currItem) {
        const key = currItem[keyProperty] || keyValueIfNotExist
        acc[key] = acc[key] || []
        acc[key].push(currItem)
        return acc
    }, {})
}

export const removeAccents = text => Object.keys(ACCENTS_MAP).reduce((acc, cv) => acc.replace(new RegExp(ACCENTS_MAP[cv], 'gi'), cv), text)

const currentYPosition = () => {
    // Firefox, Chrome, Opera, Safari
    if (self.pageYOffset)
        return self.pageYOffset
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) 
        return document.body.scrollTop
    return 0
}

const getElementYPosition = elementRef => {
    let y = elementRef.offsetTop
    while (elementRef.offsetParent && elementRef.offsetParent != document.body) {
        elementRef = elementRef.offsetParent
        y += elementRef.offsetTop
    }
    return y
}

export const doScrolling = (elementRef, containerRef, duration = 500) => {
    const startingY = currentYPosition()
    const fixedHeaderHeight = window.innerWidth >= 600 ? 169 : 135
    const diff = getElementYPosition(elementRef) - startingY - fixedHeaderHeight
    let start
    setTimeout(() => {
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp
            // Elapsed milliseconds since start of scrolling.
            const time = timestamp - start
            // Get percent of completion in range [0, 1].
            const percent = Math.min(time / duration, 1)
            containerRef ? containerRef.scrollTo(0, startingY + diff * percent) : window.scrollTo(0, startingY + diff * percent)
            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step)
            }
        })
    }, 500)
}

export const postponeAction = async (actionFunc, time) => {
    return await new Promise(resolve => {
        const timeoutHandler = setTimeout(async () => {
            const result = await actionFunc()
            clearTimeout(timeoutHandler)
            resolve(result)
        }, time)
    })
}