import React from 'react'
import { getEmbedLink } from 'dpn-common/utils/VideoUtils/VideoPlayerHelper'

export default class LinkWithImage extends React.Component {
    render() {
        const { index, link, onContextMenu } = this.props
        const embedLink = getEmbedLink(link)
        return (
            <iframe
                index={index}
                scrolling="no"
                className="max-w-full"
                src={embedLink}
                frameBorder='0'
                allow='encrypted-media'
                allowFullScreen
                title='video'
                onContextMenu={onContextMenu}
            />
        )
    }
}