import React from 'react'

export const PrivacyTermsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/terms-of-use',
            component: React.lazy(() => import('./Terms')),
            applicationPath: 'Terms',
        },
        {
            path: '/support/privacy-policy',
            component: React.lazy(() => import('./PrivacyPolicy')),
            applicationPath: 'PrivacyPolicy',
        },
    ]
}