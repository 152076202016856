import ActionTypes from '../actions/ActionTypes'
import {
    setDictionaryState, addItemToItemsInDictionary,
    changeItemInItemsInDictionary, replaceOldItem, deleteItem
} from '../utils/HelperFunctions'
import { GROUP_TYPES } from 'dpn-common/utils/AppConstants'
import { mergeUserDataRedux } from './BaseReducer'

const initialState = {
    globalGroups: [],
    userGroups: [],
    users: [],
    students: [],
    selectedGroupStudentIds: [],
    notes: {}
}

function groupReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GROUPS_DATA_SET_ACTION:
        return Object.assign({}, state, {
            globalGroups: action.data.globalGroups ? action.data.globalGroups : state.globalGroups,
            userGroups: action.data.userGroups ? action.data.userGroups : state.userGroups,
            users: action.data.users ? action.data.users : state.users,
            students: action.data.students ? action.data.students : state.students
        })
    case ActionTypes.GROUP_DELETE_ACTION:
        return Object.assign({}, state, {
            userGroups: state.userGroups ? state.userGroups.filter(x => x.id !== action.groupId) : state.userGroups,
        })
    case ActionTypes.SELECTED_GROUP_STUDENTS_SET:
        return Object.assign({}, state, {
            selectedGroupStudentIds: action.selectedGroupStudentIds
        })
    case ActionTypes.SET_GROUP_NOTES_ACTION: {
        return setDictionaryState(state, 'notes', action.groupId, action.notes)
    }
    case ActionTypes.ADD_GROUP_NOTE_ACTION: {
        return addItemToItemsInDictionary(state, 'notes',
            action.groupId, action.note, noteCompareFunc)
    }
    case ActionTypes.UPDATE_GROUP_NOTE_ACTION: {
        return changeItemInItemsInDictionary(state, 'notes', action.groupId,
            replaceOldItemCreate(action.note, noteCompareFunc))
    }
    case ActionTypes.DELETE_GROUP_NOTE_ACTION: {
        return changeItemInItemsInDictionary(state, 'notes', action.groupId,
            deleteItemCreate(action.noteId))
    }
    case ActionTypes.DELETE_GROUP_NOTES_ACTION: {
        const noteIds = action.noteIds
        let tempState = state
        noteIds.forEach(id => 
            tempState = changeItemInItemsInDictionary(tempState, 'notes', action.groupId,
                deleteItemCreate(id)))
        return tempState
    }
    case ActionTypes.ACTIVE_STUDENT_UPDATE: {
        return {
            ...state,
            students: state.students.map((item) => {
                if (action.studentId in item.students) {
                    return {
                        ...item,
                        students: {
                            ...item.students,
                            [action.studentId]: {
                                ...item.students[action.studentId],
                                isActive: action.isActive
                            }
                        }
                    }
                }

                return {
                    ...item
                }
            }),
            userGroups: state.userGroups.map((item) => {
                return {
                    ...item,
                    users: item.users.map((x) => {
                        if (action.studentId !== x.userId) {
                            return x
                        }
                    
                        return {
                            ...x,
                            isActive: action.isActive
                        }
                    })
                }
            }),
        }
    }
    case ActionTypes.DELETE_STUDENT: {
        return {
            ...state,
            users: state.users.filter(x => x.id !== action.studentId),
            students: state.students.map((item) => {
                if (action.studentId in item.students) {
                    const newStudents = { ...item.students }
                    delete newStudents[action.studentId]
                    return {
                        ...item,
                        students: newStudents
                    }
                }

                return {
                    ...item
                }
            }),
            userGroups: state.userGroups
                .filter(x => !(x.userId === action.teacherId && x.users.some(y => y.userId === action.studentId) && x.groupType === GROUP_TYPES.TEACHER_SINGLE_STUDENT))
                .map((item) => {
                    return {
                        ...item,
                        users: item.users.filter(x => x.userId !== action.studentId)
                    }
                }),
        }
    }
    // if user change his/hers data, those changes should reflect on corresponding user in state.users
    case ActionTypes.CHANGE_USER_DATA_ACTION: {
        const users = state.users.map(x => {
            if (x.id !== action.userData.id) {
                return x
            }
            return mergeUserDataRedux(x, action.userData)
        })
        return {
            ...state,
            users,
        }
    }
    
    default:
        return state
    }
}

export default groupReducer

// function studentCompareFunc(student) {
//     return x => x.firstName > student.firstName || (x.firstName === student.firstName && x.lastName > student.lastName)
// }

function noteCompareFunc(note) {
    return x => {
        if (x.order !== note.order) {
            return x.order > note.order
        }
        if (x.name != note.name) {
            return x.name.toLowerCase() > note.name.toLowerCase()
        }
        return x.id < note.id
    }
}

function replaceOldItemCreate(item, compareFunc) {
    return items => replaceOldItem(items, item, compareFunc)
}

function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}