function canOpenURL(url, onError) {
    let xhr = new XMLHttpRequest()
    return new Promise((resolve, reject) => {
        xhr.onload = () => {
            resolve && resolve(true)
        }
        xhr.onerror = () => {
            onError && onError(false)
        }
        xhr.open('HEAD', url, true)
        xhr.send()
    })
}

function openURL(url, onError) {
    return new Promise((resolve, reject) => {
        try {
            let win = window.open(url, '_blank')
            win.focus()
            resolve && resolve(true)
        }
        catch (e) {
            onError && onError('Error: ' + e)
            reject && reject()
        }
    })
}

export default {
    canOpenURL,
    openURL,
}
