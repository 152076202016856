import { USER_TYPES, FIRST_TIME_GROUP_FETCH_PARAMS, IDENTITY_SERVICE, StudentChooseTeacherRouteName, ROLE_ID } from './AppConstants'
import { getUserConnectionsFetchNoDispatch, loadSavedTeacher, setStudentChosenTeacher } from '../actions/StudentActions'
import { loadGroupsDataForStudentWithProvidedUserConnectionsRequiredFetch, loadGroupsDataForTeacherFetch } from '../actions/GroupActions'
import { getSiteGlobalGroups, getTeacherGlobalGroups, getTeacherCustomGroups } from './GroupsDataUtils'
import { loadEmoticonsFetch } from '../actions/EmoticonActions'
import { studentSelectedTeacherDataSelector } from 'dpn-common/reselects/StudentGroupsDataReselects'
import SharedService from 'dpn-common/services/SharedServices'
import FetchService from 'dpn-common/services/FetchService'
import PostponeActionHelper from './PostponeActionHelper'
import { loadAssignmentsFetch } from 'dpn-common/actions/AssignmentActions'

const guestUser = Object.freeze({
    getHomeRoute: () => 'GuestHome',
    onLogin: async (_userData, _dispatch) => ({}),
    onLogout: () => undefined,
})

const teacherUser = Object.freeze({
    getHomeRoute: () => 'TeacherHome',
    onLogin: async (userData, dispatch) => {
        await loadEmoticonsFetch(dispatch)
        const groupData = await loadGroupsDataForTeacherFetch(FIRST_TIME_GROUP_FETCH_PARAMS, dispatch, userData)
        if (groupData.success) {
            const teacher = groupData.users.find(x => x.id === userData.id)
            if (teacher) {
                userData.leaderboardParticipation = teacher.leaderboardParticipation
            }
            else {
                console.error('onLogin teacher in users not found. ', userData.id, groupData.users)
            }
            await loadPAAssignments(USER_TYPES.TEACHER, groupData.globalGroups, dispatch)
            await loadCustomAssignments(USER_TYPES.TEACHER, groupData.userGroups, userData.id, dispatch)
        }
        return groupData
    },
    onLogout: () => PostponeActionHelper.onLogout()
})

const studentUser = Object.freeze({
    getHomeRoute: (additionalData) => {
        if (SharedService.PlatformService.getOS() !== 'web') {
            return 'StudentHome'
        }
        return (!additionalData || additionalData.hasTeacher) ? 'StudentHome' : StudentChooseTeacherRouteName
    },
    onLogin: async (userData, dispatch) => {
        await loadEmoticonsFetch(dispatch)
        const userConnections = await getUserConnectionsFetchNoDispatch()
        if (!userConnections) {
            return undefined
        }
        const teacherConnections = userConnections.filter(x => x.roleId === ROLE_ID.TEACHER)
        const studentDetails = await loadSavedTeacher(userData.id)
        // first: try to pick saved teacher from connections. second: try to pick first if there is only one active and not invited
        let teacherId = undefined
        if (studentDetails && teacherConnections.some(x => x.id === studentDetails.currentTeacherId && !x.isInvited && x.isActive)) {
            teacherId = studentDetails.currentTeacherId
        }
        if (!teacherId && teacherConnections.length === 1 &&
            !teacherConnections[0].isInvited && teacherConnections[0].isActive) {
            teacherId = teacherConnections[0].id
        }

        const groupData = await loadGroupsDataForStudentWithProvidedUserConnectionsRequiredFetch(
            FIRST_TIME_GROUP_FETCH_PARAMS, userConnections, teacherId, dispatch)
        if (groupData.success) {
            const student = groupData.users.find(x => x.id === userData.id)
            userData.leaderboardParticipation = student?.leaderboardParticipation
            if (teacherId) {
                const teacherData = studentSelectedTeacherDataSelector({
                    studentId: userData.id,
                    group: groupData,
                })
                dispatch(setStudentChosenTeacher(teacherData))
                await loadCustomAssignments(USER_TYPES.STUDENT, groupData.userGroups, teacherId, dispatch)
            }
            await loadPAAssignments(USER_TYPES.STUDENT, groupData.globalGroups, dispatch)
        }
        return groupData
    },
    onLogout: () => PostponeActionHelper.onLogout()
})

const loadPAAssignments = async (role, globalGroups, dispatch) => {
    const siteGlobalGroupsIds = [...getSiteGlobalGroups(globalGroups).map(x => x.id)]
    const siteGlobalGroupId = siteGlobalGroupsIds[0]
    await loadAssignmentsFetch(siteGlobalGroupId, true, role, dispatch)
}

const loadCustomAssignments = async (role, userGroups, teacherId, dispatch) => {
    const teacherGlobalGroupsIds = [...getTeacherGlobalGroups(userGroups, teacherId).map(x => x.id)]
    const teacherGlobalGroupId = teacherGlobalGroupsIds[0]
    await loadAssignmentsFetch(teacherGlobalGroupId, true, role, dispatch)
}

export const getUser = (role = USER_TYPES.GUEST) => {
    switch (role) {
    case USER_TYPES.STUDENT:
        return studentUser
    case USER_TYPES.TEACHER:
        return teacherUser
    default:
        return guestUser
    }
}

export const isTeacher = userData => userData.role === USER_TYPES.TEACHER

export const isStudent = userData => userData.role === USER_TYPES.STUDENT

export const getUserIdentityString = user => {
    if (!user) {
        return ''
    }
    else if (!!user.firstName && !!user.lastName) {
        return `${user.firstName} ${user.lastName}`
    }
    return user.firstName || user.lastName || user.username || ''
}

export const userHasFullName = user => !!user && user.firstName !== undefined && user.firstName !== null &&
    !user.lastName !== undefined && user.lastName !== null

export const getTeacherBaseDataFromState = state => {
    const isUserStudent = isStudent(state.base.auth.userData)
    const userId = state.base.auth.userData.id
    const teacherId = isUserStudent && !!state.student.teacher ? state.student.teacher.id : userId
    const siteGlobalGroups = getSiteGlobalGroups(state.group.globalGroups)
    const teacherGlobalGroups = getTeacherGlobalGroups(state.group.userGroups, teacherId)
    const teacherCustomGroups = getTeacherCustomGroups(state.group.userGroups, teacherId)
    return {
        siteGlobalGroups,
        teacherGlobalGroups,
        teacherCustomGroups,
        userId,
        teacherId
    }
}

export const getTeacherStudentFromProps = ({ userData, selectedUser }) => {
    const result = {}
    if (userData) {
        if (isStudent(userData)) {
            result.student = Object.freeze(userData)
        }
        else if (isTeacher(userData)) {
            result.teacher = Object.freeze(userData)
        }
    }

    if (selectedUser) {
        if (isStudent(selectedUser)) {
            result.student = Object.freeze(selectedUser)
        }
        else if (isTeacher(selectedUser)) {
            result.teacher = Object.freeze(selectedUser)
        }
    }
    return result
}

export const getUserPictureStaticUrl = (picture, defaultImageUrl) => {
    if (!picture) {
        return defaultImageUrl
    }
    else if (picture.startsWith('premadeAvatars/')) {
        return FetchService.staticUrl(picture, defaultImageUrl)
    }
    else {
        return FetchService[IDENTITY_SERVICE].staticUrl(picture, defaultImageUrl)
    }
}

export const getUserPictureUrl = (picture, defaultImageUrl) => {
    if (!picture) {
        return defaultImageUrl
    }
    else if (picture.startsWith('premadeAvatars/')) {
        return FetchService.imageUrl(picture, defaultImageUrl)
    }
    else {
        return FetchService[IDENTITY_SERVICE].imageUrl(picture, defaultImageUrl)
    }
}

export const getFirstLastName = function (fullName) {
    const value = (fullName || '').trim()
    const spaceIndex = value.indexOf(' ')
    if (spaceIndex === -1) {
        return {
            firstName: value,
            lastName: '',
        }
    }

    return {
        firstName: value.substring(0, spaceIndex).trim(),
        lastName: value.substring(spaceIndex + 1).trim(),
    }
}

export const getUsersSortBase = function (a, b) {
    const name1 = getUserIdentityString(a).toLowerCase()
    const name2 = getUserIdentityString(b).toLowerCase()
    return name1.localeCompare(name2)
}

export const getUsersSortTime = function (a, b) {
    const cmp = a.fromTimeInMinutes - b.fromTimeInMinutes
    if (cmp !== 0) {
        return cmp
    }
    return getUsersSortBase(a, b)
}

export const getGroupsSortBase = function (a, b) {
    return a.name.localeCompare(b.name)
}

export const getGroupsSortTime = function (a, b) {
    const cmp = a.fromTimeInMinutes - b.fromTimeInMinutes
    if (cmp !== 0) {
        return cmp
    }
    return a.name.localeCompare(b.name)
}

export const sortUsers = function(users) {
    return users.sort(getUsersSortBase)
}

export const isPremiumUser = userData => true