import { Messages, texti18n, GLOBAL_FIELD_KEY } from './MessageConstants'
import { FETCH_OLD_RESPONSE_ARRIVED_STRING_CONST, FETCH_TIMEOUT_ERROR_STRING_CONST } from './AppConstants'
import { showInfo, showError, isOnline } from '../services/SharedServices'
import AppConfig from 'dpn-common/config/AppConfig'
import { timeoutPromise } from './HelperFunctions'

export const tryCatchJson = async (fetchPromise, showErrorMessage = false, catchErrorMessage = Messages.NETWORK_ERROR) => {
    try {
        const fullPromise = fetchPromise.then(response => getJson(response))
        const { isErrorMessage, responseData } = await timeoutPromise(fullPromise, AppConfig.fetchTimeout, FETCH_TIMEOUT_ERROR_STRING_CONST)
        if (showErrorMessage && !!responseData.message) {
            if (isErrorMessage) {
                showError(responseData.message)
            }
            else {
                showInfo(responseData.message)
            }
        }
        return responseData
    }
    catch (error) {
        console.log(error)
        if (!await isOnline()) {
            showError(Messages.NOT_CONNECTED_TO_INTERNET)
            return createErrorResponse(GLOBAL_FIELD_KEY, Messages.NOT_CONNECTED_TO_INTERNET)
        }
        else if (error instanceof Error && error.message === FETCH_TIMEOUT_ERROR_STRING_CONST) {
            showErrorMessage && showError(Messages.FETCH_TIMED_OUT)
            return createErrorResponse(GLOBAL_FIELD_KEY, Messages.FETCH_TIMED_OUT)
        }
        else if (error instanceof Error && error.message === FETCH_OLD_RESPONSE_ARRIVED_STRING_CONST) {
            return createErrorResponse(GLOBAL_FIELD_KEY, Messages.FETCH_OLD_RESULT_ARRIVED)
        }
        else if (showErrorMessage) {
            showError(catchErrorMessage)
        }
        return createErrorResponse(GLOBAL_FIELD_KEY, catchErrorMessage)
    }
}

export const getStringStream = async (fetchPromise, errorMessage = Messages.ERROR_RETRIEVING_LANG_DEF) => {
    try {
        const fullPromise = fetchPromise.then(response => {
            if (response.status === 200) {
                return response.text()
            }
            return null
        })
        const text = await timeoutPromise(fullPromise, AppConfig.fetchTimeout, FETCH_TIMEOUT_ERROR_STRING_CONST)
        return text
    }
    catch (error) {
        console.log(error)
        errorMessage && showError(errorMessage)
        return null
    }
}

function translateAndFixResponse(responseData) {
    if (responseData.success) {
        return responseData
    }

    const errorFieldNames = Object.keys(responseData.errors)
    // translate all error messages
    errorFieldNames.forEach(x => {
        const error = responseData.errors[x]
        error.message = texti18n(error.message)
    })

    // populate message and messageCode with first error
    const firstErrorField = responseData.errors[errorFieldNames[0]]
    responseData.message = firstErrorField.message
    responseData.messageCode = firstErrorField.messageCode
    return responseData
}

export function createErrorResponse(errorField, errorMessage) {
    errorMessage = texti18n(errorMessage)
    return {
        success: false,
        message: errorMessage,
        errors: {
            [errorField]: {
                message: errorMessage,
            }
        }
    }
}

async function getJson(response) {
    let responseData
    let isErrorMessage = true
    if (response.status === 401) {
        if(response.headers.has('www-authenticate') && response.headers.get('www-authenticate').includes('invalid_token')) {
            isErrorMessage = false
            console.log('Login has expired!')
            responseData = createErrorResponse(GLOBAL_FIELD_KEY, texti18n(Messages.LOGIN_HAS_BEEN_EXPIRED))
        }
        else {
            console.log('Unauthorized access!')
            responseData = createErrorResponse(GLOBAL_FIELD_KEY, texti18n(Messages.UNAUTHORIZED_ACCESS))
        }
    }
    else if (response.status == 404) {
        responseData = createErrorResponse(GLOBAL_FIELD_KEY, texti18n(Messages.NOT_FOUND_404_ERROR))
    }
    else if (response.status == 400 || response.status < 300 || response.status >= 500) {
        const data = await response.json()
        responseData = translateAndFixResponse(data)
    }
    else {
        responseData = createErrorResponse(GLOBAL_FIELD_KEY, texti18n(Messages.UNKNOWN_ERROR_OCCURRED))
    }

    responseData.responseStatusCode = response.status
    return {
        responseData,
        isErrorMessage
    }
}
