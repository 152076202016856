import React from 'react'

export const LandingConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/landing',
            component: React.lazy(() => import('./Landing')),
            applicationPath: ['_GuestHome', 'Landing']
        },
        {
            path: '/landing-early-adopter',
            component: React.lazy(() => import('./EarlyAdopter')),
            applicationPath: 'EarlyAdopter'
        }
    ]
}