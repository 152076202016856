import { createSelector } from 'reselect'

export const stickerEmoticonsRegex = createSelector(
    state => state || [],
    emoticons => {
        const codes = emoticons.map(x => x.code)
        const codesInRegex = codes.join('|')
        const regExp = `(${codesInRegex})`
        return new RegExp(regExp, 'gi')
    }
)

export const emoticonCodesSelector = createSelector(
    state => state,
    emoticons => {
        if (!emoticons || emoticons.length === 0) {
            return undefined
        }

        const regex = stickerEmoticonsRegex(emoticons)
        
        let emoticonUrls = {}
        emoticons.forEach(x => {
            emoticonUrls[x.code] = x.emoticonUrl
        })

        return { regex, emoticonUrls }
    }
)

export const utfEmoticons = createSelector(
    state => state || [],
    emoticons => {
        return emoticons.filter(x => x.emoticonUrl === '')
    }
)

export const stickerEmoticons = createSelector(
    state => state || [],
    emoticons => {
        return emoticons.filter(x => x.emoticonUrl !== '')
    }
)