
import React from 'react'
import PropTypes from 'prop-types'

function ArrowLeftCircleIcon(props) {
    const { color, size, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            fill={color}
            fillRule='evenodd'
            clipRule='evenodd'
            viewBox="0 0 24 24"
            {...otherProps}>
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm3 5.753l-6.44 5.247 6.44 5.263-.678.737-7.322-6 7.335-6 .665.753z"/>
        </svg>
    )
}

ArrowLeftCircleIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ArrowLeftCircleIcon.defaultProps = {
    color: 'white',
    size: 36,
}

export default ArrowLeftCircleIcon