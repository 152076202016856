import { createSelector } from 'reselect'
import { getFromDict } from '../utils/HelperFunctions'
import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'

const emptyArray = []

export const activeStudentsSelector = createSelector(
    students => students,
    students => students.filter(x => x.isActive && !x.isInvited)
)

export const invitedStudentsSelector = createSelector(
    students => students,
    students => students.filter(x => x.isInvited)
)

export const suspendedStudentsSelector = createSelector(
    students => students,
    students => students.filter(x => !x.isActive && !x.isInvited)
)

export const filterStudentsByDaySelector = createSelector(
    state => state.students,
    state => state.perDayMap,
    state => state.day,
    (students, perDayMap, day) => {
        if (day === '' || day === undefined || day === null) {
            return students
        }
        const idMap = getFromDict(perDayMap, day, {})
        return students.filter(x => x.defaultUserGroupId in idMap).map(x => ({
            ...x,
            fromTimeInMinutes: idMap[x.defaultUserGroupId],
        }))
    }
)

export const filterItemsSelector = createSelector(
    state => state.items,
    state => state.fn,
    state => state.text,
    (items, fn, text) => {
        if (!!fn && text !== '') {
            return fn(items, text)
        }
        return items
    }
)

export const sortItemsSelector = createSelector(
    state => state.items,
    state => state.fn,
    (items, fn) => items.sort(fn)
)

export const mergedStudentsSelector = createSelector(
    state => activeStudentsSelector(state.students),
    state => invitedStudentsSelector(state.students),
    state => suspendedStudentsSelector(state.students),
    (active, invited, suspended) => {
        let result = [].concat(active)
        if (invited.length > 0) {
            // we need to mark last item before label with skipSeparator because we do not need separator before label 
            if (result.length > 0) {
                result[result.length - 1] = { ...result[result.length - 1], skipSeparator: true }
            }
            const label = { id: 'l0', title: texti18n(Messages.INVITED), isTextLabel: true, isInvited: true, skipSeparator: true }
            result = result.concat(label).concat(invited)
        }
        if (suspended.length > 0) {
            if (result.length > 0) {
                result[result.length - 1] = { ...result[result.length - 1], skipSeparator: true }
            }
            const label = { id: 'l1', title: texti18n(Messages.SUSPENDED), isTextLabel: true, isInvited: false, skipSeparator: true }
            result = result.concat(label).concat(suspended)
        }
        if (result.length > 0) {
            result[result.length - 1] = { ...result[result.length - 1], skipSeparator: true }
        }
        return result
})

const createMapOfItemsByIdReduceFunc = (map, item) => {
    map[item.id] = item
    return map
}

export const createMapOfItemsById = createSelector(
    // or emptyArray because mapKey can exist in state but it could be undefined eq state.students === undefined
    state => getFromDict(state, state.mapKey, emptyArray) || emptyArray,
    items => {
        const result = items.reduce(createMapOfItemsByIdReduceFunc, {})
        return result
    }
)

const createMapOfItemsByUserIdReduceFunc = (map, item) => {
    map[item.userId] = item
    return map
}

export const createMapOfItemsByUserId = createSelector(
    // or emptyArray because mapKey can exist in state but it could be undefined eq state.students === undefined
    state => state.dict || {},
    state => state.mapKey,
    (dict, key) => {
        const items = getFromDict(dict, key, emptyArray) || emptyArray
        return items.reduce(createMapOfItemsByUserIdReduceFunc, {})
    }
)

export const createMapOfItemsByIdFromItems = createSelector(
    // or emptyArray because mapKey can exist in state but it could be undefined eq state.students === undefined
    state => state.items || emptyArray,
    items => {
        const result = items.reduce(createMapOfItemsByIdReduceFunc, {})
        return result
    }
)

export const treeSelector = createSelector(
    x => x.items,
    items => {
        function recursive(items, obj) {
            items.forEach(it => {
                it.children && recursive(it.children, obj)
                obj[it.id] = it
            })
            return obj
        }
        return recursive(items, {})
    }
)

export const selectItemsComponentFilterReselect = createSelector(
    x => x.filterItems,
    x => x.text || '',
    (filterItems, text) => filterItems(text)
)