import React from 'react'
import { Typography, Link } from '@material-ui/core'

export default class LinkComponent extends React.PureComponent {
    render() {
        return (
            <Typography component={'span'}>
                <Link {...this.props} />
            </Typography>
        )
    }
}