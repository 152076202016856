import { Messages, texti18n } from '../MessageConstants'
import VideoPlayerHelper, { isVimeoLink, isYouTubeLink, getVimeoVideoId } from './VideoPlayerHelper'
import { showError, canOpenURL, openURL } from '../../services/SharedServices'

const vimeoJsonUrl = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/'

const vimeoImageUrlBase = 'https://i.vimeocdn.com/video/'

const vimeoImageUrlImageParams = '?mw=960' // width 960 pixels

const imageExtensions = Object.freeze([
    '.jpg',
    '.png',
    '.gif'
])

const translate = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>'
}

export const getLinkData = async (link) => {
    const data = { linkTitle: '', imageUrl: '' }
    if (isVimeoLink(link)) {
        const videoId = getVimeoVideoId(link)
        const text = await getTextFromLink(vimeoJsonUrl + videoId)
        if (text) {
            try {
                const json = JSON.parse(text)
                const thumbnailId = getVimeoVideoId(json.thumbnail_url)
                data.imageUrl = `${vimeoImageUrlBase}${thumbnailId}${vimeoImageUrlImageParams}`
                data.linkTitle = json.title
            }
            catch (ex) {
                console.log(`error retrieving vimeo thumnbnail: ${text}. exception: ${ex}`)
            }
        }
    }
    else {
        const html = await getTextFromLink(link)
        if (html) {
            data.linkTitle = getLinkTitle(html)
            data.imageUrl = getLinkImage(html)
        }
    }

    return data
}

const getLinkTitle = html => {
    return filterTitle(getTitleFromHTML(html))
}

const getLinkImage = html => {
    const startSearchString = 'property="og:image" content="'
    const endSearchString = '">'
    const startIndex = html.indexOf(startSearchString)
    const substr = html.substring(startIndex + startSearchString.length)
    const endIndex = substr.indexOf(endSearchString)
    if (startIndex === -1 || endIndex === -1) {
        return ''
    }
    const fromIndex = startIndex + startSearchString.length
    return html.substring(fromIndex, fromIndex + endIndex)
}

const getTextFromLink = async (link) => {
    try {
        const response = await fetch(link)
        const html = await response.text()
        return html
    }
    catch (ex) {
        console.log('getTextFromLink failed for link: ' + link, ex)
    }
}

const getTitleFromHTML = (html) => {
    const startSearchString = '<title>'
    const endSearchString = '</title>'
    const startIndex = html.indexOf(startSearchString)
    const endIndex = html.indexOf(endSearchString)
    if (startIndex === -1 || endIndex === -1) {
        return ''
    }
    return html.substring(startIndex + startSearchString.length, endIndex)
}

const filterTitle = (title) => {
    let newTitle = title
    for (const key in translate) {
        if (title.indexOf(key) !== -1) {
            const re = new RegExp(key, 'gi')
            newTitle = newTitle.replace(re, translate[key])
        }
    }
    return newTitle
}

export function playVideo(link) {
    if (isYouTubeLink(link)) {
        VideoPlayerHelper.playYouTubeVideo(link)
    }
    else if (isVimeoLink(link)) {
        VideoPlayerHelper.playVimeoVideo(link)
    }
    else {
        openURL(link, () => showError(texti18n(Messages.COULD_NOT_OPEN_THE_LINK)))
    }
}

export const isImageLink = link => {
    if (!link) {
        return false
    }
    for (let i = 0; i < imageExtensions.length; ++i) {
        if (link.endsWith(imageExtensions[i])) {
            return true
        }
    }
    return false
}

export const loadLinkTitle = async link => {
    const canOpen = await canOpenURL(link)

    let changedState = { isLoading: false, finishedLoading: true }

    if (canOpen) {
        const isImage = isImageLink(link)
        if (isImage) {
            changedState = { ...changedState, imageUrl: link }
        }
        else if (isYouTubeLink(link) || isVimeoLink(link)) {
            const data = await getLinkData(link)
            if (data) {
                changedState = { ...changedState, ...data }
            }
        }
    }

    return changedState
}