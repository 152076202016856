import ActionTypes from '../actions/ActionTypes'

const initialState = {
    originalAccountAccessToken: undefined,
    inProgress: false,
}

function automaticLoginReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_ORIGINAL_ACCOUNT_ACCESS_TOKEN:
        return Object.assign({}, state, {
            originalAccountAccessToken: action.originalAccountAccessToken
        })
    case ActionTypes.SET_AUTOMATIC_LOGIN_IN_PROGRESS:
        return Object.assign({}, state, {
            inProgress: action.inProgress
        })
    default:
        return state
    }
}

export default automaticLoginReducer