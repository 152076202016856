import ActionTypes from './ActionTypes'
import { 
    flashCardsGet, pianoAdventuresBooksGet, videoTutorialsGet,
    teacherVideoTutorialsGet
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { Messages, texti18n } from '../utils/MessageConstants'
import { showSuccess } from '../services/SharedServices'
import {
    forgotEmailGet, forgotPasswordGet, registerStudentPost, registerTeacherPost, sendEmailPost,
    genericTemporaryForJwtTokenPost,
    resetPasswordPost
} from 'dpn-common/services/IdentityEndpointsService'

export async function loadFlashCardsFetch(groupId, dispatch) {
    const fetchPromise = flashCardsGet(groupId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && 
        dispatch(setFlashCards(groupId, responseData.items))
    return responseData
}

export async function loadPianoAdventuresBooksFetch(groupId, dispatch) {
    const fetchPromise = pianoAdventuresBooksGet(groupId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setPianoAdventuresBooks(groupId, responseData.items))
    return responseData
}

export async function getVideoTutorialsFetch(isMobile, dispatch) {
    const fetchPromise = videoTutorialsGet(isMobile)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setVideoTutorials(responseData.items))
    return responseData
}

export async function getTeacherVideoTutorialsFetch(dispatch) {
    const fetchPromise = teacherVideoTutorialsGet()
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setTeacherVideoTutorials(responseData.items))
    return responseData
}

export async function registerTeacherFetch(data, showSuccessMessage = true) {
    const fetchPromise = registerTeacherPost(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        showSuccessMessage && showSuccess(texti18n(Messages.SIGN_UP_SUCCESSFULLY))
    } 
    return responseData
}

export async function registerStudentFetch(data, showSuccessMessage = true) {
    const fetchPromise = registerStudentPost(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        showSuccessMessage && showSuccess(texti18n(Messages.SIGN_UP_SUCCESSFULLY))
    } 
    return responseData
}

export async function forgotPasswordFetch({ email, username }) {
    const fetchPromise = forgotPasswordGet(email, username)
    const responseData = await tryCatchJson(fetchPromise)
    return responseData
}

export async function forgotEmailFetch({ username }) {
    const fetchPromise = forgotEmailGet(username)
    const responseData = await tryCatchJson(fetchPromise)
    return responseData
}

export async function resetPasswordFetch(data) {
    const fetchPromise = resetPasswordPost(data)
    const responseData = await tryCatchJson(fetchPromise)
    return responseData
}

export async function sendEmailFetch(params) {
    const fetchPromise = sendEmailPost(params)
    const responseData = await tryCatchJson(fetchPromise)
    return responseData
}

export async function getTemporaryForJwtToken() {
    const fetchPromise = genericTemporaryForJwtTokenPost()
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData
}

export function homeScreenAccessTimesIncrement(dispatch) {
    dispatch({ type: ActionTypes.HOME_SCREEN_ACCESS_TIMES_INC })
}

function setFlashCards(groupId, flashCards) {
    return { type: ActionTypes.SET_FLASH_CARDS_ACTION, flashCards, groupId }
}

function setPianoAdventuresBooks(groupId, pianoAdventuresBooks) {
    return { type: ActionTypes.SET_PIANO_ADVENTURES_BOOKS, pianoAdventuresBooks, groupId }
}

function setVideoTutorials(videoTutorials) {
    return { type: ActionTypes.SET_VIDEO_TUTORIALS, videoTutorials }
}

function setTeacherVideoTutorials(videoTutorials) {
    return { type: ActionTypes.SET_TEACHER_VIDEO_TUTORIALS, videoTutorials }
}