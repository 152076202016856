export const appBaseColor = 'rgba(0, 173, 238, 1)'
export const lightBlueColor = '#DFECF3'
export const assignmentSubtitleColor = 'rgba(0, 0, 0, 0.54)'
export const deleteColor = 'red'
export const lessonUnpublishedColor = '#FF6600'

export const blueBubbleBtnStyle = {
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: appBaseColor
}