
import ActionTypes from './ActionTypes'
import { 
    schoolYearsGet, deleteSchoolYearDelete, addSchoolYearPost,
    updateSchoolYearPut, activateSchoolYearPost
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { Messages, texti18n } from '../utils/MessageConstants'
import { NavigatorService, showSuccess } from '../services/SharedServices'

export async function activateSchoolYearFetch(schoolYear, dispatch, goBack) {
    const fetchPromise = activateSchoolYearPost(schoolYear.id)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const isActive = responseData.value
        dispatch(setSchoolYearIsActive(schoolYear.id, isActive))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function getSchoolYearsFetch(teacherId, isTeacher, dispatch) {
    const fetchPromise = schoolYearsGet(teacherId, null)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setSchoolYears(responseData.items, isTeacher ? 0 : teacherId))
    return responseData
}

export async function addSchoolYearFetch(data, dispatch, goBack) {
    const fetchPromise = addSchoolYearPost(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(addSchoolYear(value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function updateSchoolYearFetch(data, dispatch, goBack) {
    const fetchPromise = updateSchoolYearPut(data.id, data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(updateSchoolYear(value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function deleteSchoolYearFetch(schoolYear, dispatch, goBack) {
    const fetchPromise = deleteSchoolYearDelete(schoolYear.id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteSchoolYear(schoolYear))
        showSuccess(texti18n(Messages.SCHOOL_YEAR_HAS_BEEN_DELETED))
        goBack && NavigatorService.goBack()
    }
    return responseData
}

function setSchoolYearIsActive(id, isActive) {
    return { type: ActionTypes.SCHOOL_YEAR_ACTIVATE_ACTION, id, isActive, teacherId: 0 }
}

function setSchoolYears(schoolYears, teacherId) {
    return { type: ActionTypes.SCHOOL_YEARS_SET_ACTION, schoolYears, teacherId }
}

function addSchoolYear(schoolYear) {
    return { type: ActionTypes.SCHOOL_YEAR_ADD_ACTION, schoolYear, teacherId: 0 }
}

function updateSchoolYear(schoolYear) {
    return { type: ActionTypes.SCHOOL_YEAR_UPDATE_ACTION, schoolYear, teacherId: 0 }
}

function deleteSchoolYear(schoolYear) {
    return { type: ActionTypes.SCHOOL_YEAR_DELETE_ACTION, schoolYear, teacherId: 0 }
}