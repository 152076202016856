import React from 'react'

export const StudentsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/students',
            component: React.lazy(() => import('./Students')),
            applicationPath: 'TeacherStudentList',
        }

    ]
}

