import ActionTypes from '../actions/ActionTypes'
import { 
    insertAtPosition,
} from '../utils/HelperFunctions'

const initialState = {
    sentences: undefined,
}

function sentencesReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SENTENCE_ADD_ACTION:
        return Object.assign({}, state, {
            sentences: insertAtPosition(state.sentences, action.sentence, compareFunc)
        })
    case ActionTypes.SENTENCE_UPDATE_ACTION:
        return Object.assign({}, state, {
            sentences: replaceItem(state.sentences, action.sentence, compareFunc)
        })
    case ActionTypes.SENTENCES_SET_ACTION:
        return Object.assign({}, state, {
            sentences: action.sentences
        })
    case ActionTypes.SENTENCE_DELETE_ACTION:
        return Object.assign({}, state, {
            sentences: state.sentences.filter(x => x.id !== action.sentence.id || x.isGlobal !== action.sentence.isGlobal)
        })
    default:
        return state
    }
}

export default sentencesReducer

function compareFunc(sentence) {
    return x => {
        // return user defined first
        if (x.isGlobal !== sentence.isGlobal) {
            return !sentence.isGlobal
        }
        if (x.order != sentence.order) {
            return x.order > sentence.order
        }
        // desc order of id - newly created appears first
        return x.id < sentence.id
    }
}

function replaceItem(items, newItem, itemCompareFunc) {
    const item = items.find(x => x.id === newItem.id && x.isGlobal === newItem.isGlobal)
    if (item) {
        const newItems = items.filter(x => x.id !== newItem.id || x.isGlobal !== newItem.isGlobal)
        return insertAtPosition(newItems, newItem, itemCompareFunc)
    }
    return items
}