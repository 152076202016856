export const FETCH_OLD_RESPONSE_ARRIVED_STRING_CONST = 'fetch_old_response_arrived'
export const FETCH_TIMEOUT_ERROR_STRING_CONST = 'fetch_timeout_error'

export const SETTINGS_STORAGE_KEY = 'SETTINGS_STORAGE_KEY_x1_'
export const LANGUAGE_DEF_STORAGE_PREFIX_KEY = 'LANGUAGE_x5_'
export const LANGUAGES_STORAGE_PREFIX_KEY = 'LANGUAGES_xyz_'

export const STUDENT_DETAILS = 'STUDENT_DETAILS_v4_'

export const NOT_SHOW_AGAIN = 'NOW_SHOW_AGAIN_x3'
export const NOT_SHOW_AGAIN_CUSTOM_ASSIGN_LEVELS = 'NOT_SHOW_AGAIN_CUSTOM_ASSIGN_LEVELS_x3'

export const ORIGINAL_ACCOUNT_TOKEN = 'ORIGINAL_ACCOUNT_TOKEN_x7'

export const STUDENTS_LIMIT_REACHED_MESSAGE_CODE = 'STUDENTS_LIMIT_REACHED'

export const ROUTE_LOCATION = 'ROUTE_LOCATION'

export const DUMMY_LANGUAGE_DEFINITION = {
    version: 0,
    code: 'en-US',
    definitions: {},
}

export const USER_TYPES = Object.freeze({
    STUDENT: 'Student',
    TEACHER: 'Teacher',
    GUEST: 'Guest',
})

export const ROLE_ID = Object.freeze({
    TEACHER: 3,
    STUDENT: 4,
})

export const NOTE_TYPE = Object.freeze({
    USER: 'User',
    GROUP: 'Group',
    LESSON: 'Lesson'
})

export const IDENTITY_SERVICE = 'identity'

export const GROUP_TYPES = Object.freeze({
    SITE_GLOBAL: 'SiteGlobal',
    SITE_GLOBAL_TEACHERS: 'SiteGlobalTeachers',
    SITE_GLOBAL_STUDENTS: 'SiteGlobalStudents',
    TEACHER_GLOBAL: 'TeacherGlobal',
    TEACHER_CUSTOM: 'TeacherCustom',
    TEACHER_SINGLE_STUDENT: 'TeacherSingleStudent',
})

export const GROUP_FETCH_PARAMS = Object.freeze({
    loadGlobalGroups: false,
    loadUsers: true,
    loadSingleStudentGroups: true,
    loadSchedules: true
})

export const FIRST_TIME_GROUP_FETCH_PARAMS = Object.freeze({
    loadGlobalGroups: true,
    loadUsers: true,
    loadSingleStudentGroups: true,
    loadSchedules: true
})

export const ARE_UTF8_EMOTICONS_AVAILABLE = false

export const TABS = Object.freeze({
    ACTIVE: 1,
    INVITED: 2,
    SUSPENDED: 3,
    CURRENT: 4,
    PREVIOUS: 5,
    SKILLS: 6,
    REWARDS: 7,
    INACTIVE: 8,
    CLASS_POINTS: 9,
    MY_POINTS: 10,
    CHANNELS: 11,
    CHAT: 12,
    STUDENT_LIST: 13,
    GROUPS: 14,
    INVITE_STUDENT: 15,
    INVITE_STUDENT_CHILD: 16,
})

export const COLORS = Object.freeze([
    { hexCode: '#1A1818', name: 'Black' },
    { hexCode: '#29AAE1', name: 'Blue' },
    { hexCode: '#D5AA80', name: 'Bronze' },
    { hexCode: '#AE7165', name: 'Brown' },
    { hexCode: '#669180', name: 'Forest' },
    { hexCode: '#E7B948', name: 'Gold' },
    { hexCode: '#808080', name: 'Gray' },
    { hexCode: '#4DB297', name: 'Green' },
    { hexCode: '#1F324A', name: 'Navy' },
    { hexCode: '#DC5536', name: 'Orange' },
    { hexCode: '#EC1561', name: 'Pink' },
    { hexCode: '#5D52A2', name: 'Purple' },
    { hexCode: '#D7242A', name: 'Red' },
    { hexCode: '#999999', name: 'Silver' },
    { hexCode: '#5BC4C3', name: 'Turquoise' },
    { hexCode: '#B093C5', name: 'Violet' },
    { hexCode: '#FFFFFF', name: 'White', txtColor: '#000000' },
    { hexCode: '#FACF1E', name: 'Yellow' },
])

export const SWIPE_DEFINITIONS = Object.freeze({
    LEFT_SWIPE_MARGIN_OFFSET: -50,
    ANIMATION_DURATION: 300,
    VELOCITY_THRESHOLD: 0.3,
    DIRECTIONAL_OFFSET_THRESHOLD: 15,
    HOLD_BEFORE_SWIPE_DELAY: 300,
})

export const StudentChooseTeacherRouteName = 'StudentChooseTeacher'

export const CONTACT_TEMPLATE_NAME = 'Contact'

export const IMPORT_FILE_CONTENT = true

export const REFRESH_TOKEN_INTERVAL_TIMEOUT = 14 * 60 * 1000

export const ACCENTS_MAP = {
    a: 'à|â|ä|À|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'ï|î|Ï|Î',
    o: 'ô|ö|Ô|Ö',
    u: 'ù|û|ü|Ù|Û|Ü',
    c: 'ç|Ç',
    y: 'ÿ|Ÿ',
    ae: 'æ|Æ',
    oe: 'œ|Œ',
    ss: 'ß|ẞ',
}