import React from 'react'

export const StudentLeaderboardNotificationSettingsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-settings/leaderboard-notification',
            component: React.lazy(() => import('./StudentLeaderboardNotificationSettings')),
            applicationPath: 'StudentLeaderboardNotificationSettings',
        },
    ]
}