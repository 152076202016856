import SettingsService from '../services/SettingsService'

export const GLOBAL_FIELD_KEY = ''

export const Messages = Object.freeze({
    BACK_TO_LEADERBOARD_SETTINGS: 'Back to Leaderboard Settings',
    CLOSE: 'Close',
    GROUP_INFO: 'Group info',
    NO_UPLOADS_TO_SHOW: 'No uploads to show',
    CURRENT_COLOR: 'User Color',
    PDF: 'PDF',
    BACK_TO_REWARDS_STORE: 'Back to Rewards Store',
    BACK_TO_GROUP_SELECTION: 'Back to Group Selection',
    GROUP_SELECTION: 'Group Selection',
    GROUP_HAS_BEEN_DELETED: 'Group has been deleted',
    BACK_TO_STUDENT_INFO: 'Back to Student Info',
    CUSTOM: 'Custom',
    START_TIME: 'Start Time',
    END_TIME: 'End Time',
    SCHEDULE: 'Schedule',
    FOR_THIS_GROUP: 'For this group',
    BACK_TO_GROUP_PROFILE: 'Back to Group Profile',
    STUDENT_OR_GROUP: 'Student or Group',
    GROUP: 'Group',
    GROUPS: 'Groups',
    SEARCH: 'Search',
    SEARCH_GROUPS: 'Search Groups',
    ADD_NEW_GROUP: 'Add New Group',
    ADD_GROUP: 'Add Group',
    EDIT_GROUP: 'Edit Group',
    GROUP_NAME: 'Group Name',
    GROUP_MEMBERS: 'Group Members',
    BACK_TO_ADD_GROUP: 'Back to Add Group',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_GROUP: 'Are you sure you want to delete group?',
    NO_GROUPS_TO_SHOW: 'No groups to show',
    BACK_TO_GROUPS: 'Back to Groups',
    SELECT_STUDENTS: 'Select Students',
    ADD_STUDENTS: 'Add Students',
    DAY: 'Day',
    TIME: 'Time',
    SELECT_DAY: 'Select Day',
    SELECT_TIME: 'Select Time',
    CUSTOM_ASSIGN_LEVELS_TEXT: 'You can customize assign levels that you can assign to students by clicking Ok or by opening the side menu and going to:',
    SUBSCRIPTIONS_ENDPOINT: 'Payment/Purchase',
    FULL_ACCESS_GRANTED: 'Activation code applied. Full access granted!',
    ACTIVATION_CODE: 'Activation Code',
    FOR_MORE_INFORMATION: 'For more information, go to the following link:',
    MAX_NUMBER_OF_STUDENTS_REACHED: 'Maximum number of students reached!',
    ACTIVATION: 'Activation',
    ACTIVATION_CODE_APPLIED: 'Activation Code applied!',
    FAILED_TO_GET_IMAGE_SIZE: 'Failed to get image size.',
    FAILED_TO_EDIT_THE_IMAGE: 'Failed to edit the image.',
    AVATAR_IMAGE_EDITOR_HELP: 'You can zoom the image inside the circle and then center it.',
    PREVIEW: 'Preview',
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SKILL: 'Are you sure you want to remove this skill?',
    ARE_YOU_SURE_YOU_WANT_TO_RETURN_REWARD: 'Are you sure you want to return this reward?',
    REMOVE_SKILLS: 'Remove Skills',
    OPTIONAL: 'Optional',
    FOR_YOU: 'For You',
    FOR_EVERYONE: 'For Everyone',
    SIGN_UP: 'Sign Up',
    LOGIN: 'Login',
    PIANO_ADVENTURES_INTERACTIVE_PRESETS: 'Piano Adventures Interactive Presets',
    CUSTOM_PRESETS: 'Custom Presets',
    PIANO_ADVENTURES_INTERACTIVE_HUB: 'Piano Adventures Interactive Hub',
    INTERACTIVE_HUB: 'My Hub',
    PIANO_ADVENTURES_COURSES: 'Piano Adventures Courses',
    COURSES: 'Courses',
    PIANO_ADVENTURES_ASSIGNMENTS: 'Piano Adventures Assignments',
    ASSIGN_LEVEL_PRESET_HAS_BEEN_DELETED: 'Assign Level Preset has been deleted',
    TEXT_PRESET_HAS_BEEN_DELETED: 'Text Preset has been deleted',
    NO_TEXT_PRESETS_TO_SHOW: 'No text presets to show',
    NO_PRESETS_TO_SHOW: 'No presets to show',
    BACK_TO_TEXT_PRESETS: 'Back to Text Presets',
    EDIT_TEXT_PRESET: 'Edit Text Preset',
    ADD_TEXT_PRESET: 'Add Text Preset',
    ADD_NEW_TEXT_PRESET: 'New Text Preset',
    TEXT_PRESETS: 'Text Presets',
    NO_ASSIGN_LEVEL_PRESETS_TO_SHOW: 'No assign level presets to show',
    BACK_TO_ASSIGN_LEVEL_PRESETS: 'Back to Assign Level Presets',
    EDIT_ASSIGN_LEVEL_PRESET: 'Edit Assign Level Preset',
    ADD_ASSIGN_LEVEL_PRESET: 'Add Assign Level Preset',
    ASSIGN_LEVEL_PRESETS: 'Assign Level Presets',
    ADD_NEW_ASSIGN_LEVEL_PRESET: 'New Assign Level Preset',
    BACK_TO_PRESETS: 'Back to Presets',
    STUDENT_PROFILE: 'Student Profile',
    MY_DASHBOARD: 'My Dashboard',
    MY_TEACHER: 'My Teacher',
    LEADERBOARD_CODE: 'L',
    SEARCH_STUDENTS: 'Search Students',
    SEARCH_MESSAGES: 'Search Messages',
    SEARCH_LESSONS: 'Search Lessons',
    TEACHER_RESOURCES: 'Tips and Tricks',
    REWARDS_STORE: 'Rewards Store',
    ADD_NEW_PRESET: 'Add New Preset',
    BACK_TO_COMMENT_SENTENCES: 'Back to Comment Sentences',
    ENTER_NAME: 'Enter Name',
    ADD_SCHOOL_YEAR: 'Add School Year',
    EDIT_SCHOOL_YEAR: 'Edit School Year',
    ADD_SENTENCE: 'Add Sentence',
    EDIT_SENTENCE: 'Edit Sentence',
    TEXT: 'Text',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SENTENCE: 'Are you sure you want to delete this sentence?',
    ADD_NEW_SENTENCE: 'New Sentence',
    COMMENT_SENTENCES: 'Comment Sentences',
    SENTENCE_HAS_BEEN_DELETED: 'Sentence has been deleted.',
    DO_NOT_SHOW_AGAIN: 'Don\'t show again',
    SHOW_TUTORIALS: 'Show Tutorials?',
    INVITATIONS: 'Invitations',
    CATEGORY_OR_SUBCATEGORY_COLOR: 'Category or Subcategory color',
    CAN_NOT_CREATE_ASSIGNMENT_OR_CATEGORY_HERE: 'A Category or Subcategory can not contain both Assignments and other Categories or Subcategories at the same time.',
    DESCRIPTION_TITLE: 'Description Title',
    CAN_NOT_DELETE_CHANNEL_MESSAGES: 'Can not delete channel messages.',
    STICKER: 'Sticker',
    NO_LEVELS_TO_SHOW: 'No Levels to show',
    NO_BOOKS_TO_SHOW: 'No Books to show',
    NO_UNITS_TO_SHOW: 'No Units to show',
    NO_ASSIGNMENTS_TO_SHOW: 'No Assignments to show',
    BACK_TO_UNIT: 'Back to Unit',
    BACK_TO_UNIT_LIST: 'Back to Unit List',
    BACK_TO_BOOK_LIST: 'Back to Book List',
    BACK_TO_LEVEL_LIST: 'Back to Level List',
    BACK_TO_ASSIGNMENT: 'Back to Assignment',
    CLOSE_BUTTON_TITLE: '  Close  ',
    INFORMATION: 'Information',
    DONE: 'Done',
    CAN_NOT_DELETE_THIS_SUBCATEGORY: 'Can not delete this Subcategory while it contains other Subcategories or Assignments.',
    CAN_NOT_DELETE_THIS_CATEGORY: 'Can not delete this Category while it contains Subcategories or Assignments.',
    CAN_NOT_DELETE_ALL_CATEGORIES: 'Can not delete Categories while they contains Subcategories or Assignments.',
    COLOR: 'Color',
    NEXT: 'Next',
    CATEGORIES: 'Categories',
    SUBCATEGORIES: 'Subcategories',
    NEW_CATEGORY: 'New Category',
    NEW_SUBCATEGORY: 'New Subcategory',
    NO_CATEGORIES_OR_ASSIGNMENTS_TO_SHOW: 'No Categories or Assignments to show',
    NO_SUBCATEGORIES_OR_ASSIGNMENTS_TO_SHOW: 'No Subcategories or Assignments to show',
    BACK_TO_CATEGORIES: 'Back to Categories',
    BACK_TO_SUBCATEGORIES: 'Back to Subcategories',
    EDIT_SUBCATEGORY: 'Edit Subcategory',
    EDIT_CATEGORY: 'Edit Category',
    ADD_SUBCATEGORY: 'Add Subcategory',
    ADD_CATEGORY: 'Add Category',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT_PRESET: 'Are you sure you want to delete this Text Preset?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ASSIGN_LEVEL_PRESET: 'Are you sure you want to delete this Assign Level Preset?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SUBCATEGORY: 'Are you sure you want to delete this Subcategory?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CATEGORY: 'Are you sure you want to delete this Category?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ITEMS: 'Are you sure you want to delete selected items?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_CATEGORIES: 'Are you sure you want to delete selected Categories?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_ASSIGNMENTS: 'Are you sure you want to delete selected Assignments?',
    SUBCATEGORY_DELETED_SUCCESSFULLY: 'Subcategory deleted successfully',
    NUMBER_OF_CATEGORIES_DELETED_SUCCESSFULLY: 'Number of Categories deleted: {0}',
    CATEGORIES_DELETED_SUCCESSFULLY_FAILED: 'Number of Categories deleted: {0}. Number of Categories failed to deleted: {1}.',
    NUMBER_OF_ASSIGNMENTS_DELETED_SUCCESSFULLY: 'Number of Assignments deleted: {0}',
    ASSIGNMENTS_DELETED_SUCCESSFULLY_FAILED: 'Number of Assignments deleted: {0}. Number of Assignments failed to deleted: {1}.',
    CATEGORY_DELETED_SUCCESSFULLY: 'Category deleted successfully',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_MESSAGE: 'Are you sure you want to delete this message?',
    COPY: 'Copy',
    PIANO_ADVENTURES_LINK: 'https://pianoadventures.com',
    PRIVACY_TERMS_LINK: '/docs/privacy-terms.html',
    ABOUT_LINK: 'https://pianoadventures.com/about/',
    LICENCE_AGREEMENT_LINK: '/docs/licence-agreement.html',
    PRIVACY_POLICY_LINK: '/docs/privacy-policy.html',
    EDIT_PROFILE: 'Edit Profile',
    COULD_NOT_LOAD_CORRECTLY: 'Could not load correctly',
    NOT_CONNECTED_TO_INTERNET: 'Not connected to internet',
    CONNECTED_TO_INTERNET: 'Connected to internet',
    DISCONNECTED_FROM_INTERNET: 'Disconnected from internet',
    NO_PRACTICE_STEPS_TO_SHOW: 'No practice steps to show',
    NO_COMMENTS_TO_SHOW: 'No comments to show',
    LOAD_MORE: 'Load More',
    NO_STUDENTS_TO_SHOW: 'No students to show',
    NO_LESSONS_TO_SHOW: 'No lessons to show',
    NO_SETTINGS_TO_SHOW: 'No settings to show',
    NO_NOTIFICATION_SETTINGS_TO_SHOW: 'No notification settings to show',
    NO_LANGUAGES_TO_SHOW: 'No languages to show',
    NO_SCHOOL_YEARS_TO_SHOW: 'No school years to show',
    NO_ITEMS_TO_SHOW: 'No items to show',
    NO_USERS_TO_SHOW: 'No users to show',
    NO_CONVERSATIONS_TO_SHOW: 'No conversations to show',
    NO_MESSAGES_TO_SHOW: 'No messages to show',
    NO_SKILLS_TO_SHOW: 'No skills to show',
    NO_REWARDS_TO_SHOW: 'No rewards to show',
    NO_VIDEO_TUTORIALS_TO_SHOW: 'No video tutorials to show',
    NO_TEACHER_RESOURCES_TO_SHOW: 'No teacher resources to show',
    NOTHING_TO_SHOW: 'Nothing to show',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    LEADERBOARD_IS_DISABLED: 'Leaderboard is disabled',
    IMPORT_DEMO_SKILLS: 'Import Demo Skills',
    IMPORT_DEMO_REWARDS: 'Import Demo Rewards',
    IMPORT_DEMO_LESSONS: 'Import Demo Lessons',
    IMPORT_DEMO_ASSIGNMENTS: 'Import Demo Assignments',
    IMPORT_DEMO_STUDENTS: 'Import Demo Student List',
    BACK_TO: 'Back to',
    ERROR_DATA_NOT_LOADED_CORRECTLY: 'Error retrieving data. Try swiping down to reload.',
    ERROR_RETRIEVING_LANG_DEF: 'Error while trying to retrieve language definitions',
    TOTAL: 'Total',
    AVAILABLE: 'Available',
    VIDEOS: 'Videos',
    PRACTICE_STEPS: 'Practice Steps',
    NOT_ENOUGH_POINTS_TO_BUY_THIS_REWARD: 'Not enough points to buy this reward',
    YOU_HAVE: 'You have',
    ADD_MORE: 'Add more...',
    LESSON_HAS_BEEN_DELETED: 'Lesson has been deleted',
    SCHOOL_YEAR_HAS_BEEN_DELETED: 'School Year has been deleted',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_LESSON: 'Are you sure you want to delete this lesson?',
    PIANO_LESSON: 'Piano Lesson',
    DASHBOARD: 'Dashboard',
    DASHBOARDS: 'Dashboards',
    CLONE_LAST_LESSON: 'Clone Last Lesson',
    CLONE: 'Clone',
    NO_PREVIOUS_LESSON_TO_CLONE: 'No previous lesson to clone',
    INFO: 'Info',
    SUCCESS: 'Success',
    ERROR: 'Error',
    COULD_NOT_OPEN_THE_LINK: 'Could not open the link',
    LESSON: 'Lesson',
    COMMENTS: 'Comments',
    ASSIGNMENT: 'Assignment',
    COMMENT_HAS_BEEN_DELETED: 'Comment has been deleted',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMMENT: 'Are you sure you want to delete this comment?',
    TO: 'To',
    AND: 'and',
    OTHERS: 'others',
    OTHER: 'other',
    SEND_MESSAGE: 'Send Message',
    ADMIN: 'admin',
    UNKNOWN: 'Unknown',
    COMING_SOON: 'Feature not ready yet. Coming soon!',
    COMMENT_HAS_BEEN_POSTED: 'Comment has been posted',
    CAN_NOT_LOAD_COMMENT_INFO: 'Can not load comment info',
    YES: 'Yes',
    NO: 'No',
    CHOOSE_WHICH_LIBRARY: 'Choose which Library...',
    CHOOSE_FROM_LIBRARY: 'Choose from Library...',
    TAKE_PHOTO: 'Take Photo...',
    CHOOSE_ONE_OF_PRE_MADE_AVATARS: 'Choose one of pre-made Avatars...',
    NO_PREMADE_AVATARS_TO_SHOW: 'No pre-made Avatars to show',
    CHOOSE_YOUR_AVATAR: 'Choose Your Avatar',
    CHOOSE_PICTURE_FROM_COMPUTER: 'Choose a picture from computer',
    ASSIGNMENT_HAS_BEEN_DELETED: 'Assignment has been deleted',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ASSIGNMENT: 'Are you sure you want to delete this assignment?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ASSIGNMENT_FROM_LESSON: 'Are you sure you want to delete this assignment from this lesson?',
    SORT_ASSIGNMENTS: 'Sort Assignments',
    SORT_NOTES: 'Sort Notes',
    SORT_CATEGORIES: 'Sort Categories',
    SORT_SUBCATEGORIES: 'Sort Subcategories',
    EDIT_ASSIGNMENT: 'Edit Assignment',
    EDIT_ASSIGNMENTS: 'Edit Assignments',
    ADD_ASSIGNMENT: 'Add Assignment',
    REMOVE_ASSIGNMENT: 'Remove Assignment',
    ADD_NEW_ASSIGNMENT: 'Add Assignment',
    IMPORT_ASSIGNMENTS: 'Import Assignments',
    EXPORT_ASSIGNMENTS: 'Export Assignments',
    EXPORT: 'Export',
    DESCRIPTION: 'Description',
    LINK: 'Link',
    LINKS: 'Links',
    ASSIGNMENTS: 'Assignments',
    NEW_ASSIGNMENT: 'New Assignment',
    NEW_PRIVATE_MESSAGE_RECEIVED: 'New chat message received!',
    BACK_TO_LESSON_LIST: 'Back to Lesson List',
    BACK_TO_LESSONS: 'Back to Lessons',
    BACK_TO_LESSON: 'Back to Lesson',
    BACK_TO_COMMUNICATION: 'Back to Communication',
    BACK_TO_CUSTOM_PRESET: 'Back to Custom Presets',
    BACK_TO_SETTINGS: 'Back to Settings',
    BACK_TO_STUDENT_PROFILE: 'Back to Student Profile',
    BACK_TO_DASHBOARD: 'Back to Dashboard',
    ARE_YOU_SURE_YOU_WANT_TO_ACCEPT_INVITATION: 'Are you sure you want to accept invitation?',
    STUDENTS: 'Students',
    TEACHERS: 'Teachers',
    HOME: 'Home',
    LOGOUT: 'Logout',
    BACK_TO_MY_SKILLS: 'Back to My Skills',
    BACK_TO_MY_POINTS: 'Back to My Points',
    BACK_TO_SKILLS: 'Back to Skills',
    BACK_TO_SCHOOL_YEAR: 'Back to School Year',
    BACK_TO_REWARDS: 'Back to Rewards',
    BACK_TO_ASSIGNMENTS: 'Back to Assignments',
    ADD: 'Add',
    REMOVE: 'Remove',
    SKILL_ADD_SUCCESSFUL: 'Skill added successfully',
    SKILL_REMOVE_SUCCESSFUL: 'Skill removed successfully',
    REWARD_BUY_SUCCESSFUL: 'Reward bought successfully',
    REWARD_RETURN_SUCCESSFUL: 'Reward returned successfully',
    BUY_REWARD: 'Buy reward',
    BUY: 'Buy',
    INVALID_FIELD: 'Invalid field',
    CLASS_POINTS: 'Class Points',
    MY_POINTS: 'My Points',
    MY_SKILLS: 'My Skills',
    MY_REWARDS: 'My Rewards',
    LEADERBOARD: 'Leaderboard',
    REWARDS: 'Rewards',
    ADD_REWARD: 'Add Reward',
    EDIT_REWARD: 'Edit Reward',
    SKILLS: 'Skills',
    ACTIVE: 'Active',
    ACTIVE_STATUS: 'ACTIVE',
    POINTS: 'Points',
    PERFORMANCE: 'Performance',
    EXPERTISE: 'Expertise',
    PERSONAL: 'Personal',
    PRIVATE_LESSON: 'Private Lesson',
    PRIVATE: 'Private',
    SELECT_PICTURE: 'Select picture',
    PICTURE: 'Picture',
    ADD_SKILL: 'Add Skill',
    EDIT_SKILL: 'Edit Skill',
    CURRENT_FILE_EXPORT_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
    CURRENT_DATE_TIME_FORMAT: 'MM/DD/YY, h:mm a',
    CURRENT_DATE_FORMAT: 'MM/DD/YYYY',
    CURRENT_TIME_FORMAT: 'h:mm a',
    CURRENT_SHORT_DATE_FORMAT: 'MM/DD/YY',
    CURRENT_READONLY_DATE_FORMAT: 'MMMM D, YYYY',
    CONFIRM: 'Confirm',
    SELECT_DATE: 'Select date',
    ARCHIVE: 'Archive',
    ARCHIVED_STATUS: 'ARCHIVED',
    LESSONS: 'Lessons',
    ADD_NEW_LESSON: 'Add New Lesson',
    EDIT_LESSON: 'Edit Lesson',
    NAME: 'Name',
    DATE: 'Date',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    VIEW_PREVIOUS_YEARS: 'View Previous Years',
    CHANGES_HAS_BEEN_SAVED: 'Changes have been saved',
    SAVE: 'Save',
    CURRENT: 'Current',
    PREVIOUS: 'Previous',
    COMPLETED: 'Completed',
    COMPLETED_LESSONS: 'Completed Lessons',
    CANCEL: 'Cancel',
    OK: 'Ok',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_STUDENT: 'Are you sure you want to delete student?',
    ASK_DIALOG_TITLE: 'Are you sure?',
    STUDENT_HAS_BEEN_ACTIVATED: 'Student has been activated',
    STUDENT_HAS_BEEN_SUSPENDED: 'Student has been suspended',
    STUDENT_HAS_BEEN_DELETED: 'Student has been deleted',
    BACK_TO_STUDENT_LIST: 'Back to Student List',
    STUDENT_LIST: 'Student List',
    ACTIVATE: 'Activate',
    SUSPEND: 'Suspend',
    DELETE: 'Delete',
    RESEND: 'Resend',
    NOT_FOUND_404_ERROR: 'Whoops, something went wrong...',
    UNKNOWN_ERROR_OCCURRED: 'Whoops, something went wrong...',
    LOGIN_HAS_BEEN_EXPIRED: 'Login has expired',
    INVITE_STUDENT_EMAIL_ANDROID_TITLE: 'Share details with student',
    INVITE_STUDENT_EMAIL_SUBJECT: 'You have new student invitation for DPL!',
    ASSIGN_LEVEL: 'Assign Level',
    SKILL_TYPE: 'Skill Type',
    ACCEPTED: 'Accepted',
    INVITED: 'Invited',
    SUSPENDED: 'Suspended',
    BACK_TO_PREVIOUS_SCREEN: 'Back to previous screen',
    BACK_TO_PIANO_ADVENTURES: 'Back to Piano Adventures',
    STUDENT: 'Student',
    TEACHER: 'Teacher',
    LOGIN_FORM_TITLE: 'Login',
    REGISTER_STUDENT_FORM_TITLE: 'Register Student',
    REGISTER_TEACHER_FORM_TITLE: 'Register Teacher',
    REGISTER: 'Register',
    ROLE: 'Role',
    FORGOT_PASSWORD_FORM_TITLE: 'Recover your password',
    SELECT_AVATAR_TITLE: 'Select avatar',
    PROFILE_AVATAR_HAS_BEEN_CHANGED: 'Avatar has been changed',
    BACK_TO_HOME: 'Back to Home',
    PROFILE_HAS_BEEN_CHANGED: 'Profile has been changed',
    LEAVE_EMPTY_TO_KEEP_OLD_PASSWORD: 'Empty to keep old password',
    EMAIL_HAS_BEEN_RESENT: 'Email has been resent',
    EMAIL_ALREADY_EXISTS: 'Email already exists',
    USERNAME_ALREADY_EXISTS: 'Username already exists',
    INVALID_EMAIL: 'Email is not valid',
    INVALID_USERNAME: 'Username is not valid',
    FIELD_IS_REQUIRED: 'Field is required',
    INVALID_FULL_NAME: 'Required (first and last name)',
    START_END_DATE_VALIDATION: 'Start date must be earlier than end date',
    CREATE_AN_ACCOUNT_MESSAGE: 'Create an account',
    CREATE_NEW_ACCOUNT_MESSAGE: 'Create new Account',
    HOME_LOGIN_LINK: 'I Already Have An Account',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    FULL_NAME: 'Full Name',
    USERNAME: 'Username',
    WHAT_IS_YOUR_USERNAME: 'What is your username?',
    EMAIL_ADDRESS: 'Email Address',
    EMAIL: 'Email',
    WHAT_IS_YOUR_EMAIL: 'What is your email?',
    PASSWORD: 'Password',
    BACK_TO_SIGN_UP: 'Back to sign up',
    INVALID_PASSWORD: 'Password must be greater than 6 chars',
    SUBMIT: 'Submit',
    NETWORK_ERROR: 'Network error',
    INVALID_EMAIL_LOGIN: 'Invalid email / password',
    INVALID_USERNAME_LOGIN: 'Invalid username / password',
    BACK_TO_LOG_IN: 'Back to log in',
    SIGN_UP_SUCCESSFULLY: 'You have created account. Please log in',
    FORGET_PASSWORD_SUCCESSFULLY: 'We have e-mailed your password reset link.\nPlease check email for more instructions.',
    FORGET_PASSWORD: 'Forgot password?',
    STUDENT_HAS_BEEN_INVITED: 'Student has been invited',
    ADD_STUDENT: 'Add New Student',
    ADD_NEW_STUDENT: 'Add New Student',
    BACK_TO_PROFILE: 'Back to Profile',
    STUDENT_HAS_ALREADY_BEEN_INVITED: 'Student is already invited/accepted',
    TEACHER_STUDENTS: 'Students',
    PRESETS: 'Presets',
    COMMUNICATION: 'Communication',
    PIANO_ADVENTURES: 'Piano Adventures',
    PIANO_ADVENTURE: 'Piano Adventure',
    PIANO_ADVENTURES_KIDS: 'Kids',
    PIANO_ADVENTURES_PRIMER_LEVEL_GUIDE: 'Primer Level Guide',
    PIANO_ADVENTURES_TECHNIQUE_ARTISTRY: 'Technique & Artistry',
    PIANO_ADVENTURES_ADULT: 'Adult',
    MY_PROFILE: 'My Profile',
    SCHOOL_YEAR: 'School Year',
    SCHOOL_YEARS: 'School Years',
    BOOK_LIST: 'Book List',
    UNIT_LIST: 'Unit List',
    LEVEL_LIST: 'Level List',
    LEVEL: 'Level',
    MESSAGES: 'Messages',
    MESSAGE: 'Message',
    CHANNELS: 'Channels',
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    SELECT_ALL: 'Select All',
    DESELECT_ALL: 'Deselect All',
    CONVERSATION: 'Conversation',
    ADD_COMMENT: 'Add Comment',
    SETTINGS: 'Settings',
    MORE: 'More',
    VIDEO_TUTORIALS: 'Video Tutorials',
    HELP: 'Help',
    LANGUAGES: 'Languages',
    NOTIFICATIONS: 'Notifications',
    PRIVACY_TERMS: 'Privacy & Terms',
    LEADERBOARD_PARTICIPATION: 'Leaderboard Participation',
    SHOW_LEADERBOARD: 'Show Leaderboard',
    CUSTOMIZE_PER_STUDENT: 'Customize Per Student',
    BY_SIGNING_UP_YOU_AGREE_WITH_OUR: 'By signing up you agree with our',
    LICENCE_AGREEMENT: 'Licence agreement',
    PRIVACY_POLICY: 'Privacy Policy',
    EDIT: 'Edit',
    EDIT_STUDENT: 'Edit Student',
    MENU: 'Menu',
    ABOUT: 'About',
    WELCOME_BACK: 'Welcome Back',
    SIGN_INTO_YOUR_ACCOUNT: 'Sign into your My Piano Adventures account',
    FORGOT_EMAIL: 'Forgot email?',
    FORGOT_EMAIL_FORM_TITLE: 'Forgot email',
    CREATE_ACCOUNT: 'Create Account',
    CREATE_ACCOUNT_FINISH: 'Finish Create Account',
    FORGOT_EMAIL_SUCCESSFULLY: 'An email with your account information has been sent to your email address.',
    THANK_YOU: 'Thank you.',
    SELECT_RECIPIENTS: 'Select Recipients',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SKILL: 'Are you sure you want to delete this skill?',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_REWARD: 'Are you sure you want to delete this reward?',
    UNAUTHORIZED_ACCESS: 'Unauthorized access',
    NOTES: 'Notes',
    NO_NOTES_TO_SHOW: 'No Notes to show',
    ADD_NEW_NOTE: 'Add New Note',
    ADD_NOTE: 'Add Note',
    EDIT_NOTE: 'Edit Note',
    EDIT_NOTES: 'Edit Notes',
    NOTE_INFO: 'Note Info',
    NOTE: 'Note',
    UPLOAD_FILE: 'Upload File',
    NO_FILES_TO_SHOW: 'No Files to show',
    NOTE_HAS_BEEN_DELETED: 'Note has been deleted',
    SELECT_IMAGE_OR_PDF: 'Select Image or PDF',
    CHOOSE_PDF_FROM_LIBRARY: 'Choose PDF from library...',
    CHOOSE_AUDIO_FROM_LIBRARY: 'Choose Audio from library...',
    CHOOSE_IMAGE_FROM_LIBRARY: 'Choose Image from library...',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_NOTE: 'Are you sure you want to delete this note?',
    SEARCH_NOTES: 'Search Notes',
    SEARCH_ASSIGNMENTS: 'Search Assignments',
    BACK_TO_GROUP: 'Back to Group',
    RELOAD: 'Reload',
    NAVIGATION: 'Navigation',
    CONTENT: 'Content',
    SITE_NAME: 'My Piano Adventures',
    GO_BACK: 'Go back',
    BACK: 'Back',
    COULD_NOT_FIND_THE_PAGE: 'Sorry but we could not find the page you are looking for',
    WELCOME_LOGIN_MESSAGE: 'Welcome to the My Piano Adventures!',
    LOGIN_TO_YOUR_ACCOUNT: 'LOGIN TO YOUR ACCOUNT',
    DONT_HAVE_AN_ACCOUNT: 'Don\'t have an account?',
    CHATS: 'Chats',
    SEARCH_OR_START_NEW_CHAT: 'Search or start new chat',
    SELECT_CONTACT_TO_START_CONVERSATION: 'Select a contact to start a conversation',
    SHOW_CHATS: 'Show Chats',
    SHOW_CONTACTS: 'Show Contacts',
    CONTACTS: 'Contacts',
    CURRENT_DATE_TIME_FORMAT_V2: 'MMMM Do YYYY, h:mm:ss a',
    CURRENT_DATE_FORMAT_V2: 'MM/dd/yyyy',
    LOADING: 'Loading...',
    TYPE_YOUR_MESSAGE: 'Type your message',
    FETCH_TIMED_OUT: 'Request timed out',
    FETCH_OLD_RESULT_ARRIVED: 'Unknown error',
    AVATAR_BADGE_TEACHER: 'T',
    FORBIDDEN: 'Whoops, you do not have permission to access this page',
    INVITE_STUDENT_UPDATED_SOON: 'Invite operation was successful and data will be updated soon',
    ACCEPT_INVITE_UPDATED_SOON: 'Accept invite operation was successful and data will be updated soon',
    IMPORT_DEMO_STUDENTS_UPDATED_SOON: 'Import demo students was successful and data will be updated soon',
    NO_TEACHERS_FOR_STUDENT: 'There are no teachers for you right now :(',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    NO_MEMBERS: 'No members',
    REGISTRATION_SUCCESS: 'Registration Successful. Please Login',
    ONLY_REGISTRATION_SUCCESS: 'Registration Successful.',
    SEND_RESET_LINK: 'Send Reset Link',
    COULD_NOT_FOUND_YOUR_ACCOUNT: 'Couldn\'t find your account',
    BACK_TO_LOG_IN_WEB_GUEST: 'Login to your My Piano Adventures account',
    ALL: 'All',
    WELCOME_TO: 'Welcome to',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    OPEN_SHEET_MUSIC: 'Open Sheet Music',
    SUPPORT: 'Support',
    TERMS_OF_USE: 'Terms of Use',
    CONTACT: 'Contact',
    SUBJECT: 'Subject',
    CONTACT_MESSAGE_SUCCESSFULLY: 'Message has been sent successfully.',
    TUTORIALS: 'Tutorials',
    MY_PIANO_ADVENTURES: 'My piano adventures',
    MY_LESSONS: 'My Lessons',
    MY_ASSIGNMENTS: 'My Assignments',
    MY_STUDENTS: 'My Students',
    ALL_STUDENTS: 'All Students',
    GENERAL_INFORMATION: 'General Information',
    PA_FLASH_CARDS: 'Open Flash Cards',
    PA_PLAYER: 'Open PA Player',
    OPEN_SYNTHESIA_APP: 'Open Synthesia',
    PIANO_ADVENTURES_NOTE: 'Piano Adventures Note',
    TEMPLATE_NOTE: 'Template Note',
    CUSTOM_NOTE: 'Custom Note',
    BACK_TO_NOTES: 'Back to Notes',
    NUMBER_OF_NOTES_DELETED_SUCCESSFULLY: 'Number of Notes deleted: {0}',
    NOTES_DELETED_SUCCESSFULLY_FAILED: 'Number of Notes deleted: {0}. Number of Notes failed to deleted: {1}.',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SELECTED_NOTE: 'Are you sure you want to delete selected Notes?',
    CAN_NOT_CREATE_NOTE_OR_CATEGORY_HERE: 'A Category or Subcategory can not contain both Notes and other Categories or Subcategories at the same time.',
    CAN_NOT_DELETE_THIS_SUBCATEGORY_NOTES: 'Can not delete this Subcategory while it contains other Subcategories or Notes.',
    CAN_NOT_DELETE_THIS_CATEGORY_NOTES: 'Can not delete this Category while it contains Subcategories or Notes.',
    CAN_NOT_DELETE_ALL_CATEGORIES_NOTES: 'Can not delete Categories while they contains Subcategories or Notes.',
    NEW_NOTE: 'New Note',
    NO_CATEGORIES_OR_NOTES_TO_SHOW: 'No Categories or Notes to show',
    NO_SUBCATEGORIES_OR_NOTES_TO_SHOW: 'No Subcategories or Notes to show',
    TEMPLATE: 'Template',
    START_END_TIME_VALIDATION: 'End Time is before Start Time',
    STUDENT_INFO: 'Student info',
    PROFILE_INFO: 'Profile info',
    FACEBOOK_LOGIN: 'Facebook Login',
    GOOGLE_LOGIN: 'Sign in with Google',
    APPLE_LOGIN: 'Sign in with Apple',
    NOT_AUTHORIZED: 'Not authorized for this action',
    WRONG_FILE_TYPE_CHOOSEN: 'Choosen file type is not supported',
    IMPORT_ASSIGNMENTS_SUCCESSFUL: 'Assignments import is successful',
    EXPORT_ASSIGNMENTS_SUCCESSFUL: 'Assignments export is successful',
    FILE_NAME_ERROR: 'File name contains illegal character whitespace',
    SELECT_CATEGORIES_TO_EXPORT: 'Please select Categories or Subcategories to export',
    COULD_NOT_OPEN_SYNTHESIA_LINK: 'Could not open Synthesia link. Please download Synthesia application to your device.',
    SYNTHESIA_LINKS: 'Synthesia links',
    ADD_FILE_IMAGE_PDF_AUDIO_MIDI: 'Add File (Image, PDF, Audio, MIDI)',
    ADD_FILE_IMAGE_PDF_MIDI: 'Add File (Image, PDF, MIDI)',
    ADD_FILE_IMAGE_PDF: 'Add File (Image, PDF)',
    CLICK: 'Click',
    SELECT: 'Select',
    TOUR_GUIDE_ADD_ASSIGNMENT: 'to Add Assignment.',
    TOUR_GUIDE_CHOOSE_PRESET_OR_CUSTOM: 'Choose Preset or Custom',
    TOUR_GUIDE_CHOOSE_LIBRARY: 'Choose Library',
    TOUR_GUIDE_BASIC_METHOD: 'Basic Method.',
    TOUR_GUIDE_CHOOSE_LEVEL: 'Choose Level',
    TOUR_GUIDE_LEVEL_1: 'Level 1.',
    TOUR_GUIDE_CHOOSE_BOOK: 'Choose Book',
    TOUR_GUIDE_LESSON_BOOK: 'Lesson Book.',
    TOUR_GUIDE_CHOOSE_UNIT: 'Choose Unit',
    TOUR_GUIDE_UNIT_1: 'Unit 1.',
    TOUR_GUIDE_CHOOSE_ASSIGNMENT: 'Choose Assignment',
    TOUR_GUIDE_LITTLE_RIVER: 'Little River',
    TOUR_GUIDE_CHECKBOX: 'Checkbox.',
    TOUR_GUIDE_VIEW_ASSIGNMENT: 'View Assignment',
    TOUR_GUIDE_TO_ACCESS_LESSON: 'to access lesson.',
    TOUR_GUIDE_TO_ADD_NOTE: 'to Add Note.',
    TOUR_GUIDE_CHOOSE_CATEGORY: 'Choose Category',
    TOUR_GUIDE_MUSIC_THEORY: 'Music Dictionary.',
    TOUR_GUIDE_CHOOSE_NOTE: 'Choose Note',
    TOUR_GUIDE_ACCENT_MARK: 'Accent Mark',
    TOUR_GUIDE_VIEW_NOTE: 'View Note',
    TOUR_GUIDE_TO_ACCESS_NOTE: 'to access note.',
    TOUR_GUIDE_CONGRATULATIONS: 'Congratulations!',
    TOUR_GUIDE_COMPLETED: 'You Completed the tutorial. Start your',
    TOUR_GUIDE_PIANO_ADVENTURE: 'Piano Adventure.',
    LEARN_MORE_WITH_ADDITIONAL: 'Or learn more with additional',
    VIEW_VIDEOS: 'View Videos',
    SKIP_TUTORIAL: 'Skip Tutorial',
    VISIT_DASHBOARD: 'Visit Dashboard',
    ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_YEAR: 'Are you sure you want to archive this year?',
    ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_YEAR: 'Are you sure you want to activate this year?',
    CLONE_SCHOOL_YEAR_TITLE: 'Clone School Year',
    COPY_LESSONS_ON_CLONE_SCHOOL_YEAR: 'Do you want to copy last lessons for each student from this year to the new school year?',
    PLEASE_WAIT: 'Please wait...',
    OPEN_PA_PLUS: 'Open PA Plus',
    OPEN_APP_EXTERNAL_LINK: 'Open External Link',
    ARE_YOU_SURE_YOU_WANT_OPEN_THIS_LINK: 'Are you sure you want to open this link?',
    COOKIE_CONSENT_TEXT_1: 'This site uses third-party statistic tools that make use of cookies. This is necessary for the purposes outlined in the privacy policy.',
    COOKIE_CONSENT_TEXT_2: 'To learn more or opt out, see the privacy policy. By accepting, you consent to the use of these cookies.',
    NECESSARY: 'Necessary',
    USAGE_STATISTICS: 'Usage Statistics',
    ACCEPT: 'Accept',
    LIBRARY: 'Library',
    PIANO_ADVENTURES_REGISTERED: 'Piano Adventures®',
    SELECT_CHANNEL: 'Select Channel',
    SELECT_CHAT: 'Select Chat',
    PIANO: 'PIANO',
    ADVENTURES: 'Adventures',
    BACK_TO_MY_HUB: 'Back to My Hub',
    REGISTRATION_CODE: 'Registration Code',
    WITH_YOU_FIRST_NOTE: 'With you from the very first note',
    ALLOW_ALL: 'Allow All',
    LANDING_PAGE_TEXT_1: 'My Piano Adventure is a personalized instruction and feedback platform for ',
    LANDING_PAGE_TEXT_2: 'teachers and students, supporting the ',
    LANDING_PAGE_TEXT_3: ' library.',
    GET_STARTED: 'Get Started',
    TEACH_LEARN_CONNECT: 'Teach, Learn and Connect',
    TEACH_LEARN_CONNECT_TEXT_1: ' provides an easy way for teachers, students and parents to engage with the ',
    TEACH_LEARN_CONNECT_TEXT_2: ' library and our digital resources.',
    LANDING_PAGE_TEACHERS_TEXT: 'Customized to your teaching style, set lesson length, focus on repetition, and practicing',
    LANDING_PAGE_STUDENTS_TEXT: 'Engage with your teacher using our fun and simple learning and communication tools and features',
    PARENTS: 'Parents',
    LANDING_PAGE_PARENTS_TEXT: 'Encourage your child by monitoring progress, communicate directly with teachers',
    QUICK_LOOK: 'Quick look',
    FEATURE_OVERVIEW: 'Feature Overview',
    PIANO_ADVENTURES_HUB: 'Piano Adventures Hub',
    PIANO_ADVENTURES_HUB_TEXT: 'Personal dashboard to access all Faber Piano Adventures digital resources.',
    COMMUNICATION_TEXT: 'Access news and updates directly from Faber Piano Institutue Direct communication between teachers, students and parents',
    VIDEO_LIBRARY: 'Video Library',
    VIDEO_LIBRARY_TEXT: 'Access an ever-growing library (100’s) of full-length videos grouped by book and unit levels as published in the Faber Adult Piano Adventures® books.',
    STUDENTS_AND_ASSIGNMENTS: 'Students and Assignments',
    STUDENTS_AND_ASSIGNMENTS_TEXT: 'Manage a class and track individual student lesson progress using built-in FPA assignments or create custom assignments and notes.',
    LANDING_BECOME_EARLY_ADOPTER_TITLE: 'Become an Early Adopter',
    LANDING_BECOME_EARLY_ADOPTER_SUBTITLE: 'Be the first to try a new digital platform from ',
    LANDING_BECOME_EARLY_ADOPTER_TEXT_1: 'Do you enjoy trying out new piano teaching products and wish to ',
    LANDING_BECOME_EARLY_ADOPTER_TEXT_2: 'shape the digital future of ',
    LANDING_BECOME_EARLY_ADOPTER_TEXT_3: 'We’re looking for active piano teachers able to try the platform with their students. We’re hoping to receive honest feedback on myPA’s content and features and any enhancements you believe could benefit your teaching experience.',
    PIANO_ADVENTURES_LANDING_LINK_TITLE: 'PianoAdventures.com',
    FABER_PIANO_ADVENTURES: 'Faber Piano Adventures',
    TRADEMARK: '®',
    QUESTION_MARK: '?',
    I_HAVE_READ_AND_AGREE: 'I have read and agree to the ',
    YOU_MUST_ACCEPT_PRIVACY_POLICY_AND_TOU: 'You must accept our Terms of Use and Privacy Policy',
    EARLY_ADOPTER_PAGE_TEXT: 'Become an early adopter and be the first to try a new digital platform from ',
    APPLY_NOW: 'Apply Now',
    SHAPE_DIGITAL_FUTURE_TEXT: 'Do you wish to shape the digital future of ',
    NEW_PIANO_TEACHING_PRODUCTS_TEXT: 'Do you enjoy trying out new piano teaching products?',
    INVITED_TO_APPLY_TEXT: 'You’re invited to apply to be a myPA early adopter giving you free exclusive access to My Piano Adventures (myPA), the new ',
    INVITED_TO_APPLY_TEXT_2: ' digital platform.',
    LOOKING_ACTIVE_PIANO_TEACHERS_TEXT: 'We’re looking for active piano teachers able to try the platform with at least 3+ students. We’re hoping to receive honest feedback on myPA’s content and features and any enhancements you believe could benefit your teaching experience.',
    APPLY_NOW_EARLY_ACCESS_TEXT_1: 'Apply now ',
    APPLY_NOW_EARLY_ACCESS_TEXT_2: 'for early access to the platform.',
    BECOME_EARLY_ADOPTER_TEXT: 'Why Become a ',
    BECOME_EARLY_ADOPTER_TEXT_2: ' Early Adopter?',
    FIRST_LOOK_UPCOMING_FEATURES_TEXT: 'Get a first look at upcoming features',
    SHAPE_THE_FUTURE_TEXT: 'Shape the future of ',
    JOIN_COMMUNITY_TEXT: 'Join a growing teacher community',
    REQUIREMENTS_TO_BECOME_EARLY_ADOPTER_TEXT: 'What are the requirements to become a myPA early adopter?',
    PIANO_TEACHERS: 'Piano Teachers',
    REQUIREMENT_TEXT_1: ' with experience teaching using the ',
    REQUIREMENT_TEXT_1_METHOD: ' method',
    REQUIREMENT_TEXT_2: 'Teachers with a minimum of ',
    PIANO_STUDENT_3: '3+ piano students',
    REQUIREMENT_TEXT_2_USING: ' using ',
    REQUIREMENT_TEXT_3: 'Use the myPA platform for at least 4 consecutive lessons',
    EXPECTATIONS_EARLY_ADOPTER_TEXT: 'What are the expectations for myPA early adopters?',
    EXPECTATION_TEXT_1_TITLE: 'Commitment to use the myPA platform',
    EXPECTATION_TEXT_1: 'Use the myPA platform with a select group of your students (3+) for at least 4 consecutive lessons',
    EXPECTATION_TEXT_2_TITLE: 'Honest feedback on your myPA experience',
    EXPECTATION_TEXT_2: 'Your opinion matters. Let us know how myPA benefits your teaching method.',
    GENERAL: 'General',
    BUG_REPORT: 'Bug Report',
    FEATURE_SUGGESTION: 'Feature Suggestion',
    TECHNICAL_SUPPORT: 'Technical Support',
    RETURN: 'Return',
    ABBREVIATION: 'Abbreviation (e.g. A, AB)',
    FAILED_TO_UPLOAD_THE_IMAGE: 'Failed to upload the image.',
    RELATED_ASSIGNMENTS: 'Related Assignments',
    ASSIGNMENT_COMMENTS: 'Assignment comments',
    STUDENT_CHILDREN: 'Student Profiles',
    ADD_NEW_STUDENT_CHILD: 'Add new Student Profile',
    NO_STUDENT_CHILDREN_TO_SHOW: 'No Student Profiles to show',
    SEARCH_STUDENT_CHILDREN: 'Search Student Profiles',
    STUDENT_CHILD_HAS_BEEN_CREATED: 'Student Profile has been created',
    STUDENT_CHILD_HAS_BEEN_UPDATED: 'Student Profile has been updated',
    ADD_STUDENT_CHILD: 'Add Student Profile',
    STUDENT_CHILD_INFO: 'Student Profile',
    EDIT_STUDENT_CHILD: 'Edit Student Profile',
    STUDENT_CHILD_AVATAR_HAS_BEEN_CHANGED: 'Student Profile avatar has been changed',
    AS_STUDENT_CHILD: 'Student Profile',
    REGULAR: 'Primary Account',
    NONE: 'None',
    STUDENT_PARENT_FULL_NAME: 'Primary Account Name',
    STUDENT_CHILD_FULLNAME: 'Student Name',
    INVITE_STUDENT: 'Invite Student',
    INVITE_STUDENT_CHILD: 'Add Student Profile',
    TEACHER_INVITE_REQUIRED_CHILD_OR_FULLNAME: 'Student Profile or Student Name is required',
    BACK_TO_STUDENT_CHILDREN_LIST: 'Back to Student Profiles List',
    EMAIL_OR_USERNAME: 'Email or Username',
    INVALID_EMAIL_OR_USERNAME: "Invalid Email or Username",
    USERNAME_CONTAINS_LETTERS_NUMBERS: 'Username can only contains letters, numbers and \".-_\" characters.',
    ARE_YOU_SURE_YOU_WANT_TO_LOGIN_AS_STUDENT_CHILD: 'Are you sure you want to login as this Student Profile?',
    MANAGE_PROFILES: 'Manage Profiles',
    ADD_PROFILE: 'Add Profile',
    NEW_STUDENT: 'New Student',
    SWITCH_PROFILE: 'Switch Profile',
    CHOOSE_STUDENT_PROFILE: 'Choose a student profile to use:',
    SELECT_STUDENT_PROFILE_OR_ADD_NEW: 'An account with this email already exists. Select student profile (or add new).',
    ADD_NEW_STUDENT_PROFILE: 'Add New Student Profile',
    CLONE_LAST_LESSONS: 'Clone Last Lessons',
    NUMBER_OF_LESSONS_CLONED_SUCCESSFULLY: 'Number of Lessons cloned: {0}',
    CAN_NOT_CLONE_LESSONS_FOR_STUDENTS_WITHOUT_LESSONS: 'Can not Clone Lessons for selected students.',
    CAN_NOT_CLONE_LESSONS_FOR_GROUPS_WITHOUT_LESSONS: 'Can not Clone Lessons for selected groups.',
    NUMBER_OF_LESSONS_SUCCESSFULLY_CLONED_FAILED: 'Number of Lessons cloned: {0}. Number of Lessons failed to clone: {1}.',
    LESSON_SETTINGS: 'Lesson Settings',
    ADD_LESSON_SETTING: 'Add Lesson Setting',
    WEEKLY: 'Weekly',
    DAY_OF_WEEK_OR_SPECIFIC_DATE_REQUIRED: 'Only one from Day of a week / specific date is required',
    FROM_TIME_REQUIRED: 'From time is not specified',
    ASSIGN_TO_STUDENT: 'Assign to Student',
    NO_LEVEL_ASSIGNED: 'No Level Assigned',
    CONTINUE: 'Continue',
    VIEW_LESSONS: 'View Lessons',
    PUBLISH: 'Publish',
    UNPUBLISH: 'Unpublish',
    EDIT_STUDENT_PROFILE: 'Edit Student Profile',
    EDIT_GROUP_PROFILE: 'Edit Group Profile',
    BIRTHDAY: 'Birthday',
    CLASS_LEADERBOARD: 'Class Leaderboard',
    REMOVE_SKILL: 'Remove Skill',
    ASSIGN_NEW_SKILL: 'Assign New Skill',
    CLASS_REWARDS_STORE: 'Class Rewards Store',
    ADD_CLASS_REWARD: 'Add Class Reward',
    ADD_CUSTOM_REWARD: 'Add Custom Reward',
    PAUSE_PROFILE: 'Pause Profile',
    ACTIVATE_PROFILE: 'Activate Profile',
    DELETE_PROFILE: 'Delete Profile',
    DOWNLOADS: 'Downloads',
    UNPUBLISHED: 'Unpublished',
    CUSTOM_ASSIGNMENTS: 'Custom Assignments',
    CUSTOM_NOTES: 'Custom Notes',
    CUSTOM_REWARDS: 'Custom Rewards',
    CUSTOM_SKILLS: 'Custom Skills',
    IMPORT: 'Import',
    LEADERBOARD_SETTINGS: 'Leaderboard Settings',
    BACK_TO_LEADERBOARD: 'Back to Leaderboard',
    FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
    WHY: 'Why',
    MY_PIANO_ADVENTURES: 'My Piano Adventures',
    CONNECT: 'Connect',
    LANDING_PAGE_CONNECT: 'Access lessons and learning tools from anywhere.',
    LEARN: 'Learn',
    LANDING_PAGE_LEARN: 'Unlock new techniques with helpful resources.',
    EXPLORE: 'Explore',
    CREATE_YOUR_FREE_ACCOUNT: 'Create Your Free Account',
    ADDITIONAL_FAQ: 'Additional FAQ',
    LADNING_PAGE_TITLE: 'Connect. Learn. Explore.',
    LADNING_PAGE_FIRST_SECTION_TEXT: ' is a personalized music platform for every stage of your piano journey from ',
    LANDING_PAGE_SECOND_SECTION_TITLE: 'What is My Piano Adventures?',
    LANDING_PAGE_SECOND_SECTION_TEXT_1: 'Support for ',
    LANDING_PAGE_SECOND_SECTION_TEXT_2: ' through ',
    LANDING_PAGE_SECOND_SECTION_TEXT_3: 'each stage of the musical journey',
    LANDING_PAGE_EXPLORE: 'Stay up-to-date on Faber’s full library of products.',
    LANDING_WHATS_INCLUDED: 'What’s Included',
    LANDING_WHATS_INCLUDED_TEXT_1: 'Enhance your Piano Adventures with ',
    LANDING_WHATS_INCLUDED_TEXT_2: 'note-worthy resources from Faber',
    LANDING_PERSONALIZED_DASHBOARD: 'Personalized Dashboard',
    LANDING_PERSONALIZED_DASHBOARD_TEXT: 'The entire Piano Adventures family of resources at your fingertips.',
    LANDING_FLEXIBILE_LEARNING: 'Flexible Learning',
    LANDING_FLEXIBILE_LEARNING_TEXT: 'Organized lesson-planning and access to myPA video library.',
    LANDING_COMPREHENSIVE_SUPPORT: 'Comprehensive Support',
    LANDING_COMPREHENSIVE_SUPPORT_TEXT: 'Video tutorials, accessible tips and helpful insights.',
    LANDING_DIRECT_ACCESS: 'Direct Access',
    LANDING_DIRECT_ACCESS_TEXT: 'Safe and secure online chat between Teachers, Students & Parents.',
    LANDING_CUSTOMIZABLE_CONTENT: 'Customizable Content',
    LANDING_CUSTOMIZABLE_CONTENT_TEXT: 'Tools to create assignments and incentivize progress.',
    LANDING_A_GROWING_PLATFORM: 'A Growing Platform',
    LANDING_A_GROWING_PLATFORM_TEXT: 'Stay tuned for more exciting features coming soon!',
    LANDING_SLIDE_TEXT_1: 'I love the ease of making assignments with multiple platforms all in one place. The video samples and tutorials are great resources to help students see and hear their pieces played correctly while at home.',
    LANDING_SLIDE_TEXT_AUTHOR_1: '-Heidi N., Rexburg, ID',
    LANDING_SLIDE_TEXT_2: 'I like the ability to organize my studio and keep track of my students’ progress using a single platform designed for teachers in the digital world.',
    LANDING_SLIDE_TEXT_AUTHOR_2: '-Juan G., Brooklyn, NY',
    LANDING_SLIDE_TEXT_3: 'You are taking the workload off the teacher so we can simply click a button. A picture is worth a thousand words, but so is a video!',
    LANDING_SLIDE_TEXT_AUTHOR_3: '-Lesli M., Seymour, TN',
    LANDING_SLIDE_TEXT_4: 'The instructional videos for introducing the piece and the challenge videos are awesome tools to create a fun practice time.',
    LANDING_SLIDE_TEXT_AUTHOR_4: '-Julie I., Boise, ID',
    LANDING_SLIDE_TEXT_5: 'My favorite aspect of myPA is how easy it is to create assignments from the PA Basic Method, which definitely saves the teacher time. I have been able to create my students’ assignments during their lessons as it is faster than using any other platform.',
    LANDING_SLIDE_TEXT_AUTHOR_5: '-Maria M., Mystic, CT',
    LANDING_HIGHLIGHT_FIRST_BUBBLE_TEXT_1: 'Only digital platform to offer',
    LANDING_HIGHLIGHT_FIRST_BUBBLE_TEXT_2: '30 YEARS',
    LANDING_HIGHLIGHT_FIRST_BUBBLE_TEXT_3: 'of Piano Adventures Methodology',
    LANDING_HIGHLIGHT_SECOND_BUBBLE_TEXT_1: 'myPA Library includes over',
    LANDING_HIGHLIGHT_SECOND_BUBBLE_TEXT_2: '1,500+',
    LANDING_HIGHLIGHT_SECOND_BUBBLE_TEXT_3: 'Piano Adventures Assignments and Videos',
    LANDING_HIGHLIGHT_THIRD_BUBBLE_TEXT_1: 'Built with the assistance of',
    LANDING_HIGHLIGHT_THIRD_BUBBLE_TEXT_2: '125+',
    LANDING_HIGHLIGHT_THIRD_BUBBLE_TEXT_3: 'Piano Teachers and over 500+ Students',
    LANDING_FAQ_ACCORDION_HEADER_TEXT_1: 'What is myPA?',
    LANDING_FAQ_ACCORDION_BODY_TEXT_1: 'myPA is your key to the entire Faber Piano Adventures experience. Supporting teachers and students through every step of the musical journey, myPA provides access to the full range of Piano Adventures resources, including custom assignments, video tutorials and more, as well as secure direct messaging and other features to enhance the learning experience.',
    LANDING_FAQ_ACCORDION_HEADER_TEXT_2: 'How much does myPA cost?',
    LANDING_FAQ_ACCORDION_BODY_TEXT_2: 'All of the basic functionality of myPA is free to use for both teachers and students (assignments, messaging, and rewards). Future releases of new features may have additional costs associated, which we will announce as those features are released.',
    LANDING_FAQ_ACCORDION_HEADER_TEXT_3: 'Is myPA web-based or app-based?',
    LANDING_FAQ_ACCORDION_BODY_TEXT_3: 'Currently, My Piano Adventures is a web-based platform, and will work on any modern browser, such as Chrome, Firefox, and Safari. Mobile browsers, such as Chrome for Android, and Mobile Safari are also supported. In the future, myPianoAdventures will offer a dedicated mobile app for iOS and Android devices.',
    LANDING_FAQ_ACCORDION_HEADER_TEXT_4: 'How do I sign up?',
    LANDING_FAQ_ACCORDION_BODY_TEXT_4: 'Click on the “Create Account” button and follow the prompts. Once you register, you will have access to the basic myPA platform and can begin browsing the product tools and resources.',
    LANDING_FAQ_ACCORDION_HEADER_TEXT_5: 'Can I make my own assignments?',
    LANDING_FAQ_ACCORDION_BODY_TEXT_5: 'Yes! As a teacher, you can create custom notes and assignments that can include PDF files, video links, and text. These custom assignments and notes are re-assignable to multiple students and lessons.',
    LANDING_FOOTER_TITLE: 'Create a free account now to unlock the next chapter of your musical adventure.',
    LANDING_FOOTER_TEXT_1: 'Already have an account?',
    LANDING_FOOTER_TEXT_2: ' to your myPA account.',
    LANDING_FOOTER_APP_INFO: '© 2023 Faber Piano Adventures',
    VIEW_STUDENT_PROFILE: 'View Student Profile',
    VIEW_GROUP_PROFILE: 'View Group Profile',
    RESET_PASSWORD_SUCCESSFULLY: 'Password has been changed.',
    CHANGE_PASSWORD: 'Change password',
    NEW_PASSWORD: 'New password',
    REPEAT_NEW_PASSWORD: 'Repeat new password',
    WELCOME: 'Welcome',
    TEACHER_ACCOUNT: 'Teacher Account',
    STUDENT_ACCOUNT: 'Student Account',
})

export const texti18n = code => {
    const def = SettingsService.getLanguageDefinition()
    const text = def && def.definitions && code in def.definitions ? def.definitions[code] : code
    return text
}