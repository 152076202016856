import React from 'react'
import { AppBar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { LocalImages } from 'utils/LocalImages'
import { Messages, texti18n } from 'dpn-common/utils/MessageConstants'

const useStyles = makeStyles(theme => ({
    image: {
        width: 62,
        height: 62,
        padding: 3.5,
        boxSizing: 'content-box',
    }
}))

function UserNavbarHeader() {
    const classes = useStyles()
    return (
        <AppBar
            position="static"
            color="inherit"
            elevation={0}
            className="user relative flex flex-col items-center justify-center pt-16 pb-20 z-0"
        >
            <img className={clsx(classes.image, 'logo-icon')} src={LocalImages.PIANO_ADV} alt="logo"/>
            <Typography className="text-20 font-bold ml-12 logo-text" color="inherit">{texti18n(Messages.SITE_NAME)}</Typography>
        </AppBar>
    )
}

export default UserNavbarHeader
