import React from 'react'
import { Avatar, Icon, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Messages } from 'dpn-common/utils/MessageConstants'
import { LocalImages } from 'utils/LocalImages'
import NavigatorService from 'services/common/NavigatorService'
import { getUserIdentityString, getUserPictureStaticUrl } from 'dpn-common/utils/UserHelper'
import DropDownButtonComponent from 'components/General/DropDownButtonComponent'
import { USER_TYPES } from 'dpn-common/utils/AppConstants'

export default function UserMenu(_props) {
    const user = useSelector(({ base }) => base.auth.userData)
    const originalAccountAccessToken = useSelector(({ automaticLogin }) => automaticLogin.originalAccountAccessToken)
    const isGuest = user.role == USER_TYPES.GUEST
    const shouldShowChildrenLink = user.role == USER_TYPES.STUDENT && user.isEmailOwner
    const items = isGuest ?
        [
            { 
                title: Messages.LOGIN,
                action: () => NavigatorService.navigate('Login'),
                icon: <Icon>lock</Icon>,
            },
            { 
                title: Messages.CREATE_NEW_ACCOUNT_MESSAGE,
                action: () => NavigatorService.navigate('Register'),
                icon: <Icon>person_add</Icon>,
            }
        ]
        :
        [
            { 
                title: Messages.MY_PROFILE,
                action: () => NavigatorService.navigate(user.role === USER_TYPES.TEACHER ? 'TeacherProfileEdit' : '/student-profile?componentType=skills'),
                icon: <Icon>account_circle</Icon>,
            },
            shouldShowChildrenLink ?
                {
                    title: Messages.STUDENT_CHILDREN,
                    action: () => NavigatorService.navigate('StudentProfiles'),
                    icon: <Icon>assignment_ind</Icon>,
                }
                : undefined,
            { 
                title: !originalAccountAccessToken ? Messages.LOGOUT : Messages.SWITCH_PROFILE,
                action: () => NavigatorService.navigate('Logout'),
                icon: <Icon>exit_to_app</Icon>,
            }
        ].filter(x => !!x)

    return <DropDownButtonComponent button={<UserMenuButton user={user} />} items={items} />
}

function UserMenuButton({ user }) {
    const url = getUserPictureStaticUrl(user.picture, LocalImages.DEFAULT_AVATAR_IMAGE)
    const identity = getUserIdentityString(user)
    return (
        <React.Fragment>
            <Avatar className="" alt={identity} src={url}/>
            <div className="hidden md:flex flex-col ml-12 items-start">
                <Typography component="span" className="normal-case font-600 flex">
                    {identity}
                </Typography>
                {
                    !!user.role && 
                    <Typography className="text-11 uppercase" color="textSecondary">
                        {user.role}
                    </Typography>
                }
            </div>
            <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
        </React.Fragment>
    )
}