import ActionTypes from './ActionTypes'
import {
    addLessonPost, updateLessonPut,
    activateLessonPost, lessonAssignmentsGet,
    setLessonAssignmentsPost, deleteLessonDelete, lessonsGet,
    updateLessonNotePut, addLessonNotePost, lessonNotesGet,
    deleteLessonNoteDelete, lessonGet, sortLessonNotesPost,
    addLessonTemplateNotePost,
    publishLessonPost
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { Messages, texti18n } from '../utils/MessageConstants'
import { NavigatorService, showError, showSuccess } from '../services/SharedServices'
import { getJSDateFromJson, getNthDateFromDateJson } from 'dpn-common/utils/TimeHelperFunctions'

export async function changeLessonIsActiveFetch(lesson, dispatch, goBack) {
    const fetchPromise = activateLessonPost(lesson.id)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const isActive = responseData.value
        dispatch(setLessonIsActive(lesson.schoolYearId, lesson.id, lesson.groupId, isActive))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function changeLessonIsPublishedFetch(lesson, dispatch, goBack) {
    const fetchPromise = publishLessonPost(lesson.id)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const isPublished = responseData.value
        dispatch(setLessonIsPublished(lesson.schoolYearId, lesson.id, lesson.groupId, isPublished))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function loadLessonsForGroupsFetch(schoolYearId, groupIds, dispatch, isPublished) {
    const fetchPromise = lessonsGet(schoolYearId, groupIds, undefined, undefined, undefined, undefined, undefined, isPublished)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success &&
        dispatch(setLessons(schoolYearId, responseData.items, groupIds)) 
    return responseData
}

export async function loadLessonsForGroupsFetchNoDispatch(groupIds, search) {
    const fetchPromise = lessonsGet(undefined, groupIds, undefined, undefined, undefined, undefined, search)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.items || []
}

// export async function getGroupsHavingLessonsByDate(userId, daysBefore, daysAfter) {
//     const fromDate = getNthDateFromDateJson(-daysBefore)
//     const toDate = getNthDateFromDateJson(daysAfter)
//     const fetchPromise = lessonsGet(undefined, undefined, true, userId, fromDate, toDate)
//     const responseData = await tryCatchJson(fetchPromise, true)
//     const result = responseData.success ? responseData.items : []
//     return result.reduce((acc, lesson) => {
//         const date = getJSDateFromJson(lesson.date)
//         const dayOfWeek = date.getDay() + 1
//         acc[dayOfWeek] = acc[dayOfWeek] || {}
//         acc[dayOfWeek][lesson.groupId] = true
//         return acc
//     }, {})
// }

export async function loadLessonFetch(lessonId, dispatch) {
    const fetchPromise = lessonGet(lessonId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(updateLesson(responseData.value)) 
    return responseData
}

export async function addUpdateLessonFetch(data, dispatch, goBack, shouldShowMessage = true) {
    const fetchPromise = data.id
        ? updateLessonPut(data)
        : addLessonPost(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        // dispatch update or add lesson redux action
        dispatch(data.id ? updateLesson(value) : addLesson(value))
        shouldShowMessage && showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function loadSelectedAssignmentsForTeacherFetch(lessonId, dispatch) {
    const fetchPromise = lessonAssignmentsGet(lessonId)
    const responseData = await tryCatchJson(fetchPromise)
    responseData.success && dispatch(setSelectedAssignments(
        responseData.items.map(x => ({ assignmentId: x.id, assignmentFolderId: x.parentId || 0 }))
    ))
    return responseData
}

export async function getAllAssignmentsFetchNoDispatch(lessons) {
    const calls = (lessons || []).map(x => {
        return tryCatchJson(lessonAssignmentsGet(x.id), false)
    })
    const responseData = await Promise.all(calls)
    if(responseData.every(x=> x.success)) {
        const noteIds = new Set()
        const response = responseData.reduce((acc, rd) => {
            return acc.concat(rd.items.filter(item => {
                const contains = noteIds.has(item.id)
                noteIds.add(item.id)
                return !contains
            }))
        }, [])
        return response
    }
    return undefined
}

export async function getAllNotesFetchNoDispatch(lessons) {
    const calls = (lessons || []).map(x => {
        return tryCatchJson(lessonNotesGet(x.id), false)
    })
    const responseData = await Promise.all(calls)
    if(responseData.every(x=> x.success)) {
        const assignmentIds = new Set()
        const response = responseData.reduce((acc, rd) => {
            return acc.concat(rd.items.filter(item => {
                const contains = assignmentIds.has(item.id)
                assignmentIds.add(item.id)
                return !contains
            }))
        }, [])
        return response
    }
    return undefined
}

export async function setLessonAssignmentsFetch(lessonId, selectedAssignments, dispatch) {
    const fetchPromise = setLessonAssignmentsPost(lessonId, selectedAssignments.map(x => x.assignmentId))
    const responseData = await tryCatchJson(fetchPromise)
    responseData.success && dispatch(setSelectedAssignments(selectedAssignments))
    return responseData
}

export function setSelectedNotesDispatch(selectedNotes, dispatch) {
    dispatch(setSelectedNotes(selectedNotes))
}

export async function deleteLessonFetch(lesson, dispatch, goBack) {
    const fetchPromise = deleteLessonDelete(lesson.id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteLesson(lesson.groupId, lesson.schoolYearId, lesson.id))
        showSuccess(texti18n(Messages.LESSON_HAS_BEEN_DELETED))
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function loadLessonNotesFetch(lessonId, dispatch) {
    const fetchPromise = lessonNotesGet(lessonId)
    const responseData = await tryCatchJson(fetchPromise)
    responseData.success && dispatch(setLessonNotes(lessonId, responseData.items))
    return responseData
}

export async function addLessonTemplateNoteFetch(lessonId, noteId, templateGroupId, note, dispatch) {
    const fetchPromise = addLessonTemplateNotePost(lessonId, noteId, templateGroupId)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        // eslint-disable-next-line no-unused-vars
        const { isFolder, parentId, noteType, isActive, children, ...updatedNote } = note
        const value = {
            ...updatedNote,
            templateGroupId
        }
        dispatch(addLessonNote(lessonId, value))
    }
    return responseData
}

export async function addUpdateLessonNoteFetch(lessonId, newFiles, deletedFiles, data, dispatch, goBack) {
    const fetchPromise = data.id
        ? updateLessonNotePut(lessonId, newFiles, deletedFiles, data)
        : addLessonNotePost(lessonId, newFiles, deletedFiles, data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        if (data.templateGroupId) {
            value.templateGroupId = data.templateGroupId
        }
        dispatch(data.id ? updateLessonNote(lessonId, { ...value, order: data.order }) : addLessonNote(lessonId, value))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function deleteLessonNoteFetch(lessonId, noteId, dispatch, goBack) {
    const fetchPromise = deleteLessonNoteDelete(lessonId, noteId)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteLessonNote(lessonId, noteId))
        showSuccess(texti18n(Messages.NOTE_HAS_BEEN_DELETED))
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function deleteLessonNotesFetch(lessonId, noteIds, dispatch, goBack) {
    const calls = (noteIds || []).map(x => {
        return tryCatchJson(deleteLessonNoteDelete(lessonId, x), false)
    })
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        dispatch(deleteLessonNotes(lessonId, noteIds))
        responseDatas.success = true
        goBack && NavigatorService.goBack()
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas.success = false
    }
    return responseDatas
}

export async function sortLessonNotesFetch(lessonId, noteIds, dispatch) {
    const fetchPromise = sortLessonNotesPost(lessonId, noteIds)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setLessonNotes(lessonId, responseData.items))
    }
    return responseData
}

export function setLessons(schoolYearId, lessons, groupIds) {
    const type = ActionTypes.SET_LESSONS_ACTION
    return { type, lessons, schoolYearId, groupIds }
}

function setSelectedAssignments(selectedAssignments) {
    return { type: ActionTypes.SET_SELECTED_ASSIGNMENTS, selectedAssignments }
}

function setSelectedNotes(selectedNotes) {
    return { type: ActionTypes.SET_SELECTED_NOTES, selectedNotes }
}

function addLesson(lesson) {
    return { type: ActionTypes.ADD_LESSON_ACTION, lesson }
}

function updateLesson(lesson) {
    return { type: ActionTypes.UPDATE_LESSON_ACTION, lesson }
}

function setLessonIsActive(schoolYearId, id, groupId, isActive) {
    const type = ActionTypes.ACTIVATE_LESSON_ACTION
    return { type, id, groupId, isActive, schoolYearId }
}

function setLessonIsPublished(schoolYearId, id, groupId, isPublished) {
    const type = ActionTypes.PUBLISH_LESSON_ACTION
    return { type, id, groupId, isPublished, schoolYearId }
}

function deleteLesson(groupId, schoolYearId, id) {
    return { type: ActionTypes.DELETE_LESSON_ACTION, groupId, schoolYearId, id }
}

function setLessonNotes(lessonId, notes) {
    return { type: ActionTypes.SET_LESSON_NOTES_ACTION, lessonId, notes }
}

function addLessonNote(lessonId, note) {
    return { type: ActionTypes.ADD_LESSON_NOTE_ACTION, lessonId, note }
}

function updateLessonNote(lessonId, note) {
    return { type: ActionTypes.UPDATE_LESSON_NOTE_ACTION, lessonId, note }
}

function deleteLessonNote(lessonId, noteId) {
    return { type: ActionTypes.DELETE_LESSON_NOTE_ACTION, lessonId, noteId }
}

function deleteLessonNotes(lessonId, noteIds) {
    return { type: ActionTypes.DELETE_LESSON_NOTES_ACTION, lessonId, noteIds }
}