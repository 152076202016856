import React from 'react'

export const TeacherTutorialsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/teacher-resources',
            component: React.lazy(() => import('./TeacherTutorials')),
            applicationPath: 'TeacherTutorials'
        },
    ]
}