import React from 'react'

export const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/login',
            component: React.lazy(() => import('./Login')),
            applicationPath: ['_GuestHome', 'Login']
        },
        {
            path: '/loginExternal',
            component: React.lazy(() => import('./LoginExternal')),
            applicationPath: ['LoginExternal']
        }
    ]
}

