import ActionTypes from './ActionTypes'
import { getUser } from '../utils/UserHelper'
import { initializeSettings } from './SettingsAndLanguagesActions'
import { createErrorResponse, tryCatchJson } from '../utils/FetchUtils'
import { USER_TYPES } from '../utils/AppConstants'
import { Messages, texti18n, GLOBAL_FIELD_KEY } from 'dpn-common/utils/MessageConstants'
import SharedServices, { getOS } from '../services/SharedServices'
import NotificationsHelper from 'dpn-common/utils/NotificationsHelper'
import { loginExternalGet } from 'dpn-common/services/IdentityEndpointsService'
import { isEmail } from 'dpn-common/utils/FormValidators'
import { onAppInitLoggedInForAutomaticLogin, onAppInitLoggedOutForAutomaticLogin } from './AutomaticLoginActions'

export async function initializeAppAction(dispatch) {
    SharedServices.FirebaseService.setOnAuthStateChanged(async firebaseUser => {
        SharedServices.FirebaseService.setOnAuthStateChanged(null)

        if (getOS() === 'web') {
            await initializeAuth(firebaseUser, dispatch)
        }
        else {
            // fix for: on native this part hangs when inside firebases OnAuthStateChanged
            setTimeout(() => initializeAuth(firebaseUser, dispatch), 100)
        }
    })
}

const initializeAuth = async (firebaseUser, dispatch) => {
    if (firebaseUser) {
        const fetchPromise = loginExternalGet(firebaseUser.uid)
        const responseData = await tryCatchJson(fetchPromise)
        if (responseData.success) {
            await loginUser(responseData.user, dispatch)
            await onAppInitLoggedInForAutomaticLogin(dispatch)
            return
        }
    }

    await onAppInitLoggedOutForAutomaticLogin(dispatch)
    await logoutSimple(dispatch)
}

export async function executeLoginWithEmailAndPassword({ email, username, password }, isStudentChildLogin, loginUserInRedux, dispatch) {
    const firebaseLoginResponse = await firebaseLoginWithEmailAndPassword(email, username, password, isStudentChildLogin)
    if (firebaseLoginResponse.errorData || !firebaseLoginResponse.user) {
        return firebaseLoginResponse.errorData || {}
    }

    return await appLoginUsingFirebaseUser(firebaseLoginResponse.user, loginUserInRedux, dispatch)
}

export async function executeGoogleLogin(loginUserInRedux, dispatch) {
    const firebaseLoginResponse = await firebaseGoogleLogin()
    if (firebaseLoginResponse.errorData || !firebaseLoginResponse.user) {
        return firebaseLoginResponse.errorData || {}
    }

    return await appLoginUsingFirebaseUser(firebaseLoginResponse.user, loginUserInRedux, dispatch)
}

export async function executeAppleLogin(loginUserInRedux, dispatch) {
    const firebaseLoginResponse = await firebaseAppleLogin()
    if (firebaseLoginResponse.errorData || !firebaseLoginResponse.user) {
        return firebaseLoginResponse.errorData || {}
    }

    return await appLoginUsingFirebaseUser(firebaseLoginResponse.user, loginUserInRedux, dispatch)
}

export async function executeLoginWithCustomToken(customLoginToken, dispatch) {
    const firebaseLoginResponse = await firebaseLoginWithCustomToken(customLoginToken)
    if (firebaseLoginResponse.errorData || !firebaseLoginResponse.user) {
        return firebaseLoginResponse.errorData || {}
    }

    return await appLoginUsingFirebaseUser(firebaseLoginResponse.user, true, dispatch)
}

async function firebaseLoginWithEmailAndPassword(email, username, password, isStudentChildLogin) {
    const firebaseEmail = getFirebaseEmail(email, username, isStudentChildLogin)
    if (!firebaseEmail) {
        const errorData = isStudentChildLogin ? createErrorResponse('username', Messages.INVALID_USERNAME) : createErrorResponse('email', Messages.INVALID_EMAIL)
        return { errorData }
    }

    try {
        const user = await SharedServices.FirebaseService.loginWithEmailAndPassword(firebaseEmail, password)
        return { user }
    }
    catch (error) {
        const errorData = createErrorResponse(GLOBAL_FIELD_KEY, formatFirebaseLoginError(error, isStudentChildLogin))
        return { errorData }
    }
}

async function firebaseGoogleLogin() {
    try {
        const user = await SharedServices.FirebaseService.loginWithGoogle()
        return { user }
    }
    catch (error) {
        const errorData = createErrorResponse(GLOBAL_FIELD_KEY, formatFirebaseLoginError(error))
        return { errorData }
    }
}

async function firebaseAppleLogin() {
    try {
        const user = await SharedServices.FirebaseService.loginWithApple()
        return { user }
    }
    catch (error) {
        const errorData = createErrorResponse(GLOBAL_FIELD_KEY, formatFirebaseLoginError(error))
        return { errorData }
    }
}

async function firebaseLoginWithCustomToken(customLoginToken) {
    try {
        const user = await SharedServices.FirebaseService.loginWithCustomToken(customLoginToken)
        return { user }
    }
    catch (error) {
        const errorData = createErrorResponse(GLOBAL_FIELD_KEY, formatFirebaseLoginError(error, undefined, true))
        return { errorData }
    }
}

export async function appLoginUsingFirebaseUser(firebaseUser, loginUserInRedux, dispatch) {
    const fetchPromise = loginExternalGet(firebaseUser.uid)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        loginUserInRedux && await loginUser(responseData.user, dispatch)
    }

    const token = await SharedServices.FirebaseService.getAccessToken(firebaseUser)

    return {
        ...responseData,
        firebaseUser,
        token,
    }
}

export async function executeLogout(wasLoggedIn, role, dispatch) {
    getUser(role).onLogout()
    // // Comment line bellow if you want to enable notifications for last logged in (but now logged out) user
    wasLoggedIn && await NotificationsHelper.sendTokenToServer(false)
    await logoutSimple(dispatch)
}

async function logoutSimple(dispatch) {
    await initializeSettings(0, USER_TYPES.GUEST, false, dispatch)
    SharedServices.SentryService.setUser(null)
    SharedServices.AnalyticsService.setUser(null)
    dispatch(logoutActionSimple())
    await firebaseLogout()
}

export async function firebaseLogout() {
    return await SharedServices.FirebaseService.logout()
}

export async function executeFbLogin(accessToken, { email, username, firstName, lastName, roleId }, dispatch) {
    return {}
    /*
    const fetchPromise = loginFbPost({ email, username, firstName, lastName, roleId, accessToken })
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        if (responseData.refreshToken) {
            const { token, refreshToken, user: userData } = responseData
            await loginUser(token, refreshToken, userData, dispatch)
        }
    }
    return responseData
    */
}

async function loginUser(userData, dispatch) {
    // execute "in parallel" two tasks (settings and languages / user basic data) because they do not depend on each other
    // eslint-disable-next-line no-unused-vars
    const [_, onLoginResultData] = await Promise.all([
        (async () => {
            const settingsData = await NotificationsHelper.sendTokenToServer(true)
            await initializeSettings(userData.id, userData.role, settingsData, dispatch)
        })(),
        (async () => {
            return await getUser(userData.role).onLogin(userData, dispatch)
        })()
    ])

    if (!onLoginResultData || !onLoginResultData.success) {
        // if groups or some other mandatory data failed to load during login process => execute logout - this will set isInitializing to false
        await logoutSimple(dispatch)
    }
    else {
        SharedServices.SentryService.setUser(userData)
        SharedServices.AnalyticsService.setUser(userData)
        // set login data in redux and initialize sentry
        dispatch(loginActionSimple(userData))
    }
}

function formatFirebaseLoginError(error, isStudentChildLogin, isCustomTokenLogin) {
    const defaultErrorMessage = `${error.code} - ${error.message}`

    if (isCustomTokenLogin) {
        return defaultErrorMessage
    }

    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        return texti18n(isStudentChildLogin ? Messages.INVALID_USERNAME_LOGIN : Messages.INVALID_EMAIL_LOGIN)
    }

    return defaultErrorMessage
}

const FIREBASE_DEFAULT_EMAIL_PROVIDER = '@internalpianoadventuresdomain.com'

function getFirebaseEmail(email, username, isStudentChildLogin) {
    if ((isStudentChildLogin && !username) || (!isStudentChildLogin && !email)) {
        return
    }

    const firebaseEmail = isStudentChildLogin ? `${username}${FIREBASE_DEFAULT_EMAIL_PROVIDER}` : email
    return isEmail(firebaseEmail) ? firebaseEmail : undefined
}

function loginActionSimple(userData) {
    return { type: ActionTypes.LOG_IN_ACTION, userData }
}

function logoutActionSimple() {
    return { type: ActionTypes.LOG_OUT_ACTION }
}

