import ActionTypes from '../actions/ActionTypes'
import { 
    setDictionaryState, getFromDict, deleteItem,
    changeItemInItemsInDictionary
} from '../utils/HelperFunctions'

const initialState = {
    conversations: undefined,
    channels: undefined,
    groupMessages: {}, // mapped by group id
}

function privateMessageReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.PRIVATE_MESSAGE_ADD_MESSAGES_TO_GROUPS: {
        const keyConversations = !action.isChannel ? 'conversations' : 'channels'
        let result = updateConversations(action.messages, keyConversations, state)
        if (result.hasChanges) {
            console.log('PrivateMessage reducer conversations change')
            state = result.state
        }
        result = updateGroupMessages(action.messages, state)
        if (result.hasChanges) {
            console.log('PrivateMessage reducer messages change')
            state = result.state
        }

        return state
    }

    case ActionTypes.PRIVATE_MESSAGE_ADD_CONVERSATIONS: {
        const keyConversations = !action.groupType ? 'conversations' : 'channels'
        const result = updateConversations(action.messages, keyConversations, state)
        if (result.hasChanges) {
            console.log('PrivateMessage reducer conversations change')
            state = result.state
        }
        return state
    }
    case ActionTypes.PRIVATE_MESSAGE_ADD_GROUP_MESSAGES: {
        if (action.clearCache) {
            state = setDictionaryState(state, 'groupMessages', action.groupId, action.messages)
        }
        else {
            const oldMessages = getFromDict(state.groupMessages, action.groupId)
            const newMessages = mergeGroupMessages(oldMessages, action.messages)
            if (oldMessages != newMessages) {
                console.log(`PrivateMessage reducer messages change ${action.groupId}`)
                state = setDictionaryState(state, 'groupMessages', action.groupId, newMessages)
            }
        }
        return state
    }
    case ActionTypes.PRIVATE_MESSAGE_DELETE: {
        let changedState = state
        // when last message is deleted, update conversation
        if (state && state.conversations && state.groupMessages) {
            const conversation = state.conversations.find(x => x.groupIds[0] == action.groupId)
            if (conversation && conversation.id == action.messageId) {
                const messages = getFromDict(state.groupMessages, action.groupId, [])
                const msg = messages.filter(x => x.id !== action.messageId).pop()
                if (msg) {
                    const newConversations = state.conversations.map(x => {
                        return x === conversation ? { ...msg, groupIds: x.groupIds } : x
                    })
                    changedState = Object.assign({}, state, {
                        conversations: newConversations
                    })
                }
                else {
                    changedState = Object.assign({}, state, {
                        conversations: state.conversations.filter(x => x.id !== conversation.id)
                    })
                }
            }
        }
        return changeItemInItemsInDictionary(changedState, 'groupMessages', action.groupId,
            deleteItemCreate(action.messageId))
    }
    
    default:
        return state
    }
}

export default privateMessageReducer

function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}

function mergeGroupMessages(oldMessages, newMessages, mustContainLastMessage) {
    if (!newMessages || newMessages.length === 0) {
        return oldMessages
    }

    // backend returns messages ordered from bigger id to lower id if offset is == 0
    // or offset is > 0 and limit is negative.
    // maybe this will be fixed on backend, but until then
    if (newMessages.length > 1 && newMessages[0].id > newMessages[1].id) {
        newMessages.reverse()
    }

    // if there are no old messages, just return newMessages
    if (!oldMessages || oldMessages.length === 0) {
        return newMessages 
    }

    // if mustContainLastMessage:
    // - if last old message is not included in new messages only new messages are returned
    // - if last old message is last message in new messages then nothing has changed
    // - otherwise concat old messages to portion of new messages
    const oldLastMessageId = oldMessages[oldMessages.length - 1].id
    if (mustContainLastMessage) {
        const index = newMessages.findIndex(x => x.id == oldLastMessageId)
        if (index === -1) {
            return newMessages
        }
        else if (index === newMessages.length - 1) {
            return oldMessages
        }
        const sliced = newMessages.slice(index + 1, newMessages.length)
        return oldMessages.concat(sliced)
    }
    
    const oldLastMsgId = oldMessages[oldMessages.length - 1].id
    // check if it should add new messages on the end
    for (let i = 0; i < newMessages.length; ++i) {
        if (oldLastMsgId < newMessages[i].id) {
            const newMessagesSliced = newMessages.slice(i, newMessages.length)
            const returnMessages = oldMessages.concat(newMessagesSliced)
            return returnMessages
        }
    }
    
    const oldFirstMsgId = oldMessages[0].id
    // check if it should add new messages on the begining
    for (let i = newMessages.length - 1; i >= 0; --i) {
        if (oldFirstMsgId > newMessages[i].id) {
            const returnMessages = newMessages.slice(0, i + 1).concat(oldMessages)
            return returnMessages
        }
    }
    
    return oldMessages
}

function updateConversations(messages, keyConversations, state) {
    let hasChanges = false

    let conversations = state[keyConversations] || []
    const mapGroupIdMessage = conversations.reduce((acc, x) => {
        const groupId = x.groupIds[0]
        acc[groupId] = x
        return acc
    }, {})

    messages.forEach(message => {
        message.groupIds.forEach(groupId => {
            if (!(groupId in mapGroupIdMessage) || mapGroupIdMessage[groupId].id < message.id) {
                mapGroupIdMessage[groupId] = { ...message, groupIds: [groupId] }
                hasChanges = true
            }
        })
    })

    if (hasChanges) {
        conversations = Object.values(mapGroupIdMessage)
            .sort((a, b) => {
                const diff = Math.sign(b.id - a.id)
                return diff != 0 ? diff : Math.sign(b.groupIds[0] - a.groupIds[0])
            })
        state = Object.assign({}, state, {
            [keyConversations]: conversations
        })
    }
    return { hasChanges, state }
}

function updateGroupMessages(messages, state) {
    const mapGroupIdMessages = {}
    messages.forEach(message => {
        message.groupIds.forEach(groupId => {
            if (!(groupId in mapGroupIdMessages)) {
                mapGroupIdMessages[groupId] = [message]
            }
            else {
                mapGroupIdMessages[groupId].push(message)
            }
        })
    })

    let hasChanges = false
    for (let groupId in mapGroupIdMessages) {
        const oldMessages = getFromDict(state.groupMessages, groupId)
        const newMessages = mapGroupIdMessages[groupId]
        const updatedMessages = mergeGroupMessages(oldMessages, newMessages, false)
        if (updatedMessages != oldMessages) {
            hasChanges = true
            state = setDictionaryState(state, 'groupMessages', groupId, updatedMessages)
        }
    }
    return { hasChanges, state }
}