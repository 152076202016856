import ActionTypes from '../actions/ActionTypes'
import { 
    replaceOldItem, updateOldItem, setDictionaryState,
    addItemToItemsInDictionary, changeItemInItemsInDictionary
} from '../utils/HelperFunctions'

const initialState = {
    schoolYears: {},
}

function schoolYearsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SCHOOL_YEAR_ADD_ACTION:
        return addItemToItemsInDictionary(state, 'schoolYears',
            action.teacherId, action.schoolYear, schoolYearCompareFunc)
    case ActionTypes.SCHOOL_YEAR_UPDATE_ACTION:
        return changeItemInItemsInDictionary(state, 'schoolYears',
            action.teacherId, replaceOldSchoolYear(action.schoolYear))
    case ActionTypes.SCHOOL_YEAR_ACTIVATE_ACTION:
        return changeItemInItemsInDictionary(state, 'schoolYears',
            action.teacherId, updateSchoolYearIsActive(action.id, action.isActive))
    case ActionTypes.SCHOOL_YEARS_SET_ACTION:
        return setDictionaryState(state, 'schoolYears', action.teacherId, action.schoolYears)

    default:
        return state
    }
}

export default schoolYearsReducer

function replaceOldSchoolYear(schoolYear) {
    return schoolYearsByKey => replaceOldItem(schoolYearsByKey, schoolYear, schoolYearCompareFunc)
}

function updateSchoolYearIsActive(id, value) {
    return schoolYearsByKey => updateOldItem(schoolYearsByKey, id, 
        x => x.isActive = value, schoolYearCompareFunc)
}

function schoolYearCompareFunc(schoolYear) {
    return x => {
        if (x.isActive !== schoolYear.isActive) {
            return !x.isActive
        }
        if (x.startDate !== schoolYear.startDate) {
            return x.startDate < schoolYear.startDate
        }
        return x.id < schoolYear.id
    }
}