
import { fixGroupedItems } from '../utils/HelperFunctions'
import { 
    updateRewardPut, addRewardPost, deleteRewardDelete, rewardsGet, studentRewardsGet, activateRewardPost 
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { NavigatorService, showSuccess } from '../services/SharedServices'
import ActionTypes from './ActionTypes'

/* Rewards start */

export async function addUpdateRewardFetch(reward, dispatch, goBack) {
    const fetchPromise = reward.id 
        ? updateRewardPut(reward) 
        : addRewardPost(reward)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(reward.id ? updateReward(value) : addReward(value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function deleteRewardFetch(id, dispatch, goBack) {
    const fetchPromise = deleteRewardDelete(id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(dispatch(deleteReward(value)))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function changeRewardIsActiveFetch(reward, dispatch, goBack) {
    const fetchPromise = activateRewardPost(reward.id)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setRewardIsActive(reward.id, reward.groupId, responseData.value))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function loadRewardsFetch(groupIds, teacherId, isActive, dispatch) {
    const fetchPromise = rewardsGet(groupIds, teacherId, isActive)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setRewards(responseData.items, groupIds))
    return responseData
}

export async function loadStudentRewardsFetch(isTeacher, groupIds, teacherId, studentId, dispatch) {
    const fetchPromise = studentRewardsGet(studentId, groupIds, teacherId, true)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        // dispatch(addOrUpdateRewards(responseData.items, groupIds)) TODO: do we need this?
        const studentItems = fixGroupedItems(responseData, 'rewardId')
        dispatch(setStudentRewards(isTeacher ? studentId : teacherId, studentItems, groupIds))
    }
    return responseData
}

/* Rewards end */

/* Rewards redux start */

export function addReward(reward) {
    return { type: ActionTypes.TEACHER_ADD_REWARD_ACTION, reward }
}

export function updateReward(reward) {
    return { type: ActionTypes.TEACHER_UPDATE_REWARD_ACTION, reward }
}

export function deleteReward(reward) {
    return { type: ActionTypes.TEACHER_DELETE_REWARD_ACTION, reward } 
}

export function setRewardIsActive(id, groupId, isActive) {
    return { type: ActionTypes.TEACHER_REWARD_CHANGE_IS_ACTIVE_ACTION, id, isActive, groupId }
}

export function setRewards(rewards, groupIds) {
    return { type: ActionTypes.TEACHER_SET_REWARDS_ACTION, rewards, groupIds }
}

export function setStudentRewards(userId, rewards, groupIds) {
    return { type: ActionTypes.SET_STUDENT_REWARDS, userId, rewards, groupIds }
}

export function addOrUpdateRewards(rewards, groupIds) {
    return { type: ActionTypes.TEACHER_ADD_OR_UPDATE_REWARDS_ACTION, rewards, groupIds }
}

/* Rewards redux end */