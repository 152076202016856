import React from 'react'

export const TeacherStudentInfoConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/studentInfo',
            component: React.lazy(() => import('./TeacherStudentInfo')),
            applicationPath: 'TeacherStudentProfile',
        }
    ]
}