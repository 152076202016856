
export const SUPPORTED_IMAGE_MIME_TYPES = Object.freeze({
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    GIF: 'image/gif',
    PNG: 'image/png',
    BMP: 'image/bmp',
})

export const IMAGE_TYPES = Object.freeze({
    '.jpg': SUPPORTED_IMAGE_MIME_TYPES.JPG,
    '.jpeg': SUPPORTED_IMAGE_MIME_TYPES.JPEG,
    '.gif': SUPPORTED_IMAGE_MIME_TYPES.GIF,
    '.png': SUPPORTED_IMAGE_MIME_TYPES.PNG,
    '.bmp': SUPPORTED_IMAGE_MIME_TYPES.BMP,
})

export const MAX_IMAGE_SIZE = 1920