import {
    teacherGlobalGroupsSelector,
    teacherCustomGroupsSelector,
    studentsMapByTeacherIdSelector,
    teacherSingleStudentGroupsSelector,
    siteGlobalGroupsSelector,
    usersMapByIdSelector,
    studentsMapByTeacherIdAndStudentIdSelector,
    studentRankingsByGroupIdSelector,
    singleStudentGroupMap,
    groupStudentList,
    globalGroupsMapByIdSelector,
    userGroupsMapByIdSelector,
    globalGroupOfGroupOwnerSelector,
    teacherDataListSelector,
    teachersMapById,
    teacherCustomAndSingleStudentsGroupsSelector,
    siteGlobalTeacherGroupsSelector,
    siteGlobalStudentGroupsSelector,
    maxLoadedGlobalGroupIdSelector,
    maxLoadedUserGroupIdSelector,
} from '../reselects/GroupsDataReselect'
import { getFromDict } from './HelperFunctions'
import {
    invitedStudentsSelector, suspendedStudentsSelector,
    activeStudentsSelector
} from '../reselects/BaseReselects'
import { GROUP_TYPES, USER_TYPES } from './AppConstants'

// ========================= GROUPS =========================

export const isGroupSiteGlobal = group => !!group && group.groupType === GROUP_TYPES.SITE_GLOBAL
export const isGroupTeacherSiteGlobal = group => !!group && group.groupType === GROUP_TYPES.SITE_GLOBAL_TEACHERS
export const isGroupStudentSiteGlobal = group => !!group && group.groupType === GROUP_TYPES.SITE_GLOBAL_STUDENTS
export const isGroupTeacherGlobal = group => !!group && group.groupType === GROUP_TYPES.TEACHER_GLOBAL
export const isGroupTeacherCustom = group => !!group && group.groupType === GROUP_TYPES.TEACHER_CUSTOM
export const isGroupSingleStudent = group => !!group && group.groupType === GROUP_TYPES.TEACHER_SINGLE_STUDENT

export const getSiteGlobalGroups = globalGroups => {
    return siteGlobalGroupsSelector({ globalGroups })
}

export const getTeacherGlobalGroups = (userGroups, teacherId) => {
    return teacherGlobalGroupsSelector({ userGroups, teacherId })
}

export const getTeacherCustomGroups = (userGroups, teacherId) => {
    return teacherCustomGroupsSelector({ userGroups, teacherId })
}

export const getTeacherSingleStudentGroups = (userGroups, teacherId) => {
    return teacherSingleStudentGroupsSelector({ userGroups, ownerId: teacherId })
}

export const getGlobalGroupDataByGroupId = (globalGroups, groupId) => {
    return getFromDict(globalGroupsMapByIdSelector({ globalGroups }), groupId)
}

export const getUserGroupDataByGroupId = (userGroups, groupId) => {
    return getFromDict(userGroupsMapByIdSelector({ userGroups }), groupId)
}

export const getTeacherCustomAndSingleStudentsGroups = (userGroups, teacherId, onlyActive) => {
    return teacherCustomAndSingleStudentsGroupsSelector({ userGroups, teacherId, onlyActive })
}

export const getSiteGlobalTeacherGroups = globalGroups => {
    return siteGlobalTeacherGroupsSelector({ globalGroups })
}

export const getSiteGlobalStudentGroups = globalGroups => {
    return siteGlobalStudentGroupsSelector({ globalGroups })
}

export const getShouldReloadGroups = (globalGroups, userGroups, groupIdsToCheck, checkingGlobalGroups) => {
    const maxLoadedGroupId = checkingGlobalGroups ? maxLoadedGlobalGroupIdSelector({ globalGroups }) : maxLoadedUserGroupIdSelector({ userGroups })
    const getGroupDataByGroupId = checkingGlobalGroups ? getGlobalGroupDataByGroupId : getUserGroupDataByGroupId
    const groups = checkingGlobalGroups ? globalGroups : userGroups

    return groupIdsToCheck.reduce((acc, groupId) => {
        const groupDataAvailable = !!(getGroupDataByGroupId(groups, groupId))
        if (!groupDataAvailable && groupId < maxLoadedGroupId) {
            console.log(`<getShouldReloadGroups> - Data unavailable for groupId: ${groupId}, where maxGroupId of loaded groups is: ${maxLoadedGroupId}`)
        }
        return acc || (!groupDataAvailable && groupId > maxLoadedGroupId)
    }, false)
}

export const isSingleStudentOrCustomGroupActive = group => {
    if (!group || (!isGroupTeacherCustom(group) && !isGroupSingleStudent(group))) {
        return false
    }

    if (isGroupTeacherCustom(group)) {
        return group.isActive
    }
    else {
        let hasStudents = false
        let allActive = true
        group.users.forEach(user => {
            hasStudents = hasStudents || user.role === USER_TYPES.STUDENT
            allActive = allActive && user.isActive
        })
        const isActive = hasStudents && allActive
        return isActive
    }
}

export const getSingleStudentGroupId = (userGroups, teacherId, otherUserId) => {
    const teacherSSGroups = getTeacherSingleStudentGroups(userGroups, teacherId)
    const group = teacherSSGroups && teacherSSGroups.find(x => x.users.some(y => 
        y.userId === otherUserId && y.isActive
    ))
    return group ? group.id : undefined
}

export const getGroupIdsForTeacherGroupLessons = (userGroups, selectedGroupId, studentGroupId) => {
    if (!selectedGroupId && !studentGroupId) {
        return []
    }
    else if (selectedGroupId && !studentGroupId) {
        return [selectedGroupId]
    }
    else {
        const customGroupIds = getCustomGroupsForStudent(userGroups, studentGroupId)
        if (!customGroupIds) {
            return [studentGroupId]
        }
        return [...customGroupIds, studentGroupId]
    }
}

// ========================= USERS =========================

export const getUserDataByUserId = (users, userId) => {
    return getFromDict(usersMapByIdSelector({ users }), userId)
}

export const getGlobalGroupOfGroupOwner = (userGroups, customOrStudentGroupId) => {
    return globalGroupOfGroupOwnerSelector({ userGroups, groupId: customOrStudentGroupId })
}

// ========================= STUDENTS =========================

export const getCustomGroupStudents = (userGroups, users, students, groupId) => {
    return groupStudentList({ userGroups, users, students, groupId, sort: true })
}

export const getStudentDataByTeacherAndStudentIds = (users, students, teacherId, studentId) => {
    return getFromDict(
        getFromDict(
            studentsMapByTeacherIdAndStudentIdSelector({ users, students }),
            teacherId,
            {}
        ),
        studentId
    )
}

export const getStudentDataBySingleStudentGroupId = (userGroups, users, students, singleStudentGroupId) => {
    const map = singleStudentGroupMap({ userGroups })
    const { teacherId, studentId } = getFromDict(map, singleStudentGroupId, {})
    if (!teacherId || !studentId) {
        return undefined
        // throw 'GroupDataUtils - Could not find single student group for id: ' + singleStudentGroupId
    }
    return getStudentDataByTeacherAndStudentIds(users, students, teacherId, studentId)
}

export const getStudentPointsByGroupId = (userGroups, users, students, singleStudentGroupId) => {
    // for now points are global, so we don't use groupId
    const studentData = getStudentDataBySingleStudentGroupId(userGroups, users, students, singleStudentGroupId)
    if (!studentData) {
        return {
            points: 0,
            availablePoints: 0,
        }
    }
    const { points, availablePoints } = studentData
    return {
        points,
        availablePoints,
    }
}

export const getStudentRankingsByGroupId = (userGroups, users, students, groupId) => studentRankingsByGroupIdSelector({ userGroups, users, students, groupId })

export const getTeacherStudentsList = (users, students, teacherId) => getFromDict(studentsMapByTeacherIdSelector({ users, students }), teacherId, [])

export const getInvitedTeacherStudents = (users, students, teacherId) => {
    return invitedStudentsSelector(getTeacherStudentsList(users, students, teacherId))
}

export const getSuspendedTeacherStudents = (users, students, teacherId) => {
    return suspendedStudentsSelector(getTeacherStudentsList(users, students, teacherId))
}

export const getActiveTeacherStudents = (users, students, teacherId) => {
    return activeStudentsSelector(getTeacherStudentsList(users, students, teacherId))
}

export const filteredStudents = (students, text) => {
    const regex = new RegExp(text, 'i')
    return students.filter(x => `${x.firstName} ${x.lastName}`.search(regex) !== -1)
}

export const filteredGroups = (groups, text) => {
    const regex = new RegExp(text, 'i')
    return groups.filter(x => x.name.search(regex) !== -1)
}

export const getRawTeacherStudentData = (students, teacherId, studentId) => {
    return getFromDict(
        getFromDict(students, teacherId, {}),
        studentId
    )
}

export const getCustomGroupsDataByStudentId = (userGroups, singleStudentGroupId) => {
    const { teacherId, studentId } = getFromDict(
        singleStudentGroupMap({ userGroups }),
        singleStudentGroupId,
        {}
    )
    if (!teacherId || !studentId) {
        return undefined
        // throw 'GroupDataUtils - Could not find single student group for id: ' + singleStudentGroupId
    }
    
    const teacherCustomGroups = getTeacherCustomGroups(userGroups, teacherId)

    return (teacherCustomGroups || []).filter(x => x.users && x.users.some(x => x.userId === studentId))
}

export const getCustomGroupsForStudent = (userGroups, singleStudentGroupId) => {
    const { teacherId, studentId } = getFromDict(
        singleStudentGroupMap({ userGroups }),
        singleStudentGroupId,
        {}
    )
    if (!teacherId || !studentId) {
        return undefined
        // throw 'GroupDataUtils - Could not find single student group for id: ' + singleStudentGroupId
    }
    const teacherCustomGroups = getTeacherCustomGroups(userGroups, teacherId)

    return !teacherCustomGroups ? [] : teacherCustomGroups.reduce((acc, customGroup) => {
        if (customGroup.users && customGroup.users.find(x => x.userId === studentId)) {
            acc.push(customGroup.id)
        }
        return acc
    }, [])
}

// ========================= TEACHERS =========================

export const getTeacherDataById = (users, teacherId) => {
    return getFromDict(teachersMapById({ users }), teacherId)
}

export const getTeacherDataList = (users) => {
    return teacherDataListSelector({ users })
}

export const getTeacherDataBySingleStudentGroupId = (userGroups, users, singleStudentGroupId) => {
    const { teacherId, studentId } = getFromDict(
        singleStudentGroupMap({ userGroups }),
        singleStudentGroupId,
        {}
    )
    if (!teacherId || !studentId) {
        return undefined
        // throw 'GroupDataUtils - Could not find single student group for id: ' + singleStudentGroupId
    }
    return getTeacherDataById(users, teacherId)
}

export const getGroupsIdsForStudentItems = ({ studentGroupId, selectedGroupId, groupId, selectedUser }, studentItems) => {
    const items = getFromDict(studentItems, selectedUser.id, {})
    return getGroupsIdsForItems({ studentGroupId, selectedGroupId, groupId }, items )
}

export const getGroupsIdsForItems = ({ studentGroupId, selectedGroupId, groupId }, items, forceStudentGroup) => {
    const groupIds = []
    const globalGroupItemsLoaded = getFromDict(items, groupId) !== undefined
    const studentGroupItemsLoaded = getFromDict(items, studentGroupId) !== undefined

    !globalGroupItemsLoaded && groupIds.push(groupId)
    if (selectedGroupId) {
        const customGroupItemsLoaded = getFromDict(items, selectedGroupId) !== undefined
        !customGroupItemsLoaded && groupIds.push(selectedGroupId)
    }
    (!studentGroupItemsLoaded || forceStudentGroup) && groupIds.push(studentGroupId)
    return groupIds
}