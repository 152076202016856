import { tryCatchJson } from '../utils/FetchUtils'
import { texti18n, Messages } from '../utils/MessageConstants'
import ActionTypes from './ActionTypes'
import {
    groupsGet, updateGroupPut, addGroupPost,
    deleteGroupDelete, groupNotesGet, updateGroupNotePut,
    addGroupNotePost, deleteGroupNoteDelete, sortGroupNotesPost
} from '../services/EndpointsService'
import { NavigatorService, showError, showSuccess } from '../services/SharedServices'
import { userConnectionForUserIdGet, userConnectionGet } from 'dpn-common/services/IdentityEndpointsService'
import { GROUP_TYPES, ROLE_ID, USER_TYPES } from 'dpn-common/utils/AppConstants'

export async function loadGroupsDataForStudentWithProvidedUserConnectionsRequiredFetch( // TODO: make this name not soo f big
    { loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate },
    userConnections, teacherId, dispatch) {
    const fetchPromise = groupsGet({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate })
    const calls = [
        tryCatchJson(fetchPromise, false),
    ]
    if (teacherId) {
        const fetchPromiseGetUsersForTeacher = userConnectionForUserIdGet(teacherId)
        calls.push(tryCatchJson(fetchPromiseGetUsersForTeacher, false))
    }
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForStudent([responseDatas[0], { items: userConnections }, responseDatas[1]], teacherId)
        dispatch(setGroupsData(responseDatas[0]))
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas[0].success = false // we need to mark somehow to called that request failed
    }
    return responseDatas[0]
}

export async function loadGroupsDataForStudentFetch({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate }, dispatch, teacherId) {
    const fetchPromise = groupsGet({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate })
    const fetchPromiseGetUsers = userConnectionGet()
    const calls = [
        tryCatchJson(fetchPromise, false),
        tryCatchJson(fetchPromiseGetUsers, false)
    ]
    if (teacherId) {
        const fetchPromiseGetUsersForTeacher = userConnectionForUserIdGet(teacherId)
        calls.push(tryCatchJson(fetchPromiseGetUsersForTeacher, false))
    }
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForStudent([responseDatas[0], responseDatas[1], responseDatas[2]], teacherId)
        dispatch(setGroupsData(responseDatas[0]))
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas[0].success = false // we need to mark somehow to called that request failed
    }
    return responseDatas[0]
}

export async function loadGroupsDataForTeacherFetch({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate }, dispatch, userData) {
    const fetchPromise = groupsGet({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate })
    const fetchPromiseGetUsers = userConnectionGet()
    const calls = [
        tryCatchJson(fetchPromise, false),
        tryCatchJson(fetchPromiseGetUsers, false)
    ]
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForTeacher([responseDatas[0], responseDatas[1]], userData)
        dispatch(setGroupsData(responseDatas[0]))
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas[0].success = false // we need to mark somehow to called that request failed
    }
    return responseDatas[0]
}

export async function loadGroupsDataForStudentFetchNoDispatch({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate }, teacherId) {
    const fetchPromise = groupsGet({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate })
    const fetchPromiseGetUsers = userConnectionGet()
    const calls = [
        tryCatchJson(fetchPromise, false),
        tryCatchJson(fetchPromiseGetUsers, false)
    ]
    if (teacherId) {
        const fetchPromiseGetUsersForTeacher = userConnectionForUserIdGet(teacherId)
        calls.push(tryCatchJson(fetchPromiseGetUsersForTeacher, false))
    }
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForStudent([responseDatas[0], responseDatas[1], responseDatas[2]], teacherId)
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas[0].success = false // we need to mark somehow to called that request failed
    }
    return responseDatas[0]
}

export async function loadGroupsDataForTeacherFetchNoDispatch({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate }, userData) {
    const fetchPromise = groupsGet({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate })
    const fetchPromiseGetUsers = userConnectionGet()
    const calls = [
        tryCatchJson(fetchPromise, false),
        tryCatchJson(fetchPromiseGetUsers, false)
    ]
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForTeacher([responseDatas[0], responseDatas[1]], userData)
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas[0].success = false // we need to mark somehow to called that request failed
    }
    return responseDatas[0]
}

export async function addUpdateGroupFetch(group, users, schedules, userData, dispatch, goBack) {
    const fetchPromise = group.id
        ? updateGroupPut(group, users, schedules)
        : addGroupPost(group, users, schedules)
    const fetchPromiseGetUsers = userConnectionGet()
    const calls = [
        tryCatchJson(fetchPromise, false),
        tryCatchJson(fetchPromiseGetUsers, false)
    ]   
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        fixGroupDataForTeacher([responseDatas[0], responseDatas[1]], userData)
        dispatch(setGroupsData(responseDatas[0]))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
    }
    return responseDatas[0]
}

export async function deleteGroupFetch(id, dispatch, goBack) {
    const fetchPromise = deleteGroupDelete(id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteGroup(id))
        showSuccess(texti18n(Messages.GROUP_HAS_BEEN_DELETED))
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function loadGroupNotesFetch(groupId, dispatch) {
    const fetchPromise = groupNotesGet(groupId)
    const responseData = await tryCatchJson(fetchPromise)
    responseData.success && dispatch(setGroupNotes(groupId, responseData.items))
    return responseData
}

export async function addUpdateGroupNoteFetch(groupId, data, dispatch, goBack) {
    const fetchPromise = data.id
        ? updateGroupNotePut(groupId, data)
        : addGroupNotePost(groupId, data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        dispatch(data.id ? updateGroupNote(groupId, value) : addGroupNote(groupId, value))
        showSuccess()
        goBack && NavigatorService.goBack()
    } 
    return responseData
}

export async function deleteGroupNoteFetch(groupId, noteId, dispatch, goBack) {
    const fetchPromise = deleteGroupNoteDelete(groupId, noteId)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteGroupNote(groupId, noteId))
        showSuccess(texti18n(Messages.NOTE_HAS_BEEN_DELETED))
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function deleteGroupNotesFetch(groupId, noteIds, dispatch, goBack) {
    const calls = (noteIds || []).map(x => {
        return tryCatchJson(deleteGroupNoteDelete(groupId, x), false)
    })
    const responseDatas = await Promise.all(calls)
    if (responseDatas.every(x => x.success)) {
        dispatch(deleteGroupNotes(groupId, noteIds))
        responseDatas.success = true
        goBack && NavigatorService.goBack()
    }
    else {
        const error = responseDatas.find(x => !x.success)
        showError(error.message)
        responseDatas.success = false
    }
    return responseDatas
}

export async function sortGroupNotesFetch(groupId, noteIds, dispatch) {
    const fetchPromise = sortGroupNotesPost(groupId, noteIds)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setGroupNotes(groupId, responseData.items))
    }
    return responseData
}

function fixGroupDataForStudent([responseData, users, teacherUsers], teacherId) {
    let allUsers
    if (teacherUsers && teacherUsers.items) {
        // currently its easier to fix this on front
        teacherUsers.items.forEach(x => {
            if (x.roleId === ROLE_ID.STUDENT) {
                x.firstName = x.lastName = undefined
            }
        })
        allUsers = users.items.concat(teacherUsers.items)
    }
    else {
        allUsers = users.items
    }

    // user must exist on both dpn and identity in order to be inside users
    const dpnUsersMap = responseData.users.reduce((p, x) => {
        p[x.id] = x
        return p
    }, {})
    responseData.users = allUsers
        .filter(x => x.id in dpnUsersMap)
        .map(user => ({
            ...user,
            leaderboardParticipation: dpnUsersMap[user.id].leaderboardParticipation,
        }))

    // TODO: do we need this?!?!? why should we put dpn users who are not in identity list (leaderboard participation only) inside user list?!?!?
    // if (!teacherId) {
    //     responseData.users = responseData.users.concat(dpnUsers.filter(x => !allUsers.some(y => y.id === x.id)))
    // }

    const studentIds = teacherUsers && teacherUsers.items.map(x => x.id)
    const teachersStudents = []

    if (studentIds) {

        const students = {}
        students.students = {}

        studentIds.forEach(id => {
            const student = {}
            const teacherSingleStudentGroups = responseData.userGroups.filter(x => x.groupType === GROUP_TYPES.TEACHER_SINGLE_STUDENT)
            const teacherSingleStudentGroup = teacherSingleStudentGroups.find(x => x.userId === teacherId && x.users.some(y => y.userId === id))
            if (teacherSingleStudentGroup) {
                const user = teacherSingleStudentGroup.users.find(x => x.role === USER_TYPES.STUDENT)
                student.assignLevelId = teacherSingleStudentGroup.assignLevelId
                student.availablePoints = user.availablePoints || 0
                student.leaderboardParticipation = user.leaderboardParticipation
                student.points = user.points || 0
                student.isActive = user.isActive
                student.defaultUserGroupId = teacherSingleStudentGroup.id
                student.isInvited = teacherUsers.items.find(user => user.id === id).isInvited
                students.students[id] = student
            }
        })
        students.teacherId = teacherId
        teachersStudents.push(students)
    }
    responseData.students = teachersStudents
}

function fixGroupDataForTeacher([responseData, users], userData) {
    const allUsers = users.items.concat(userData)
    const dpnUsersMap = responseData.users.reduce((p, x) => {
        p[x.id] = x
        return p
    }, {})
    // user must exist on both dpn and identity in order to be inside users
    responseData.users = allUsers
        .filter(x => x.id in dpnUsersMap)
        .map(user => ({
            ...user,
            leaderboardParticipation: dpnUsersMap[user.id].leaderboardParticipation,
        }))

    const studentIds = users.items.map(x => x.id)
    const teachersStudents = []

    if (studentIds) {
        const students = {}
        students.students = {}

        studentIds.forEach(id => {
            const student = {}
            const teacherSingleStudentGroups = responseData.userGroups.filter(x => x.groupType === GROUP_TYPES.TEACHER_SINGLE_STUDENT)
            const teacherSingleStudentGroup = teacherSingleStudentGroups.find(x => x.userId === userData.id && x.users.some(y => y.userId === id))
            if (teacherSingleStudentGroup) {
                const user = teacherSingleStudentGroup.users.find(x => x.role === USER_TYPES.STUDENT)
                student.assignLevelId = teacherSingleStudentGroup.assignLevelId
                student.availablePoints = user.availablePoints || 0
                student.leaderboardParticipation = user.leaderboardParticipation
                student.points = user.points || 0
                student.isActive = user.isActive
                student.defaultUserGroupId = teacherSingleStudentGroup.id
                student.isInvited = users.items.find(user => user.id === id).isInvited
                student.schedules = teacherSingleStudentGroup.schedules
                students.students[id] = student
            }
        })
        students.teacherId = userData.id
        teachersStudents.push(students)
    }
    responseData.students = teachersStudents
}

export function setGroupsData(data) {
    return { type: ActionTypes.GROUPS_DATA_SET_ACTION, data }
}

function deleteGroup(groupId) {
    return { type: ActionTypes.GROUP_DELETE_ACTION, groupId }
}

export function setSelectedGroupStudents(selectedGroupStudentIds) {
    return { type: ActionTypes.SELECTED_GROUP_STUDENTS_SET, selectedGroupStudentIds }
}

function setGroupNotes(groupId, notes) {
    return { type: ActionTypes.SET_GROUP_NOTES_ACTION, groupId, notes }
}

function addGroupNote(groupId, note) {
    return { type: ActionTypes.ADD_GROUP_NOTE_ACTION, groupId, note }
}

function updateGroupNote(groupId, note) {
    return { type: ActionTypes.UPDATE_GROUP_NOTE_ACTION, groupId, note }
}

function deleteGroupNote(groupId, noteId) {
    return { type: ActionTypes.DELETE_GROUP_NOTE_ACTION, groupId, noteId }
}

function deleteGroupNotes(groupId, noteIds) {
    return { type: ActionTypes.DELETE_GROUP_NOTES_ACTION, groupId, noteIds }
}
