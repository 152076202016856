export const LayoutIcons = Object.freeze({
    LESSONS: 'assignment_ind',
    STUDENTS: 'people',
    GROUPS: 'group_work',
    COMMUNICATION: 'message',
    CHATS: 'chat',
    CHANNELS: 'chat_bubble_outline',
    SCHOOL_YEAR: 'school',
    VIDEO_TUTORIALS: 'video_library',
    TEACHER_RESOURCES: 'videocam',
    PRESETS: 'tune',
    TEXT_PRESETS: 'text_fields',
    ASSIGN_LEVEL_PRESETS: 'assignment_ind',
    LEADERBOARD: 'score',
    LANGUAGES: 'translate',
    NOTIFICATIONS: 'notifications',
    PRIVACY_TERMS: 'security',
    SKILLS: 'cast_for_education',
    REWARDS: 'sentiment_satisfied',
    ASSIGNMENTS: 'assignment',
    PIANO_ADVENTURES: 'music_note',
    TEACHERS: 'school',
    DASHBOARD: 'library_music',
    HOME: 'home',
    HELP: 'help',
    CONTACT: 'contact_mail',
    TUTORIALS: 'videocam',
    NOTE: 'note',
    ALL_STUDENTS: 'person',
    ALL_GROUPS: 'groups',
    COURSES: 'subject',
    SETTINGS: 'settings',
    HELP_OUTLINE: 'help_outline',
    VIDEO_LIBRARY: 'play_circle_outline',
    MY_PROFILE: 'account_circle',
})