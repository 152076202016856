import ActionTypes from './ActionTypes'
import { tryCatchJson } from '../utils/FetchUtils'
import { Messages, texti18n } from '../utils/MessageConstants'
import { ORIGINAL_ACCOUNT_TOKEN, IDENTITY_SERVICE } from '../utils/AppConstants'
import SharedService, { setStorageItem, showError, removeStorageItem, NavigatorService, getStorageItem } from '../services/SharedServices'
import { userConnectionLoginTokenGet } from 'dpn-common/services/IdentityEndpointsService'
import { reduxUpdateWait } from 'dpn-common/utils/HelperFunctions'
import FetchService from 'dpn-common/services/FetchService'

export const onAppInitLoggedInForAutomaticLogin = async dispatch => {
    const originalAccountAccessToken = await getStorageItem(ORIGINAL_ACCOUNT_TOKEN)
    dispatch(setOriginalAccountAccessTokenSimple(originalAccountAccessToken))
}

export const onAppInitLoggedOutForAutomaticLogin = async dispatch => {
    await removeStorageItem(ORIGINAL_ACCOUNT_TOKEN)
    dispatch(setOriginalAccountAccessTokenSimple(undefined))
}

export const executeAutomaticLogin = async (userId, loginAction, logoutAction, dispatch) => {
    const fetchPromise = userConnectionLoginTokenGet(userId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (!responseData?.success) {
        NavigatorService.goBack()
        return
    }

    const loginToken = responseData.token

    const originalAccountAccessToken = await SharedService.FirebaseService.getAccessToken(undefined, true)
    await setStorageItem(ORIGINAL_ACCOUNT_TOKEN, originalAccountAccessToken)
    dispatch(setOriginalAccountAccessTokenSimple(originalAccountAccessToken))
    dispatch(setAutomaticLoginInProgress(true))
    reduxUpdateWait()

    await logoutAction()

    const loginResponseData = await loginAction(loginToken)
    if (!loginResponseData.success) {
        showError(texti18n(Messages.UNKNOWN_ERROR_OCCURRED))
        NavigatorService.goBack()
    }
    
    dispatch(setAutomaticLoginInProgress(false))
}

export const executeLogoutFromAutomaticLogin = async (originalAccountAccessToken, loginAction, logoutAction, dispatch) => {
    let loginToken
    if (originalAccountAccessToken) {
        FetchService[IDENTITY_SERVICE].setCustomHeaders({ token: originalAccountAccessToken })
        
        const fetchPromise = userConnectionLoginTokenGet()
        const responseData = await tryCatchJson(fetchPromise, true)
        loginToken = responseData?.success ? responseData.token : undefined

        FetchService[IDENTITY_SERVICE].setCustomHeaders(undefined)
    }
    
    await removeStorageItem(ORIGINAL_ACCOUNT_TOKEN)
    dispatch(setOriginalAccountAccessTokenSimple(undefined))

    if (loginToken) {
        dispatch(setAutomaticLoginInProgress(true))
        reduxUpdateWait()
    
        await logoutAction()
        
        const loginResponseData = await loginAction(loginToken)
        if (!loginResponseData.success) {
            showError(texti18n(Messages.UNKNOWN_ERROR_OCCURRED))
            NavigatorService.goBack()
        }
        
        dispatch(setAutomaticLoginInProgress(false))
    }
    else {
        await logoutAction()
    }
}

function setAutomaticLoginInProgress(inProgress) {
    return { type: ActionTypes.SET_AUTOMATIC_LOGIN_IN_PROGRESS, inProgress }
}

function setOriginalAccountAccessTokenSimple(originalAccountAccessToken) {
    return { type: ActionTypes.SET_ORIGINAL_ACCOUNT_ACCESS_TOKEN, originalAccountAccessToken }
}