import { FIREBASE_CONFIG } from 'app/FirebaseConfig'
import { initializeApp } from 'firebase/app'
import {
    getAuth, signInWithEmailAndPassword, signInWithPopup, signOut,
    GoogleAuthProvider, OAuthProvider, signInWithCustomToken,
} from 'firebase/auth'

let firebaseApp

function init() {
    firebaseApp = initializeApp(FIREBASE_CONFIG)
}

function getFirebaseAuth() {
    return getAuth(firebaseApp)
}

function getFirebaseUser() {
    return getFirebaseAuth()?.currentUser
}

async function loginWithEmailAndPassword(email, password) {
    const { user } = await signInWithEmailAndPassword(getFirebaseAuth(), email, password)
    return user
}

async function loginWithGoogle() {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    const { user } = await signInWithPopup(getFirebaseAuth(), provider)
    return user
}

async function loginWithApple() {
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    const { user } = await signInWithPopup(getFirebaseAuth(), provider)
    return user
}

async function loginWithCustomToken(customLoginToken) {
    const { user } = await signInWithCustomToken(getFirebaseAuth(), customLoginToken)
    return user
}

async function getAccessToken(firebaseUser, forceRefresh) {
    if (!firebaseUser) {
        firebaseUser = getFirebaseUser()
    }

    return await firebaseUser?.getIdToken(forceRefresh)
}

async function logout() {
    return await signOut(getFirebaseAuth())
}

let onAuthStateChangedListener

function setOnAuthStateChanged(callback) {
    onAuthStateChangedListener && onAuthStateChangedListener()

    if (callback) {
        onAuthStateChangedListener = getFirebaseAuth().onAuthStateChanged(callback)
    }
}

async function appleLoginSupported() {
    return true
}

async function googleLoginSupported() {
    return true
}

export default {
    init,
    getFirebaseAuth,
    getFirebaseUser,
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithApple,
    loginWithCustomToken,
    getAccessToken,
    logout,
    setOnAuthStateChanged,
    appleLoginSupported,
    googleLoginSupported,
}