
import React from 'react'
import PropTypes from 'prop-types'

function ExportIcon(props) {
    const { color, size, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 24 24"
            {...otherProps}>
            <path transform='rotate(90, 12, 12)' d="M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z" />
        </svg>
    )
}

ExportIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ExportIcon.defaultProps = {
    color: 'white',
    size: 24,
}

export default ExportIcon