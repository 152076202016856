import React from 'react'

export const TeacherSentencesConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/custom-presets/text',
            component: React.lazy(() => import('./TeacherSentences')),
            applicationPath: 'TeacherSentences',
        },
    ]
}