import AppConfig from 'dpn-common/config/AppConfig'

export const NOTIFICATION_TYPES = Object.freeze({
    MESSAGES: 0,
    CHANNELS: 1,
    LEADERBOARD: 2,
    STUDENT_INVITED: 3,
    STUDENT_ACCEPTED_INVITATION: 4,
    LESSONS: 5,
    ASSIGNMENT_COMMENTS: 6,
})

export const TEACHER_NOTIFICATION_TYPES = Object.freeze([
    NOTIFICATION_TYPES.MESSAGES,
    NOTIFICATION_TYPES.CHANNELS,
    NOTIFICATION_TYPES.STUDENT_ACCEPTED_INVITATION,
    NOTIFICATION_TYPES.ASSIGNMENT_COMMENTS,
])

export const STUDENT_NOTIFICATION_TYPES = Object.freeze([
    NOTIFICATION_TYPES.MESSAGES,
    NOTIFICATION_TYPES.CHANNELS,
    NOTIFICATION_TYPES.LEADERBOARD,
    NOTIFICATION_TYPES.STUDENT_INVITED,
    NOTIFICATION_TYPES.LESSONS,
    NOTIFICATION_TYPES.ASSIGNMENT_COMMENTS,

])

export const ACTION_NOTIFICATION_TYPES = Object.freeze({
    STUDENT_INVITED: 'STUDENT_INVITED',
    NEW_MESSAGE: 'NEW_MESSAGE',
    NEW_CHANNEL_MESSAGE: 'NEW_CHANNEL_MESSAGE',
    STUDENT_ACCEPTED_INVITATION: 'STUDENT_ACCEPTED_INVITATION',
    SKILL_ADDED_TO_STUDENT: 'SKILL_ADDED_TO_STUDENT',
    SKILL_REMOVED_FROM_STUDENT: 'SKILL_REMOVED_FROM_STUDENT',
    NEW_LESSON: 'NEW_LESSON',
    NEW_ASSIGNMENT_COMMENT: 'NEW_ASSIGNMENT_COMMENT',
})

export const NOTIFICATION_TOPICS = Object.freeze({
    TEACHER_CHANNEL: 'TeacherChannel' + AppConfig.topicSuffix,
    STUDENT_CHANNEL: 'StudentChannel' + AppConfig.topicSuffix,
})