const ActionTypes = Object.freeze({
    LOG_IN_ACTION: 'LOG_IN_ACTION',
    LOG_OUT_ACTION: 'LOG_OUT_ACTION',
    CHANGE_USER_DATA_ACTION: 'CHANGE_USER_DATA_ACTION',
    SET_SETTINGS_ACTION: 'SET_SETTINGS_ACTION',
    SET_LANGUAGES_ACTION: 'SET_LANGUAGES_ACTION',
    SET_LANGUAGE_DEFINITION_ACTION: 'SET_LANGUAGE_DEFINITION_ACTION',
    SET_NOTIFICATIONS_ACTION: 'SET_NOTIFICATIONS_ACTION',
    SET_EMAIL_NOTIFICATIONS_ACTION: 'SET_EMAIL_NOTIFICATIONS_ACTION',
    SET_LANGUAGE_CODE_ACTION: 'SET_LANGUAGE_CODE_ACTION',
    CHANGE_LEADERBOARD_PARTICIPATION: 'CHANGE_LEADERBOARD_PARTICIPATION',

    STUDENT_SET_CHOSEN_TEACHER_ACTION: 'STUDENT_SET_CHOSEN_TEACHER_ACTION',
    SET_STUDENT_CHILDREN: 'SET_STUDENT_CHILDREN',

    ADD_LESSON_ACTION: 'ADD_LESSON_ACTION',
    UPDATE_LESSON_ACTION: 'UPDATE_LESSON_ACTION',
    ACTIVATE_LESSON_ACTION: 'ACTIVATE_LESSON_ACTION',
    PUBLISH_LESSON_ACTION: 'PUBLISH_LESSON_ACTION',
    SET_LESSONS_ACTION: 'SET_LESSONS_ACTION',
    SET_SELECTED_ASSIGNMENTS: 'SET_SELECTED_ASSIGNMENTS',
    DELETE_LESSON_ACTION: 'DELETE_LESSON_ACTION',

    ADD_LESSON_NOTE_ACTION: 'ADD_LESSON_NOTE_ACTION',
    UPDATE_LESSON_NOTE_ACTION: 'UPDATE_LESSON_NOTE_ACTION',
    SET_LESSON_NOTES_ACTION: 'SET_LESSON_NOTES_ACTION',
    SET_SELECTED_NOTES: 'SET_SELECTED_NOTES',
    DELETE_LESSON_NOTE_ACTION: 'DELETE_LESSON_NOTE_ACTION',
    DELETE_LESSON_NOTES_ACTION: 'DELETE_LESSON_NOTES_ACTION',

    ADD_GROUP_NOTE_ACTION: 'ADD_GROUP_NOTE_ACTION',
    UPDATE_GROUP_NOTE_ACTION: 'UPDATE_GROUP_NOTE_ACTION',
    SET_GROUP_NOTES_ACTION: 'SET_GROUP_NOTES_ACTION',
    DELETE_GROUP_NOTE_ACTION: 'DELETE_GROUP_NOTE_ACTION',
    DELETE_GROUP_NOTES_ACTION: 'DELETE_GROUP_NOTES_ACTION',

    TEACHER_ADD_SKILL_ACTION: 'TEACHER_ADD_SKILL_ACTION',
    TEACHER_UPDATE_SKILL_ACTION: 'TEACHER_UPDATE_SKILL_ACTION',
    TEACHER_DELETE_SKILL_ACTION: 'TEACHER_DELETE_SKILL_ACTION',
    TEACHER_SKILL_CHANGE_IS_ACTIVE_ACTION: 'TEACHER_SKILL_CHANGE_IS_ACTIVE_ACTION',
    TEACHER_SET_SKILLS_ACTION: 'TEACHER_SET_SKILLS_ACTION',
    SET_STUDENT_SKILLS: 'SET_STUDENT_SKILLS',
    SET_STUDENT_REWARDS: 'SET_STUDENT_REWARDS',
    TEACHER_ADD_OR_UPDATE_SKILLS_ACTION: 'TEACHER_ADD_OR_UPDATE_SKILLS_ACTION',

    TEACHER_ADD_REWARD_ACTION: 'TEACHER_ADD_REWARD_ACTION',
    TEACHER_UPDATE_REWARD_ACTION: 'TEACHER_UPDATE_REWARD_ACTION',
    TEACHER_DELETE_REWARD_ACTION: 'TEACHER_DELETE_REWARD_ACTION',
    TEACHER_REWARD_CHANGE_IS_ACTIVE_ACTION: 'TEACHER_REWARD_CHANGE_IS_ACTIVE_ACTION',
    TEACHER_SET_REWARDS_ACTION: 'TEACHER_SET_REWARDS_ACTION',
    TEACHER_ADD_OR_UPDATE_REWARDS_ACTION: 'TEACHER_ADD_OR_UPDATE_REWARDS_ACTION',

    SET_ASSIGNMENTS_FOR_ASSIGNMENT_FOLDER: 'SET_ASSIGNMENTS_FOR_ASSIGNMENT_FOLDER',
    ADD_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: 'ADD_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER',
    UPDATE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: 'UPDATE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER',
    DELETE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER: 'DELETE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER',
    
    PRIVATE_MESSAGE_ADD_CONVERSATIONS: 'PRIVATE_MESSAGE_ADD_CONVERSATIONS',
    PRIVATE_MESSAGE_ADD_GROUP_MESSAGES: 'PRIVATE_MESSAGE_ADD_GROUP_MESSAGES',
    PRIVATE_MESSAGE_DELETE: 'PRIVATE_MESSAGE_DELETE',
    PRIVATE_MESSAGE_ADD_MESSAGES_TO_GROUPS: 'PRIVATE_MESSAGE_ADD_MESSAGES_TO_GROUPS',

    SET_FLASH_CARDS_ACTION: 'SET_FLASH_CARDS_ACTION',
    SET_PIANO_ADVENTURES_BOOKS: 'SET_PIANO_ADVENTURES_BOOKS',
    SET_EMOTICONS_ACTION: 'SET_EMOTICONS_ACTION',
    SET_VIDEO_TUTORIALS: 'SET_VIDEO_TUTORIALS',
    SET_TEACHER_VIDEO_TUTORIALS: 'SET_TEACHER_VIDEO_TUTORIALS',

    SENTENCES_SET_ACTION: 'SENTENCES_SET_ACTION',
    SENTENCE_ADD_ACTION: 'SENTENCE_ADD_ACTION',
    SENTENCE_UPDATE_ACTION: 'SENTENCE_UPDATE_ACTION',
    SENTENCE_DELETE_ACTION: 'SENTENCE_DELETE_ACTION',

    ASSIGN_LEVELS_SET_ACTION: 'ASSIGN_LEVELS_SET_ACTION',
    ASSIGN_LEVEL_ADD_ACTION: 'ASSIGN_LEVEL_ADD_ACTION',
    ASSIGN_LEVEL_UPDATE_ACTION: 'ASSIGN_LEVEL_UPDATE_ACTION',
    ASSIGN_LEVEL_DELETE_ACTION: 'ASSIGN_LEVEL_DELETE_ACTION',

    SCHOOL_YEAR_ACTIVATE_ACTION: 'SCHOOL_YEAR_ACTIVATE_ACTION',
    SCHOOL_YEARS_SET_ACTION: 'SCHOOL_YEARS_SET_ACTION',
    SCHOOL_YEAR_ADD_ACTION: 'SCHOOL_YEAR_ADD_ACTION',
    SCHOOL_YEAR_UPDATE_ACTION: 'SCHOOL_YEAR_UPDATE_ACTION',
    SCHOOL_YEAR_DELETE_ACTION: 'SCHOOL_YEAR_DELETE_ACTION',

    SET_PREMADE_AVATARS_ACTION: 'SET_PREMADE_AVATARS_ACTION',

    GROUPS_DATA_SET_ACTION: 'GROUPS_DATA_SET_ACTION',
    GROUP_DELETE_ACTION: 'GROUP_DELETE_ACTION',
    SELECTED_GROUP_STUDENTS_SET: 'SELECTED_GROUP_STUDENTS_SET',
    HOME_SCREEN_ACCESS_TIMES_INC: 'HOME_SCREEN_ACCESS_TIMES_INC',

    ACTIVE_STUDENT_UPDATE: 'ACTIVE_STUDENT_UPDATE',
    IMPORT_DEMO_STUDENTS: 'IMPORT_DEMO_STUDENTS',
    DELETE_STUDENT: 'DELETE_STUDENT',

    SET_NOTES_FOR_NOTE_FOLDER: 'SET_NOTES_FOR_NOTE_FOLDER',
    ADD_NOTE_FOR_NOTE_FOLDER: 'ADD_NOTE_FOR_NOTE_FOLDER',
    UPDATE_NOTE_FOR_NOTE_FOLDER: 'UPDATE_NOTE_FOR_NOTE_FOLDER',
    DELETE_NOTE_FOR_NOTE_FOLDER: 'DELETE_NOTE_FOR_NOTE_FOLDER',
    EXTERNAL_LOGIN_FROM: 'EXTERNAL_LOGIN_FROM',

    SET_ORIGINAL_ACCOUNT_ACCESS_TOKEN: 'SET_ORIGINAL_ACCOUNT_ACCESS_TOKEN',
    SET_AUTOMATIC_LOGIN_IN_PROGRESS: 'SET_AUTOMATIC_LOGIN_IN_PROGRESS',
})

export default ActionTypes