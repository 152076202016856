import ActionTypes from '../actions/ActionTypes'
import { 
    replaceOldItem, deleteItem, insertAtPositionMutable,
} from '../utils/HelperFunctions'

const initialState = {
    assignLevels: undefined,
}

function assignLevelsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ASSIGN_LEVELS_SET_ACTION:
        return Object.assign({}, state, {
            assignLevels: action.assignLevels
        })
    case ActionTypes.ASSIGN_LEVEL_ADD_ACTION:
        return Object.assign({}, state, {
            assignLevels: insertAtPositionMutable([...state.assignLevels], action.assignLevel, assignLevelCompareFunc)
        })
    case ActionTypes.ASSIGN_LEVEL_UPDATE_ACTION:
        return Object.assign({}, state, {
            assignLevels: replaceOldItem([...state.assignLevels], action.assignLevel, assignLevelCompareFunc)
        })
    case ActionTypes.ASSIGN_LEVEL_DELETE_ACTION:
        return Object.assign({}, state, {
            assignLevels: deleteItem([...state.assignLevels], action.assignLevel.id)
        })
    default:
        return state
    }
}

export default assignLevelsReducer

function assignLevelCompareFunc(assignLevel) {
    return x => {
        if (x.order != assignLevel.order) {
            return x.order > assignLevel.order
        }
        if (x.color != assignLevel.color) {
            return x.color > assignLevel.color
        }
        if (x.name != assignLevel.name) {
            return x.name.toLowerCase() > assignLevel.name.toLowerCase()
        }
        return x.id > assignLevel.id
    }
}