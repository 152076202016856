import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'

let alertService

function init(alertServiceRef) {
    alertService = alertServiceRef
}

function showError(error) {
    alertService && alertService.alertWithType('error', error)
}

function showSuccess(message = texti18n(Messages .CHANGES_HAS_BEEN_SAVED)) {
    alertService && alertService.alertWithType('success', message)
}

function showInfo(message) {
    alertService && alertService.alertWithType('info', message)
}

function showMessage(title, message) {
    alertService && alertService.alertWithType('info', message)
}

export default {
    init,
    showError,
    showSuccess,
    showInfo,
    showMessage,
}