import React from 'react'

export const TeacherLessonsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/teacherLessons',
            component: React.lazy(() => import('./TeacherGroupLessonList')),
            applicationPath: 'TeacherGroupLessonList',
        },
        {
            path: '/teacherPreviousSchoolYears',
            component: React.lazy(() => import('./TeacherGroupSchoolYear')),
            applicationPath: 'TeacherGroupSchoolYear',
        },
        {
            path: '/teacherPreviousSchoolYearLessons',
            component: React.lazy(() => import('./TeacherGroupSchoolYearLessons')),
            applicationPath: 'TeacherGroupSchoolYearLessons',
        },
        {
            path: '/teacherLessonInfo',
            component: React.lazy(() => import('./TeacherLessonInfo')),
            applicationPath: 'TeacherLessonInfo',
        }
    ]
}