import ActionTypes from '../actions/ActionTypes'
import { NOTIFICATION_TYPES } from '../utils/NotificationsConstants'
import { deepDiffer } from 'dpn-common/utils/HelperFunctions'

const initialState = {
    settings: {
        languageCode: 'en-US',
        notifications: Object.values(NOTIFICATION_TYPES),
        emailNotifications: undefined,
    },
    languages: undefined,
    languageDefinition: {},
}

function settingsAndLanguagesReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_SETTINGS_ACTION:
        if (areDiffer(action.settings, state.settings)) {
            state = Object.assign({}, state, {
                settings: action.settings,
            })
        }
        break    
    case ActionTypes.SET_LANGUAGES_ACTION:
        state = Object.assign({}, state, {
            languages: action.languages
        })
        break
    case ActionTypes.SET_NOTIFICATIONS_ACTION:
        state = Object.assign({}, state, {
            settings: { ...state.settings, notifications: action.notifications }
        })
        break
    case ActionTypes.SET_EMAIL_NOTIFICATIONS_ACTION:
        state = Object.assign({}, state, {
            settings: { ...state.settings, emailNotifications: action.emailNotifications }
        })
        break
    case ActionTypes.SET_LANGUAGE_DEFINITION_ACTION:
        state = Object.assign({}, state, {
            languageDefinition: action.languageDefinition
        })
        break
    case ActionTypes.SET_LANGUAGE_CODE_ACTION:
        state = Object.assign({}, state, {
            settings: { ...state.settings, languageCode: action.languageCode }
        })
        break
    }
    return state
}

export default settingsAndLanguagesReducer

function areDiffer(newSettings, oldSettings) {
    let result = false
    if (newSettings) {
        const oldKeys = Object.keys(oldSettings)
        const newKeys = Object.keys(newSettings)
        result = oldKeys.length == newKeys.length &&
            oldKeys.every(x => x in newSettings && newSettings[x] !== undefined) &&
            deepDiffer(newSettings, oldSettings)
    }
    return result
}