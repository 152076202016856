import FetchService from './FetchService'
import { GROUP_FETCH_PARAMS } from '../utils/AppConstants'

export const changeUserLeaderboardParticipationPut = (data) => {
    return FetchService.executePut('/api/user/changeLeaderboardParticipation', data)
}

export const enabledEmailTypesGet = () => {
  return FetchService.executeGet('/api/user/getEnabledEmailTypes')
}

export const setEnabledEmailTypesPost = (enabledEmailTypes) => {
    return FetchService.executePost('/api/user/setEnabledEmailTypes', { enabledEmailTypes })
}

export const assignLevelsGet = () => {
    return FetchService.executeGet('/api/assignLevel')
}

export const premadeAvatarsGet = () => {
    return FetchService.executeGet('/api/premadeAvatars')
}

export const addAssignLevelPost = ({ name, code, color }) => {
    return FetchService.executePost('/api/assignLevel', { name, code, color })
}

export const updateAssignLevelPut = (id, { name, code, color }) => {
    return FetchService.executePut(`/api/assignLevel/${id}`, { name, code, color })
}

export const deleteAssignLevelDelete = (id) => {
    return FetchService.executeDelete(`/api/assignLevel/${id}`)
}

export const editStudentPut = (defaultUserGroupId, fullName, assignLevelId, schedules) => {
    return FetchService.executePut(`/api/group/${defaultUserGroupId}`, { assignLevelId, name: fullName, schedules })
}

export const studentByEmailGet = (email) => {
    return FetchService.executeGet('/api/teacher/student/byEmail', { email })
}

export const addLessonPost = ({ groupId, schoolYearId, name, date, code, color, cloneLessonId, cloneLastLesson, isPublished }) => {
    return FetchService.executePost('/api/lesson', { groupId, schoolYearId, name, date, code, color, cloneLessonId, cloneLastLesson, isPublished })
}

export const updateLessonPut = ({ id, groupId, schoolYearId, name, date, code, color, isPublished }) => {
    return FetchService.executePut('/api/lesson/' + id, { id, groupId, schoolYearId, name, date, code, color, isPublished })
}

export const lessonNotesGet = (lessonId) => {
    return FetchService.executeGet(`/api/lesson/${lessonId}/note`)
}

export const addLessonNotePost = (lessonId, newFiles, deletedFiles, { id, name, text, code, color }) => {
    return FetchService.executePost(`/api/lesson/${lessonId}/note/`, { files: newFiles, deletedFiles, id, name, text, code, color })
}

export const addLessonTemplateNotePost = (lessonId, noteId, templateGroupId) => {
    return FetchService.executePost(`/api/lesson/${lessonId}/note/template`, { noteId, templateGroupId })
}

export const updateLessonNotePut = (lessonId, newFiles, deletedFiles, { id, name, text, code, color }) => {
    return FetchService.executePut(`/api/lesson/${lessonId}/note/${id}`, { newFiles, deletedFiles, name, text, code, color })
}

export const deleteLessonNoteDelete = (lessonId, noteId) => {
    return FetchService.executeDelete(`/api/lesson/${lessonId}/note/${noteId}`)
}

export const sortLessonNotesPost = (id, noteIds) => {
    return FetchService.executePost(`/api/lesson/${id}/note/sort/`, { noteIds })
}

export const groupNotesGet = (groupId) => {
    return FetchService.executeGet(`/api/group/${groupId}/note`)
}

export const addGroupNotePost = (groupId, { id, name, text, code, color }) => {
    return FetchService.executePost(`/api/group/${groupId}/note/`, { id, name, text, code, color })
}

export const updateGroupNotePut = (groupId, { id, name, text, code, color }) => {
    return FetchService.executePut(`/api/group/${groupId}/note/${id}/`, { groupId, id, name, text, code, color })
}

export const deleteGroupNoteDelete = (groupId, id) => {
    return FetchService.executeDelete(`/api/group/${groupId}/note/${id}/`)
}

export const sortGroupNotesPost = (id, noteIds) => {
    return FetchService.executePost(`/api/group/${id}/note/sort/`, { noteIds })
}

export const notesGet = (groupId, grouped, isActive) => {
    return FetchService.executeGet('/api/note/' + groupId, { grouped, isActive })
}

export const updateNotePut = ({ groupId, id, name, text, code, color, parentId, deletedFiles, newFiles, isFolder }) => {
    return FetchService.executePut(`/api/note/${groupId}/${id}`, { name, text, code, color, parentId, deletedFiles, newFiles, isFolder })
}

export const addNotePost = ({ groupId, id, name, text, code, color, parentId, isFolder, newFiles }) => {
    return FetchService.executePost(`/api/note/${groupId}`, { id, name, text, code, color, parentId, isFolder, files: newFiles })
}

export const deleteNoteDelete = (groupId, id) => {
    return FetchService.executeDelete(`/api/note/${groupId}/${id}`)
}

export const sortNotePost = (groupId, parentId, noteIds) => {
    return FetchService.executePost(`/api/note/${groupId}/sort`, { noteIds, parentId })
}

export const lessonsGet = (schoolYearId, groupIds, isActive, userId, dateFrom, dateTo, search, isPublished) => {
    return FetchService.executeGet('/api/lesson/', { schoolYearId, groupIds, isActive, userId, dateFrom, dateTo, search, isPublished })
}

export const activateLessonPost = (id) => {
    return FetchService.executePost(`/api/lesson/${id}/activate`)
}

export const publishLessonPost = (id) => {
    return FetchService.executePost(`/api/lesson/${id}/publish`)
}

export const deleteLessonDelete = (id) => {
    return FetchService.executeDelete(`/api/lesson/${id}`)
}

export const addSkillPost = ({ groupId, name, points, picture, skillType }) => {
    return FetchService.executePost('/api/skill', { groupId, name, points, picture, skillType })
}

export const updateSkillPut = ({ id, groupId, name, points, picture, skillType }) => {
    return FetchService.executePut('/api/skill/' + id, { groupId, name, points, picture, skillType })
}

export const deleteSkillDelete = (skillId) => {
    return FetchService.executeDelete(`/api/skill/${skillId}`)
}

// Use groupIds or teacherId but not both
export const skillsGet = (groupIds, teacherId, isActive) => {
    return FetchService.executeGet('/api/skill', { groupIds, teacherId, isActive })
} 

export const activateSkillPost = (id) => {
    return FetchService.executePost(`/api/skill/${id}/activate`)
}

export const addRewardPost = ({ groupId, name, points, picture, description }) => {
    return FetchService.executePost('/api/reward', { groupId, name, points, picture, description })
}

export const updateRewardPut = ({ id, groupId, name, points, picture, description }) => {
    return FetchService.executePut('/api/reward/' + id, { groupId, name, points, picture, description })
}

export const deleteRewardDelete = (rewardId) => {
    return FetchService.executeDelete(`/api/reward/${rewardId}`)
}

export const rewardsGet = (groupIds, teacherId, isActive) => {
    return FetchService.executeGet('/api/reward', { groupIds, teacherId, isActive })
} 

export const activateRewardPost = (id) => {
    return FetchService.executePost(`/api/reward/${id}/activate`)
}

export const studentRewardsGet = (studentId, groupIds, teacherId, isActive, grouped = true) => {
    return FetchService.executeGet(`/api/reward/student/${studentId}`, {
        groupIds, teacherId, isActive, grouped
    })
}

export const studentSkillsGet = (studentId, groupIds, teacherId, isActive, grouped = true) => {
    return FetchService.executeGet(`/api/skill/student/${studentId}`, {
        groupIds, teacherId, isActive, grouped
    })
}

export const buyRewardPost = (rewardId, studentId = null) => {
    return FetchService.executePost(`/api/reward/item/${rewardId}`, null, { studentId })
}

export const sellRewardDelete = (id) => {
    return FetchService.executeDelete(`/api/reward/item/${id}`)
}

export const addSkillToStudentPost = (skillId, studentId) => {
    return FetchService.executePost(`/api/skill/${skillId}/student/${studentId}`)
}

export const removeSkillFromStudentDelete = (id) => {
    return FetchService.executeDelete(`/api/skill/item/${id}`)
}

export const importAssignmentPost = (groupId, parentId, content, importFileContent) => {
    return FetchService.executePost(`/api/assignment/import/${groupId}`, { parentId, content, importFileContent })
}

export const exportAssignmentPost = (groupId, ids, exportFileContent) => {
    return FetchService.executePost(`/api/assignment/export/${groupId}`, { ids, exportFileContent })
}

export const updateAssignmentPut = ({ id, groupId, name, links, description, code, color, parentId, deletedFiles, newFiles, isFolder }) => {
    return FetchService.executePut(`/api/assignment/${id}`, { groupId, name, description, links, code, color, parentId, deletedFiles, newFiles, isFolder })
}

export const addAssignmentPost = ({ groupId, name, links, description, code, color, parentId, isFolder, newFiles }) => {
    return FetchService.executePost(`/api/assignment/group/${groupId}`, { groupId, name, description, links, code, color, parentId, isFolder, newFiles })
}

export const sortAssignmentPost = (groupId, parentId, assignmentIds) => {
    return FetchService.executePost(`/api/assignment/group/${groupId}/sort`, { assignmentIds, parentId })
}

export const assignmentsGet = ({ groupId, parentId, isActive, search, filterByParent, isFolder, offset, limit, populateLinks, grouped, relateTo }) => {
    return FetchService.executeGet(`/api/assignment/group/${groupId}`, { parentId, isActive, search, filterByParent, isFolder, offset, limit, populateLinks, grouped, relateTo })
}

export const assignmentGet = id => {
    return FetchService.executeGet(`/api/assignment/${id}`)
}

export const assignmentsRelatedGet = (id, userRelateTo) => {
    return FetchService.executeGet(`/api/assignment/related/${id}`, { userRelateTo })
}

export const deleteAssignmentDelete = (assignmentId) => {
    return FetchService.executeDelete(`/api/assignment/${assignmentId}`)
}

export const assignmentCommentsGet = (assignmentId, groupIds, offset, limit) => {
    const params = { offset, limit, groupIds }
    return FetchService.executeGet(`/api/comment/assignment/${assignmentId}`, params)
}

export const addAssignmentCommentPost = (groupId, assignmentId, comment, picture, lessonId) => {
    return FetchService.executePost('/api/comment', { groupId, assignmentId, comment, fileContent: picture, lessonId })
}

export const deleteAssignmentCommentDelete = (commentId) => {
    return FetchService.executeDelete(`/api/comment/${commentId}`)
}

export const lessonAssignmentsGet = (lessonId) => {
    return FetchService.executeGet(`/api/lesson/${lessonId}/assignment`)
}

export const lessonGet = (id) => {
    return FetchService.executeGet(`/api/lesson/${id}`)
}

export const setLessonAssignmentsPost = (lessonId, assignmentIds) => {
    return FetchService.executePost(`/api/lesson/${lessonId}/assignment`, { assignmentIds })
}

export const messagesGroupedGet = (groupOwnerUserId, groupType, offset, limit) => {
    return FetchService.executeGet('/api/message/grouped', { offset, limit, groupOwnerUserId, groupType })
}

export const messagesByTextGet = (text, offset, limit) => {
    return FetchService.executeGet('/api/message/search' , { text, offset, limit })
}

export const messagesGet = (groupId, offset, limit) => {
    return FetchService.executeGet(`/api/message/${groupId}`, { offset, limit })
}

export const sendMessagePost = (groupIds, message) => {
    return FetchService.executePost('/api/message', { groupIds, message })
}

export const deleteMessageDelete = (groupId, messageId) => {
    return FetchService.executeDelete(`/api/message/${groupId}/${messageId}`)
}

export const demoSkillsPost = (groupId) => {
    return FetchService.executePost(`/api/demo/generate/group/${groupId}/skills`)
}

export const demoRewardsPost = (groupId) => {
    return FetchService.executePost(`/api/demo/generate/group/${groupId}/rewards`)
}

export const demoLessonsPost = (schoolYearId, groupId) => {
    return FetchService.executePost(`/api/demo/generate/group/${groupId}/lessons/${schoolYearId || 0}`)
}

export const pianoAdventuresBooksGet = (groupId) => {
    return FetchService.executeGet(`/api/pianoAdventures/book/group/${groupId}`)
}

export const flashCardsGet = (groupId) => {
    return FetchService.executeGet(`/api/pianoAdventures/flashCard/group/${groupId}`)
}

export const emoticonsGet = () => {
    return FetchService.executeGet('/api/emoticons/')
}

export const videoTutorialsGet = isMobile => {
    return FetchService.executeGet('/api/videos/', { isMobile })
}

export const teacherVideoTutorialsGet = () => {
    return FetchService.executeGet('/api/teacherVideos/')
}

export const sentencesGet = () => {
    return FetchService.executeGet('/api/sentence')
}

export const addSentencePost = ({ text }) => {
    return FetchService.executePost('/api/sentence', { text })
}

export const updateSentencePut = ({ text, id, isGlobal }) => {
    return FetchService.executePut(`/api/sentence/${isGlobal ? 'global' : 'user'}/${id}`, { text })
}

export const deleteSentenceDelete = ({ id, isGlobal }) => {
    return FetchService.executeDelete(`/api/sentence/${isGlobal ? 'global' : 'user'}/${id}`)
}

export const groupsGet = ({ loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate }) => {
    return FetchService.executeGet('/api/group', {
        loadGlobalGroups, loadUsers, loadSchedules, loadSingleStudentGroups, schedulesStartDate
    })
}

export const addGroupPost = ({ assignLevelId, name, picture, isActive }, users, schedules) => {
    return FetchService.executePost('/api/group', { 
        assignLevelId, name, picture, isActive, users, schedules,
        ...GROUP_FETCH_PARAMS
    })
}

export const updateGroupPut = ({ id, assignLevelId, name, picture, isActive }, users, schedules) => {
    return FetchService.executePut(`/api/group/${id}`, { 
        assignLevelId, name, picture, isActive, users, schedules,
        ...GROUP_FETCH_PARAMS
    })
}

export const deleteGroupDelete = (id) => {
    return FetchService.executeDelete(`/api/group/${id}`)
}

export const groupsUsersGet = (groupIds, loadSingleStudentGroups) => {
    return FetchService.executeDelete('/api/group/user', { groupIds, loadSingleStudentGroups })
}

export const groupsSchedulesGet = (groupIds, startDate) => {
    return FetchService.executeDelete('/api/group/schedule', { groupIds, startDate })
}

export const setFcmTokenPost = (fcmToken, isLoggedIn, platform) => {
    return FetchService.executePost('/api/notification/setFcmToken', { fcmToken, isLoggedIn, platform })
}

export const setEnabledNotificationTypesPost = (enabledNotificationTypes, fcmToken) => {
    return FetchService.executePost('/api/notification/setEnabledNotificationTypes', { enabledNotificationTypes, fcmToken })
}

export const setLanguagePost = (languageCode, fcmToken) => {
    return FetchService.executePost('/api/notification/setLanguage', { languageCode, fcmToken })
}

export const addSchoolYearPost = data => {
    return FetchService.executePost('/api/schoolYear', data)
}

export const updateSchoolYearPut = (id, data) => {
    return FetchService.executePut('/api/schoolYear/' + id, data)
}

export const deleteSchoolYearDelete = id => {
    return FetchService.executeDelete(`/api/schoolYear/${id}`)
}

export const schoolYearGet = id => {
    return FetchService.executeGet(`/api/schoolYear/${id}`)
}

export const schoolYearsGet = (teacherId, isActive) => {
    return FetchService.executeGet(`/api/schoolYear/getAll/${teacherId}`, { isActive })
}

export const activateSchoolYearPost = schoolYearId => {
    return FetchService.executePost(`/api/schoolYear/${schoolYearId}/activate`)
}

export const useActivationCodePut = code => {
    return FetchService.executePut('/api/activationCode/activate', { code })
}

export const activationCodeUsedGet = () => {
    return FetchService.executeGet('/api/activationCode/isActivated')
}

export const didReachSubscriptionLimitGet = () => {
    return FetchService.executeGet('/api/subscription/didReachStudentCountLimit')
}

/*
DO NOT USE THESE!!! All user data should be retrieved via group endpoints

export const studentTeacherStudentsGet = (teacherId) => {
    return FetchService.executeGet(`/api/student/teacher/${teacherId}/students`)
}

export const studentTeachersGet = (isInvited) => {
    const parameters = { isActive: true }
    if (isInvited !== undefined) {
        parameters.isInvited = isInvited
    }
    return FetchService.executeGet('/api/student/teacher', parameters)
}

export const teacherStudentsGet = (isInvited) => {
    const parameters = {}
    if (isInvited !== undefined) {
        parameters.isInvited = isInvited
    }
    return FetchService.executeGet('/api/teacher/student', parameters)
}
*/