import React from 'react'

export const GroupsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/teacher-groups',
            component: React.lazy(() => import('./Groups')),
            applicationPath: ['TeacherGroupsList'],
        }
    ]
}

