import FetchService from './FetchService'
import { IDENTITY_SERVICE, ROLE_ID } from 'dpn-common/utils/AppConstants'


export const loginExternalGet = (externalId, generateToken = false) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/login/externalUser', { externalId, generateToken })
}

export const registerTeacherPost = (data) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/register', data)
}

export const registerStudentPost = (data) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/register', data)
}

export const loginFbPost = (data) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/login/facebook', data)
}

export const forgotPasswordGet = (email, username) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/forgot/password', { email, username })
}

export const resetPasswordPost = ({ password, passwordConfirmation, token, userId }) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/forgot/password', { password, passwordConfirmation, token, userId })
}

export const forgotEmailGet = (username) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/forgot/email', { username })
}

export const checkEmailExistsGet = (email) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/user/exists', { email })
}

export const checkUsernameExistsGet = (username, excludeUserId) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/user/exists', { username, excludeUserId })
}

export const changeUserProfilePut = (data) => {
    if (data.password === undefined || data.password === '') {
        data.password = null
    }
    return FetchService[IDENTITY_SERVICE].executePut(`/api/user/${data.id}`, data)
}

export const changeUserPicturePut = (picture, userId) => {
    return FetchService[IDENTITY_SERVICE].executePut(`/api/user/${userId}/picture`, { picture })
}

export const changeUserPictureToPremadeAvatarPut = (url, userId) => {
    return FetchService[IDENTITY_SERVICE].executePut(`/api/user/${userId}/picture`, { avatarUrl: url })
}

export const inviteStudentPost = (firstName, lastName, email, parentFirstName, parentLastName, existingChildUserId) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/connection/invite', {
        firstName, lastName, email, createAccountIfNotExist: true, roleId: ROLE_ID.STUDENT,
        parentFirstName, parentLastName, existingChildUserId
    })
}

export const reinviteStudentGet = (studentId) => {
    return FetchService[IDENTITY_SERVICE].executeGet(`/api/connection/resend/${studentId}`)
}

export const deleteStudentPost = (studentId) => {
    return FetchService[IDENTITY_SERVICE].executeDelete(`/api/connection/${studentId}`)
}

export const acceptStudentInvitationPost = (teacherId, code) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/connection/accept', { senderUserId: teacherId, code })
}

export const userConnectionGet = () => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/connection?includeUsers=true')
}

export const userConnectionForUserIdGet = (userId) => {
    return FetchService[IDENTITY_SERVICE].executeGet(`/api/connection?includeUsers=true&userId=${userId}`)
}

export const userConnectionUpdatePut = (studentId, firstName, lastName) => {
    return FetchService[IDENTITY_SERVICE].executePut(`/api/connection/${studentId}`, { firstName, lastName })
}

export const userConnectionStudentChildrenGet = (email, isActive = true) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/connection/studentChildren', { email, isActive })
}

export const userConnectionStudentAddChildPost = (firstName, lastName, password) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/connection/addChild', { firstName, lastName, password })
}

export const activateStudentPut = (studentId, isActive, { firstName, lastName }) => {
    return FetchService[IDENTITY_SERVICE].executePut(`/api/connection/${studentId}`, { isActive, firstName, lastName })
}

export const languagesGet = () => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/language/')
}

export const languageGet = (languageCode) => {
    return FetchService[IDENTITY_SERVICE].executeGet(`/api/language/${languageCode}`)
}

export const userConnectionLoginTokenGet = (userId) => {
    return FetchService[IDENTITY_SERVICE].executeGet('/api/userConnectionLoginToken', { userId })
}

export const demoStudentsPost = () => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/importUsers/demoStudents')
}

export const sendEmailPost = (params) => {
    return FetchService[IDENTITY_SERVICE].executePost('/api/email/send', { ...params })
}

export const genericTemporaryForJwtTokenPost = apiToken => {
    let customHeaders = undefined
    if (apiToken) {
        customHeaders = { token: apiToken }
    }
    return FetchService[IDENTITY_SERVICE].executePost('/api/genericToken/temporaryForJwt', undefined, undefined, undefined, customHeaders)
}
