const INTERVAL_TIME = 60000
const FETCH_ADDR = 'https://www.google.com'

let intervalHandler
let connectivityStatus

function dispose() {
    intervalHandler && clearInterval(intervalHandler)
}

function subscribe(listener) {
    intervalHandler = setInterval(async () => {
        const isConnected = await isOnline()
        if (isConnected !== connectivityStatus) {
            listener({ type: isConnected ? 'yes' : 'none' })
        }
        connectivityStatus = isConnected
    }, INTERVAL_TIME)
}

async function isOnline() {
    try {
        await fetch(FETCH_ADDR, {
            method: 'GET',
            mode: 'no-cors',
    
        })
        return true
    }
    catch (_ex) {
        return false
    }
}

export default {
    subscribe,
    isOnline,
    dispose,
}
