import { Messages, texti18n } from './MessageConstants'

const moment = require('moment')

export const compareDatesByDay = (date1, date2) => {
    let firstUtc = moment.utc(date1)
    let secondUtc = moment.utc(date2)
    const firstLocal = moment(firstUtc.local())
    const secondLocal = moment(secondUtc.local())
    return firstLocal.isBefore(secondLocal, 'day')
}

export function calculateTimeForConversation(from) {
    from = moment.utc(from)
    const todayLocal = moment().startOf('day')
    const fromLocal = moment(from.local())

    if (isToday(fromLocal, todayLocal)) {
        return fromLocal.format('HH:mm')
    }
    else {
        const yesterdayLocal = moment().subtract(1, 'days')

        if (isYesterday(fromLocal, yesterdayLocal)) {
            return texti18n(Messages.YESTERDAY)
        }
        else if (isBeforeYesterday(fromLocal, yesterdayLocal)) {
            return getDate(fromLocal)
        }
    }
}

export function calculateTimeForChatHistory(from) {
    from = moment.utc(from)
    const todayLocal = moment().startOf('day')
    const fromLocal = moment(from.local())

    if (isToday(fromLocal, todayLocal)) {
        return texti18n(Messages.TODAY)
    }
    else {
        const yesterdayLocal = moment().subtract(1, 'days')

        if (isYesterday(fromLocal, yesterdayLocal)) {
            return texti18n(Messages.YESTERDAY)
        }
        else if (isBeforeYesterday(fromLocal, yesterdayLocal)) {
            return getDate(fromLocal)
        }
    }
}

export function getCurrentDate() {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
}

export function getNthDateFromDateJson(nth, startdate) {
    const momentDate = moment(startdate || new Date(), 'YYYY-MM-DD').add(nth, 'days')
    return momentDate.utc().format('YYYY-MM-DD')
}

export function getJSDateFromJson(json) {
    const momentDate = moment.utc(json, 'YYYY-MM-DD HH:mm:ss')
    return momentDate.toDate()
}

export function getMomentDateFromJson(json) {
    const momentDate = moment.utc(json, 'YYYY-MM-DD HH:mm:ss')
    return momentDate
}

export const getMomentDate = (date) => moment(date)

export function formatDateJson(date, fullDateTime, setEndOfTheDay) {
    let momentDate
    if (typeof date === 'string' || date instanceof String) {
        momentDate = moment(date, texti18n(Messages.CURRENT_DATE_FORMAT))
    }
    else {
        momentDate = moment(date)
    }

    if (setEndOfTheDay) {
        momentDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    }

    const result = momentDate.utc().format(fullDateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')
    return result
}

export function formatTimeJson(value) {
    const momentDate = moment(value, texti18n(Messages.CURRENT_TIME_FORMAT))
    const result = momentDate.utc().format('HH:mm')
    return result
}

export function formatDate(date) {
    const momentDate = moment.utc(date).local()
    const result = momentDate.format(texti18n(Messages.CURRENT_DATE_FORMAT))
    return result
}

export function formatTime(date) {
    const momentDate = moment.utc(date).local()
    const result = momentDate.format(texti18n(Messages.CURRENT_TIME_FORMAT))
    return result
}

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ')
  
    let [hours, minutes] = time.split(':')
  
    if (hours === '12') {
        hours = '00'
    }
  
    if (modifier === 'pm') {
        hours = parseInt(hours, 10) + 12
    }
  
    return `${hours}:${minutes}`
}

export function formatDateTime(dateTime, format = texti18n(Messages.CURRENT_DATE_TIME_FORMAT)) {
    const momentDate = moment.utc(dateTime).local()
    const result = momentDate.format(format)
    return result
}

export const formatTimeLength = seconds => {
    const intSeconds = parseInt(seconds)
    if (intSeconds < 60 * 60) {
        return moment.utc(intSeconds * 1000).format('mm:ss')
    }
    else {
        return moment.utc(intSeconds * 1000).format('HH:mm:ss')
    }
}

export const convertTimeInMinutesToDateTime = timeInMinutes => {
    const hours = Math.floor(timeInMinutes / 60)
    const minutes = timeInMinutes % 60
    return moment().hour(hours).minute(minutes).toDate()
}

export const getTimeInMinutes = timeString => {
    const timeStringJson = moment(timeString, texti18n(Messages.CURRENT_TIME_FORMAT)).format('HH:mm')
    const [hours, minutes] = timeStringJson.split(':').map(x => parseInt(x))
    return hours * 60 + minutes
}

function isToday(fromLocal, todayLocal) {
    return fromLocal.isSame(todayLocal, 'd')
}

function isYesterday(fromLocal, yesterdayLocal) {
    return fromLocal.isSame(yesterdayLocal, 'd')
}

function isBeforeYesterday(fromLocal, yesterdayLocal) {
    return fromLocal.isBefore(yesterdayLocal)
}

const getDate = dateTimeLocal => {
    return dateTimeLocal.format(texti18n(Messages.CURRENT_SHORT_DATE_FORMAT))
}

export const getDayIndexFromDayString = day => {
    switch (day) {
        case Messages.MONDAY:
            return 1
        case Messages.TUESDAY:
            return 2
        case Messages.WEDNESDAY:
            return 3
        case Messages.THURSDAY:
            return 4
        case Messages.FRIDAY:
            return 5
        case Messages.SATURDAY:
            return 6
        case Messages.SUNDAY:
            return 0
    }
}

export const formatSchedules = schedules => (schedules || []).map(x => ({ ...x, dayOfWeek: x.dayOfWeek || undefined, specificDate: x.dayOfWeek ? undefined : x.specificDate }))

export const formatReadOnlyDate = date => getMomentDateFromJson(date).format(texti18n(Messages.CURRENT_READONLY_DATE_FORMAT))

export const sortSchedules = schedules => (schedules || []).sort((a, b) => {
    if (a.dayOfWeek && b.dayOfWeek) {
        if (a.dayOfWeek !== b.dayOfWeek) {
            const aDayIndex = getDayIndexFromDayString(a.dayOfWeek) === 0 ? 7 : getDayIndexFromDayString(a.dayOfWeek)
            const bDayIndex = getDayIndexFromDayString(b.dayOfWeek) === 0 ? 7 : getDayIndexFromDayString(b.dayOfWeek)
            return aDayIndex - bDayIndex
        }
        else {
            if (a.fromTimeInMinutes && !b.fromTimeInMinutes) {
                return 1
            }
            else if (!a.fromTimeInMinutes && b.fromTimeInMinutes) {
                return -1
            }
            return a.fromTimeInMinutes - b.fromTimeInMinutes
        }
    }
    else if (a.dayOfWeek && !b.dayOfWeek) {
        return - 1
    }
    else if (a.specificDate && b.specificDate) {
        const aSpecificDateMoment = getMomentDateFromJson(a.specificDate)
        const bSpecificDateMoment = getMomentDateFromJson(b.specificDate)
        if (!aSpecificDateMoment.isSame(bSpecificDateMoment)) {
            return aSpecificDateMoment.isBefore(bSpecificDateMoment) ? -1 : 1
        }
        else {
            if (a.fromTimeInMinutes && !b.fromTimeInMinutes) {
                return 1
            }
            else if (!a.fromTimeInMinutes && b.fromTimeInMinutes) {
                return -1
            }
            return a.fromTimeInMinutes - b.fromTimeInMinutes
        }
    }
    return 1
})

export function convertMinutesToAmPmString(minutes) {
    const totalHours = Math.floor(minutes / 60);
    const minutesRemaining = minutes % 60;
    const ampm = totalHours >= 12 ? 'pm' : 'am';
    const hours = (totalHours % 12) || 12; // if it is 0 then make it 12 :)
    const minutesString = minutesRemaining < 10 ? '0' + minutesRemaining : minutesRemaining;
    return `${hours}:${minutesString}${ampm}`;
}