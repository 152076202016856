import React from 'react'

export const StudentChildrenConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-profiles',
            component: React.lazy(() => import('./StudentProfiles')),
            applicationPath: ['StudentProfiles'],
        },
        {
            path: '/student-children',
            component: React.lazy(() => import('./StudentChildren')),
            applicationPath: ['StudentChildren'],
        },
        {
            path: '/student-child-info',
            component: React.lazy(() => import('./StudentChildInfo')),
            applicationPath: 'StudentChildInfo',
        }
    ]
}