export const LocalImages = Object.freeze({
    NO_AVATAR_IMAGE: require('../../assets/NoAvatarImage.png'),
    DEFAULT_AVATAR_IMAGE: require('../../assets/DefaultAvatarImage.png'),
    DEFAULT_REWARD_IMAGE: require('../../assets/DefaultRewardImage.png'),
    DEFAULT_SKILL_IMAGE: require('../../assets/DefaultSkillImage.png'),
    PIANO_ADV: require('../../assets/PianoAdventures.png'),
    MY_PA_BUG_ICON: require('../../assets/MyPaBugIcon.png'),
    VIEW_TUTORIALS: require('../../assets/ViewTutorials.png'),
    INTERACTIVE_TOUR: require('../../assets/InteractiveTour.png'),
    SYNTHESIA_ICON: require('../../assets/Synthesia.png'),
    LANDING_TEACHERS: require('../../assets/LandingTeachers.png'),
    LANDING_STUDENTS: require('../../assets/LandingStudents.png'),
    LANDING_PARENTS: require('../../assets/LandingParents.png'),
    APP_APPLE: require('../../assets/AppApple.png'),
    APP_GOOGLE: require('../../assets/AppGoogle.png'),
    SIGNUP_IMAGE: require('../../assets/SignUpImage.png'),
    PLAY_IMAGE: require('../../assets/Play.png'),
    GOOGLE_LOGO: require('../../assets/GoogleLogo.svg'),
    APPLE_LOGO: require('../../assets/AppleLogo.svg'),
    HEADER_IMAGE: require('../../assets/HeaderImage.png'),
    HOME_LOGO: require('../../assets/HomeLogo.png'),
    LANDING_CONNECT: require('../../assets/LandingConnect.png'),
    LANDING_LEARN: require('../../assets/LandingLearn.png'),
    LANDING_EXPLORE: require('../../assets/LandingExplore.png'),
    ICON_CUSTOMIZE: require('../../assets/IconCustomize.svg'),
    ICON_DASHBOARD: require('../../assets/IconDashboard.svg'),
    ICON_GROWTH: require('../../assets/IconGrowth.svg'),
    ICON_LEARNING: require('../../assets/IconLearning.svg'),
    ICON_MESSAGING: require('../../assets/IconMessaging.svg'),
    ICON_SUPPORT: require('../../assets/IconSupport.svg'),
    MYPA_EARLY_ADOPTER_VIDEO_FIRST_FRAME: require('../../assets/MyPAEarlyAdopterProgramFirstFrame.jpg')
})