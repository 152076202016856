import { combineReducers } from 'redux'
import { processUserDependantReducersMap } from 'dpn-common/reducers/reducerUtils'
import navigation from './navigation.reducer'
import settings from './settings.reducer'
import navbar from './navbar.reducer'
import message from './message.reducer'
import dialog from './dialog.reducer'
import routes from './routes.reducer'

const generalFuseReducers = {
    settings,
}

const userDependantFuseReducers = {
    navigation,
    navbar,
    message,
    dialog,
    routes
}

const fuseReducers = combineReducers({ ...(processUserDependantReducersMap(userDependantFuseReducers)), ...generalFuseReducers })

export default fuseReducers
