import React from 'react'

function LeftSideLayout1() {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default LeftSideLayout1
