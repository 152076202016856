import React from 'react'

export const ContactConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/contact',
            component: React.lazy(() => import('./Contact')),
            applicationPath: 'Contact',
        }
    ]
}