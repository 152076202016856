import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'
import { LayoutIcons } from 'utils/LayoutIcons'
import { USER_TYPES } from 'dpn-common/utils/AppConstants'

const navigationConfig = [
    {}
]

const setNavigationConfig = role => {
    switch (role) {
    case USER_TYPES.TEACHER: return teacherNavigationConfig()
    case USER_TYPES.STUDENT: return studentBaseNavigationConfig()
    default: return navigationConfig
    }
}

const studentBaseNavigationConfig = () => [
    // {
    //     'id': 'student-home',
    //     'title': texti18n(Messages.HOME),
    //     'type': 'item',
    //     'icon': LayoutIcons.HOME,
    //     'url': '/student-lessons',
    // },
    {
        'id': 'piano-interactive-hub',
        'title': texti18n(Messages.INTERACTIVE_HUB),
        'type': 'item',
        'icon': LayoutIcons.HOME,
        'url': '/piano-adventures/student-interactive-hub',
    },
    {
        'id': 'navigation',
        'title': texti18n(Messages.MY_PIANO_ADVENTURES),
        'type': 'group',
        'children': [
            {
                'id': 'student-lessons',
                'title': texti18n(Messages.MY_LESSONS),
                'type': 'item',
                'icon': LayoutIcons.LESSONS,
                'url': '/student-lessons',
                'teacherRequired': true,
            },
            {
                'id': 'student-my-assignments',
                'title': texti18n(Messages.MY_ASSIGNMENTS),
                'type': 'item',
                'icon': LayoutIcons.PIANO_ADVENTURES,
                'url': '/my-assignments',
                'teacherRequired': true,
            },
            {
                'id': 'student-dashboard',
                'title': texti18n(Messages.MY_PROFILE),
                'type': 'item',
                'icon': LayoutIcons.MY_PROFILE,
                'url': '/student-profile?componentType=skills',
                'teacherRequired': true,
            },
            {
                'id': 'student-teachers',
                'title': texti18n(Messages.MY_TEACHER),
                'type': 'item',
                'icon': LayoutIcons.TEACHERS,
                'url': '/student-teachers',
            },
        ]
    },
    {
        'id': 'student-piano-adventure',
        'title': texti18n(Messages.PIANO_ADVENTURES),
        'type': 'group',
        'teacherRequired': true,
        'children': [
            {
                'id': 'student-assignments',
                'title': texti18n(Messages.LIBRARY),
                'type': 'item',
                'icon': LayoutIcons.PIANO_ADVENTURES,
                'url': '/piano-adventures/assignments',
            },
            // {
            //     'id': 'student-piano-adventures-courses',
            //     'title': texti18n(Messages.COURSES),
            //     'type': 'item',
            //     'icon': LayoutIcons.COURSES,
            //     'url': '/piano-adventures/courses',
            // },
        ]
    },
    {
        'id': 'student-communication',
        'title': texti18n(Messages.COMMUNICATION),
        'type': 'group',
        'children': [
            {
                'id': 'messages',
                'icon': LayoutIcons.CHATS,
                'title': texti18n(Messages.MESSAGES),
                'type': 'item',
                'url': '/student-communication/chat',
                'teacherRequired': true,
            },
            {
                'id': 'channels',
                'icon': LayoutIcons.CHANNELS,
                'title': texti18n(Messages.CHANNELS),
                'type': 'item',
                'url': '/student-communication/channels',
            },
        ]
    },
    {
        'id': 'more',
        'title': texti18n(Messages.MORE),
        'type': 'group',
        'children': [
            // {
            //     'id': 'student-settings',
            //     'title': texti18n(Messages.SETTINGS),
            //     'type': 'item',
            //     'icon': LayoutIcons.SETTINGS,
            //     'url': '/student-settings/leaderboard'
            // },
            {
                'id': 'help',
                'title': texti18n(Messages.SUPPORT),
                'type': 'item',
                'icon': LayoutIcons.HELP_OUTLINE,
                'url': '/support/student-support'
            },
        ]
    },
]

const teacherNavigationConfig = () => [
    // {
    //     'id': 'teacher-home',
    //     'title': texti18n(Messages.HOME),
    //     'type': 'item',
    //     'icon': LayoutIcons.HOME,
    //     'url': '/students',
    // },
    {
        'id': 'piano-interactive-hub',
        'title': texti18n(Messages.INTERACTIVE_HUB),
        'type': 'item',
        'icon': LayoutIcons.HOME,
        'url': '/piano-adventures/interactive-hub',
    },
    {
        'id': 'students',
        'title': texti18n(Messages.STUDENTS),
        'type': 'group',
        'children': [
            {
                'id': 'students-all-students',
                'title': texti18n(Messages.MY_STUDENTS),
                'type': 'item',
                'icon': LayoutIcons.ALL_STUDENTS,
                'url': '/students',
            },
            {
                'id': 'students-groups',
                'title': texti18n(Messages.GROUPS),
                'type': 'item',
                'icon': LayoutIcons.ALL_GROUPS,
                'url': '/teacher-groups',
            },
        ]
    },
    {
        'id': 'piano-adventures',
        'title': texti18n(Messages.PIANO_ADVENTURES),
        'type': 'group',
        'children': [
            {
                'id': 'piano-adventures-assignments',
                'title': texti18n(Messages.LIBRARY),
                'type': 'item',
                'icon': LayoutIcons.PIANO_ADVENTURES,
                'url': '/piano-adventures/assignments',
            },
            // {
            //     'id': 'piano-adventures-courses',
            //     'title': texti18n(Messages.COURSES),
            //     'type': 'item',
            //     'icon': LayoutIcons.COURSES,
            //     'url': '/piano-adventures/courses',
            // },
        ]
    },
    {
        'id': 'communication',
        'title': texti18n(Messages.COMMUNICATION),
        'type': 'group',
        'children': [
            {
                'id': 'messages',
                'title': texti18n(Messages.MESSAGES),
                'type': 'item',
                'url': '/communication/chat',
                'icon': LayoutIcons.CHATS,
            },
            {
                'id': 'channels',
                'title': texti18n(Messages.CHANNELS),
                'type': 'item',
                'url': '/communication/channels',
                'icon': LayoutIcons.CHANNELS,
            },
        ]
    },
    {
        'id': 'more',
        'title': texti18n(Messages.MORE),
        'type': 'group',
        'children': [
            // {
            //     'id': 'settings',
            //     'title': texti18n(Messages.SETTINGS),
            //     'type': 'item',
            //     'icon': LayoutIcons.SETTINGS,
            //     'url': '/settings/leaderboard'
            // },
            {
                'id': 'help',
                'title': texti18n(Messages.SUPPORT),
                'type': 'item',
                'icon': LayoutIcons.HELP_OUTLINE,
                'url': '/support/teacher-support'
            },
        ]
    },
]

export default navigationConfig

export {
    setNavigationConfig
}