import React from 'react'

export const StudentSupportConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/student-support',
            component: React.lazy(() => import('./StudentSupport')),
            applicationPath: 'StudentSupport'
        },
    ]
}