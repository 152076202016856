import React from 'react'

export const TeacherAssignmentsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/custom-presets/teacher-assignments',
            component: React.lazy(() => import('./TeacherAssignments')),
            applicationPath: 'TeacherAssignments',
        },
        {
            path: '/teacherAssignmentsSort',
            component: React.lazy(() => import('./TeacherAssignmentsSort')),
            applicationPath: 'TeacherAssignmentsSort',
        },
    ]
}
