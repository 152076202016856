import AppConfig from 'dpn-common/config/AppConfig'
import FetchService from 'dpn-common/services/FetchService'
import SharedServices from 'dpn-common/services/SharedServices'
import { IDENTITY_SERVICE } from 'dpn-common/utils/AppConstants'

const getStateFuncGenerator = getStateFunc => () => {
    const state = getStateFunc()
    return {
        userId: state.base.auth.userData.id || 0,
        language: state.settingsAndLanguages.settings.languageCode
    }
}

export const createFetchServices = function (getStateFunc) {
    FetchService.add(
        '', AppConfig.backendUrls.baseEndPointUrl, AppConfig.staticFilesPath.baseEndPointPath,
        getStateFuncGenerator(getStateFunc), SharedServices.FirebaseService.getAccessToken,
    )
    
    FetchService.add(
        IDENTITY_SERVICE, AppConfig.backendUrls.identityService, AppConfig.staticFilesPath.identityPath,
        getStateFuncGenerator(getStateFunc), SharedServices.FirebaseService.getAccessToken,
    )
    
    FetchService[IDENTITY_SERVICE].setGuestEndpoints([
        '/api/login',
        '/api/login/refreshTokenWithToken',
        '/api/register',
        '/api/login/facebook',
        '/api/forgot/password',
        '/api/forgot/email',
        '/api/language/',
        '/api/resetPassword'
    ])
}