import React from 'react'

export const TeacherSchoolYearConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/school-years',
            component: React.lazy(() => import('./TeacherSchoolYear')),
            applicationPath: 'TeacherSchoolYear',
        },
    ]
}