import React, { useEffect, useMemo, useState } from 'react'
import { FuseAnimate } from 'app/@fuse'
import { Typography } from '@material-ui/core'
import NavigatorService from 'services/common/NavigatorService'
import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon'
import { useSelector } from 'react-redux'

// TODO: hard as f hack. find better solution. There are no such thing as 'slots' in react
let onBackFunc = undefined

export const globalSetOnBackFunc = function (onBackFuncParam) {
    onBackFunc = onBackFuncParam
}

const onBackPress = () => {
    if (onBackFunc) {
        return onBackFunc()
    }
    NavigatorService.goBack()
}

function GoBackComponent() {
    const [hidden, setHidden] = useState(true)
    const [currentPath, setCurrentPath] = useState(NavigatorService.getCurrentRoute().path)

    const { navigation } = useSelector(({ fuse }) => ({
        navigation: fuse.navigation
    }))

    const initialPages = useMemo(() => createNavigationMap(navigation), [navigation])

    useEffect(() => {
        setHidden(!initialPages || currentPath in initialPages)
    }, [initialPages, currentPath])

    useEffect(() => {
        const routeChangeListener = NavigatorService.addRouteChangeListener(function (data) {
            setCurrentPath(data.currentLocation.pathname + data.currentLocation.search)
        })
        return () => routeChangeListener.remove()
    }, [])

    if (hidden) {
        return false
    }
    return (
        <div className="flex flex-row justify-center ml-16">
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Typography className="normal-case flex items-center text-14"
                    onClick={onBackPress} role="button" color="inherit">
                    <ArrowLeftCircleIcon color='black' size={24} className="mr-8 text-20"/>
                    {texti18n(Messages.BACK)}
                </Typography>
            </FuseAnimate>
        </div>
    )
}

export default GoBackComponent

function createNavigationMap(navigation) {
    function oneLevel(items, acc) {
        for (let item of items) {
            if (item.url) {
                acc[item.url] = true
            }
            if (item.children && item.children.length) {
                oneLevel(item.children, acc)
            }
        }
        return acc
    }
    return oneLevel(navigation, {})
}