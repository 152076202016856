import React from 'react'

export const StudentMyAssignmentsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/my-assignments',
            component: React.lazy(() => import('./StudentMyAssignments')),
            applicationPath: 'StudentMyAssignments',
        },
    ]
}