import React from 'react'
import { FuseUtils } from '@fuse'
import { LogoutConfig } from 'pages/logout/LogoutConfig'
import { errorsPagesConfigs } from 'pages/errors/ErrorPagesConfigs'
import { HomeConfig } from 'pages/HomeConfig'
import { teacherConfigs } from 'pages/Teacher/TeacherConfigs'
import { studentConfigs } from 'pages/Student/StudentConfigs'
import { guestConfigs } from 'pages/Guest/GuestConfigs'
import { USER_TYPES } from 'dpn-common/utils/AppConstants'
import { Redirect } from 'react-router-dom'
import { anyUserConfig } from 'pages/AnyUser/AnyUserConfig'

const sharedRoutes = [
    HomeConfig,
    LogoutConfig,
    errorsPagesConfigs,
]

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(teacherConfigs, [USER_TYPES.TEACHER]),
    ...FuseUtils.generateRoutesFromConfigs(studentConfigs, [USER_TYPES.STUDENT]),
    ...FuseUtils.generateRoutesFromConfigs(anyUserConfig, [USER_TYPES.STUDENT, USER_TYPES.TEACHER]),
    ...FuseUtils.generateRoutesFromConfigs(guestConfigs, [USER_TYPES.GUEST]),
    ...FuseUtils.generateRoutesFromConfigs(sharedRoutes),
    {
        component: () => <Redirect to="/error-404"/>
    }
]

function getMappings(config) {
    if (Array.isArray(config)) {
        return config.reduce(function (map, x) {
            const mappings = getMappings(x)
            return { ...map, ...mappings }
        }, {})
    }
    else if (config.routes) {
        return config.routes.reduce(function (map, x) {
            if (Array.isArray(x.applicationPath)) {
                x.applicationPath.forEach(function (oneAppPath) {
                    map[oneAppPath.startsWith('_') ? oneAppPath.substring(1) : oneAppPath] = x.path
                })
            }
            else {
                map[x.applicationPath] = x.path
            }
            return map
        }, {})
    }
    else {
        console.log('Should never happened!!! ', config)
    }
}

export const routeMappings = Object.freeze(
    getMappings([sharedRoutes, teacherConfigs, guestConfigs, studentConfigs, anyUserConfig])
)

export const routeReverseMappings = Object.freeze(
    Object.keys(routeMappings).reduce((obj, key) => { 
        const value = routeMappings[key]
        // route with _ at the beginning of the name has top priority
        if (!(value in obj)) {
            obj[value] = key
        }
        else if (key.startsWith('_')) {
            obj[value] = key.substring(1)
        }
        return obj
    }, {})
)

export function getGuestUrls() {
    const urls = []
    guestConfigs.forEach(function (value) {
        value.routes.forEach(function (route) {
            urls.push(route.path)
        })
    })
    return urls
}
