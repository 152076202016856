import { FETCH_OLD_RESPONSE_ARRIVED_STRING_CONST } from 'dpn-common/utils/AppConstants'
import { parametersToQueryString } from 'dpn-common/utils/UrlQueryHelperFunctions'

const DEFAULT_SERVICE_KEY = 'default'

const SHOW_REQUEST_LOGS = false

const fetchService = {
    executeDelete: function (endpoint, urlParameters, signal, customHeaders) { 
        return this[DEFAULT_SERVICE_KEY].executeDelete(endpoint, urlParameters, signal, customHeaders)
    },
    executeGet: function (endpoint, urlParameters, signal, customHeaders) {
        return this[DEFAULT_SERVICE_KEY].executeGet(endpoint, urlParameters, signal, customHeaders)
    },
    executePost: function (endpoint, data, urlParameters, signal, customHeaders) {
        return this[DEFAULT_SERVICE_KEY].executePost(endpoint, data, urlParameters, signal, customHeaders)
    },
    executePut: function (endpoint, data, urlParameters, signal, customHeaders) {
        return this[DEFAULT_SERVICE_KEY].executePut(endpoint, data, urlParameters, signal, customHeaders)
    },
    url: function (resourcePath, defaultResource = '') {
        return this[DEFAULT_SERVICE_KEY].url(resourcePath, defaultResource)
    },
    staticUrl: function (resourcePath, defaultAsset = '') {
        return this[DEFAULT_SERVICE_KEY].staticUrl(resourcePath, defaultAsset)
    },
    getFullUrl: function (route, urlParameters) {
        return this[DEFAULT_SERVICE_KEY].getFullUrl(route, urlParameters)
    },
    imageUrl: function (resourcePath, defaultAsset) {
        return this[DEFAULT_SERVICE_KEY].imageUrl(resourcePath, defaultAsset)
    },
    setCustomHeaders: function (customHeaders) {
        return this[DEFAULT_SERVICE_KEY].setCustomHeaders(customHeaders)
    },
    setGuestEndpoints: function (guestEndpoints) {
        return this[DEFAULT_SERVICE_KEY].setGuestEndpoints(guestEndpoints)
    },
    add: function (name, baseEndPointUrl, baseStaticFilesUrl, getStateFunc, getAccessTokenFunc) {
        const ns = createFetchWrapperService(baseEndPointUrl, baseStaticFilesUrl, getStateFunc, getAccessTokenFunc, SHOW_REQUEST_LOGS)
        this[name || DEFAULT_SERVICE_KEY] = ns
        return this
    },
}

export default fetchService

/**
 * Create fetch wrapper service
 * @param {String} baseEndPointUrl base for endpoints
 * @param {String} baseStaticFilesUrl base for static files
 * @param {Function} getStateFunc function should return { userId, token, language }. UserId should be 0 for guest users
 * @param {Function} getAccessTokenFunc function should return string accessToken
 * @param {Boolean} SHOW_REQUEST_LOGS log some info or not - by default not
*/
function createFetchWrapperService(baseEndPointUrl, baseStaticFilesUrl, getStateFunc, getAccessTokenFunc, SHOW_REQUEST_LOGS) {
    let globalCustomHeaders = undefined
    let globalGuestEndpoints = undefined

    /**
     * Get url for api call
     * @param {string} route route
     * @param {Object} urlParameters key->value map of query parameters
     * @return {string} full url to api
     */
    function getFullUrl(route, urlParameters) {
        return `${baseEndPointUrl}${route}${parametersToQueryString(urlParameters)}`
    }

    /**
     * Set custom header for each request. Caution, when using this!
     * @param {Object} customHeaders key->value map of header values
     */
    function setCustomHeaders(customHeaders) {
        globalCustomHeaders = customHeaders
    }

    /**
     * Set global guest endpoints. Those endpoints do not depend on api key from redux
     * @param {Array} guestEndpoints array of endpoints which are available for guests
     */
    function setGuestEndpoints(guestEndpoints) {
        globalGuestEndpoints = guestEndpoints
    }

    /**
     * Absolute url to resource file like image etc
     * @param {string} resourcePath relative path to resource
     * @param {string} defaultResource default resource if resourcePath is null or not defined
     * @return {string} resourcePath url
     */
    function url(resourcePath, defaultResource = '') {
        if (resourcePath && resourcePath.length) {
            if (resourcePath.startsWith('http') || resourcePath.startsWith('data:')) {
                return resourcePath
            }
    
            const path = resourcePath[0] !== '/' ? '/' + resourcePath : resourcePath
            return getFullUrl(path)
        }
        return defaultResource
    }
    
    /**
     * Creates object for source parameter of react native image component
     * @deprecated use staticUrl or url
     * @param {string} resourcePath relative path to resource
     * @param {string} defaultResource default value if resourcePath is not set
     */
    function imageUrl(resourcePath, defaultAsset) {
        if (!resourcePath || !resourcePath.length) {
            return defaultAsset
        }
        const uri = staticUrl(resourcePath)
        return { uri } 
    }

    /**
     * Creates path to static file
     * @param {string} resourcePath relative path to resource
     * @param {string} defaultResource default value if resourcePath is not set
     */
    function staticUrl(resourcePath, defaultAsset) {
        if (!resourcePath || !resourcePath.length) {
            return defaultAsset
        }
        else if (resourcePath.startsWith('http') || resourcePath.startsWith('data:')) {
            return resourcePath
        }
        const pathFix = resourcePath[0] !== '/' ? '/' : ''
        const uri = `${baseStaticFilesUrl}${pathFix}${resourcePath}`
        return uri
    }
    
    /**
     * Execute fetch post. Adds token if user is logged in
     * @param {string} endpoint 
     * @param {Object} data what should we post in body. This object will be stringified 
     * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
     * @param {signal} @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
     * @param {customHeaders} custom headers for this request eq { token: 'xyz' }
     * @return {Promise<Response>}
     */
    async function executePost(endpoint, data, urlParameters, signal, customHeaders) {
        const url = getFullUrl(endpoint, urlParameters)
        const body = data ? JSON.stringify(data) : null
        SHOW_REQUEST_LOGS && console.log('post: ' + url)
        SHOW_REQUEST_LOGS && console.log('body: ', body)
        return await customFetch(url, {
            method: 'POST',
            headers: await getBaseHeaders(endpoint, customHeaders || globalCustomHeaders),
            body,
            signal,
        })
    }
    
    /**
     * Execute fetch get. Adds token if user is logged in
     * @param {string} endpoint 
     * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
     * @param {signal} @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
     * @param {customHeaders} custom headers for this request eq { token: 'xyz' }
     * @return {Promise<Response>}
     */
    async function executeGet(endpoint, urlParameters, signal, customHeaders) {
        const url = getFullUrl(endpoint, urlParameters)
        SHOW_REQUEST_LOGS && console.log('get: ' + url)
        return await customFetch(url, {
            method: 'GET',
            headers: await getBaseHeaders(endpoint, customHeaders || globalCustomHeaders),
            signal,
        })
    }
    
    /**
     * Execute fetch delete. Adds token if user is logged in
     * @param {string} endpoint 
     * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
     * @param {signal} @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
     * @param {customHeaders} custom headers for this request eq { token: 'xyz' }
     * @return {Promise<Response>}
     */
    async function executeDelete(endpoint, urlParameters, signal, customHeaders) {
        const url = getFullUrl(endpoint, urlParameters)
        SHOW_REQUEST_LOGS && console.log('delete: ' + url)
        return await customFetch(url, {
            method: 'DELETE',
            headers: await getBaseHeaders(endpoint, customHeaders || globalCustomHeaders),
            signal,
        })
    }
    
    /**
     * Execute fetch put. Adds token if user is logged in
     * @param {string} endpoint 
     * @param {Object} data what should we put in body. This object will be stringified 
     * @param {Array} urlParameters format like [{key1: 'hello hello}, {key2: 'whats up'}]
     * @param {signal} @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/signal
     * @param {customHeaders} custom headers for this request eq { token: 'xyz' }
     * @return {Promise<Response>}
     */
    async function executePut(endpoint, data, urlParameters, signal, customHeaders) {
        const url = getFullUrl(endpoint, urlParameters)
        const body = data ? JSON.stringify(data) : null
        SHOW_REQUEST_LOGS && console.log('put: ' + url)
        SHOW_REQUEST_LOGS && console.log('body: ', body)
        return await customFetch(url, {
            method: 'PUT',
            headers: await getBaseHeaders(endpoint, customHeaders || globalCustomHeaders),
            body,
            signal,
        })
    }

    async function getBaseHeaders(endpoint, customHeaders) {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }

        const accessToken = await getAccessTokenFunc()
        const state = getStateFunc()
        // TODO: allow wildcard matching not just full string for guestEndpoints
        if (!!accessToken && (!globalGuestEndpoints || !globalGuestEndpoints.includes(endpoint))) {
            headers['Authorization'] = 'Bearer ' + accessToken
        }
        if (state.language) {
            headers['Accept-Language'] = state.language
        }
        if (customHeaders) {
            Object.keys(customHeaders).forEach(x => {
                if (x.toLowerCase() === 'token') {
                    if (customHeaders[x]) {
                        headers['Authorization'] = 'Bearer ' + customHeaders[x]
                    }
                    else {
                        delete headers['Authorization']
                    }
                }
                else {
                    headers[x] = customHeaders[x]
                }
            })
        }
        return headers
    }

    async function customFetch(url, fetchOptions) {
        const userId1 = getStateFunc().userId
        const response = await fetch(url, fetchOptions)
        const userId2 = getStateFunc().userId

        // another user uses app between request and response -> must return error
        if (userId1 !== userId2) {
            console.log(`Fetch call sent by user ${userId1} but received for ${userId2}: ${url}`)
            throw new Error(FETCH_OLD_RESPONSE_ARRIVED_STRING_CONST)
        }

        return response
    }

    return {
        executeDelete,
        executeGet,
        executePost,
        executePut,
        setCustomHeaders,
        setGuestEndpoints,
        url,
        staticUrl,
        getFullUrl,
        imageUrl,
    }
}