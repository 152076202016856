import fuse from './fuse'
import quickPanel from 'app/fuse-layouts/shared-components/quickPanel/store/reducers'
import { getAppReducer } from 'dpn-common/reducers/AppReducers'


const createReducer = (asyncReducers) => {
    const webAppSpecificReducers = {
        fuse,
        quickPanel,
        ...asyncReducers
    }

    return getAppReducer(webAppSpecificReducers)
}

export default createReducer
