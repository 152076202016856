import { getTemporaryForJwtToken } from 'dpn-common/actions/Actions'
import AppConfig from 'dpn-common/config/AppConfig'
import { openURL, showError } from 'dpn-common/services/SharedServices'
import { Messages, texti18n } from './MessageConstants'
import { isImageLink } from './VideoUtils/VideoLinkUtils'
import { isVimeoLink, isYouTubeLink } from './VideoUtils/VideoPlayerHelper'

const externalAppUrls = Object.freeze({
    PA_FLASH_CARDS: 'paflashcards://',
    PA_PLAYER: 'paplayer://',
    ISM: 'ismapp://',
    PA_ADULT: AppConfig.paAdultBaseUrl,
})

const externalAppUrlsArray = Object.freeze(Object.values(externalAppUrls))

export const LinksHelper = {
    ExternalAppUrls: externalAppUrls,
    getLinkRegex: () => /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.){1}[-a-zA-Z0-9@:%_\\+.~#?*&//=]+/gi,
    getExternalAppLinkRegex: () => new RegExp(`(${externalAppUrlsArray.map(x => `${x}`).join('|')})[-a-zA-Z0-9@:%_\\+.~#?*&//=]*`, 'gi'),
    toHttp: link => !!link && link.toLowerCase().startsWith('www') ? `http://${link}` : link,
    getLinkTitle: link => link.startsWith(externalAppUrls.PA_PLAYER) ? Messages.PA_PLAYER : link.startsWith(externalAppUrls.PA_FLASH_CARDS) ? Messages.PA_FLASH_CARDS : Messages.OPEN_PA_PLUS,
    toIsmLink: function (url) {
        const args = url.replace(externalAppUrls.ISM, '')
        const [, compositionId] = args.split('=')
        return `${AppConfig.ismUrl}/${compositionId}/mode`
    },
    generateFullUrl: async (url, userId) => {
        const responseData = await getTemporaryForJwtToken()
        return `${url}${url.includes('?') ? '&' : '?'}mypaUserId=${userId}&mypaCode=${responseData.value}`
    },
    openLinkAction: function (url, userId) {
        return () => {
            if (userId) {
                getTemporaryForJwtToken().then(function (responseData) {
                    const fullUrl = `${url}${url.includes('?') ? '&' : '?'}mypaUserId=${userId}&mypaCode=${responseData.value}`
                    openURL(fullUrl, () => showError(texti18n(Messages.COULD_NOT_OPEN_THE_LINK)))
                })
            }
            else {
                openURL(url, () => showError(texti18n(Messages.COULD_NOT_OPEN_THE_LINK)))
            }
        }
    },
    prepareLinks: function (links) {
        const ismLinks = links.filter(x => x.includes(externalAppUrls.ISM))
        const externalAppLinkRegex = this.getExternalAppLinkRegex()
        const otherLinks = links.filter(x => !!x && !ismLinks.includes(x)).map(x => {
            const isExternalAppLink = x.match(externalAppLinkRegex) || false
            const isVideoLink = !isExternalAppLink && (isYouTubeLink(x) || isVimeoLink(x))
            return {
                isExternalAppLink,
                isVideoLink,
                isImageLink: !isVideoLink && !isExternalAppLink && isImageLink(x),
                url: x,
            } 
        })
        
        otherLinks.sort(function (a, b) {
            const valueA = a.isVideoLink || a.isExternalAppLink ? 1 : a.isImageLink ? 2 : 4
            const valueB = b.isVideoLink || b.isExternalAppLink ? 1 : b.isImageLink ? 2 : 4
            return valueA - valueB
        })
        return {
            ismLinks: AppConfig.ismEnabled ? ismLinks : [],
            otherLinks,
        }
    }
}
