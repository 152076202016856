import ActionTypes from '../actions/ActionTypes'
import { 
    replaceOldItem, updateOldItem, setDictionaryState,
    addItemToItemsInDictionary, changeItemInItemsInDictionary,
    deleteItem
} from '../utils/HelperFunctions'


const initialState = {
    skills: undefined,
    rewards: undefined,
    importStarted: false
}

function teacherReducer(state = initialState, action) {
    switch (action.type) {

    case ActionTypes.TEACHER_SET_SKILLS_ACTION: {
        const groupedSkills = groupByGroupId(action.skills || [], action.groupIds)
        let tempState = state
        Object.keys(groupedSkills).forEach(groupId => {
            tempState = setDictionaryState(tempState, 'skills', groupId, groupedSkills[groupId])
        })
        return tempState
    }
    case ActionTypes.TEACHER_ADD_SKILL_ACTION:
        return addItemToItemsInDictionary(state, 'skills', 
            action.skill.groupId, action.skill, skillCompareFunc)
    case ActionTypes.TEACHER_SKILL_CHANGE_IS_ACTIVE_ACTION:
        return changeItemInItemsInDictionary(state, 'skills', action.groupId, 
            updateItemCreate(action.id, x => x.isActive = action.isActive, skillCompareFunc))
    case ActionTypes.TEACHER_UPDATE_SKILL_ACTION:
        return changeItemInItemsInDictionary(state, 'skills', action.skill.groupId, 
            replaceOldItemCreate(action.skill, skillCompareFunc))
    case ActionTypes.TEACHER_DELETE_SKILL_ACTION:
        return changeItemInItemsInDictionary(state, 'skills', action.skill.groupId,
            deleteItemCreate(action.skill.id))

    case ActionTypes.TEACHER_ADD_OR_UPDATE_SKILLS_ACTION: {
        let tempState = state
        const newSkillsGrouped = groupByGroupId(action.skills || [], action.groupIds)
        Object.keys(newSkillsGrouped).forEach(groupId => {
            const groupSkills = state.skills && state.skills[groupId]
            if (!groupSkills) {
                // if there were no items for that groupId
                tempState = setDictionaryState(tempState, 'skills', groupId, newSkillsGrouped[groupId])
            }
            else {
                const newGroupSkills = newSkillsGrouped[groupId]
                newGroupSkills.forEach(newSkill => {
                    // go through all new items and see if we should add or update
                    if (groupSkills.find(x => x.id === newSkill.id)) {
                        // updating
                        tempState = changeItemInItemsInDictionary(tempState, 'skills', groupId, 
                            replaceOldItemCreate(newSkill, skillCompareFunc))
                    }
                    else {
                        // adding
                        tempState = addItemToItemsInDictionary(tempState, 'skills', 
                            groupId, newSkill, skillCompareFunc)
                    }
                })
            }
        })
        return tempState
    }

    case ActionTypes.TEACHER_SET_REWARDS_ACTION: {
        const groupedRewards = groupByGroupId(action.rewards || [], action.groupIds)
        let tempState = state
        Object.keys(groupedRewards).forEach(groupId => {
            tempState = setDictionaryState(tempState, 'rewards', groupId, groupedRewards[groupId])
        })
        return tempState
    }
    case ActionTypes.TEACHER_ADD_REWARD_ACTION:
        return addItemToItemsInDictionary(state, 'rewards', 
            action.reward.groupId, action.reward, rewardCompareFunc)
    case ActionTypes.TEACHER_REWARD_CHANGE_IS_ACTIVE_ACTION:
        return changeItemInItemsInDictionary(state, 'rewards', action.groupId, 
            updateItemCreate(action.id, x => x.isActive = action.isActive, rewardCompareFunc))
    case ActionTypes.TEACHER_UPDATE_REWARD_ACTION:
        return changeItemInItemsInDictionary(state, 'rewards', action.reward.groupId, 
            replaceOldItemCreate(action.reward, rewardCompareFunc))
    case ActionTypes.TEACHER_DELETE_REWARD_ACTION:
        return changeItemInItemsInDictionary(state, 'rewards', action.reward.groupId,
            deleteItemCreate(action.reward.id))

    case ActionTypes.TEACHER_ADD_OR_UPDATE_REWARDS_ACTION: {
        let tempState = state
        const newRewardsGrouped = groupByGroupId(action.rewards || [], action.groupIds)
        Object.keys(newRewardsGrouped).forEach(groupId => {
            const groupRewards = state.rewards && state.rewards[groupId]
            if (!groupRewards) {
                // if there were no items for that groupId
                tempState = setDictionaryState(tempState, 'rewards', groupId, newRewardsGrouped[groupId])
            }
            else {
                const newGroupRewards = newRewardsGrouped[groupId]
                newGroupRewards.forEach(newReward => {
                    // go through all new items and see if we should add or update
                    if (groupRewards.find(x => x.id === newReward.id)) {
                        // updating
                        tempState = changeItemInItemsInDictionary(tempState, 'rewards', groupId, 
                            replaceOldItemCreate(newReward, rewardCompareFunc))
                    }
                    else {
                        // adding
                        tempState = addItemToItemsInDictionary(tempState, 'rewards', 
                            groupId, newReward, rewardCompareFunc)
                    }
                })
            }
        })
        return tempState
    }
    case ActionTypes.IMPORT_DEMO_STUDENTS: {
        return {
            ...state,
            importStarted: action.isStarted
        }
    }
    
    default:
        return state
    }
}

export default teacherReducer

function groupByGroupId(items, groupIds) {
    const itemsReduceFunc = (acc, cv) => {
        if (!acc[cv.groupId]) {
            acc[cv.groupId] = []
        }
        acc[cv.groupId].push(cv)
        return acc
    }
    const itemsMap = items.reduce(itemsReduceFunc, {})
    return groupIds && groupIds.length > 0
        ?
        groupIds.reduce((acc, cv) => {
            acc[cv] = itemsMap[cv] || []
            return acc
        }, {})
        :
        itemsMap
}

const getItemName = x => (x && (x.name || (x.item && x.item.name))) || ''

export function skillCompareFunc(skill) {
    return x => getItemName(x).toLowerCase() > getItemName(skill).toLowerCase()
}

export function rewardCompareFunc(reward) {
    return x => getItemName(x).toLowerCase() > getItemName(reward).toLowerCase()
}

function replaceOldItemCreate(item, compareFunc) {
    return items => replaceOldItem(items, item, compareFunc)
}

function updateItemCreate(id, updateFunc, compareFunc) {
    const changeFunc = items => updateOldItem(items, id, updateFunc, compareFunc)
    return changeFunc
}

function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}