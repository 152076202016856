import React from 'react'

export const StudentPianoAdventuresConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/piano-adventures/student-interactive-hub',
            component: React.lazy(() => import('./PianoAdventures')),
            applicationPath: ['_StudentHome', 'StudentPianoAdventures'],
        },
    ]
}
