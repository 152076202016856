import { loadAssignmentsFetch } from 'dpn-common/actions/AssignmentActions'
import { changeImportDemoStudents } from 'dpn-common/actions/DemoActions'
import { loadGroupsDataForStudentFetchNoDispatch, loadGroupsDataForTeacherFetchNoDispatch, setGroupsData } from 'dpn-common/actions/GroupActions'
import { chooseTeacher } from 'dpn-common/actions/StudentActions'
import { studentSelectedTeacherDataSelector } from 'dpn-common/reselects/StudentGroupsDataReselects'
import { NavigatorService, showSuccess } from 'dpn-common/services/SharedServices'
import { GROUP_FETCH_PARAMS, StudentChooseTeacherRouteName, USER_TYPES } from './AppConstants'
import { getTeacherGlobalGroups } from './GroupsDataUtils'
import { Messages, texti18n } from './MessageConstants'
import { getUser } from './UserHelper'

const INVITE_INTERVAL_TIMEOUT = 5000

const DEMO_IMPORT_INTERVAL_TIMEOUT = 10000

const ACCEPT_INVITE_INTERVAL_TIMEOUT = 2500

const PostponeActionHelper = {
    intervalHandlers: [],
    addIntervalHandler: function (callback, time) {
        const handler = setInterval(callback, time)
        this.intervalHandlers.push(handler)
        return () => {
            clearInterval(handler)
            this.intervalHandlers = this.intervalHandlers.filter(x => x != handler)
        }
    },
    onLogout: function() {
        this.intervalHandlers.forEach(element => clearInterval(element))
        this.intervalHandlers = []
    },
    teacherInviteStudent: function (userId, userData, dispatch) {
        const removeFunc = this.addIntervalHandler(async function () {
            const responseData = await loadGroupsDataForTeacherFetchNoDispatch(GROUP_FETCH_PARAMS, userData)
            if (responseData.success) {
                const isLoaded = responseData.users.find(x => x.id === userId)
                if (isLoaded) {
                    dispatch(setGroupsData(responseData))
                    showSuccess(texti18n(Messages.STUDENT_HAS_BEEN_INVITED))
                    removeFunc()
                }
            }
        }, INVITE_INTERVAL_TIMEOUT)
    },
    demoImport: function (userData, dispatch) {
        dispatch(changeImportDemoStudents(true))
        const removeFunc = this.addIntervalHandler(async function () {
            const responseData = await loadGroupsDataForTeacherFetchNoDispatch(GROUP_FETCH_PARAMS, userData)
            if (responseData.success) {
                const isLoaded = responseData.users.length > 1
                if (isLoaded) {
                    dispatch(setGroupsData(responseData))
                    dispatch(changeImportDemoStudents(false))
                    removeFunc()
                }
            }
        }, DEMO_IMPORT_INTERVAL_TIMEOUT)
    },
    acceptInvite: function (studentId, teacherId, dispatch) {
        const removeFunc = this.addIntervalHandler(async function () {
            const responseData = await loadGroupsDataForStudentFetchNoDispatch(GROUP_FETCH_PARAMS, teacherId)
            const isLoaded = responseData.users.find(x => x.id === studentId)
            if (isLoaded) {
                dispatch(setGroupsData(responseData))
                const teacher = studentSelectedTeacherDataSelector({
                    studentId: studentId,
                    group: responseData,
                })
                await chooseTeacher(teacher, studentId, dispatch)
                const teacherGlobalGroupsIds = [...getTeacherGlobalGroups(responseData.userGroups, teacher.id).map(x => x.id)]
                await loadAssignmentsFetch(teacherGlobalGroupsIds[0], true, USER_TYPES.STUDENT, dispatch)
                removeFunc()
                // jump out from student choose teacher screen if needed
                const currentRoute = NavigatorService.getCurrentRoute()
                if (currentRoute && currentRoute.name === StudentChooseTeacherRouteName) {
                    const route = getUser(USER_TYPES.STUDENT).getHomeRoute({ hasTeacher: true })
                    NavigatorService.navigate(route)
                }
            }
        }, ACCEPT_INVITE_INTERVAL_TIMEOUT)
    }
}

export default PostponeActionHelper