import AmplitudeService from './AmplitudeService'
import NavigatorService from './common/NavigatorService'
import GAService from './GAService'
import HotjarService from './HotjarService'

function init() {
    // hotjar
    HotjarService.init()
    // google analytics
    GAService.init()
    // amplitude analytics
    AmplitudeService.init()

    NavigatorService.addRouteChangeListener(stateChange)
}

function setUser(user) {
    HotjarService.setUser(user)
    GAService.setUser(user)
    AmplitudeService.setUser(user)
}

function sendEvent(event) {
    HotjarService.sendEvent(event)
    GAService.sendEvent(event)
    AmplitudeService.sendEvent(event)
}

function stateChange(data) {
    const newState = data.currentLocation.pathname

    HotjarService.stateChange(newState)
    GAService.stateChange(newState)
    AmplitudeService.stateChange(newState)
}

function sendEventAmplitudeOnly(event, properties) {
    AmplitudeService.sendEvent(event, properties)
}

export default {
    init,
    setUser,
    sendEvent,
    sendEventAmplitudeOnly
}