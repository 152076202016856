import { StudentProfileConfig } from './profile/StudentProfileConfig'
import { StudentChooseTeacherConfig } from './chooseTeacher/StudentChooseTeacherConfig'
import { StudentCommunicationConfig } from './chat/StudentCommunicationConfig'
import { StudentLeaderboardConfig } from './settings/StudentLeaderboardConfig'
import { StudentNotificationsConfig } from './settings/StudentNotificationsConfig'
import { StudentVideoTutorialsConfig } from './videoTutorials/StudentVideoTutorialsConfig'
import { StudentTeacherLessonConfigConfig } from './lessons/StudentTeacherLessonsConfig'
import { StudentMyAssignmentsConfig } from './assignments/StudentMyAssignmentsConfig'
import { StudentPianoAdventuresConfig } from './pianoAdventures/StudentPianoAdventuresConfig'
import { StudentChildrenConfig } from './children/StudentChildrenConfig'
import { StudentSupportConfig } from './support/StudentSupportConfig'
import { StudentLeaderboardNotificationSettingsConfig } from './settings/StudentLeaderboardNotificationSettingsConfig'

export const studentConfigs = [
    StudentProfileConfig,
    StudentChildrenConfig,
    StudentChooseTeacherConfig,
    StudentCommunicationConfig,
    StudentLeaderboardConfig,
    StudentNotificationsConfig,
    StudentVideoTutorialsConfig,
    StudentTeacherLessonConfigConfig,
    StudentMyAssignmentsConfig,
    StudentPianoAdventuresConfig,
    StudentSupportConfig,
    StudentLeaderboardNotificationSettingsConfig,
]