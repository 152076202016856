import React from 'react'

export const TeacherRewardsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/custom-presets/teacher-rewards',
            component: React.lazy(() => import('./TeacherRewards')),
            applicationPath: 'TeacherRewardList'
        }
    ]
}