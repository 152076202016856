import ActionTypes from '../actions/ActionTypes'

const initialState = {
    emoticons: undefined,
}

function emoticonReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_EMOTICONS_ACTION:
        return Object.assign({}, state, {
            emoticons: action.emoticons,
        })
    default:
        return state
    }
}

export default emoticonReducer
