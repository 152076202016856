import React from 'react'

export const TeacherPianoAdventuresConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/piano-adventures/interactive-hub',
            component: React.lazy(() => import('./PianoAdventures')),
            applicationPath: ['_TeacherHome', 'TeacherPianoAdventures'],
        },
        {
            path: '/piano-adventures/home',
            component: React.lazy(() => import('./PianoAdventuresHome')),
            applicationPath: 'TeacherPianoAdventuresHome',
        },
    ]
}
