import React from 'react'

export const TeacherGroupInfoConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/teacherGroupInfo',
            component: React.lazy(() => import('./TeacherGroupInfo')),
            applicationPath: 'TeacherGroupInfo',
        }
    ]
}