import React from 'react'

export const AnyUserAssignmentsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/assignment-info',
            component: React.lazy(() => import('./AssignmentInfo')),
            applicationPath: 'AssignmentInfo',
        },
        {
            path: '/piano-adventures/assignments',
            component: React.lazy(() => import('./PianoAdventuresAssignments')),
            applicationPath: ['TeacherPianoAdventuresAssignments', 'StudentPianoAdventuresAssignments', 'PianoAdventuresAssignments']
        },
        {
            path: '/piano-adventures/courses',
            component: React.lazy(() => import('./PianoAdventuresInteractiveGame')),
            applicationPath: ['TeacherPianoAdventuresInteractiveGame', 'StudentPianoAdventuresInteractiveGame', 'PianoAdventuresInteractiveGame']
        },
    ]
}