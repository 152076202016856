import {
    demoSkillsPost,
    demoRewardsPost, demoLessonsPost, 
} from '../services/EndpointsService'
import { setLessons } from './LessonActions'
import { tryCatchJson } from '../utils/FetchUtils'
import { setSkills } from './SkillAction'
import { setRewards } from './RewardActions'
import { demoStudentsPost } from 'dpn-common/services/IdentityEndpointsService'
import ActionTypes from './ActionTypes'

export async function loadDemoStudentsFetch() {
    const fetchPromise = demoStudentsPost()
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData
}

export async function loadDemoSkillsFetch(groupId, dispatch) {
    const fetchPromise = demoSkillsPost(groupId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setSkills(responseData.items, [groupId]))
    return responseData
}

export async function loadDemoRewardsFetch(groupId, dispatch) {
    const fetchPromise = demoRewardsPost(groupId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && dispatch(setRewards(responseData.items, [groupId]))
    return responseData
}

export async function loadDemoLessonsFetch(schoolYearId, groupId, dispatch) {
    const fetchPromise = demoLessonsPost(schoolYearId, groupId)
    const responseData = await tryCatchJson(fetchPromise, true)
    responseData.success && 
        dispatch(setLessons(schoolYearId, responseData.items, [groupId])) 
    return responseData
}

export function changeImportDemoStudents(isStarted) {
    return { type: ActionTypes.IMPORT_DEMO_STUDENTS, isStarted }
}