import React from 'react'

export const TeacherSupportConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/support/teacher-support',
            component: React.lazy(() => import('./TeacherSupport')),
            applicationPath: 'TeacherSupport'
        },
    ]
}