import React from 'react'
import { Icon } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { LocalImages } from 'utils/LocalImages'
import TrophyIconSvg from 'icons/TrophyIcon'
import MedalIconSvg from 'icons/MedalIcon'
import AwardIconSvg from 'icons/AwardIcon'
import MusicIconSvg from 'icons/MusicIcon'
import InfoIconSvg from 'icons/InfoIcon'
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon'
import ImportIconSvg from 'icons/ImportIcon'
import ExportIconSvg from 'icons/ExportIcon'

export const LessonIcon = props => {
    return (
        <MusicIconSvg {...props}/>
    )
}

export const LeaderboardIcon = props => {
    return (
        <TrophyIconSvg {...props}/>
    )
}

export const InfoIcon = props => {
    return (
        <InfoIconSvg {...props} />
    )
}

export const SkillsIcon = props => {
    return (
        <MedalIconSvg {...props} />
    )
}

export const RewardsIcon = props => {
    return (
        <AwardIconSvg {...props} />
    )
}

export const RevealPasswordIcon = props => (
    <VisibilityIcon
        {...props}
    />
)

export const HidePasswordIcon = props => (
    <VisibilityOffIcon
        {...props}
    />
)

export const ArrowLeftCircle = props => (
    <ArrowLeftCircleIcon
        {...props} />
)

export const DownloadIcon = props => (
    <ExportIconSvg {...props} />
)

export const UploadIcon = props => (
    <ImportIconSvg {...props} />
)

export const EmailIcon = props => (
    <Icon {...props}>email</Icon>
)

export const PlusCircleIcon = (props) => (
    <Icon {...props}>add</Icon>
)

export const PlusButtonIcon = (props) => (
    <Icon {...props}>add_circle</Icon>
)

export const CloseIcon = (props) => (
    <Icon {...props}>close</Icon>
)

export const ReloadIcon = (props) => (
    <Icon {...props}>refresh</Icon>
)

export const ImportIcon = (props) => (
    <Icon {...props}>import_export</Icon>
)

export const EditIcon = (props) => (
    <Icon {...props}>edit</Icon>
)

export const SearchIcon = ({ className = 'mr-8', color = 'action' }) => {
    return <Icon className={className} color={color}>search</Icon>
}

export const AssignmentIcon = ({ className = 'mr-8', color = 'action', style }) => {
    return <Icon className={className} color={color} style={style}>assignment</Icon>
}

export const AssignmentFolderIcon = ({ className = 'mr-8', color = 'action' }) => {
    return <Icon className={className} color={color}>assignment</Icon>
}

export const ImportAssignmentIcon = ({ className = 'mr-8', color = 'action' }) => {
    return <Icon className={className} color={color}>assignment</Icon>
}

export const ExportAssignmentIcon = ({ className = 'mr-8', color = 'action' }) => {
    return <Icon className={className} color={color}>assignment</Icon>
}

export const DeleteIcon = (props) => (
    <Icon {...props}>delete</Icon>
)

export const ActivateIcon = (props) => (
    <Icon {...props}>unarchive</Icon>
)

export const ArchiveIcon = (props) => (
    <Icon {...props}>archive</Icon>
)

export const RemoveIcon = (props) => (
    <Icon {...props}>remove_circle</Icon>
)

export const BasicRemoveIcon = (props) => (
    <Icon {...props}>remove</Icon>
)

export const UnpublishIcon = (props) => (
    <Icon {...props}>check_circle_outline</Icon>
)

export const PublishIcon = (props) => (
    <Icon {...props}>error_outline</Icon>
)

export const CloneIcon = (props) => <Icon {...props}>file_copy</Icon>

export const RightIcon = props => <Icon {...props}>chevron_right</Icon>

export const CalendarIcon = props => <Icon {...props}>calendar_today</Icon>

export const HamburgerMenuIcon = props => <Icon {...props}>menu</Icon>

export const ChatIcon = props => <Icon {...props}>chat</Icon>

export const InsertEmoticonIcon = props => <Icon {...props}>insert_emoticon</Icon>

export const SendIcon = props => <Icon {...props}>send</Icon>

export const PianoAdvIcon = props => {
    return (
        <img
            {...props}
            src={LocalImages.PIANO_ADV} 
        />
    )
}

export const TimeIcon = props => <Icon {...props}>access_time</Icon>

export const CheckIcon = props => <Icon {...props}>check_circle_outline</Icon>

export const ThreeDotsIcon = props => <Icon {...props}>more_horiz</Icon>

export const ArrowLeft = props => <Icon {...props}>arrow_back</Icon>

export const SaveIcon = props => <Icon {...props}>save</Icon>

export const SortIcon = props => <Icon {...props}>sort</Icon>

export const DragIcon = props => <Icon {...props}>drag_indicator</Icon>

export const CancelIcon = props => <Icon {...props}>cancel</Icon>

export const AccountBoxIcon = props => <Icon {...props}>account_box</Icon>

export const PersonIcon = props => <Icon {...props}>person</Icon>

export const CakeIcon = props => <Icon {...props}>cake</Icon>