import ActionTypes from './ActionTypes'
import { 
    changeUserLeaderboardParticipationPut, setFcmTokenPost,
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'
import { GROUP_FETCH_PARAMS } from '../utils/AppConstants'
import { Messages, texti18n } from '../utils/MessageConstants'
import { showSuccess, getOS } from '../services/SharedServices'
import { loadGroupsDataForStudentFetch, loadGroupsDataForTeacherFetch } from './GroupActions'
import { 
    checkEmailExistsGet, checkUsernameExistsGet, 
    changeUserProfilePut, changeUserPicturePut, changeUserPictureToPremadeAvatarPut 
} from 'dpn-common/services/IdentityEndpointsService'

export async function changeUserDataFetch(data, dispatch) {
    const fetchPromise = changeUserProfilePut(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(changeUserData(responseData.value))
        showSuccess(texti18n(Messages.PROFILE_HAS_BEEN_CHANGED))
    } 
    return responseData
}

export async function changeLeaderboardParticipationForStudentFetch(userId, leaderboardParticipation, dispatch, teacherId) {
    const data = { userId, leaderboardParticipation }
    const fetchPromise = changeUserLeaderboardParticipationPut(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success && dispatch) {
        !!teacherId && await loadGroupsDataForStudentFetch(GROUP_FETCH_PARAMS, dispatch, teacherId)
        userId === 0 && dispatch(changeLeaderboardParticipation(leaderboardParticipation))
    } 
    return responseData
}

export async function changeLeaderboardParticipationForTeacherFetch(userId, leaderboardParticipation, dispatch, userData) {
    const data = { userId, leaderboardParticipation }
    const fetchPromise = changeUserLeaderboardParticipationPut(data)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success && dispatch) {
        await loadGroupsDataForTeacherFetch(GROUP_FETCH_PARAMS, dispatch, userData)
        userId === 0 && dispatch(changeLeaderboardParticipation(leaderboardParticipation))
    } 
    return responseData
}


export async function changePictureFetch(picture, userId, dispatch, otherUserData) {
    const fetchPromise = changeUserPicturePut(picture, userId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        if (!otherUserData) {
            dispatch(changeUserData(responseData.value))
        }
        else {
            await loadGroupsDataForTeacherFetch(GROUP_FETCH_PARAMS, dispatch, otherUserData)
        }
        showSuccess(texti18n(Messages.PROFILE_AVATAR_HAS_BEEN_CHANGED))
    } 
    return responseData
}

export async function changePictureToPremadeAvatarFetch(url, userId, dispatch, otherUserData) {
    const fetchPromise = changeUserPictureToPremadeAvatarPut(url, userId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        if (!otherUserData) {
            dispatch(changeUserData(responseData.value))
        }
        else {
            await loadGroupsDataForTeacherFetch(GROUP_FETCH_PARAMS, dispatch, otherUserData)
        }
        showSuccess(texti18n(Messages.PROFILE_AVATAR_HAS_BEEN_CHANGED))
    }
    return responseData
}

export async function setFcmTokenFetchNoDispatch(fcmToken, isLoggedIn) {
    const fetchPromise = setFcmTokenPost(fcmToken, isLoggedIn, getOS())
    const responseData = await tryCatchJson(fetchPromise, false)
    return responseData.success && responseData.value
}

export async function checkEmailExistsFetch(email) {
    const fetchPromise = checkEmailExistsGet(email)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.success && responseData.emailExists
}

export async function checkUsernameExistsFetch(username, excludeUserId) {
    const fetchPromise = checkUsernameExistsGet(username, excludeUserId)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.success && responseData.usernameExists
}

export function changeUserData(userData) {
    return { type: ActionTypes.CHANGE_USER_DATA_ACTION, userData }
}

function changeLeaderboardParticipation(leaderboardParticipation) {
    return { type: ActionTypes.CHANGE_LEADERBOARD_PARTICIPATION, leaderboardParticipation }
}