import { USER_TYPES } from 'dpn-common/utils/AppConstants'
import { createSelector } from 'reselect'
import { createMapOfItemsById } from './BaseReselects'

const SHOW_LOGS = false

const getUserIdentityString = user => {
    if (!!user.firstName && !!user.lastName) {
        return `${user.firstName} ${user.lastName}`
    }
    return user.firstName || user.lastName || user.username || ''
}

export const studentTeacherDataListSelector = createSelector(
    state => state.group.users,
    state => state.group.userGroups,
    state => state.onlyActive,
    (users = [], userGroups = [], onlyActive = true) => {
        SHOW_LOGS && console.log('executing reselect: studentTeacherDataListSelector')
        let result = users.filter(user => user.role === USER_TYPES.TEACHER && userGroups.some(group => group.userId === user.id))
        if (onlyActive) {
            result = result.filter(x => x.isActive)
        }
        return result.sort((a, b) => {
            if (a.isActive && b.isActive) {
                if (a.isInvited && b.isInvited) {
                    const name1 = getUserIdentityString(a).toLowerCase()
                    const name2 = getUserIdentityString(b).toLowerCase()
                    return name1.localeCompare(name2)
                }
                else if (!a.isInvited) return -1
                else return 1
            }
            else if (a.isActive) return -1
            else return 1
        })
    }
)

export const studentSelectedTeacherDataSelector = createSelector(
    state => state.studentId || state.base.auth.userData.id, // studentId
    state => state.group.users,
    state => state.group.students,
    (studentId, users = [], students = []) => {
        SHOW_LOGS && console.log('executing reselect: studentSelectedTeacherDataSelector')
        const usersMap = createMapOfItemsById({ mapKey: 'users', users })
        const studentData = usersMap[studentId]
        let student = students.find(x => studentId in x.students)
        if (!student) {
            return undefined
        }
        const teacherId = student.teacherId
        const teacherStudentData = student.students[studentId]
        const teacherData = usersMap[teacherId]
        let result = {
            ...teacherData,
            ...teacherStudentData,
            leaderboardParticipation: teacherStudentData.leaderboardParticipation && studentData.leaderboardParticipation &&
                teacherData.leaderboardParticipation,
            leaderboardParticipationByTeacherStudent: teacherStudentData.leaderboardParticipation,
            leaderboardParticipationByStudent: studentData.leaderboardParticipation,
            leaderboardParticipationByTeacher: teacherData.leaderboardParticipation,
        }
        return result  
    }
)

export const studentTeacherGroupsDataListSelector = createSelector(
    state => state.studentId || state.base.auth.userData.id, // studentId
    state => state.teacherId || (state.student.teacher ? state.student.teacher.id : 0), // teacherId
    state => state.group.userGroups,
    state => state.groupFilters,
    (studentId, teacherId, groups = [], groupFilters = { active: true, own: false }) => {
        SHOW_LOGS && console.log('executing reselect: studentTeacherGroupsDataListSelector')
        const filteredGroups = groupFilters.own 
            ? groups.filter(group => group.users.some(x => x.userId === studentId) && group.userId === teacherId)
            : groups.filter(group => group.users.some(x => x.userId === studentId) && group.users.some(x => x.userId === teacherId))
        let result = filteredGroups.map(group => {
            const data = group.users.find(x => x.userId === studentId)
            return {
                ...group,
                role: data.role,
                isActive: group.isActive && data.isActive
            }
        })
        if (groupFilters.active) {
            result = result.filter(x => x.isActive)
        }
        return result.sort((a, b) => {
            if (a.userId !== b.userId) {
                if (a.userId === teacherId) return -1
                else if (b.userId === teacherId) return 1
                else return a.userId <= b.userId ? -1 : 1
            }
            else if (a.order > b.order) return 1
            else if (a.order < b.order) return -1
            else if (a.isActive && b.isActive) {
                const name1 = (a.name || '').toLowerCase()
                const name2 = (b.name || '').toLowerCase()
                return name1.localeCompare(name2)
            }
            else if (a.isActive) return -1
            else return 1
        })
    }
)

