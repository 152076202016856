import React from 'react'

export const TeacherCommunicationConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/communication/chat',
            component: React.lazy(() => import('./TeacherChat')),
            applicationPath: 'TeacherCommunicationChat',
        },
        {
            path: '/communication/channels',
            component: React.lazy(() => import('./TeacherChannels')),
            applicationPath: 'TeacherCommunicationChannels',
        },
    ]
}