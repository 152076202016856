import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'
import { LinksHelper } from 'dpn-common/utils/LinkRegexHelper'

function ExternalAppLink({ url, onOpenAssignmentLink }) {
    const lowerCaseUrl = url.toLowerCase()
    const userId = useSelector(({ base }) => base.auth.userData.id)
    if (lowerCaseUrl.startsWith(LinksHelper.ExternalAppUrls.ISM)) {
        const ismUrl = LinksHelper.toIsmLink(url)
        const onIsmPressHandler = () => onOpenAssignmentLink(ismUrl, userId)
        return (
            <Button
                className="ml-5"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none', borderRadius: '20px' }}
                onClick={onIsmPressHandler}
                title={texti18n(Messages.OPEN_SHEET_MUSIC)}
            >
                {texti18n(Messages.OPEN_SHEET_MUSIC)}
            </Button>
        )
    }

    const onPressHandler = () => onOpenAssignmentLink(url, userId)
    const title = LinksHelper.getLinkTitle(lowerCaseUrl)
    return (
        <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'none', borderRadius: '20px' }}
            onClick={onPressHandler}
            title={texti18n(title)}
        >
            {texti18n(title)}
        </Button>
    )
}

export const ExternalAppLinkComponent = React.memo(ExternalAppLink)