import React from 'react'

export const TeacherSkillsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/settings/custom-presets/teacher-skills',
            component: React.lazy(() => import('./TeacherSkills')),
            applicationPath: 'TeacherSkillList',
        },
    ]
}
