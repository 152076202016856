import { LoginConfig } from './login/LoginConfig'
import { ForgotEmailConfig } from './forgotEmail/ForgotEmailConfig'
import { ForgotPasswordConfig } from './forgotPassword/ForgotPasswordConfig'
import { RegistrationConfig } from './registration/RegistrationConfig'
import { ContactConfig } from './contact/ContactConfig'
import { PrivacyTermsConfig } from './privacyTerms/PrivacyTermsConfig'
import { LandingConfig } from './landing/LandingConfig'
import { AutomaticLoginConfig } from './automaticLogin/AutomaticLoginConfig'

export const guestConfigs = [
    LoginConfig,
    ForgotEmailConfig,
    ForgotPasswordConfig,
    RegistrationConfig,
    ContactConfig,
    PrivacyTermsConfig,
    LandingConfig,
    AutomaticLoginConfig,
]