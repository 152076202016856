import { SUPPORTED_CSV_FILE_FORMATS } from 'dpn-common/utils/FileConstants'
import FileSaver from 'file-saver'

function setItem(key, value, callback) {
    return new Promise((resolve, reject) => {
        try {
            window.localStorage.setItem(key, value)
            callback && callback()
            resolve && resolve()
        }
        catch (e) {
            console.log('ERROR: ' + e)
            reject && reject(e)
        }
    })
}

function getItem(key, callback) {
    return new Promise((resolve, reject) => {
        try {
            const result = window.localStorage.getItem(key)
            callback && callback()
            resolve && resolve(result)
        }
        catch (e) {
            console.log('ERROR: ' + e)
            reject && reject(e)
        }
    })
}

function removeItem(key, callback) {
    return new Promise((resolve, reject) => {
        try {
            window.localStorage.removeItem(key)
            callback && callback()
            resolve && resolve()
        }
        catch (e) {
            console.log('ERROR: ' + e)
            reject && reject(e)
        }
    })
}

function saveFile(fileContent, name) {
    return new Promise((resolve, reject) => {
        try {
            const blob = new Blob([fileContent], { type: SUPPORTED_CSV_FILE_FORMATS.APPLICATION_CSV })
            FileSaver.saveAs(blob, name)
            resolve && resolve(true)
        }
        catch (e) {
            console.log('ERROR: ' + e)
            reject && reject(false)
        }
    })
}

export default {
    setItem,
    getItem,
    removeItem,
    saveFile,
}