import ActionTypes from 'dpn-common/actions/ActionTypes'

// clear user dependant data from redux on logout
export const processUserDependantReducersMap = reducers => Object.keys(reducers).reduce((acc, cv) => {
    acc[cv] = (state, action) => {
        if (action.type === ActionTypes.LOG_OUT_ACTION) {
            state = undefined
        }

        return reducers[cv](state, action)
    }
    return acc
}, {})