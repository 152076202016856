import React from 'react'
import { Typography } from '@material-ui/core'

export default class TextComponent extends React.PureComponent {
    render() {
        return (
            <Typography {...this.props} />
        )
    }
}

TextComponent.defaultProps = {
    variant: 'body1'
}