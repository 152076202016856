import { SETTINGS_STORAGE_KEY, LANGUAGE_DEF_STORAGE_PREFIX_KEY } from '../utils/AppConstants'
import { getStorageItem, setStorageItem } from '../services/SharedServices'

let getStateFunc

function getNotifications() {
    const state = getStateFunc()
    return state.settingsAndLanguages.settings.notifications
}

function getLanguageCode() {
    const state = getStateFunc()
    return state.settingsAndLanguages.settings.languageCode
}

function getLanguageDefinition() {
    const state = getStateFunc()
    return state.settingsAndLanguages.languageDefinition
}

function getLanguages() {
    const state = getStateFunc()
    return state.settingsAndLanguages.languages
}

function getAllSettings() {
    const state = getStateFunc()
    return state.settingsAndLanguages.settings
}

function getAll() {
    const state = getStateFunc()
    return state.settingsAndLanguages
}

async function loadFromStorage(userId) {
    const settings = await getStorageItem(SETTINGS_STORAGE_KEY + (userId || 0))
    return settings && JSON.parse(settings) || false
}

async function storeToStorage(settingsData, userId) {
    const settingsDataJson = JSON.stringify(settingsData)
    if (userId) {
        await setStorageItem(SETTINGS_STORAGE_KEY + userId, settingsDataJson)
    }
    // settings for guest user write in any case
    await setStorageItem(SETTINGS_STORAGE_KEY + 0, settingsDataJson)
}

async function loadLanguageDefinitionFromStorage(code) {
    const key = LANGUAGE_DEF_STORAGE_PREFIX_KEY + code
    const data = await getStorageItem(key)
    return data && JSON.parse(data)
}

async function storeLanguageDefinitionToStorage(code, langDef) {
    const key = LANGUAGE_DEF_STORAGE_PREFIX_KEY + code
    await setStorageItem(key, langDef)
}

async function loadLanguagesFromStorage() {
    const data = await getStorageItem(LANGUAGE_DEF_STORAGE_PREFIX_KEY)
    return data && JSON.parse(data)
}

async function storeLanguagesToStorage(languages) {
    await setStorageItem(LANGUAGE_DEF_STORAGE_PREFIX_KEY, languages)
}

/**
 * Init settings service
 * @param {Function} getStateFuncParam store getState function
 */
function init(getStateFuncParam) {
    getStateFunc = getStateFuncParam
}

export default {
    getAll,
    getAllSettings,
    getNotifications,
    getLanguageCode,
    getLanguageDefinition,
    getLanguages,
    loadFromStorage,
    storeToStorage,
    loadLanguageDefinitionFromStorage,
    storeLanguageDefinitionToStorage,
    loadLanguagesFromStorage,
    storeLanguagesToStorage,
    init,
}
