
import React from 'react'
import PropTypes from 'prop-types'

function MedalIcon(props) {
    const { color, size, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 24 24"
            {...otherProps}>
            <path d="M21.38,5.76a1,1,0,0,0-.47-.61l-5.2-3a1,1,0,0,0-1.37.36L12,6.57,9.66,2.51a1,1,0,0,0-1.37-.36l-5.2,3a1,1,0,0,0-.47.61,1,1,0,0,0,.1.75l4,6.83A5.91,5.91,0,0,0,6,16a6,6,0,1,0,11.34-2.72l3.9-6.76A1,1,0,0,0,21.38,5.76ZM5,6.38l3.46-2L11.68,10A5.94,5.94,0,0,0,8,11.58ZM12,20a4,4,0,0,1-4-4,4,4,0,0,1,4-4,4,4,0,1,1,0,8Zm4-8.45a5.9,5.9,0,0,0-1.86-1.15L13.16,8.57l2.42-4.19,3.46,2Z" />
        </svg>
    )
}

MedalIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

MedalIcon.defaultProps = {
    color: 'white',
    size: 36,
}

export default MedalIcon
