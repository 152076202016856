import React from 'react'

export const StudentCommunicationConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-communication/chat',
            component: React.lazy(() => import('./StudentChat')),
            applicationPath: 'StudentCommunicationChat',
        },
        {
            path: '/student-communication/channels',
            component: React.lazy(() => import('./StudentChannels')),
            applicationPath: 'StudentCommunicationChannels',
        },
    ]
}