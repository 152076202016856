import React from 'react'

export const StudentTeacherLessonConfigConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-lesson-info',
            component: React.lazy(() => import('./StudentLessonInfo')),
            applicationPath: 'StudentLessonInfo',
        },
        {
            path: '/student-school-year-lessons',
            component: React.lazy(() => import('./StudentSchoolYearLessons')),
            applicationPath: 'StudentSchoolYearLessons',
        },
        {
            path: '/student-school-years',
            component: React.lazy(() => import('./StudentSchoolYears')),
            applicationPath: 'StudentSchoolYear',
        },
        {
            path: '/student-lessons',
            component: React.lazy(() => import('./StudentTeacherLessonList')),
            applicationPath: 'StudentTeacherLessonList',
        },
    ]
}