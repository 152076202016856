import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SplashScreenComponent from './SplashScreenComponent'
import { FuseTheme, FuseLayout } from '@fuse'
import DialogMessage from './Alert/DialogMessage'
import CustomizedSnackbars from './Alert/Alert'
import * as Actions from 'app/store/actions'
import _ from '@lodash'
import { setNavigationConfig } from '../../src/fuse-configs/navigationConfig'
import AlertService from 'services/common/AlertService'
import MessageUIService from 'services/common/MessageUIService'
import RouteAuthorization from './RouteAuthorization'
import { ROUTE_LOCATION, USER_TYPES } from 'dpn-common/utils/AppConstants'
import ViewTutorialsModalComponent from './Modal/ViewTutorials/ViewTutorialsModalComponent'
import { initializeAppAction } from 'dpn-common/actions/LoginActions'
import LayoutService from 'dpn-common/services/LayoutService'
import NotificationsHelper from 'dpn-common/utils/NotificationsHelper'
import NavigatorService from 'services/common/NavigatorService'
import { getUser } from 'dpn-common/utils/UserHelper'
import CookieConsent from 'components/CookieConsent/CookieConsent'
import { getGuestUrls } from 'app/fuse-configs/routesConfig'
import { getStorageItem, removeStorageItem } from 'dpn-common/services/SharedServices'

const handleMessageDialog = ref => MessageUIService.init(ref)

const handleDropdownAlertServiceRef = ref => AlertService.init(ref)

function AppContainer() {
    const modal = useRef(null)

    const dispatch = useDispatch()
    const { group, userData, hasTeacher, isInitializing, automaticLoginInProgress } = useSelector(state => ({
        group: state.group,
        userData: state.base.auth.userData,
        hasTeacher: !!(state.student && state.student.teacher),
        isInitializing: state.base.auth.isInitializing,
        automaticLoginInProgress: state.automaticLogin.inProgress,
    }))
    const { role, id } = userData

    useEffect(() => {
        // run initialization once
        if (isInitializing) {
            initializeAppAction(dispatch)
        }
        else if (!automaticLoginInProgress) {
            // on role change, update navigation and try to show dialog
            dispatch(Actions.setNavigation(setNavigationConfig(role)))
            if (!!modal.current && role !== USER_TYPES.GUEST) {
                modal.current.show()
            }
            
            // chat layouts are saved for specific users. clear them
            LayoutService.clearLayouts()

            // try to execute pending notification action
            const reduxParams = { group, userData, dispatch }
            NotificationsHelper.executePendingAction(userData.id || 0, role, reduxParams)
                .then(function (navigationHappened) {
                    // if there is no pending notification action or action did not perform navigation route change, switch to home route
                    !navigationHappened && jumpToHomeAfterLogin(role, hasTeacher)
                }).catch(ex => {
                    console.log('Error while executing NotificationsHelper.executePendingAction: ', ex)
                    jumpToHomeAfterLogin(role, hasTeacher)
                })
        }
    }, [id, isInitializing, automaticLoginInProgress])

    if (isInitializing) {
        return <SplashScreenComponent/>
    }

    return (
        <RouteAuthorization>
            <FuseTheme>
                <FuseLayout/>
                <CookieConsent/>
                <DialogMessage ref={handleMessageDialog} />
                <CustomizedSnackbars ref={handleDropdownAlertServiceRef} />
                <ViewTutorialsModalComponent ref={modal} role={role} />
            </FuseTheme>
        </RouteAuthorization>
    )
}

export default AppContainer

let _guestRoutes = undefined

async function jumpToHomeAfterLogin(role, hasTeacher) {
    if (!_guestRoutes) {
        _guestRoutes = getGuestUrls()
    }
    const route = NavigatorService.getCurrentRoute()
    const homeRoute = getUser(role).getHomeRoute({ hasTeacher })

    if (role !== USER_TYPES.GUEST) {
        const location = await getRouteLocationFromStorage()
        if (location) {
            const accessGranted = NavigatorService.hasPermission(location.pathname, role)
            if (accessGranted) {
                const { params, path } = NavigatorService.getRoute(location)
                NavigatorService.navigateAndReset(path, params)
            }
            else {
                NavigatorService.navigateAndReset(homeRoute)
            }
            return
        }
    }
    
    switch (role) {
    case USER_TYPES.GUEST:
        if (!_guestRoutes.includes(route.path)) { // TODO: allow 404 pages?
            NavigatorService.navigateAndReset(homeRoute)
        }
        else {
            NavigatorService.navigateAndReset(route.path, route.params)
        }
        break
    default:
        if (_guestRoutes.includes(route.path) || route.path === '/' || route.path === '/logout') {
            NavigatorService.navigateAndReset(homeRoute)
        }
        else {
            NavigatorService.navigateAndReset(route.path, route.params)
        }
        break
    }
}

async function getRouteLocationFromStorage() {
    const data = await getStorageItem(ROUTE_LOCATION)
    if (data) {
        const location = JSON.parse(data)
        if (location) {
            await removeStorageItem(ROUTE_LOCATION)
            return location
        }
    }
}