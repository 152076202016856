import { StudentChooseTeacherRouteName } from 'dpn-common/utils/AppConstants'
import React from 'react'

export const StudentChooseTeacherConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-teachers',
            component: React.lazy(() => import('./StudentChooseTeacher')),
            applicationPath: [StudentChooseTeacherRouteName]
        },
    ]
}