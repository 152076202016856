import ActionTypes from './ActionTypes'
import { 
    sendMessagePost, messagesGet, messagesGroupedGet, messagesByTextGet, deleteMessageDelete
} from '../services/EndpointsService'
import { tryCatchJson } from '../utils/FetchUtils'

export const MessagesInGroupLimit = 25
export const SearchMessagesLimit = 25
export const GroupedMessagesLimit = 25

export async function sendMessageFetch(groupIds, message, existingMessages, dispatch) {
    const fetchPromise = sendMessagePost(groupIds, message)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        if (groupIds.length === 1) {
            await getMessagesInGroupFetch(groupIds[0], existingMessages, false, false, dispatch)
        }
        await getGroupedMessagesFetch(undefined, undefined, 0, dispatch)
    }
    return responseData
}

export async function getMessagesInGroupFromMessageIdFetch(groupId, messageId, isUpScroll, clearCache, dispatch) {
    const limit = isUpScroll ? -MessagesInGroupLimit : MessagesInGroupLimit
    const promise = messagesGet(groupId, messageId, limit)
    const responseData = await tryCatchJson(promise, true)
    if (responseData.success) {
        dispatch(addGroupMessages(groupId, responseData.items, true, clearCache))
    } 
    return responseData
}

export async function getMessagesInGroupFetch(groupId, existingMessages, isUpScroll, clearCache, dispatch) {
    let offset
    let limit
    let mustContainLastMessage = false
    if (!existingMessages || existingMessages.length === 0) {
        offset = 0
        limit = MessagesInGroupLimit
        mustContainLastMessage = true
    }
    else if (isUpScroll) {
        limit = -MessagesInGroupLimit
        offset = existingMessages[0].id - 1
    }
    else {
        limit = MessagesInGroupLimit
        offset = existingMessages[existingMessages.length - 1].id + 1
    }
    const promise = messagesGet(groupId, offset, limit)
    const responseData = await tryCatchJson(promise)
    if (responseData.success) {
        dispatch(addGroupMessages(groupId, responseData.items, mustContainLastMessage, clearCache))
    } 
    return responseData
}

export async function getGroupedMessagesFetch(groupOwnerUserId, groupType, offset, dispatch) {
    const fetchPromise = messagesGroupedGet(groupOwnerUserId, groupType, offset, GroupedMessagesLimit)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(addGroupConversations(responseData.items, groupType))
    } 
    return responseData.items || []
}

export async function getMessagesByTextFetchNoDispatch(text, offset) {
    const fetchPromise = messagesByTextGet(text, offset, SearchMessagesLimit)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.items || []
}

export async function deleteMessageFetch(groupId, messageId, dispatch) {
    const fetchPromise = deleteMessageDelete(groupId, messageId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(deleteGroupMessage(groupId, messageId))
    }
}

const addGroupConversations = function (messages, groupType) {
    return { type: ActionTypes.PRIVATE_MESSAGE_ADD_CONVERSATIONS, messages, groupType }
}

const addGroupMessages = function (groupId, messages, mustContainLastMessage, clearCache) {
    return { type: ActionTypes.PRIVATE_MESSAGE_ADD_GROUP_MESSAGES, groupId, messages, mustContainLastMessage, clearCache }
}

const deleteGroupMessage = function (groupId, messageId) {
    return { type: ActionTypes.PRIVATE_MESSAGE_DELETE, groupId, messageId }
}