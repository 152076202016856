import React from 'react'

export const StudentNotificationsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-settings/notifications',
            component: React.lazy(() => import('./StudentNotifications')),
            applicationPath: 'StudentNotifications',
        },
        {
            path: '/student-settings/email-notifications',
            component: React.lazy(() => import('./StudentEmailNotifications')),
            applicationPath: 'StudentEmailNotifications',
        },
    ]
}