import ActionTypes from './ActionTypes'
import {
    enabledEmailTypesGet,
    setEnabledEmailTypesPost,
    setEnabledNotificationTypesPost,
    setLanguagePost
} from '../services/EndpointsService'
import SettingsService from '../services/SettingsService'
import NotificationsHelper from '../utils/NotificationsHelper'
import AppConfig from 'dpn-common/config/AppConfig'
import { NOTIFICATION_TYPES, NOTIFICATION_TOPICS } from '../utils/NotificationsConstants'
import { tryCatchJson, getStringStream } from '../utils/FetchUtils'
import { USER_TYPES, DUMMY_LANGUAGE_DEFINITION } from '../utils/AppConstants'
import { languageGet, languagesGet } from 'dpn-common/services/IdentityEndpointsService'

export async function initializeSettings(userId, role, settingsData, dispatch) {
    let settings = await SettingsService.loadFromStorage(userId)
    if (settingsData) {
        settings = Object.assign({}, settings, settingsData)
        await SettingsService.storeToStorage(settings, userId)
    }

    dispatch(setSettingsRedux(settings))

    if (settings && settings.notifications) {
        if (role === USER_TYPES.TEACHER) {
            NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.STUDENT_CHANNEL)
            settings.notifications.includes(NOTIFICATION_TYPES.CHANNELS) ?
                NotificationsHelper.subscribeToTopic(NOTIFICATION_TOPICS.TEACHER_CHANNEL) :
                NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.TEACHER_CHANNEL)
        }
        else if (role === USER_TYPES.STUDENT) {
            NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.TEACHER_CHANNEL)
            settings.notifications.includes(NOTIFICATION_TYPES.CHANNELS) ?
                NotificationsHelper.subscribeToTopic(NOTIFICATION_TOPICS.STUDENT_CHANNEL) :
                NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.STUDENT_CHANNEL)
        }
    }

    await loadLanguagesFetch(settings.languageCode, dispatch)
}

export async function setEnabledEmailTypesFetch(enabledEmailTypes, dispatch) {
    const fetchPromise = setEnabledEmailTypesPost(enabledEmailTypes)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setEmailNotificationsRedux(enabledEmailTypes))
    }
    return responseData
}

export async function enabledEmailTypesFetch(dispatch) {
    const fetchPromise = enabledEmailTypesGet()
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        dispatch(setEmailNotificationsRedux(responseData.value.enabledEmailTypes))
    }
    return responseData.success && responseData.value || {}
}

export async function setEnabledNotificationTypesFetch(enabledNotificationTypes, loggedInUserId, role, dispatch) {
    if (!AppConfig.enableNotifications) {
        return await setNotifications(loggedInUserId, undefined, enabledNotificationTypes, dispatch)
    }
    const token = await NotificationsHelper.getCurrentToken()
    if (!token) {
        return await setNotifications(loggedInUserId, role, enabledNotificationTypes, dispatch)
    }
    const fetchPromise = setEnabledNotificationTypesPost(enabledNotificationTypes, token)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        await setNotifications(loggedInUserId, role, enabledNotificationTypes, dispatch)
    }
    return responseData
}

export async function setLanguageFetch(languageCode, loggedInUserId, dispatch) {
    if (!AppConfig.enableNotifications) {
        return await setCurrentLanguage(loggedInUserId, languageCode, dispatch)
    }
    const token = await NotificationsHelper.getCurrentToken()
    if (!token) {
        return await setCurrentLanguage(loggedInUserId, languageCode, dispatch)
    }
    const fetchPromise = setLanguagePost(languageCode, token)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        await setCurrentLanguage(loggedInUserId, languageCode, dispatch)
    }
    return responseData
}

async function loadLanguagesFetch(currentLanguageCode, dispatch) {
    const fetchPromise = languagesGet()
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const languages = responseData.items
        for (let i = 0; i < languages.length; i++) {
            const lang = languages[i]
            const isCurrentLanguage = currentLanguageCode === lang.code
            // try to read lang definitions from storage
            // if not exist in storage or storage version is less than backend, retrieve definitions from server
            let langDef = await SettingsService.loadLanguageDefinitionFromStorage(lang.code)
            if (!langDef || !langDef.version || langDef.version < lang.version) {
                langDef = await fetchLanguageDefinition(lang.code, isCurrentLanguage)
            }
            // if this is current language we should update redux with lang definition (either from storage or from server)
            isCurrentLanguage && dispatch(setLanguageDefinitionRedux(langDef))
        }
        dispatch(setLanguagesRedux(responseData.items))
    }
    
    return responseData.success && responseData.items || []
}

async function setNotifications(loggedInUserId, role, notifications, dispatch) {
    const oldSettingsData = SettingsService.getAllSettings()
    const settingsData = { ...oldSettingsData, notifications }
    SettingsService.storeToStorage(settingsData, loggedInUserId)
    dispatch(setNotificationsRedux(notifications))

    if (notifications && role) {
        if (notifications.includes(NOTIFICATION_TYPES.CHANNELS)) {
            role === USER_TYPES.TEACHER ?
                NotificationsHelper.subscribeToTopic(NOTIFICATION_TOPICS.TEACHER_CHANNEL) :
                NotificationsHelper.subscribeToTopic(NOTIFICATION_TOPICS.STUDENT_CHANNEL)
        }
        else {
            role === USER_TYPES.TEACHER ?
                NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.TEACHER_CHANNEL) :
                NotificationsHelper.unsubscribeFromTopic(NOTIFICATION_TOPICS.STUDENT_CHANNEL)
        } 
    }
}

async function setCurrentLanguage(loggedInUserId, code, dispatch) {
    const oldSettingsData = SettingsService.getAllSettings()
    let settingsData = { ...oldSettingsData, languageCode: code }
    // update new settings in both storage and redux
    SettingsService.storeToStorage(settingsData, loggedInUserId)
    dispatch(setLanguageCodeRedux(code))
    // try to retrieve language def from storage, otherwise from server
    // and then update redux with that lang def
    let langDef = await SettingsService.loadLanguageDefinitionFromStorage(code) ||
                  await fetchLanguageDefinition(code, true)
    dispatch(setLanguageDefinitionRedux(langDef))
}

async function fetchLanguageDefinition(code, shouldParse) {
    let result
    const langDef = await getStringStream(languageGet(code))
    if (langDef) {
        await SettingsService.storeLanguageDefinitionToStorage(code, langDef)
        result = shouldParse ? JSON.parse(langDef) : langDef
    }
    return result || DUMMY_LANGUAGE_DEFINITION
} 

function setSettingsRedux(settings) {
    return { type: ActionTypes.SET_SETTINGS_ACTION, settings }
}

function setLanguagesRedux(languages) {
    return { type: ActionTypes.SET_LANGUAGES_ACTION, languages }
}

function setLanguageDefinitionRedux(languageDefinition) {
    return { type: ActionTypes.SET_LANGUAGE_DEFINITION_ACTION, languageDefinition }
}

function setNotificationsRedux(notifications) {
    return { type: ActionTypes.SET_NOTIFICATIONS_ACTION, notifications }
}

function setEmailNotificationsRedux(emailNotifications) {
    return { type: ActionTypes.SET_EMAIL_NOTIFICATIONS_ACTION, emailNotifications }
}

function setLanguageCodeRedux(languageCode) {
    return { type: ActionTypes.SET_LANGUAGE_CODE_ACTION, languageCode }
}