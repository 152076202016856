import ActionTypes from '../actions/ActionTypes'
import { 
    replaceOldItem, updateOldItem, setDictionaryState,
    addItemToItemsInDictionary, changeItemInItemsInDictionary,
    deleteItem,
} from '../utils/HelperFunctions'

const initialState = {
    //  { groupId: { schoolYearId: [] } }
    lessons: {},
    // [ { assignment1Id, assignmentFolder1Id }, { assignment1Id, assignmentFolder1Id }, ...]
    selectedAssignments: [],
    // [ { lessonId, notes }, { lessonId, notes }, ...]
    notes: {},
    // [ {note1Id, noteFolder1Id }, {note1Id, noteFolder1Id }, ... ]
    selectedNotes: []
}

function lessonsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ADD_LESSON_ACTION: {
        const groupId = action.lesson.groupId
        const schoolYearId = action.lesson.schoolYearId
        const tempState = state.lessons[groupId]
            ? addItemToItemsInDictionary(state.lessons, groupId, schoolYearId, action.lesson, lessonsCompareFunc)
            : setDictionaryState(state.lessons, action.groupId, schoolYearId, [action.lesson])
        return setDictionaryState(state, 'lessons', groupId, tempState[groupId])
    }
    case ActionTypes.UPDATE_LESSON_ACTION: {
        const groupId = action.lesson.groupId
        const schoolYearId = action.lesson.schoolYearId
        const tempState = changeItemInItemsInDictionary(state.lessons, groupId, schoolYearId,
            replaceOldLession(action.lesson, lessonsCompareFunc))
        return setDictionaryState(state, 'lessons', groupId, tempState[groupId])
    }
    case ActionTypes.ACTIVATE_LESSON_ACTION: {
        const groupId = action.groupId
        const schoolYearId = action.schoolYearId
        const tempState = changeItemInItemsInDictionary(state.lessons, groupId, schoolYearId,
            updateLessonIsActive(action.id, action.isActive))
        return setDictionaryState(state, 'lessons', groupId, tempState[groupId])
    }
    case ActionTypes.PUBLISH_LESSON_ACTION: {
        const groupId = action.groupId
        const schoolYearId = action.schoolYearId
        const tempState = changeItemInItemsInDictionary(state.lessons, groupId, schoolYearId,
            updateLessonIsPublished(action.id, action.isPublished))
        return setDictionaryState(state, 'lessons', groupId, tempState[groupId])
    }
    case ActionTypes.SET_LESSONS_ACTION: {
        const schoolYearId = action.schoolYearId ? action.schoolYearId : 0
        const groupedLessons = groupByGroupId(action.lessons || [], action.groupIds)
        let lessons = state.lessons
        Object.keys(groupedLessons).forEach(groupId => {
            lessons = setDictionaryState(lessons, groupId, schoolYearId, groupedLessons[groupId])
        })
        return Object.assign({}, state, {
            lessons,
        })
    }
    case ActionTypes.SET_SELECTED_ASSIGNMENTS: {
        return Object.assign({}, state, {
            selectedAssignments: action.selectedAssignments,
        })
    }
    case ActionTypes.SET_SELECTED_NOTES: {
        return Object.assign({}, state, {
            selectedNotes: action.selectedNotes,
        })
    }
    case ActionTypes.SET_LESSON_NOTES_ACTION: {
        return setDictionaryState(state, 'notes', action.lessonId, action.notes)
    }
    case ActionTypes.ADD_LESSON_NOTE_ACTION: {
        return addItemToItemsInDictionary(state, 'notes',
            action.lessonId, action.note, noteCompareFunc)
    }
    case ActionTypes.UPDATE_LESSON_NOTE_ACTION: {
        return changeItemInItemsInDictionary(state, 'notes', action.lessonId,
            replaceOldItemCreate(action.note, noteCompareFunc))
    }
    case ActionTypes.DELETE_LESSON_NOTE_ACTION: {
        return changeItemInItemsInDictionary(state, 'notes', action.lessonId,
            deleteItemCreate(action.noteId))
    }
    case ActionTypes.DELETE_LESSON_NOTES_ACTION: {
        const noteIds = action.noteIds
        let tempState = state
        noteIds.forEach(id => 
            tempState = changeItemInItemsInDictionary(tempState, 'notes', action.lessonId,
                deleteItemCreate(id)))
        return tempState
    }
    case ActionTypes.DELETE_LESSON_ACTION: {
        const groupId = action.groupId
        const schoolYearId = action.schoolYearId
        const tempState = changeItemInItemsInDictionary(state.lessons, groupId, schoolYearId,
            deleteItemCreate(action.id))
        return setDictionaryState(state, 'lessons', groupId, tempState[groupId])
    }
    default:
        return state
    }
}

export default lessonsReducer


function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}

function replaceOldLession(lesson) {
    return lessonsByKey => replaceOldItem(lessonsByKey, lesson, lessonsCompareFunc)
}

function updateLessonIsActive(id, value) {
    return lessonsByKey => updateOldItem(lessonsByKey, id, 
        x => x.isActive = value, lessonsCompareFunc)
}

function updateLessonIsPublished(id, value) {
    return lessonsByKey => updateOldItem(lessonsByKey, id, 
        x => x.isPublished = value, lessonsCompareFunc)
}

export function lessonsCompareFunc(lesson) {
    return x => {
        if (x.date !== lesson.date) {
            return x.date > lesson.date
        }
        return x.id > lesson.id
    }
}

function groupByGroupId(items, groupIds) {
    const itemsReduceFunc = (acc, cv) => {
        if (!acc[cv.groupId]) {
            acc[cv.groupId] = []
        }
        acc[cv.groupId].push(cv)
        return acc
    }
    const itemsMap = items.reduce(itemsReduceFunc, {})
    const retVal = groupIds && groupIds.length > 0
        ?
        groupIds.reduce((acc, cv) => {
            acc[cv] = itemsMap[cv] || []
            return acc
        }, {})
        :
        itemsMap
    return retVal
}

function noteCompareFunc(note) {
    return x => {
        if (x.order !== note.order) {
            return x.order > note.order
        }
        if (x.name != note.name) {
            return x.name.toLowerCase() > note.name.toLowerCase()
        }
        return x.id < note.id
    }
}

function replaceOldItemCreate(item, compareFunc) {
    return items => replaceOldItem(items, item, compareFunc)
}