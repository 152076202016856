import React from 'react'

export const HomeConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/',
            exact: true,
            component: React.lazy(() => import('./Home')),
            applicationPath: ['Home'],
        }
    ]
}