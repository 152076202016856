import EventListenerService from 'dpn-common/services/EventListenerService'
import { decodeQueryString, parametersToQueryString } from 'dpn-common/utils/UrlQueryHelperFunctions'

const EVENT_TYPE = 'NAVIGATOR_ROUTE_CHANGED'
let _history
let stateChangedListener
let _routeMappings
let _routeReverseMappings
let _routes

function init(history, routeMappings, routeReverseMappings, routes) {
    _history = history
    _routeMappings = routeMappings
    _routeReverseMappings = routeReverseMappings
    _routes = routes
    stateChangedListener = _history.listen(stateChanged)
}

function dispose() {
    stateChangedListener && stateChangedListener()
}

function addRouteChangeListener(callback) {
    return EventListenerService.subscribe(EVENT_TYPE, callback)
}

function navigate(routeName, params, key) {
    _history.push(url(routeName, params, key))
}

function push(routeName, params) {
    navigate(routeName, params)
}

function goBack() {
    _history.goBack()
}

function pop(number) {
    if (number > 0) {
        _history.go(-number)
    }
    else {
        _history.goBack()
    }
}

function setParams(params) {
    const route = getCurrentRoute()
    navigate(route.name, params)
}

function reset(routeNames, routeParams, _routeKeys) {
    _history.go(-history.length)    
    routeNames.forEach((rn, index) => {
        navigate(rn, routeParams ? routeParams[index] : undefined)
    })
}

function navigateAndReset(routeName, params) {
    _history.go(-history.length)
    navigate(routeName, params)
}

function url(routeName, params, key) {
    const route = routeName in _routeMappings ? _routeMappings[routeName] : routeName
    if (!!key && !(key in params)) {
        params = { ...params, key }
    }
    const parametersStrings = parametersToQueryString(params, '[]')
    return route + parametersStrings
}

function toggleDrawer() {
}

function closeDrawer() {
}

function openDrawer() {
}

// gets the current screen from navigation state
function getRoute(location) {
    const params = decodeQueryString(location.search || '')
    const path = location.pathname || '/'
    const name = path in _routeReverseMappings ? _routeReverseMappings[path] : path
    return { name, params, path }
}

function getCurrentRoute() {
    return getRoute(_history.location)
}

function stateChanged(location, action) {
    const data = { currentLocation: location, action }
    EventListenerService.notify(EVENT_TYPE, data)
}

function hasPermission(pathname, role) {
    const matched = (_routes || []).find(x => x.path === pathname)
    if (matched) {
        const auth = matched.auth
        if (auth === null || auth === undefined || auth.length === 0) {
            return true
        }
        return auth.includes(role)
    }
    return false
}

// add other navigation functions that you need and export them
export default {
    init,
    dispose,
    navigate,
    reset,
    push,
    goBack,
    pop,
    setParams,
    navigateAndReset,
    toggleDrawer,
    closeDrawer,
    openDrawer,
    stateChanged,
    getCurrentRoute,
    addRouteChangeListener,
    url,
    getRoute,
    hasPermission
}