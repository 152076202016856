import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import AppContext from 'app/AppContext'
import NavigatorService from 'services/common/NavigatorService'
import { ROUTE_LOCATION, USER_TYPES } from 'dpn-common/utils/AppConstants'
import { setStorageItem } from 'dpn-common/services/SharedServices'

function RouteAuthorization({ location, role, routes, children }) {
    const { pathname } = location
    const matched = (routes || []).find(x => x.path === pathname)
    const hasMatched = !!matched
    const accessGranted = hasMatched && _hasPermission(matched.auth, role)
    const isGuestRoute = hasMatched && _isGuestRoute(matched.auth)

    useEffect(() => {
        const saveRouteToStorage = async () => {
            await setStorageItem(ROUTE_LOCATION, JSON.stringify(location))
        }

        if (hasMatched && !accessGranted) {
            if (role == USER_TYPES.GUEST) {
                saveRouteToStorage()
                NavigatorService.navigateAndReset('Login')
            }
            else if (!isGuestRoute) {
                console.log(`Forbidden 403: ${pathname} for ${role}`)
                NavigatorService.navigateAndReset('Error403')
            }
        }
    }, [accessGranted, hasMatched])

    if (hasMatched && !accessGranted && !isGuestRoute) {
        return null
    }
    return children
}

const RouteAuthorizationMemo = React.memo(RouteAuthorization)

const RouteAuthorizationWrapper = function ({ children, location, role }) {
    return (
        <AppContext.Consumer>
            {({ routes }) => 
                <RouteAuthorizationMemo 
                    routes={routes} location={location} role={role}>
                    {children}
                </RouteAuthorizationMemo>}
        </AppContext.Consumer>
    )
}

const mapStateToProps = state => {
    return {
        role: state.base.auth.userData.role,
    }
}

export default connect(mapStateToProps)(withRouter(RouteAuthorizationWrapper))

function _hasPermission(authArr, role) {
    if (authArr === null || authArr === undefined || authArr.length === 0) {
        return true
    }
    return authArr.includes(role)
}

function _isGuestRoute(authArr) {
    return !!authArr && authArr.length === 1 && authArr[0] === USER_TYPES.GUEST
}