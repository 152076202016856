import * as React from 'react'
import { isImageLink } from './VideoUtils/VideoLinkUtils'
import { emoticonCodesSelector } from '../reselects/EmoticonReselect'
import { isYouTubeLink, isVimeoLink } from './VideoUtils/VideoPlayerHelper'
import { compareDatesByDay, calculateTimeForChatHistory } from './TimeHelperFunctions'
import { NOTIFICATION_TYPES } from './NotificationsConstants'
import AppConfig from 'dpn-common/config/AppConfig'
import { texti18n, Messages } from './MessageConstants'
import { showInfo } from '../services/SharedServices'
import { LinksHelper } from './LinkRegexHelper'
import {
    getTeacherCustomAndSingleStudentsGroups, isGroupSingleStudent,
    getStudentDataBySingleStudentGroupId,
} from './GroupsDataUtils'
import { userHasFullName, getUserIdentityString } from './UserHelper'

const NEW_MESSAGE_TIMEOUT = 10000
const types = {
    EMOTICON: 0,
    VIDEO_IMAGE: 1,
    LINK: 2,
    TEXT: 3,
    EXTERNAL_APP_LINK: 4
}

let RenderHelper = { 
    getUrlText: (_index, _link, _onPressLink) => <></>,
    renderLinkWithImage: (_i, _token, _openMenu, _sendMessageDisabled) => <></>,
    renderStringTokens: (_i, _tokens) => <></>,
    renderEmoticon: (_index, _url) => <></>,
    renderTokens: _tokenizer => <></>,
    renderExternalAppLink: (_i, _link, _onPressLink) => <></>
}

export const init = function(renderHelper) {
    RenderHelper = renderHelper
}

export const periodicallyCheck = (sendMessageDisabled, enabledNotificationTypes, getPropsFunc) => {
    const getLastMessage = messages => messages && messages.length && messages[messages.length - 1]
    const notificationTypeToCheck = sendMessageDisabled ? NOTIFICATION_TYPES.CHANNELS : NOTIFICATION_TYPES.MESSAGES
    return setInterval(async () => {
        const { messages, groupId, getMessages, userData } = getPropsFunc()
        const shouldCheckPeriodically = !AppConfig.enableNotifications || !enabledNotificationTypes.includes(notificationTypeToCheck)
        if (shouldCheckPeriodically) {
            const lastMessage = getLastMessage(messages)
            const lastMessageId = lastMessage && lastMessage.id            
            const responseData = await getMessages(groupId, messages, false)
            if (responseData.success) {
                const newLastMessage = getLastMessage(responseData.items)
                const newLastMessageId = newLastMessage && newLastMessage.senderId !== userData.id && newLastMessage.id
                if (newLastMessageId && newLastMessageId !== lastMessageId) {
                    showInfo(texti18n(Messages.NEW_PRIVATE_MESSAGE_RECEIVED))
                }
            }
        }
    }, NEW_MESSAGE_TIMEOUT)
}

export const getTimeForMessage = (message, allMessages, index) => {
    if (index == 0 || compareDatesByDay(allMessages[index - 1].createdAt, message.createdAt)) {
        return calculateTimeForChatHistory(message.createdAt)
    }
    return undefined
}

// options { openMenu: handler, parseVideosAndImages: true, onPressLink: handler }
export const parseMessage = (message, emoticons, options) => {
    const data = emoticonCodesSelector(emoticons)
    const emoticonUrls = data.emoticonUrls
    const nonTextTokens = getLinkAndEmoticonTokens(message, data)
    const allTokens = getAllMergedTokens(nonTextTokens, message)
    const retTokenizer = renderTokens(allTokens, emoticonUrls, options)

    return retTokenizer
}

const getLinkAndEmoticonTokens = (message, data) => {
    let match
    let nonTextTokens = []

    if (data) {
        while ((match = data.regex.exec(message))) {
            const content = match[0]
            nonTextTokens.push({
                type: types.EMOTICON,
                index: match.index,
                content
            })
        }
    }

    const linkRegex = LinksHelper.getLinkRegex()
    while ((match = linkRegex.exec(message))) {
        let content = match[0]
        const isTypeImageLink = isYouTubeLink(content) || isVimeoLink(content) || isImageLink(content)
        const reducedContent = LinksHelper.toHttp(content)

        nonTextTokens.push({
            type: isTypeImageLink ? types.VIDEO_IMAGE : types.LINK,
            index: match.index,
            content: reducedContent,
            originalContent: content
        })
    }

    const externalAppLinkRegex = LinksHelper.getExternalAppLinkRegex()
    while ((match = externalAppLinkRegex.exec(message))) {
        let content = match[0]
        nonTextTokens.push({
            type: types.EXTERNAL_APP_LINK,
            index: match.index,
            content
        })
    }

    return nonTextTokens.sort((a, b) => a.index - b.index)
}

const getAllMergedTokens = (nonTextTokens, message) => {
    let allTokens = []
    let startInd = 0
    let token
    let i = -1

    if (nonTextTokens.length) {
        while ((token = nonTextTokens[++i])) {
            if (startInd === token.index) {
                allTokens.push(token)
            }
            else {
                const text = message.substring(startInd, token.index)
                allTokens.push({
                    type: types.TEXT,
                    index: startInd,
                    content: text
                })
                allTokens.push(token)
            }
            const contentLength = token.originalContent ? token.originalContent.length : token.content.length
            startInd = token.index + contentLength
        }
    }

    if (startInd < message.length) {
        const text = startInd > 0 ? message.substring(startInd) : message
        allTokens.push({
            type: types.TEXT,
            index: startInd,
            content: text
        })
    }

    return allTokens
}

const renderTokens = (tokens, emoticonUrls, options) => {
    const retTokenizer = []
    const openMenuHandler = options && options.openMenu || false
    const sendMessageDisabled = options && options.sendMessageDisabled || false
    const onPressLink = options !== undefined && options.onPressLink

    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i]
        if (token.type === types.EMOTICON) {
            retTokenizer.push(RenderHelper.renderEmoticon(i, emoticonUrls[token.content]))
        }
        else if (token.type === types.LINK) {
            retTokenizer.push(RenderHelper.getUrlText(i, token.content, onPressLink))
        }
        else if (token.type === types.TEXT) {
            retTokenizer.push(RenderHelper.renderStringTokens(i, token.content))
        }
        else if (token.type === types.VIDEO_IMAGE) {
            retTokenizer.push(RenderHelper.renderLinkWithImage(i, token.content, openMenuHandler, sendMessageDisabled))
        }
        else if (token.type === types.EXTERNAL_APP_LINK) {
            retTokenizer.push(RenderHelper.renderExternalAppLink(i, token.content, onPressLink))
        }
    }

    return RenderHelper.renderTokens(retTokenizer)
}

export const nonSiteGlobalGroupsMapForTeacherCommunication = (userGroups, users, students, teacherId) =>
    getTeacherCustomAndSingleStudentsGroups(userGroups, teacherId, true)
        .reduce((acc, x) => {
            if (isGroupSingleStudent(x)) {
                const studentData = getStudentDataBySingleStudentGroupId(userGroups, users, students, x.id)
                if (userHasFullName(studentData)) {
                    const name = getUserIdentityString(studentData)
                    const picture = studentData.picture
                    acc[x.id] = {
                        ...x,
                        name,
                        picture
                    }
                }
            }
            else {
                acc[x.id] = x
            }
            return acc
        }, {})