import React from 'react'

export const errorsPagesConfigs = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/error-404',
            component: React.lazy(() => import('./Error404Page')),
            applicationPath: 'Error404',
        },
        {
            path: '/error-403',
            component: React.lazy(() => import('./Error403Page')),
            applicationPath: 'Error403',
        }
    ]
}
