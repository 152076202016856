import ActionTypes from '../actions/ActionTypes'

const initialState = {
    teacher: undefined,
    children: undefined,
}

function studentReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.STUDENT_SET_CHOSEN_TEACHER_ACTION:
        return Object.assign({}, state, {
            teacher: action.teacher
        })
    case ActionTypes.CHANGE_LEADERBOARD_PARTICIPATION: {
        if (!state.teacher) {
            return state
        }

        const teacher = {
            ...state.teacher,
            leaderboardParticipation: state.teacher.leaderboardParticipationByTeacherStudent && state.teacher.leaderboardParticipationByTeacher && action.leaderboardParticipation,
            leaderboardParticipationByStudent: action.leaderboardParticipation
        }

        return Object.assign({}, state, { teacher })
    }
    case ActionTypes.SET_STUDENT_CHILDREN:
        return Object.assign({}, state, {
            children: action.children
        })
    default:
        return state
    }
}

export default studentReducer