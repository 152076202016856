import ActionTypes from '../actions/ActionTypes'
import {
    addItemToItemsInDictionary, changeItemInItemsInDictionary, deleteItem,
    replaceOldItem, setDictionaryState
} from '../utils/HelperFunctions'

const initialState = {
    // { groupId1: { parent: [note1, note2, ...] } }
    notes: undefined,
}

function notesReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_NOTES_FOR_NOTE_FOLDER: {
        const notes = { ...state.notes }
        if (action.noteFolderId) {
            notes[action.groupId] = {
                ...notes[action.groupId],
                [action.noteFolderId]: action.notes
            }
            return {
                ...state,
                notes
            }
        }
        else {
            notes[action.groupId] = {}
            const recursive = (list, parentId) => {
                notes[action.groupId][parentId] = list
                list.forEach(x => {
                    if (x.children && x.children.length) {
                        recursive(x.children, x.id)
                    }
                })
            }
            recursive(action.notes, 0)
            return {
                ...state,
                notes,
            }
        }
    }

    case ActionTypes.ADD_NOTE_FOR_NOTE_FOLDER: {
        const noteFolderId = action.noteFolderId ? action.noteFolderId : 0
        // addItemToItemsInDictionary will be calledif notes[groupId] exists and its [] or array with more than zero elements
        const tempState = state.notes[action.groupId]
            ? addItemToItemsInDictionary(state.notes || {}, action.groupId, noteFolderId, action.note, compareFunc)
            : setDictionaryState(state.notes || {}, action.groupId, noteFolderId, [action.note])
        
        return setDictionaryState(state, 'notes', action.groupId, tempState[action.groupId])
    }

    case ActionTypes.UPDATE_NOTE_FOR_NOTE_FOLDER: {
        const noteFolderId = action.noteFolderId ? action.noteFolderId : 0
        const tempState = changeItemInItemsInDictionary(state.notes || {}, action.groupId, noteFolderId,
            replaceOldItemCreate(action.note, compareFunc))
        return setDictionaryState(state, 'notes', action.groupId, tempState[action.groupId])
    }

    case ActionTypes.DELETE_NOTE_FOR_NOTE_FOLDER: {
        const noteFolderId = action.noteFolderId ? action.noteFolderId : 0
        const tempState = changeItemInItemsInDictionary(state.notes || {}, action.groupId, noteFolderId,
            deleteItemCreate(action.id))
        return setDictionaryState(state, 'notes', action.groupId, tempState[action.groupId])
    }

    default:
        return state
    }
}

function compareFunc(note) {
    return x => {
        if (x.order != note.order) {
            return x.order > note.order
        }
        if (x.color != note.color) {
            return x.color > note.color
        }
        if (x.name != note.name) {
            return x.name.toLowerCase() > note.name.toLowerCase()
        }
        return x.id > note.id
    }
}

function deleteItemCreate(id) {
    return items => deleteItem(items, id)
}

function replaceOldItemCreate(item, compareFunc) {
    return items => replaceOldItem(items, item, compareFunc)
}


export default notesReducer