import ActionTypes from './ActionTypes'
import {
    updateAssignmentPut, addAssignmentPost,
    deleteAssignmentDelete, assignmentCommentsGet,
    addAssignmentCommentPost, deleteAssignmentCommentDelete,
    sortAssignmentPost,
    assignmentsGet, assignmentGet, importAssignmentPost, exportAssignmentPost, assignmentsRelatedGet,
} from '../services/EndpointsService'
import { Messages, texti18n } from '../utils/MessageConstants'
import { getStringStream, tryCatchJson } from '../utils/FetchUtils'
import { NavigatorService, showSuccess, saveFile } from '../services/SharedServices'
import { SUPPORTED_IMPORT_FILE_TYPES } from 'dpn-common/utils/FileConstants'
import { IMPORT_FILE_CONTENT } from 'dpn-common/utils/AppConstants'
import { formatDateTime } from 'dpn-common/utils/TimeHelperFunctions'

const LOAD_ASSIGNMENTS_OPTIONS = Object.freeze({
    ENABLE_STREAMED: false,
    LIMIT: 5,
    TIMEOUT: 500,
})

export async function loadAssignmentCommentsFetchNoDispatch({ assignmentId, groupIds, offset, noOfItems }) {
    const fetchPromise = assignmentCommentsGet(assignmentId, groupIds, offset, noOfItems)
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData.items || []
}

export async function addAssignmentCommentFetchNoDispatch(assignmentId, comment, groupId, picture, lessonId) {
    const fetchPromise = addAssignmentCommentPost(groupId, assignmentId, comment, picture, lessonId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        showSuccess(texti18n(Messages.COMMENT_HAS_BEEN_POSTED))
    }
    return responseData
}

export async function deleteAssignmentCommentFetchNoDispatch(commentId) {
    const fetchPromise = deleteAssignmentCommentDelete(commentId)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        showSuccess(texti18n(Messages.COMMENT_HAS_BEEN_DELETED))
    }
    return responseData
}

export async function loadAssignmentsFetch(groupId, isActive, role, dispatch, offset) {
    const isStreamingEnabled = LOAD_ASSIGNMENTS_OPTIONS.ENABLE_STREAMED
    const fetchPromise = isStreamingEnabled ?
        assignmentsGet({
            groupId,
            isActive,
            populateLinks: true,
            offset,
            limit: LOAD_ASSIGNMENTS_OPTIONS.LIMIT,
            relateTo: role,
        })
        :
        assignmentsGet({
            groupId,
            isActive,
            populateLinks: true,
            grouped: true,
            relateTo: role,
        })
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const assignments = responseData.items
        if (isStreamingEnabled && assignments.length === LOAD_ASSIGNMENTS_OPTIONS.LIMIT) {
            setTimeout(() => loadAssignmentsFetch(groupId, isActive, role, dispatch, (offset || 0) + LOAD_ASSIGNMENTS_OPTIONS.LIMIT),
                LOAD_ASSIGNMENTS_OPTIONS.TIMEOUT)
        }
        const subtitle = []
        generateSubtitle(assignments, subtitle)
        dispatch(setAssignmentsForAssignmentFolder(groupId, 0, assignments, isStreamingEnabled, !offset))
    }
    return responseData
}

function generateSubtitle(assignments, subtitle) {
    assignments.forEach(element => {
        if (element.isFolder) {
            subtitle.push(element.name)
            if (element.children.length > 0) {
                generateSubtitle(element.children, subtitle)
            }
            subtitle.pop()
        } else {
            if (!element.subtitle) {
                element.subtitle = element.pageNumber ? subtitle.join(" | ").concat(" | ", element.pageNumber) : subtitle.join(" | ")   
            }
        }
   });
}

export async function getAssignmentsBySearchFetch(groupId, search, parentId, filterByParent,) {
    const fetchPromise = assignmentsGet({
        groupId,
        parentId: !parentId ? null : parentId,
        filterByParent,
        isActive: true,
        isFolder: false,
        search,
        limit: 30
    })
    const responseData = await tryCatchJson(fetchPromise, true)
    return responseData
}

export async function addUpdateAssignmentFetch(data, dispatch, goBack) {
    const requestData = {
        ...data,
        parentId: data.parentId ? data.parentId : null,
    }
    const fetchPromise = data.id
        ? updateAssignmentPut(requestData)
        : addAssignmentPost(requestData)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const value = responseData.value
        dispatch(data.id
            ? updateAssignmentForAssignmentFolder(value.groupId, value.parentId, value)
            : addAssignmentForAssignmentFolder(value.groupId, value.parentId, value))
        showSuccess()
        goBack && NavigatorService.goBack()
    }
    return responseData
}

export async function deleteAssignmentFetch(id, groupId, parentId, dispatch, goBack, successMessage = Messages.ASSIGNMENT_HAS_BEEN_DELETED) {
    const fetchPromise = deleteAssignmentDelete(id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        dispatch(deleteAssignmentForAssignmentFolder(groupId, parentId, id))
        goBack && NavigatorService.goBack()
        successMessage !== undefined && showSuccess(texti18n(successMessage))
    }
    return responseData
}

export async function sortAssignmentsFetch(groupId, parentId, assignmentIds, isActive, dispatch) {
    const fetchPromise = sortAssignmentPost(groupId, parentId === 0 ? null : parentId, assignmentIds, isActive)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        const assignments = responseData.items
        assignments.length > 0 && dispatch(setAssignmentsForAssignmentFolder(groupId, parentId, assignments))
    }
    return responseData
}

export async function importAssignmentsFetch(groupId, parentId, content, role, dispatch) {
    const fetchPromise = importAssignmentPost(groupId, parentId === 0 ? null : parentId, content, IMPORT_FILE_CONTENT)
    const responseData = await tryCatchJson(fetchPromise, true)
    if (responseData.success) {
        await loadAssignmentsFetch(groupId, true, role, dispatch, 0)
        showSuccess(texti18n(Messages.IMPORT_ASSIGNMENTS_SUCCESSFUL))
    }
    return responseData
}

export async function exportAssignmentsFetch(groupId, ids) {
    const fetchPromise = exportAssignmentPost(groupId, ids, IMPORT_FILE_CONTENT)
    const responseData = await getStringStream(fetchPromise, true)
    const fileName = `Assignments_${formatDateTime(new Date(), texti18n(Messages.CURRENT_FILE_EXPORT_DATE_TIME_FORMAT))}${SUPPORTED_IMPORT_FILE_TYPES.CSV}`.replace(':', '_').replace(' ', '_')
    const success = await saveFile(responseData, fileName)
    if (success) {
        showSuccess(texti18n(Messages.EXPORT_ASSIGNMENTS_SUCCESSFUL))
    }
}

export async function getSingleAssignmentFetch(id, dispatch) {
    const fetchPromise = assignmentGet(id)
    const responseData = await tryCatchJson(fetchPromise)
    if (responseData.success) {
        const value = responseData.value
        !!dispatch && dispatch(updateAssignmentForAssignmentFolder(value.groupId, value.parentId, value))
    }
    return responseData
}

export async function getRelatedAssignmentsFetch(id, userRelateTo) {
    const fetchPromise = assignmentsRelatedGet(id, userRelateTo)
    const responseData = await tryCatchJson(fetchPromise)
    return responseData
}

function setAssignmentsForAssignmentFolder(groupId, assignmentFolderId, assignments, streamed, clear) {
    return { type: ActionTypes.SET_ASSIGNMENTS_FOR_ASSIGNMENT_FOLDER, groupId, assignmentFolderId, assignments, streamed, clear }
}

function addAssignmentForAssignmentFolder(groupId, assignmentFolderId, assignment) {
    return { type: ActionTypes.ADD_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER, groupId, assignmentFolderId, assignment }
}

function updateAssignmentForAssignmentFolder(groupId, assignmentFolderId, assignment) {
    return { type: ActionTypes.UPDATE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER, groupId, assignmentFolderId, assignment }
}

function deleteAssignmentForAssignmentFolder(groupId, assignmentFolderId, id) {
    return { type: ActionTypes.DELETE_ASSIGNMENT_FOR_ASSIGNMENT_FOLDER, assignmentFolderId, id, groupId }
}