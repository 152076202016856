import { Messages, texti18n } from './MessageConstants'
import EventListenerService from '../services/EventListenerService'
import { showInfo, subscribeToConnectivity } from '../services/SharedServices'

const EVENT_NAME = 'NetInfo_ConnChange'
let lastIsConnected
let unsubscribe

function init() {
    unsubscribe = subscribeToConnectivity(handleConnectivityChange)
}

function dispose() {
    unsubscribe && unsubscribe()
}

function addOnConnectedCallback(callback, callOnce) {
    return EventListenerService.subscribe(EVENT_NAME, callback, callOnce)
}

async function handleConnectivityChange(state) {
    if (state.type === 'none') {
        if (lastIsConnected) {
            showInfo(texti18n(Messages.DISCONNECTED_FROM_INTERNET))
        }
        lastIsConnected = false
    }
    else if (state.type !== 'undefined') {
        if (lastIsConnected === false) {
            showInfo(texti18n(Messages.CONNECTED_TO_INTERNET))
            EventListenerService.notify(EVENT_NAME, true)
        }
        lastIsConnected = true
    }
}

export default {
    init,
    dispose,
    addOnConnectedCallback
}
