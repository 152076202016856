import { createSelector } from 'reselect'
import { getFromDict } from '../utils/HelperFunctions'
import { GROUP_TYPES, USER_TYPES } from '../utils/AppConstants'
import { sortUsers } from 'dpn-common/utils/UserHelper'
import { getDayIndexFromDayString, getJSDateFromJson, getMomentDate, getMomentDateFromJson } from 'dpn-common/utils/TimeHelperFunctions'

const SHOW_LOGS = false

const emptyArray = []
const emptyObject = {}

// ========================= GROUPS =========================

const userGroupsSelector = groupsData => groupsData.userGroups || emptyArray
const globalGroupsSelector = groupsData => groupsData.globalGroups || emptyArray

// { groupType1: [group1, group2], groupType2: [group3, group4] }
const groupsMapByTypeSelector = createSelector(
    userGroupsSelector,
    userGroups => {
        SHOW_LOGS && console.log('executing reselect: groupsMapByTypeSelector')
        const reduceFunc = (acc, group) => {
            if (!acc[group.groupType]) {
                acc[group.groupType] = []
            }
            acc[group.groupType].push(group)
            return acc
        }
        return userGroups.reduce(reduceFunc, {})
    }
)

export const siteGlobalGroupsSelector = createSelector(
    globalGroupsSelector,
    globalGroups => {
        SHOW_LOGS && console.log('executing reselect: siteGlobalGroupsSelector')
        return globalGroups.filter(x => x.groupType === GROUP_TYPES.SITE_GLOBAL)
    }
)

export const siteGlobalTeacherGroupsSelector = createSelector(
    globalGroupsSelector,
    globalGroups => {
        SHOW_LOGS && console.log('executing reselect: siteGlobalTeacherGroupsSelector')
        return globalGroups.filter(x => x.groupType === GROUP_TYPES.SITE_GLOBAL_TEACHERS)
    }
)

export const siteGlobalStudentGroupsSelector = createSelector(
    globalGroupsSelector,
    globalGroups => {
        SHOW_LOGS && console.log('executing reselect: siteGlobalStudentGroupsSelector')
        return globalGroups.filter(x => x.groupType === GROUP_TYPES.SITE_GLOBAL_STUDENTS)
    }
)

// { group1Id: group1, group2Id: group2 }
export const globalGroupsMapByIdSelector = createSelector(
    globalGroupsSelector,
    (globalGroups) => {
        SHOW_LOGS && console.log('executing reselect: globalGroupsMapByIdSelector')
        const reduceFunc = (acc, group) => {
            acc[group.id] = group
            return acc
        }
        return globalGroups.reduce(reduceFunc, {})
    }
)

// { group1Id: group1, group2Id: group2 }
export const userGroupsMapByIdSelector = createSelector(
    userGroupsSelector,
    (userGroups) => {
        SHOW_LOGS && console.log('executing reselect: userGroupsMapByIdSelector')
        const reduceFunc = (acc, group) => {
            acc[group.id] = group
            return acc
        }
        return userGroups.reduce(reduceFunc, {})
    }
)

// return highest groupId for global groups
export const maxLoadedGlobalGroupIdSelector = createSelector(
    globalGroupsSelector,
    globalGroups => {
        return globalGroups
            .map(x => x.id)
            .reduce(
                (maxGroupId, groupId) => groupId > maxGroupId ? groupId : maxGroupId,
                globalGroups.length > 0 ? globalGroups[0].id : -1)
    }
)

// return highest groupId for user groups
export const maxLoadedUserGroupIdSelector = createSelector(
    userGroupsSelector,
    userGroups => {
        return userGroups
            .map(x => x.id)
            .reduce(
                (maxGroupId, groupId) => groupId > maxGroupId ? groupId : maxGroupId,
                userGroups.length > 0 ? userGroups[0].id : -1)
    }
)

const teacherIdSelector = state => state.teacherId || 0
const ownerIdSelector = state => state.ownerId || 0

// { groupType1: { teacherId1: [group1, group2], teacherId2: [group3, group4] }, groupType2: { teacherId3: [group5, group6] } }
const groupsTeacherMapSelector = createSelector(
    userGroupsSelector,
    userGroups => {
        SHOW_LOGS && console.log('executing reselect: groupsTeacherMapSelector')
        const reduceFunc = (acc, group) => {
            if (!acc[group.userId]) {
                acc[group.userId] = []
            }
            acc[group.userId].push(group)
            return acc
        }

        const groupsTeacherMap = {}
        groupsTeacherMap[GROUP_TYPES.TEACHER_GLOBAL] = getFromDict(
            groupsMapByTypeSelector({ userGroups }),
            GROUP_TYPES.TEACHER_GLOBAL,
            emptyArray
        ).reduce(reduceFunc, {})

        groupsTeacherMap[GROUP_TYPES.TEACHER_CUSTOM] = getFromDict(
            groupsMapByTypeSelector({ userGroups }),
            GROUP_TYPES.TEACHER_CUSTOM,
            emptyArray
        ).reduce(reduceFunc, {})

        groupsTeacherMap[GROUP_TYPES.TEACHER_SINGLE_STUDENT] = getFromDict(
            groupsMapByTypeSelector({ userGroups }),
            GROUP_TYPES.TEACHER_SINGLE_STUDENT,
            emptyArray
        ).reduce(reduceFunc, {})

        return groupsTeacherMap
    }
)

export const teacherGlobalGroupsSelector = createSelector(
    userGroupsSelector,
    teacherIdSelector,
    (userGroups, teacherId) => {
        SHOW_LOGS && console.log('executing reselect: teacherGlobalGroupsSelector')
        return getFromDict(
            getFromDict(
                groupsTeacherMapSelector({ userGroups }),
                GROUP_TYPES.TEACHER_GLOBAL,
                emptyObject
            ),
            teacherId,
            emptyArray
        )
    }
)

export const teacherCustomGroupsSelector = createSelector(
    userGroupsSelector,
    teacherIdSelector,
    (userGroups, teacherId) => {
        SHOW_LOGS && console.log('executing reselect: teacherCustomGroupsSelector')
        return getFromDict(
            getFromDict(
                groupsTeacherMapSelector({ userGroups }),
                GROUP_TYPES.TEACHER_CUSTOM,
                emptyObject
            ),
            teacherId,
            emptyArray
        )
    }
)

export const filterGroupsByDaySelector = createSelector(
    state => state.groups,
    state => state.perDayMap,
    state => state.day,
    (groups, perDayMap, day) => {
        if (day === '' || day === undefined || day === null) {
            return groups
        }
        const idMap = getFromDict(perDayMap, day, {})
        return groups.filter(x => x.id in idMap).map(x => ({
            ...x,
            fromTimeInMinutes: idMap[x.id],
        }))
    }
)

export const teacherSingleStudentGroupsSelector = createSelector(
    userGroupsSelector,
    ownerIdSelector,
    (userGroups, ownerId) => {
        SHOW_LOGS && console.log('executing reselect: teacherSingleStudentGroupsSelector')
        return getFromDict(
            getFromDict(
                groupsTeacherMapSelector({ userGroups }),
                GROUP_TYPES.TEACHER_SINGLE_STUDENT,
                emptyObject
            ),
            ownerId,
            emptyArray
        )
    }
)

// { group1Id: { teacherId: 1, studentId: 3 }, group2Id: { teacherId: 2, studentId: 4 } }
export const singleStudentGroupMap = createSelector(
    userGroupsSelector,
    userGroups => {
        SHOW_LOGS && console.log('executing reselect: singleStudentGroupMap')
        const singleStudentGroups = getFromDict(
            groupsMapByTypeSelector({ userGroups }),
            GROUP_TYPES.TEACHER_SINGLE_STUDENT,
            emptyArray
        )
        const reduceFunc = (acc, group) => {
            const student = (group.users || []).find(x => x.role === USER_TYPES.STUDENT)
            acc[group.id] = {
                teacherId: group.userId,
                studentId: student && student.userId,
                isActive: student && student.isActive,
            }
            return acc
        }
        return singleStudentGroups.reduce(reduceFunc, {})
    }
)

const studentGroupValid = (group, onlyActive) => {
    if (onlyActive && !group.isActive) {
        return false
    }
    let hasStudents = false
    let allActive = true
    group.users.forEach(user => {
        hasStudents = hasStudents || user.role === USER_TYPES.STUDENT
        allActive = allActive && user.isActive
    })
    return hasStudents && (!onlyActive || allActive)
}

const onlyActiveSelector = state => state.onlyActive

export const teacherCustomAndSingleStudentsGroupsSelector = createSelector(
    userGroupsSelector,
    teacherIdSelector,
    onlyActiveSelector,
    (userGroups, teacherId, onlyActive) => {
        SHOW_LOGS && console.log('executing reselect: teacherCustomAndSingleStudentsGroupsSelector')
        const singleStudentGroups = teacherSingleStudentGroupsSelector({ userGroups, ownerId: teacherId }).filter(x => studentGroupValid(x, onlyActive))
        const customGroups = teacherCustomGroupsSelector({ userGroups, teacherId })
        return singleStudentGroups.concat(onlyActive ? customGroups.filter(x => x.isActive) : customGroups)
    }
)

// ========================= USERS =========================

const usersSelector = groupsData => groupsData.users || emptyArray
const studentsSelector = groupsData => groupsData.students || emptyArray

// { user1Id: user1, user2Id: user2 }
export const usersMapByIdSelector = createSelector(
    usersSelector,
    users => {
        SHOW_LOGS && console.log('executing reselect: usersMapByIdSelector')
        const reduceFunc = (acc, user) => {
            acc[user.id] = user
            return acc
        }
        return users.reduce(reduceFunc, {})
    }
)

const groupIdSelector = state => state.groupId || 0

// takes custom group id or student group id, finds teacher/owner for that group
// and gets that teachers global group
export const globalGroupOfGroupOwnerSelector = createSelector(
    userGroupsSelector,
    groupIdSelector,
    (userGroups, groupId) => {
        SHOW_LOGS && console.log('executing reselect: globalGroupOfGroupOwnerSelector')
        const group = getFromDict(userGroupsMapByIdSelector({ userGroups }), groupId)
        return teacherGlobalGroupsSelector({ userGroups, teacherId: group.userId })[0]
    }
)

// ========================= STUDENTS =========================

// list of { teacherId, students: { student1Id: student1 , student2Id: student2 } }
// where eg student1 is merged data of groupsData['students'][teacher1Id][student1Id]
// and groupsData['users'].find(x => x.id === student1Id)
export const studentsMapByTeacherIdAndStudentIdSelector = createSelector(
    usersSelector,
    studentsSelector,
    (users, students) => {
        SHOW_LOGS && console.log('executing reselect: studentsMapByTeacherIdAndStudentIdSelector')
        const newTeacherStudentsMap = students.reduce((teachersMap, x) => {
            const teacherId = x.teacherId
            const newStudentsMap = Object.keys(x.students || {}).reduce((studentsMap, studentId) => {
                const userData = getFromDict(usersMapByIdSelector({ users }), studentId)
                if (userData) {
                    const leaderboardParticipationByUser = userData.leaderboardParticipation
                    studentsMap[studentId] = {
                        ...userData,
                        ...getFromDict(x.students, studentId),
                        leaderboardParticipationByUser
                    }
                }
                return studentsMap
            }, {})
            teachersMap[teacherId] = newStudentsMap
            return teachersMap
        }, {})
        return newTeacherStudentsMap
    }
)

// { teacher1Id: [student1, student2], teacher2Id: [student3, student4] }
// where eg student1 is merged data of groupsData['students'][teacher1Id][student1Id]
// and groupsData['users'].find(x => x.id === student1Id)
export const studentsMapByTeacherIdSelector = createSelector(
    usersSelector,
    studentsSelector,
    (users, students) => {
        SHOW_LOGS && console.log('executing reselect: studentsMapByTeacherIdSelector')
        const reduceFunc = (acc, teacherId) => {
            if (!acc[teacherId]) {
                acc[teacherId] = []
            }
            const teacherStudents = students.find(x => x.teacherId === teacherId)
            const studentsMap = teacherStudents ? teacherStudents.students : {}
            acc[teacherId].push(
                ...Object.keys(studentsMap)
                    .map(studentId =>
                        getFromDict(
                            getFromDict(
                                studentsMapByTeacherIdAndStudentIdSelector({ users, students }),
                                teacherId,
                                {}
                            ),
                            studentId
                        )
                    )
                    .filter(x => !!x)
            )
            return acc
        }
        return students.map(x => x.teacherId).reduce(reduceFunc, {})
    }
)

export const groupStudentList = createSelector(
    userGroupsSelector,
    usersSelector,
    studentsSelector,
    groupIdSelector,
    state => state.sort,
    (userGroups, users, students, groupId, sort) => {
        SHOW_LOGS && console.log('executing reselect: groupStudentList')
        const group = getFromDict(userGroupsMapByIdSelector({ userGroups }), groupId)
        const groupUsers = getFromDict(group, 'users', [])
            .filter(x => x.isActive && x.role === USER_TYPES.STUDENT)
            .map(user => getFromDict(
                getFromDict(
                    studentsMapByTeacherIdAndStudentIdSelector({ users, students }),
                    group.userId,
                    {}
                ),
                user.userId,
                {}
            ))
        return !sort ? groupUsers : sortUsers(groupUsers)
    }
)

export const studentRankingsByGroupIdSelector = createSelector(
    userGroupsSelector,
    usersSelector,
    studentsSelector,
    groupIdSelector,
    (userGroups, users, students, groupId) => {
        SHOW_LOGS && console.log('executing reselect: studentRankingsByGroupIdSelector')
        return groupStudentList({ userGroups, users, students, groupId })
            .filter(x => x.leaderboardParticipation && x.leaderboardParticipationByUser)
            .sort(function(x, y) {
                return x.points < y.points ? 1 : -1
            })
    }
)

// ========================= TEACHERS =========================

const teacherIdsSelector = createSelector(
    usersSelector,
    (users) => {
        SHOW_LOGS && console.log('executing reselect: teacherIdsSelector')
        return users.filter(x => x.role === USER_TYPES.TEACHER).map(x => x.id)
    }
)

// { teacher1Id: teacher1 , teacher2Id: teacher2 }
export const teachersMapById = createSelector(
    usersSelector,
    (users) => {
        SHOW_LOGS && console.log('executing reselect: teachersMapById')
        const reduceFunc = (acc, teacherId) => {
            acc[teacherId] = getFromDict(usersMapByIdSelector({ users }), teacherId)
            return acc
        }
        const teacherIds = teacherIdsSelector({ users })
        return teacherIds.reduce(reduceFunc, {})
    }
)

export const teacherDataListSelector = createSelector(
    usersSelector,
    users => {
        SHOW_LOGS && console.log('executing reselect: teacherDataListSelector')
        const teacherIds = teacherIdsSelector({ users })
        return teacherIds.map(x => getFromDict(teachersMapById({ users }), x))
    }
)

export const groupsByScheduleDaySelector = createSelector(
    userGroupsSelector,
    userGroups => {
        SHOW_LOGS && console.log('executing reselect: groupsByScheduleDaySelector')
        const groupsByDay = {}
        userGroups.forEach(userGroup => {
            userGroup.schedules && userGroup.schedules.forEach(schedule => {
                if (schedule.isActive) {
                    let dayIndex = -1
                    if (schedule.specificDate) {
                        const specificDateMoment = getMomentDateFromJson(schedule.specificDate).local()
                        const today = getMomentDate(new Date()).set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
                        const endDate = getMomentDate(new Date()).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(6, 'days')
                        if (specificDateMoment.isSameOrAfter(today) && specificDateMoment.isSameOrBefore(endDate)) {
                            dayIndex = specificDateMoment.day() + 1
                        }
                    }
                    else {
                        dayIndex = getDayIndexFromDayString(schedule.dayOfWeek) + 1
                    }

                    if (dayIndex !== -1) {
                        groupsByDay[dayIndex] =  groupsByDay[dayIndex] || {}
                        groupsByDay[dayIndex][userGroup.id] = schedule.fromTimeInMinutes || -1 // if time is not specificed should be first on list
                    }
                }
            })
        })

        return groupsByDay
    }
)