import React from 'react'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}
  
const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        fontSize: 16
    },
    margin: theme.spacing(1),
}))

function MySnackbarContentWrapper(props) {
    const classes = useStyles()
    const { message, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]
  
    return (
        <SnackbarContent
            className={clsx(classes[variant], classes.margin)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={clsx(classes.message, classes.text)}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    )
}

export default class CustomizedSnackbars extends React.Component {
    constructor(props) {
        super(props)
        this.state = { show: false, message: '', messageType: '' }
    }

    alertWithType(messageType, message) {
        this.setState({ show: true, message, messageType })
    }

    handleClose = () => {
        this.setState({ show: false, message: '' })
    }

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.show}
                autoHideDuration={5000}
                onClose={this.handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleClose}
                    variant={this.state.messageType}
                    message={this.state.message}
                />
            </Snackbar>
        )
    }
}