import React from 'react'

export const StudentProfileConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/student-profile',
            component: React.lazy(() => import('./StudentProfile')),
            applicationPath: ['StudentProfileEdit'],
        }
    ]
}