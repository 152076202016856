import { texti18n, Messages } from 'dpn-common/utils/MessageConstants'

let messageService

function init(messageServiceRef) {
    messageService = messageServiceRef
}

function ask(message, onPressOk, cancelBtnText = texti18n(Messages.CANCEL), confirmBtnText = texti18n(Messages.OK), confirmBtnColor = 'red') {
    messageService.showMessage(texti18n(Messages.ASK_DIALOG_TITLE), message, onPressOk, cancelBtnText, confirmBtnText, confirmBtnColor)
}

function infoAlert(message, confirmBtnText = texti18n(Messages.OK)) {
    messageService.showMessage(texti18n(Messages.INFORMATION), message, undefined, undefined, confirmBtnText)
}

function errorAlert(message, confirmBtnText = texti18n(Messages.OK)) {
    messageService.showMessage(texti18n(Messages.ERROR), message, undefined, undefined, confirmBtnText)
}

function shareInviteToStudent(bodyMessage) {
    // const confirmBtnText = texti18n(Messages.OK)
    // const message = bodyMessage ? bodyMessage.replace(/<[^>]+>/ig, '').replace(/^\s+|\s+$/g, '') : ''
    // messageService.showMessage(texti18n(Messages.INFORMATION), message, undefined, undefined, confirmBtnText)
}

export default {
    init,
    ask,
    infoAlert,
    errorAlert,
    shareInviteToStudent,
}