import ActionTypes from '../actions/ActionTypes'
import {
    setDictionaryState
} from '../utils/HelperFunctions'
import { USER_TYPES } from '../utils/AppConstants'

const baseInitialState = {
    pianoAdventuresBooks: undefined,
    flashCards: undefined,
    videoTutorials: undefined,
    teacherVideoTutorials: undefined,
    // { studentId1: { groupId1: [skill1, skill2], groupId2: [skill3]}, studentId2: { groupId3: [skill4, skill5] } }
    // if student logged in, teacherId instead of studentId
    // for now 
    studentSkills: undefined,
    // { studentId1: { groupId1: [reward1, reward2], groupId2: [reward3]}, studentId2: { groupId3: [reward4, reward5] } }
    // if student logged in, teacherId instead of studentId
    studentRewards: undefined,
    auth: {
        isInitializing: true,
        isLoggedIn: false,
        role: USER_TYPES.GUEST,
        userData: { role: USER_TYPES.GUEST }
    },
    homeScreenAccessTimes: 0,
    externalLoginFrom: undefined,
}

export const mergeUserDataRedux = (oldUserData, newUserData) => ({
    ...oldUserData,
    ...newUserData,
    firstName: newUserData.firstName,
    lastName: newUserData.lastName,
    email: newUserData.email,
    username: newUserData.username,
})

function baseReducer(state = baseInitialState, action) {
    switch (action.type) {
    case ActionTypes.SET_PIANO_ADVENTURES_BOOKS:
        return Object.assign({}, state, {
            pianoAdventuresBooks: action.pianoAdventuresBooks
        })
    case ActionTypes.SET_FLASH_CARDS_ACTION:
        return setDictionaryState(state, 'flashCards', action.groupId, action.flashCards)
    case ActionTypes.LOG_IN_ACTION:
        return Object.assign({}, state, {
            auth: {
                isLoggedIn: true,
                userData: mergeUserDataRedux(state.auth.userData, action.userData),
                role: action.userData.role,
                isInitializing: false,
            }
        })
    case ActionTypes.LOG_OUT_ACTION:
        return Object.assign({}, state, {
            auth: {
                isLoggedIn: false,
                role: USER_TYPES.GUEST,
                userData: { role: USER_TYPES.GUEST },
                isInitializing: false,
            },
            homeScreenAccessTimes: 0,
        })
    case ActionTypes.CHANGE_USER_DATA_ACTION:
        return Object.assign({}, state, {
            auth: {
                ...state.auth,
                userData: mergeUserDataRedux(state.auth.userData, action.userData)
            }
        })
    case ActionTypes.CHANGE_LEADERBOARD_PARTICIPATION:
        return Object.assign({}, state, {
            auth: {
                ...state.auth,
                userData: {
                    ...state.auth.userData,
                    leaderboardParticipation: action.leaderboardParticipation
                }
            }
        })
    case ActionTypes.SET_STUDENT_SKILLS:
    {
        let tempState = state
        if (state.studentSkills === undefined) {
            tempState = setDictionaryState(state, 'studentSkills', action.userId, {})
        }
        const groupedSkills = groupByGroupId(action.skills || [], action.groupIds)
        let innerDictState = tempState.studentSkills
        Object.keys(groupedSkills).forEach(groupId => {
            innerDictState = setDictionaryState(innerDictState, action.userId, groupId, groupedSkills[groupId])
        })
        const newState = setDictionaryState(state, 'studentSkills', action.userId, innerDictState[action.userId])
        return newState
    }

    case ActionTypes.SET_STUDENT_REWARDS:
    {
        let tempState = state
        if (state.studentRewards === undefined) {
            tempState = setDictionaryState(state, 'studentRewards', action.userId, {})
        }
        
        const groupedRewards = groupByGroupId(action.rewards || [], action.groupIds)
        let innerDictState = tempState.studentRewards
        Object.keys(groupedRewards).forEach(groupId => {
            innerDictState = setDictionaryState(innerDictState, action.userId, groupId, groupedRewards[groupId])
        })
        return setDictionaryState(state, 'studentRewards', action.userId, innerDictState[action.userId])
    }
    case ActionTypes.SET_VIDEO_TUTORIALS:
        return Object.assign({}, state, {
            videoTutorials: action.videoTutorials
        })
    case ActionTypes.SET_TEACHER_VIDEO_TUTORIALS:
        return Object.assign({}, state, {
            teacherVideoTutorials: action.videoTutorials
        })
    case ActionTypes.HOME_SCREEN_ACCESS_TIMES_INC:
        return Object.assign({}, state, {
            homeScreenAccessTimes: state.homeScreenAccessTimes + 1
        })
    case ActionTypes.EXTERNAL_LOGIN_FROM:
        return Object.assign({}, state, {
            externalLoginFrom: action.from
        })
    default:
        return state
    }
}

export default baseReducer

function groupByGroupId(items, groupIds) {
    const itemsReduceFunc = (acc, cv) => {
        if (!acc[cv.item.groupId]) {
            acc[cv.item.groupId] = []
        }
        acc[cv.item.groupId].push(cv)
        return acc
    }
    const itemsMap = items.reduce(itemsReduceFunc, {})
    return groupIds && groupIds.length > 0
        ?
        groupIds.reduce((acc, cv) => {
            acc[cv] = itemsMap[cv] || []
            return acc
        }, {})
        :
        itemsMap
}