import { NavigatorService } from '../services/SharedServices'
import { USER_TYPES, GROUP_FETCH_PARAMS, GROUP_TYPES, StudentChooseTeacherRouteName } from './AppConstants'
import { getFromDict, reduxUpdateWait } from './HelperFunctions'
import { getMessagesInGroupFetch, getGroupedMessagesFetch } from '../actions/PrivateMessageActions'
import { chooseTeacher } from '../actions/StudentActions'
import { ACTION_NOTIFICATION_TYPES } from './NotificationsConstants'
import { loadGroupsDataForStudentFetch, loadGroupsDataForTeacherFetch } from '../actions/GroupActions'
import { loadStudentSkillsFetch } from '../actions/SkillAction'
import {
    getTeacherDataList, getUserGroupDataByGroupId,
    getGlobalGroupDataByGroupId, isSingleStudentOrCustomGroupActive, getGlobalGroupOfGroupOwner,
} from './GroupsDataUtils'
import { loadLessonsForGroupsFetch } from 'dpn-common/actions/LessonActions'
import { Messages } from './MessageConstants'
import { loadAssignmentsFetch } from 'dpn-common/actions/AssignmentActions'

const _getTeacherUserGroupByGroupId = async (userGroups, groupId, userData, dispatch) => {
    let group = getUserGroupDataByGroupId(userGroups, groupId)
    if (isSingleStudentOrCustomGroupActive(group)) {
        return group
    }

    await loadGroupsDataForTeacherFetch({
        loadUsers: true,
        loadSingleStudentGroups: true,
        loadSchedules: true,
    }, dispatch, userData)

    await reduxUpdateWait()

    group = getUserGroupDataByGroupId(userGroups, groupId)
    return isSingleStudentOrCustomGroupActive(group) ? group : undefined
}

const _getStudentUserGroupByGroupId = async (userGroups, groupId, teacherId, dispatch) => {
    let group = getUserGroupDataByGroupId(userGroups, groupId)
    if (isSingleStudentOrCustomGroupActive(group)) {
        return group
    }

    await loadGroupsDataForStudentFetch({
        loadUsers: true,
        loadSingleStudentGroups: true,
        loadSchedules: true,
    }, dispatch, teacherId)

    await reduxUpdateWait()

    group = getUserGroupDataByGroupId(userGroups, groupId)
    return isSingleStudentOrCustomGroupActive(group) ? group : undefined
}

const _getTeacherGlobalGroupByGroupId = async (globalGroups, groupId, userData, dispatch) => {
    let group = getGlobalGroupDataByGroupId(globalGroups, groupId)
    if (group && group.isActive) {
        return group
    }

    await loadGroupsDataForTeacherFetch({
        loadGlobalGroups: true,
        loadUsers: true,
        loadSingleStudentGroups: true,
        loadSchedules: true,
    }, dispatch, userData)

    await reduxUpdateWait()

    group = getGlobalGroupDataByGroupId(globalGroups, groupId)
    return group && group.isActive ? group : undefined
}

const _getStudentGlobalGroupByGroupId = async (globalGroups, groupId, teacherId, dispatch) => {
    let group = getGlobalGroupDataByGroupId(globalGroups, groupId)
    if (group && group.isActive) {
        return group
    }

    await loadGroupsDataForStudentFetch({
        loadGlobalGroups: true,
        loadUsers: true,
        loadSingleStudentGroups: true,
        loadSchedules: true,
    }, dispatch, teacherId)

    await reduxUpdateWait()

    group = getGlobalGroupDataByGroupId(globalGroups, groupId)
    return group && group.isActive ? group : undefined
}

const _studentAcceptedInvitationHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        await loadGroupsDataForTeacherFetch(GROUP_FETCH_PARAMS, dispatch, userData)
        return true
    },
    jumpOnAction: async () => {
        const currentRoute = NavigatorService.getCurrentRoute()
        if (!currentRoute || !currentRoute.name) {
            console.log('<NotificationService> - Can not resolve current route!')
            return false
        }
        else if (currentRoute.name !== 'TeacherStudentList') {
            NavigatorService.navigateAndReset('TeacherStudentList')
            return true
        }
    }
})

const _teacherNewMessageHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        const currentRoute = NavigatorService.getCurrentRoute()
        return currentRoute.name !== 'TeacherChat' ||
            !currentRoute.params || !currentRoute.params.groupIds ||
            !actionData.groupId || !currentRoute.params.groupIds.some(x => x == actionData.groupId)
    },
    processAction: async (actionData, { userGroups, groupMessages, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getTeacherUserGroupByGroupId(userGroups, groupId, userData, dispatch)
        if (!group) {
            console.log('<NotificationService - new message> Group not found')
            return false
        }
        const currentRoute = NavigatorService.getCurrentRoute()
        if (currentRoute.name === 'TeacherCommunicationChat') {
            await getGroupedMessagesFetch(undefined, undefined, 0, dispatch)
        }
        const messages = getFromDict(groupMessages, actionData['groupId'])
        await getMessagesInGroupFetch(actionData['groupId'], messages, false, false, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { userGroups, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getTeacherUserGroupByGroupId(userGroups, groupId, userData, dispatch)
        if (!group) {
            console.log('<NotificationService - new message> Group not found')
            return false
        }
        NavigatorService.navigateAndReset('TeacherChat', {
            groupIds: [groupId],
            sendMessageDisabled: false,
            showSenderData: true,
        }, groupId)
        return true
    }
})

const _teacherNewChannelMessageHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        const currentRoute = NavigatorService.getCurrentRoute()
        return currentRoute.name !== 'TeacherChat' ||
            !currentRoute.params || !currentRoute.params.groupIds ||
            !actionData.groupId || !currentRoute.params.groupIds.some(x => x == actionData.groupId)
    },
    processAction: async (actionData, { globalGroups, groupMessages, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getTeacherGlobalGroupByGroupId(globalGroups, groupId, userData, dispatch)
        if (!group) {
            console.log('<NotificationService> - New channel message> Group not found')
            return false
        }
        const currentRoute = NavigatorService.getCurrentRoute()
        if (currentRoute.name === 'TeacherCommunicationChannels') {
            await getGroupedMessagesFetch(undefined, GROUP_TYPES.SITE_GLOBAL_TEACHERS, 0, dispatch)
        }
        const messages = getFromDict(groupMessages, actionData['groupId'])
        await getMessagesInGroupFetch(actionData['groupId'], messages, false, false, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { globalGroups, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getTeacherGlobalGroupByGroupId(globalGroups, groupId, userData, dispatch)
        if (!group) {
            console.log('<NotificationService> - New channel message> Group not found')
            return false
        }
        NavigatorService.navigateAndReset('TeacherChat', {
            groupIds: [groupId],
            sendMessageDisabled: true
        }, groupId)
        return true
    }
})

const _studentInvitedHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { teacherId, dispatch }) => {
        await loadGroupsDataForStudentFetch(GROUP_FETCH_PARAMS, dispatch, teacherId)
        return true
    },
    jumpOnAction: async () => {
        const currentRoute = NavigatorService.getCurrentRoute()
        if (!currentRoute || !currentRoute.name) {
            console.log('<NotificationService> - Can not resolve current route')
            return false
        }
        const shouldJump = currentRoute.name !== StudentChooseTeacherRouteName
        if (shouldJump) {
            NavigatorService.navigateAndReset(StudentChooseTeacherRouteName)
        }
        return shouldJump
    }
})

const _studentNewMessageHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        const currentRoute = NavigatorService.getCurrentRoute()
        return currentRoute.name !== 'StudentChat' ||
            !currentRoute.params || !currentRoute.params.groupIds ||
            !actionData.groupId || !currentRoute.params.groupIds.some(x => x == actionData.groupId)
    },
    processAction: async (actionData, { userGroups, groupMessages, teacherId, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getStudentUserGroupByGroupId(userGroups, groupId, teacherId, dispatch)
        if (!group) {
            console.log('<NotificationService - new message> Group not found')
            return false
        }
        const currentRoute = NavigatorService.getCurrentRoute()
        if (currentRoute.name === 'StudentCommunicationChat') {
            await getGroupedMessagesFetch(undefined, undefined, 0, dispatch)
        }
        const messages = getFromDict(groupMessages, actionData['groupId'])
        await getMessagesInGroupFetch(actionData['groupId'], messages, false, false, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { userGroups, users, userData, teacherId, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }
        const groupId = parseInt(actionData['groupId'])
        const teachers = getTeacherDataList(users)
        const group = await _getStudentUserGroupByGroupId(userGroups, groupId, teacherId, dispatch)
        if (!group) {
            console.log('<NotificationService - new message> Group not found')
            return false
        }
        const teacher = await getStudentTeacher(teacherId, teachers)
        if (!teacher) {
            console.log('<NotificationService> - New message> Teacher not found')
            return false
        }
        await chooseTeacher(teacher, userData.id, dispatch)
        NavigatorService.navigateAndReset('StudentChat', {
            groupIds: [groupId],
            sendMessageDisabled: false,
            showSenderData: true,
        }, actionData['groupId'])
        return true
    }
})

const _studentNewChannelMessageHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        const currentRoute = NavigatorService.getCurrentRoute()
        return currentRoute.name !== 'StudentChat' ||
            !currentRoute.params || !currentRoute.params.groupIds ||
            !actionData.groupId || !currentRoute.params.groupIds.some(x => x == actionData.groupId)
    },
    processAction: async (actionData, { globalGroups, groupMessages, teacherId, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getStudentGlobalGroupByGroupId(globalGroups, groupId, teacherId, dispatch)
        if (!group) {
            console.log('<NotificationService> - New channel message> Group not found')
            return false
        }
        const currentRoute = NavigatorService.getCurrentRoute()
        if (currentRoute.name === 'StudentCommunicationChannels') {
            await getGroupedMessagesFetch(undefined, GROUP_TYPES.SITE_GLOBAL_STUDENTS, 0, dispatch)
        }
        const messages = getFromDict(groupMessages, actionData['groupId'])
        await getMessagesInGroupFetch(actionData['groupId'], messages, false, false, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { globalGroups, teacherId, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        const groupId = parseInt(actionData['groupId'])
        const group = await _getStudentGlobalGroupByGroupId(globalGroups, groupId, teacherId, dispatch)
        if (!group) {
            console.log('<NotificationService> - New channel message> Group not found')
            return false
        }
        NavigatorService.navigateAndReset('StudentChat', {
            groupIds: [groupId],
            sendMessageDisabled: true
        }, groupId)
        return true
    }
})

const _skillAddedToStudentHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        await loadStudentSkillsFetch(false, [actionData['groupId']], actionData['teacherId'], userData.id, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { users, userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }
        const teachers = getTeacherDataList(users)
        const teacherId = parseInt(actionData['teacherId'])
        const teacher = await getStudentTeacher(teacherId, teachers)
        if (!teacher) {
            console.log('<NotificationService> - Student skills Added> Teacher not found')
            return false
        }
        if (teacher.isInvited) {
            NavigatorService.navigateAndReset(StudentChooseTeacherRouteName)
        }
        else {
            await chooseTeacher(teacher, userData.id, dispatch)
            NavigatorService.navigateAndReset('StudentTeacherSkills', { groupId: parseInt(actionData['groupId']) })
        }
        return true
    }
})

const _skillRemovedFromStudentHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        await loadStudentSkillsFetch(false, [actionData['groupId']], actionData['teacherId'], userData.id, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { users, userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }
        const teachers = getTeacherDataList(users)
        const teacherId = parseInt(actionData['teacherId'])
        const teacher = await getStudentTeacher(teacherId, teachers)
        if (!teacher) {
            console.log('<NotificationService> - Student skills Removed> Teacher not found')
            return false
        }
        if (teacher.isInvited) {
            NavigatorService.navigateAndReset(StudentChooseTeacherRouteName)
        }
        else {
            await chooseTeacher(teacher, userData.id, dispatch)
            NavigatorService.navigateAndReset('StudentTeacherSkills', { groupId: parseInt(actionData['groupId']) })
        }
        return true
    }
})


const _lessonAddedToStudentHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }

        await loadLessonsForGroupsFetch(parseInt(actionData['schoolYearId']), [parseInt(actionData['groupId'])], dispatch, true)

        return true
    },
    jumpOnAction: async (actionData, { users, userData, dispatch }) => {
        if (actionData['groupId'] === undefined ||
            actionData['teacherId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }
        const teachers = getTeacherDataList(users)
        const teacherId = parseInt(actionData['teacherId'])
        const teacher = await getStudentTeacher(teacherId, teachers)
        if (!teacher) {
            console.log('<NotificationService> - Student lesson Added> Teacher not found')
            return false
        }
        if (teacher.isInvited) {
            NavigatorService.navigateAndReset(StudentChooseTeacherRouteName)
        }
        else {
            await chooseTeacher(teacher, userData.id, dispatch)
            NavigatorService.navigateAndReset('StudentTeacherLessonList', { groupId: parseInt(actionData['groupId']) })
        }
        return true
    }
})

const _studentNewAssignmentCommentHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        await loadAssignmentsFetch(parseInt(actionData['assignmentGroupId']), true, USER_TYPES.STUDENT, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { users, userGroups, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }

        const groupId =  parseInt(actionData['groupId'])
        const teachers = getTeacherDataList(users)
        const group = userGroups.find(x => x.id === groupId)
        const teacherId = group.users.find(x => x.role === USER_TYPES.TEACHER).userId

        const teacher = await getStudentTeacher(teacherId, teachers)

        if (!teacher) {
            console.log('<NotificationService> - Student assignment comment Added> Teacher not found')
            return false
        }
        if (teacher.isInvited) {
            NavigatorService.navigateAndReset(StudentChooseTeacherRouteName)
        }
        else {
            await chooseTeacher(teacher, userData.id, dispatch)

            const assignmentId = parseInt(actionData['assignmentId'])
            const globalTeacherGroup = getGlobalGroupOfGroupOwner(userGroups, groupId)
            const commentGroupId = globalTeacherGroup.id 
           
            NavigatorService.navigate('AssignmentInfo', {
                    itemId: assignmentId,
                    commentGroupId: commentGroupId,
                    studentGroupId: groupId,
                    backMessageTitle: Messages.BACK_TO_HOME,
            })
        }
        return true
    }
})

const _teacherNewAssignmentCommentHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => {
        return true
    },
    processAction: async (actionData, { userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        await loadAssignmentsFetch(parseInt(actionData['assignmentGroupId']), true, USER_TYPES.TEACHER, dispatch)
        return true
    },
    jumpOnAction: async (actionData, { users, userGroups, userData, dispatch }) => {
        if (actionData['groupId'] === undefined) {
            console.log('<NotificationService> - Invalid notification action data')
            return false
        }
        if (!users) {
            return
        }

        const groupId = parseInt(actionData['groupId'])
        const assignmentId = parseInt(actionData['assignmentId'])
        const globalTeacherGroup = getGlobalGroupOfGroupOwner(userGroups, groupId)
        const commentGroupId = globalTeacherGroup.id 
        
        NavigatorService.navigate('AssignmentInfo', {
            itemId: assignmentId,
            commentGroupId: commentGroupId,
            studentGroupId: groupId,
            backMessageTitle: Messages.BACK_TO_HOME,
        })
        return true
    }
})

const _guestActionHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => true,
    processAction: async (actionData, reduxParams) => true,
    jumpOnAction: async (actionData, reduxParams) => {
        NavigatorService.navigateAndReset('Login')
        return true
    }
})

const _dummyActionHandler = Object.freeze({
    shouldDisplayLocalNotification: (actionData, reduxParams) => true,
    processAction: async (actionData, reduxParams) => false,
    jumpOnAction: async (actionData, reduxParams) => false
})

const _studentActionHandlers = Object.freeze({
    [ACTION_NOTIFICATION_TYPES.STUDENT_INVITED]: _studentInvitedHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_MESSAGE]: _studentNewMessageHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_CHANNEL_MESSAGE]: _studentNewChannelMessageHandler,
    [ACTION_NOTIFICATION_TYPES.SKILL_ADDED_TO_STUDENT]: _skillAddedToStudentHandler,
    [ACTION_NOTIFICATION_TYPES.SKILL_REMOVED_FROM_STUDENT]: _skillRemovedFromStudentHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_LESSON]: _lessonAddedToStudentHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_ASSIGNMENT_COMMENT]: _studentNewAssignmentCommentHandler,
})

const _teacherActionHandlers = Object.freeze({
    [ACTION_NOTIFICATION_TYPES.STUDENT_ACCEPTED_INVITATION]: _studentAcceptedInvitationHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_MESSAGE]: _teacherNewMessageHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_CHANNEL_MESSAGE]: _teacherNewChannelMessageHandler,
    [ACTION_NOTIFICATION_TYPES.NEW_ASSIGNMENT_COMMENT]: _teacherNewAssignmentCommentHandler,
})

const _userActionHandlersMap = Object.freeze({
    [USER_TYPES.TEACHER]: _teacherActionHandlers,
    [USER_TYPES.STUDENT]: _studentActionHandlers,
})

function getActionHandler(role, notificationActionType) {
    let result = false
    if (role == USER_TYPES.GUEST) {
        result = _guestActionHandler
    }
    else if (role in _userActionHandlersMap) {
        const handlers = _userActionHandlersMap[role]
        if (notificationActionType in handlers) {
            result = handlers[notificationActionType]
        }
        else {
            console.log('<NotificationActionHandler> Invalid notification action type: ' + notificationActionType)
        }
    }
    else if (role !== USER_TYPES.GUEST) {
        console.log('<NotificationActionHandler> Invalid user type: ' + role)
    }
    return result || _dummyActionHandler
}

export default {
    getActionHandler,
}

async function getStudentTeacher(teacherId, teachers) {
    let teacher = undefined
    if (teachers) {
        teacher = teachers.find(x => x.id === teacherId)
    }
    return teacher
}