import React from 'react'
import Provider from 'react-redux/es/components/Provider'
import { Router } from 'react-router-dom'
import jssExtend from 'jss-extend'
import history from '@history'
import store from './store'
import AppContext from './AppContext'
import { routes, routeMappings, routeReverseMappings } from './fuse-configs/routesConfig'
import { create } from 'jss'
import { StylesProvider, jssPreset, createGenerateClassName } from '@material-ui/styles'
import ConnectivityHelper from 'dpn-common/utils/ConnectivityHelper'

import SettingsService from 'dpn-common/services/SettingsService'
import AppContainer from 'components/AppContainer'
import NavigatorService from './services/common/NavigatorService'
import VideoPlayerHelper from 'dpn-common/utils/VideoUtils/VideoPlayerHelper'
import SentryService from './services/common/SentryService'
import NotificationsHelper from 'dpn-common/utils/NotificationsHelper'
import NotificationsService from './services/common/NotificationsService'
import { init as initChatHelper } from 'dpn-common/utils/ChatHelperFunctions'
import ChatHelperComponents from './components/Chat/ChatHelperComponents'
import SharedServices from 'dpn-common/services/SharedServices'
import AlertService from './services/common/AlertService'
import AsyncStorageService from './services/common/AsyncStorageService'
import LinkingService from './services/common/LinkingService'
import MessageUIService from './services/common/MessageUIService'
import ConnectivityService from './services/common/ConnectivityService'
import PlatformService from './services/common/PlatformService'
import VideoPlayerService from './services/common/VideoPlayerService'
import { createFetchServices } from 'dpn-common/utils/FetchServicesFactory'
import AnalyticsService from 'services/AnalyticsService'
import FirebaseService from 'services/common/FirebaseService'

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
    insertionPoint: document.getElementById('jss-insertion-point'),
})

const generateClassName = createGenerateClassName()

SharedServices.init(AlertService, AsyncStorageService, LinkingService, MessageUIService, 
    NavigatorService, ConnectivityService, PlatformService, SentryService, AnalyticsService, FirebaseService)

createFetchServices(store.getState)

SettingsService.init(store.getState)
// NavigatorService must be initialized before others (SentryService, ConnectivityHelper)
NavigatorService.init(history, routeMappings, routeReverseMappings, routes)
VideoPlayerHelper.init(VideoPlayerService, () => undefined, () => undefined)
SentryService.init()
ConnectivityHelper.init()
NotificationsHelper.init(NotificationsService)
initChatHelper(ChatHelperComponents)
FirebaseService.init()

const routesObj = { routes }
class App extends React.Component {
    componentWillUnmount() {
        ConnectivityHelper.dispose()
        SharedServices.dispose()
    }

    render() {
        return (
            <AppContext.Provider value={routesObj}>
                <StylesProvider jss={jss} generateClassName={generateClassName}>
                    <Provider store={store}>
                        <Router
                            history={history}>
                            <AppContainer/>
                        </Router>
                    </Provider>
                </StylesProvider>
            </AppContext.Provider>
        )
    }
}

export default App