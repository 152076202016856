import React from 'react'
import { DialogActions, DialogTitle, Button, DialogContentText, Dialog, DialogContent } from '@material-ui/core'
import { Messages, texti18n } from 'dpn-common/utils/MessageConstants'

class DialogMessage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            content: '',
            onPressOk: null,
            dialogTitle: '',
            hasCancelBtn: false,
            cancelBtnText: '',
            confirmBtnText: '',
            confirmBtnColor: ''
        }
    }

    showMessage(dialogTitle, message, onPressOk, cancelBtnText, confirmBtnText, confirmBtnColor) {
        const hasCancelBtn = onPressOk !== undefined
        if (!onPressOk) {
            onPressOk = () => { }
        }
        this.setState({ open: true, content: message, onPressOk, dialogTitle, hasCancelBtn, cancelBtnText, confirmBtnText, confirmBtnColor })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleConfirm = () => {
        this.setState({ open: false })
        this.state.onPressOk()
    }

    render() {
        const confirmBtnStyle = this.state.confirmBtnText !== texti18n(Messages.OK) ? { backgroundColor: this.state.confirmBtnColor } : undefined
        return (
            <Dialog
                onEscapeKeyDown={this.handleClose}
                open={this.state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.state.hasCancelBtn &&
                        <Button
                            className="normal-case"
                            variant="contained"
                            color="default"
                            onClick={this.handleClose}
                        >
                            {this.state.cancelBtnText}
                        </Button>
                    }
                    <Button
                        className="normal-case"
                        variant="contained"
                        color="secondary"
                        style={confirmBtnStyle}
                        autoFocus
                        onClick={this.handleConfirm}
                    >
                        {this.state.confirmBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DialogMessage